import React from 'react';
import PropTypes from 'prop-types';

import Toolbar from 'react-big-calendar/lib/Toolbar';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import TodayIcon from '@material-ui/icons/Today';

import translation from '../../translation/translation';

class ScheduleToolbar extends Toolbar {

	constructor(props) {
		super(props);

		this.state = {
			isToolbarMenuOpen: false
		}

		this.handleToggleToolbarMenu = this.handleToggleToolbarMenu.bind(this);
		this.handleCloseToolbarMenu = this.handleCloseToolbarMenu.bind(this);
    this.handleChangeViewToDay = this.handleChangeViewToDay.bind(this);
    this.handleChangeViewToMonth = this.handleChangeViewToMonth.bind(this);
    this.handleChangeViewToWeek = this.handleChangeViewToWeek.bind(this);
    this.handleNavigatePrev = this.handleNavigatePrev.bind(this);
    this.handleNavigateNext = this.handleNavigateNext.bind(this);
    this.handleNavigateToday = this.handleNavigateToday.bind(this);
	}

	handleToggleToolbarMenu() {

    if (this.state.isToolbarMenuOpen)
      this.setState({ isToolbarMenuOpen: false });
    else
      this.setState({ isToolbarMenuOpen: true });
	}

  handleCloseToolbarMenu() {
    this.setState({ isToolbarMenuOpen: false });
	}

  handleNavigatePrev() {
    this.props.onNavigate('PREV');
  }

  handleNavigateNext() {
    this.props.onNavigate('NEXT');
  }

  handleNavigateToday() {
    this.props.onNavigate('TODAY');
  }

  handleChangeViewToDay() {
    this.props.onViewChange('day');
  }

  handleChangeViewToMonth() {
    this.props.onViewChange('month');
  }

  handleChangeViewToWeek() {
    this.props.onViewChange('week');
  }

	render() {

    const {
      view,
      label,
      messages
    } = this.props;

    const { isToolbarMenuOpen } = this.state;

    return (
      <div className="schedule-toolbar">
        <div className="col-sm-12 col-md-4 col-toolbar col-nav-desktop">
          <Fab
            size="small"
            color="default"
            aria-label="Prev"
            className="btn-view"
            onClick={this.handleNavigatePrev}
          >
            <span className="icon-chevron_gauche btn-prev" />
          </Fab>
          <Fab
            size="small"
            color="default"
            aria-label="Next"
            className="btn-view"
            onClick={this.handleNavigateNext}
          >
            <span className="icon-chevron_droite btn-next" />
          </Fab>
          <Button
            color="default"
            variant="contained"
            size="small"
            aria-label="Today"
            className="btn-view"
            onClick={this.handleNavigateToday}
          >
						{messages.today}
          </Button>
          <div
            className="btn-menu"
            tabIndex={0}
            onClick={this.handleToggleToolbarMenu}
            onBlur={this.handleCloseToolbarMenu}
          >
            <span className={"icon-infos btn-menu-icon " + (isToolbarMenuOpen ? "active" : "")}  />
            { isToolbarMenuOpen &&
              <div className="legends">
                <ul>
                  <li className="legend">
                    <p><span className="square-color archived"/> {translation().schedule.calendar.legend.archived}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color inc"/> {translation().schedule.calendar.legend.planned}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color availability"/> {translation().schedule.calendar.legend.availability}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color unavailability"/> {translation().schedule.calendar.legend.unavailability}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color todo"/> {translation().schedule.calendar.legend.todo}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color cart"/> {translation().schedule.calendar.legend.cart}</p>
                  </li>
                </ul>
              </div>
            }
					</div>
        </div>
        <div className="col-sm-12 col-md-3 col-toolbar col-nav-mobile">
          <Fab
            size="small"
            color="default"
            aria-label="Prev"
            className="btn-view"
            onClick={this.handleNavigatePrev}
          >
            <span className="icon-chevron_gauche btn-prev" />
          </Fab>
          <Button
            color="default"
            variant="contained"
            size="small"
            aria-label="Today"
            className="btn-view btn-today"
            onClick={this.handleNavigateToday}
          >
						<TodayIcon fontSize="small"/>
          </Button>
          <Fab
            size="small"
            color="default"
            aria-label="Next"
            className="btn-view"
            onClick={this.handleNavigateNext}
          >
            <span className="icon-chevron_droite btn-next" />
          </Fab>
          <div
            className="btn-menu"
            tabIndex={0}
            onClick={this.handleToggleToolbarMenu}
            onBlur={this.handleCloseToolbarMenu}
          >
            <span className={"icon-infos btn-menu-icon " + (isToolbarMenuOpen ? "active" : "")}  />
            {
              isToolbarMenuOpen &&
              <div className="legends">
                <ul>
                  <li className="legend">
                    <p><span className="square-color archived"/> {translation().schedule.calendar.legend.archived}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color inc"/> {translation().schedule.calendar.legend.planned}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color availability"/> {translation().schedule.calendar.legend.availability}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color unavailability"/> {translation().schedule.calendar.legend.unavailability}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color todo"/> {translation().schedule.calendar.legend.todo}</p>
                  </li>
                  <li className="legend">
                    <p><span className="square-color cart"/> {translation().schedule.calendar.legend.cart}</p>
                  </li>
                </ul>
              </div>
            }
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-toolbar">
					<div className="toolbar-flex">
						<p className="toolbar-label">{label}</p>
					</div>
        </div>
        <div className="col-sm-12 col-md-4 col-toolbar align-right">
          <Button
            color={view && view === "month" ? "primary" : "default"}
            variant="contained"
            size="small"
            aria-label="month"
            className="btn-view"
            onClick={this.handleChangeViewToMonth}
          >
            {messages.month}
          </Button>
          <Button
            color={view && view === "week" ? "primary" : "default"}
            variant="contained"
            size="small"
            aria-label="week"
            className="btn-view"
            onClick={this.handleChangeViewToWeek}
          >
            {messages.week}
          </Button>
          <Button
            color={view && view === "day" ? "primary" : "default"}
            variant="contained"
            size="small"
            aria-label="day"
            className="btn-view"
            onClick={this.handleChangeViewToDay}
          >
            {messages.day}
          </Button>
        </div>
        <div className="clearfix"/>
      </div>
    );
	}
}

ScheduleToolbar.propTypes = {
  messages: PropTypes.shape({
    previous: PropTypes.string,
    next: PropTypes.string,
    today: PropTypes.string,
    month: PropTypes.string,
    week: PropTypes.string,
    day: PropTypes.string,
    agenda: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
  }).isRequired,
	onNavigate: PropTypes.func.isRequired,
	onViewChange: PropTypes.func.isRequired,
	view: PropTypes.string.isRequired,
	label: PropTypes.any.isRequired,
}

export default ScheduleToolbar;
