import React from 'react';

import translation from '../../translation/translation';

function CovidCharterView() {
  return (
    <div className="container">
      <div className="covid-charter-view">
        <h3>{translation().covid.title}</h3>
        <p>
          {translation().covid.description}
        </p>
        <i>
          {translation().covid.description_2}
        </i>
        <br/>
        <br/>
        <p>
          {translation().covid.respect.title}
        </p>
        <ul>
          <li>
            <b>{translation().covid.respect.list[0]}</b> {translation().covid.respect.list[1]}
          </li>
          <li>
            {translation().covid.respect.list[2]}
          </li>
          <li>
            {translation().covid.respect.list[3]}
          </li>
          <li>
            {translation().covid.respect.list[4]}
          </li>
        </ul>
        <p>
          {translation().covid.reflexs.title}
        </p>
        <ul>
          <li>
            {translation().covid.reflexs.list[0]}
          </li>
          <li>
            {translation().covid.reflexs.list[1]}
          </li>
          <li>
            {translation().covid.reflexs.list[2]}
          </li>
        </ul>
        <br/>
        <p>
          {translation().covid.cancel}
        </p>
      </div>
    </div>
  );
}

export default CovidCharterView;
