import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TouchAppIcon from '@material-ui/icons/TouchApp';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';

import { getUserTimezone } from '../../../helpers/functions/user';
import { pad } from '../../../helpers/functions/utils';

import translation from '../../../translation/translation';

class ScheduleEventWeek extends PureComponent {

  constructor() {
    super();
    this.handleButtonPress = this.handleButtonPress.bind(this)
    this.handleButtonRelease = this.handleButtonRelease.bind(this)
  }

  handleButtonPress() {
    const {
      event,
    } = this.props;

    if (event.readOnly) return;

    this.buttonPressTimer = setTimeout(() => {
      if (event && event.type === "availability" && event.handleOpenCreateUnavailability) {
        event.handleOpenCreateUnavailability(event);
      }
    }, 600);
  }

  handleButtonRelease () {
    clearTimeout(this.buttonPressTimer);
  }

  render() {

    const {
      event
    } = this.props;

    const startHour = pad(event.start.getHours());
    const startMinutes = pad(event.start.getMinutes());
    const endHour = pad(event.end.getHours());
    const endMinutes = pad(event.end.getMinutes());

    return (
      <div className={event.className}>
        { event.product && event.product.status && event.product.status.icon_url_dark &&
          <img
            src={event.product.status.icon_url_dark}
            alt={event.product.status.name}
            className="status-img"
          />
        }
        { event.type === "orderProduct" && event.timezone_code && (getUserTimezone() !== event.timezone_code) &&
          <ErrorSharpIcon className="timezone-warning" />
        }
        <div
          className="event-content"
          onTouchStart={this.handleButtonPress}
          onTouchEnd={this.handleButtonRelease}
          onMouseDown={this.handleButtonPress}
          onMouseUp={this.handleButtonRelease}
          onMouseLeave={this.handleButtonRelease}
        >
          { event.type === "unavailability"
            ? <p className="event-title">{event.title ? event.title : translation().schedule.unavailability.unavailability}</p>
            : <p className="event-title">{ event.product && event.product.name ? event.product.name : (event.title ? event.title : '')}</p>
          }
          { event.type === "availability" &&
            <div className="long-touch-helper">
              <TouchAppIcon fontSize="small" />
              <span>{translation().schedule.unavailability.add}</span>
            </div>
          }
          <p className="event-times">{startHour}:{startMinutes} - {endHour}:{endMinutes}</p>
          { event.product && event.product.order && event.product.order.property && event.product.order.property.address &&
            <p className="event-address">{event.product.order.property.address}</p>
          }
        </div>
      </div>
    );
  }
}

ScheduleEventWeek.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.any,
    style: PropTypes.shape(),
    handleOpenCreateUnavailability: PropTypes.func,
    readOnly: PropTypes.bool,
    product: PropTypes.any,
    type: PropTypes.string,
    start: PropTypes.any,
    end: PropTypes.any,
    className: PropTypes.string,
    timezone_code: PropTypes.string
  }).isRequired,
}

export default ScheduleEventWeek;
