export const getOrderPaymentMethods = () => ({
  type: "GET_ORDER_PAYMENT_METHODS",
});

export const getOrderPaymentMethodsSuccess = (data) => ({
  type: "GET_ORDER_PAYMENT_METHODS_SUCCESS",
  data,
});

export const getOrderPaymentMethodsFailure = (error) => ({
  type: "GET_ORDER_PAYMENT_METHODS_FAILURE",
  error,
});

export const editOrderPaymentMethod = () => ({
  type: "EDIT_ORDER_PAYMENT_METHOD",
});

export const editOrderPaymentMethodSuccess = () => ({
  type: "EDIT_ORDER_PAYMENT_METHOD_SUCCESS",
});

export const editOrderPaymentMethodFailure = (error) => ({
  type: "EDIT_ORDER_PAYMENT_METHOD_FAILURE",
  error,
});
