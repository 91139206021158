/* eslint-disable react/self-closing-comp */

import React from 'react';

function InvoiceMandate() {

  return (
    <div className="cgs-mandate">
      <p className="p3"><b>1.<span className="Apple-tab-span">	</span></b><span className="s1"><b>OBJET</b></span></p>
      <p className="p3"><span className="s1">Par la présente, le Mandant, professionnel indépendant exerçant son activité sous le statut d’auto-entrepreneur ou sous forme de société, et en tout état de cause disposant d’un numéro de SIREN, donne mandat au Mandataire, à savoir :</span></p>
      <p className="p4"><span className="s2">SAS Wall-Market<br/>
      </span>22 rue Legendre 75017 Paris<br/>RCS Paris 532 727 740 RCS</p>
      <p className="p3"><span className="s1">aux fins d’émettre en son nom et pour son compte les factures relatives aux prestations réalisées pour le compte de Wall-Market.</span></p>
      <p className="p5">Le présent mandat de facturation est confié au Mandataire au moment de son acceptation de la « Convention d’utilisation d’un Compte », après avoir coché la case correspondante.</p>
      <p className="p3"><b>2. <span className="Apple-tab-span">	</span></b><span className="s1"><b>ENGAGEMENTS DU MANDANT</b></span></p>
      <p className="p3"><span className="s1">Le Mandant conserve l’entière responsabilité de ses obligations légales et fiscales en matière de facturation au titre des factures originales émises au nom et pour le compte du Mandant en application de la présente convention, notamment en ce qui concerne les obligations en matière de TVA.</span></p>
      <p className="p3"><span className="s1">En conséquence, le Mandant prend expressément l’engagement de :</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Déclarer auprès de l’administration fiscale la TVA collectée au moment de son éligibilité,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Verser au trésor public la TVA mentionnée sur les factures émises au titre du présent mandat de facturation,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Réclamer immédiatement le double de la facture si celle-ci n’a pas été mise à sa disposition par le Mandataire,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Signaler sans délai par écrit au Mandataire toute modification dans les mentions relatives à l’identification de son entreprise ; et</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Signaler au Mandataire, dans un délai d’un mois à compter de la date de communication d’une facture, toute contestation relative au contenu de la facture.</span></p>
      <p className="p3"><b>3. <span className="Apple-tab-span">	</span></b><span className="s1"><b>DROIT APPLICABLE ET CHOIX DE JURIDICTION</b></span></p>
      <p className="p3"><span className="s1">Le présent mandat est régi par et soumis au droit français.<span className="Apple-converted-space"> </span></span></p>
      <p className="p3"><span className="s1">Les parties attribuent une compétence exclusive aux juridictions de Paris (France) pour tout litige lié au présent Mandat.</span></p>
      <p className="p6"><span className="s1"><b>BON POUR MANDAT</b></span></p>
    </div>
  )
}

export default InvoiceMandate;
