export const storeTabOpen = tab => ({
  type: "STORE_TAB_OPEN",
  tab
});

export const toggleOrdersListMode = mode => ({
  type: "TOGGLE_ORDERS_LIST_MODE",
  mode
});

export const openOrder = id => ({
  type: "OPEN_ORDER",
  id
});

export const closeOrder = () => ({
  type: "CLOSE_ORDER"
});

export const getOrders = (next = null) => ({
  type: "GET_ORDERS",
  next
});

export const getOrdersSuccess = (data, paging) => ({
  type: "GET_ORDERS_SUCCESS",
  data, paging
});

export const getOrdersFailure = error => ({
  type: "GET_ORDERS_FAILURE",
  error
});

export const updateOrders = (data, paging) => ({
  type: "UPDATE_ORDERS",
  data, paging
});

export const clearMediaGroups = () => ({
  type: 'CLEAR_MEDIA_GROUPS'
});

export const getProductMediaGroups = () => ({
  type: 'GET_ORDER_PRODUCT_MEDIA_GROUPS'
});

export const getProductMediaGroupsSuccess = data => ({
  type: 'GET_ORDER_PRODUCT_MEDIA_GROUPS_SUCCESS',
  data
});

export const getProductMediaGroupsFailure = error => ({
  type: 'GET_ORDER_PRODUCT_MEDIA_GROUPS_FAILURE',
  error
});

export const postOrderProductMedias = () => ({
  type: "POST_ORDER_PRODUCT_MEDIAS"
});

export const postOrderProductMediasSuccess = () => ({
  type: "POST_ORDER_PRODUCT_MEDIAS_SUCCESS"
});

export const postOrderProductMediasFailure = error => ({
  type: "POST_ORDER_PRODUCT_MEDIAS_FAILURE",
  error
});
