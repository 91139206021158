/* eslint-disable react/self-closing-comp */

import React from 'react';

function InvoiceMandate() {

  return (
    <div className="cgs-mandate">
      <p className="p3"><b>1.<span className="Apple-tab-span">	</span></b><span className="s1"><b>OBJETO</b></span></p>
      <p className="p3"><span className="s1">Por la presente, el Mandante, profesional por cuenta propia que ejerce su actividad en calidad de trabajador autónomo o bajo la forma de sociedad,
      y que en todo caso dispone de un número de SIREN o SIRET o un número de registro asignado por la administración del país en el que tiene su sede social, otorga por la presente un poder al Mandatario, a saber:</span></p>
      <p className="p4"><span className="s2">SAS Wall-Market<br/>
      </span>22 rue Legendre 75017 Paris<br/>RM París número 532 727 740</p>
      <p className="p3"><span className="s1">con el fin de emitir en su nombre y por su cuenta facturas relativas a los servicios prestados en nombre de Wall-Market.</span></p>
      <p className="p5">Este mandato de facturación se otorga al Mandatario cuando acepta el «Acuerdo de uso de Cuenta», tras haber marcado la casilla de validación correspondiente.</p>
      <p className="p3"><b>2. <span className="Apple-tab-span">	</span></b><span className="s1"><b>COMPROMISOS DEL MANDANTE</b></span></p>
      <p className="p3"><span className="s1">El Mandante conserva la plena responsabilidad de sus obligaciones legales y fiscales en materia de facturación sobre las facturas originales emitidas en nombre y por cuenta del Mandante en aplicación del presente acuerdo, en particular en lo que respecta a las obligaciones en materia de IVA.</span></p>
      <p className="p3"><span className="s1">Por consiguiente, el Mandante se compromete expresamente a:</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Declarar a las autoridades fiscales (o a cualquier autoridad competente en función del registro y/o residencia) el IVA recaudado en el momento de la elegibilidad,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Pagar a la administración competente el IVA mencionado en las facturas emitidas en virtud del presente mandato de facturación,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Reclamar inmediatamente el duplicado de la factura si no ha sido facilitado por el Mandatario,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Notificar al Mandatario por escrito y de inmediato cualquier cambio en los datos de identificación de su empresa sin demora; y</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Notificar al Mandatario, dentro del mes siguiente a la fecha de envío de una factura, cualquier conflicto relativo al contenido de esta.</span></p>
      <p className="p3"><b>3. <span className="Apple-tab-span">	</span></b><span className="s1"><b>DERECHO APLICABLE Y TRIBUNAL COMPETENTE</b></span></p>
      <p className="p3"><span className="s1">El presente mandato se rige por el Derecho francés.</span></p>
      <p className="p3"><span className="s1">Las partes atribuyen la competencia exclusiva a los tribunales de París (Francia) para cualquier conflicto relativo a este Mandato.</span></p>
      <p className="p6"><span className="s1"><b>VISTO BUENO</b></span></p>
    </div>
  )
}

export default InvoiceMandate;
