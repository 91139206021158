import React, { Component } from "react";
import PropTypes from "prop-types";

import { List, Apps, Close } from "@material-ui/icons";
import {
  AppBar,
  Dialog,
  DialogContent,
  Switch,
  CircularProgress,
  Tabs,
  Tab,
  Slide,
  Toolbar,
  IconButton,
} from "@material-ui/core";

import SearchForm from "./forms/search/SearchForm";
import { handleInfiniteScroll } from "../../helpers/functions/jquery";
import ToggleLabel from "../../components/commons/ToggleLabel";
import OrderCard from "./order/OrderCard";
import OrderTable from "./order/OrderTable";
import OrderContainer from "../../containers/order/OrderContainer";
import translation from "../../translation/translation";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: "card",
      orderSelected: null,
    };

    this.handleCloseOrder = this.handleCloseOrder.bind(this);
    this.handleChangeViewModeToCard = this.handleChangeViewModeToCard.bind(
      this
    );
    this.handleChangeViewModeToTable = this.handleChangeViewModeToTable.bind(
      this
    );
    this.handleChangeProviderMode = this.handleChangeProviderMode.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleOpenOrder = this.handleOpenOrder.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchOrders = this.handleSearchOrders.bind(this);
    this.handleOpenOrderDialog = this.handleOpenOrderDialog.bind(this);
    this.handleCloseOrderDialog = this.handleCloseOrderDialog.bind(this);
    this.handleUploadOrderDialogSuccess = this.handleUploadOrderDialogSuccess.bind(
      this
    );

    this.renderOrders = this.renderOrders.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      getOrders,
      history,
      setTabOpen,
      toggleOrdersListMode,
      orders: { tabOpen },
      user,
    } = this.props;

    const tabs = ["#planned", "#done", "#processed", "#in_progress"];

    const mode =
      user && user.data && user.data.company_admin && user.data.company
        ? "provider-companies"
        : "providers";

    // TODO: Remove this block of code on product owner validation
    // if (mode === "provider-companies") {
    //   this.handleChangeViewModeToTable();
    // }

    /**
     * Tabs micro routing
     */
    if (history && history.location && history.location.hash) {
      if (tabs.indexOf(history.location.hash) >= 0) {
        const tab = history.location.hash.substring(1);
        setTabOpen(tab);
        getOrders(
          null,
          mode,
          tab,
          () => {
            if (mode === "provider-companies") {
              toggleOrdersListMode("provider-companies");
            }
          },
          () => {
            if (mode === "provider-companies") {
              toggleOrdersListMode("provider-companies");
            }
          }
        );
      }
    } else if (tabOpen) {
      getOrders(
        null,
        mode,
        tabOpen,
        () => {
          if (mode === "provider-companies") {
            toggleOrdersListMode("provider-companies");
          }
        },
        () => {
          if (mode === "provider-companies") {
            toggleOrdersListMode("provider-companies");
          }
        }
      );
    } else {
      getOrders(
        null,
        mode,
        tabOpen,
        () => {
          if (mode === "provider-companies") {
            toggleOrdersListMode("provider-companies");
          }
        },
        () => {
          if (mode === "provider-companies") {
            toggleOrdersListMode("provider-companies");
          }
        }
      );
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    const { clearMediaGroups } = this.props;

    clearMediaGroups();
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const {
      getOrders,
      orders: { orderOpen, paging, loading },
    } = this.props;

    if (handleInfiniteScroll(null, 200)) {
      if (paging && paging.next) {
        if (!loading && !orderOpen) getOrders(paging.next);
      }
    }
  }

  handleChangeViewModeToCard() {
    this.setState({
      viewMode: "card",
    });
  }

  handleChangeViewModeToTable() {
    this.setState({
      viewMode: "table",
    });
  }

  handleChangeProviderMode(e, value) {
    const {
      clearSearchForm,
      getOrders,
      toggleOrdersListMode,
      orders: { loading, mode, orderOpen, tabOpen },
    } = this.props;

    if (loading || orderOpen) return;

    if (mode && mode === "providers") {
      if (!value) return;

      clearSearchForm();

      getOrders(
        null,
        "provider-companies",
        tabOpen,
        () => {
          toggleOrdersListMode("provider-companies");
        },
        () => {
          toggleOrdersListMode("provider-companies");
        }
      );
    } else if (mode && mode === "provider-companies") {
      if (value) return;

      clearSearchForm();

      getOrders(
        null,
        "providers",
        tabOpen,
        () => {
          toggleOrdersListMode("providers");
        },
        () => {
          toggleOrdersListMode("providers");
        }
      );
    }
  }

  handleChangeTab(event, tabOpen) {
    const {
      getOrders,
      clearSearchForm,
      setTabOpen,
      orders: { mode },
    } = this.props;

    setTabOpen(tabOpen);
    window.location.hash = tabOpen;

    clearSearchForm();

    getOrders(null, mode, tabOpen);
  }

  handleSearchOrders(values) {
    const {
      searchOrders,
      orders: { loading, mode, tabOpen },
    } = this.props;

    if (!loading)
      searchOrders(values && values.search ? values.search : "", mode, tabOpen);
  }

  handleOpenOrder(id) {
    const {
      orders: { loading },
      openOrder,
    } = this.props;

    if (id && !loading) openOrder(id);
  }

  handleCloseOrder() {
    const {
      clearMediaGroups,
      closeOrder,
      orders: { upload_action },
    } = this.props;

    if (upload_action.isLoading) return;

    closeOrder();
    clearMediaGroups();
  }

  handleOpenOrderDialog(orderProductId) {
    this.setState({
      orderSelected: orderProductId,
    });
  }

  handleCloseOrderDialog() {
    this.setState({
      orderSelected: null,
    });
  }

  handleUploadOrderDialogSuccess() {
    const {
      getOrders,
      orders: { mode, tabOpen },
    } = this.props;

    getOrders(null, mode, tabOpen);
    this.handleCloseOrderDialog();
  }

  renderTable(orders) {
    const { user } = this.props;

    return (
      <OrderTable
        orders={orders}
        user={user}
        onSelectOrderProduct={this.handleOpenOrderDialog}
      />
    );
  }

  renderOrders(orders) {
    if (!orders) return;

    const {
      clearMediaGroups,
      getProductMediaGroups,
      getOrders,
      orderProductMedias,
      orders: {
        tabOpen,
        orderOpen,
        loading,
        mode,
        mediaGroups,
        upload_action,
        rating,
      },
      uploadOrder,
      user,
      rateClient,
      provider,
    } = this.props;

    const { viewMode } = this.state;

    if (viewMode === "table") {
      return this.renderTable(orders);
    } else {
      return (
        <ul className="orders-list">
          {orders.map((data, i) => {
            return (
              <OrderCard
                key={i}
                isLoading={loading}
                data={data}
                orderOpen={orderOpen}
                onOpenOrder={this.handleOpenOrder}
                onCloseOrder={this.handleCloseOrder}
                clearMediaGroups={clearMediaGroups}
                getProductMediaGroups={getProductMediaGroups}
                getOrders={getOrders}
                mediaGroups={mediaGroups}
                mode={mode}
                orderProductMedias={orderProductMedias}
                uploadAction={upload_action}
                uploadOrder={uploadOrder}
                tabOpen={tabOpen}
                user={user}
                rating={rating}
                rateClient={rateClient}
                provider={provider}
              />
            );
          })}
        </ul>
      );
    }
  }

  render() {
    const { orderSelected } = this.state;
    const {
      orders: { tabOpen, orderOpen, list, loading, mode, upload_action },
      user,
    } = this.props;

    return (
      <div className="App-view Orders">
        {orderOpen && (
          <div className="order-overlay" onClick={this.handleCloseOrder} />
        )}
        {upload_action.isLoading && (
          <div className="upload-order-overlay">
            <div className="center-loader">
              <CircularProgress color="primary" size={65} />
            </div>
          </div>
        )}
        <div className="page-title">
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-4">
              {/* <div className="view-mode">
                <p>Affichage: </p>
                <div
                  className={
                    "view-mode-icon" +
                    (this.state.viewMode === "table" ? " active" : "")
                  }
                  onClick={this.handleChangeViewModeToTable}
                >
                  <List />
                </div>
                <div
                  className={
                    "view-mode-icon" +
                    (this.state.viewMode === "card" ? " active" : "")
                  }
                  onClick={this.handleChangeViewModeToCard}
                >
                  <Apps />
                </div>
              </div> */}
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h3>{translation().orders.page_title}</h3>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-search">
              <SearchForm onSubmit={this.handleSearchOrders} />
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="tabs-container">
          <AppBar position="relative" color="default" elevation={0}>
            <Tabs
              centered={true}
              value={tabOpen}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              scrollButtons="on"
            >
              <Tab
                value="planned"
                label={
                  <span className="tab-label">
                    {translation().orders.tab_titles.planned}
                  </span>
                }
                icon={<div className="tab-icon icon-avenir" />}
                disabled={loading}
              />
              <Tab
                value="in_progress"
                label={
                  <span className="tab-label">
                    {translation().orders.tab_titles.inProgress}
                  </span>
                }
                icon={<div className="tab-icon icon-encours" />}
                disabled={loading}
              />
              <Tab
                value="done"
                label={
                  <span className="tab-label">
                    {translation().orders.tab_titles.done}
                  </span>
                }
                icon={<div className="tab-icon icon-upload" />}
                disabled={loading}
              />
              <Tab
                value="processed"
                label={
                  <span className="tab-label">
                    {translation().orders.tab_titles.processed}
                  </span>
                }
                icon={<div className="tab-icon icon-comment04" />}
                disabled={loading}
              />
            </Tabs>
          </AppBar>
        </div>
        <div className="orders-wrapper">
          <div className="section-controls">
            {user &&
            user.data &&
            user.data.company_admin &&
            user.data.company ? (
              <div className="toggle-mode">
                <ToggleLabel
                  label={translation().orders.switch.personal}
                  value={false}
                  onClick={this.handleChangeProviderMode}
                />
                <Switch
                  checked={mode === "providers" ? false : true}
                  value={mode}
                  color="default"
                  disabled={loading}
                  onChange={this.handleChangeProviderMode}
                />
                <ToggleLabel
                  label={translation().orders.switch.pro}
                  value={true}
                  onClick={this.handleChangeProviderMode}
                />
              </div>
            ) : (
              false
            )}
          </div>
          {list && list.length > 0 ? (
            this.renderOrders(list)
          ) : !loading ? (
            <div className="data-empty">{translation().orders.no_order}</div>
          ) : (
            ""
          )}
          {loading && (
            <div className="center-loader">
              <CircularProgress color={"secondary"} size={35} />
            </div>
          )}
        </div>
        <Dialog
          fullScreen
          onClose={this.handleCloseOrderDialog}
          open={orderSelected !== null}
          TransitionComponent={Transition}
        >
          <DialogContent style={{ padding: 0, backgroundColor: "#1A2123" }}>
            <AppBar position="sticky">
              <Toolbar className="order-product-dialog-toolbar">
                <IconButton
                  onClick={this.handleCloseOrderDialog}
                  aria-label="Close"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <OrderContainer
              orderProductId={orderSelected}
              onUploadSuccess={this.handleUploadOrderDialogSuccess}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Orders.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  clearSearchForm: PropTypes.func.isRequired,
  closeOrder: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape(),
  }).isRequired,
  orders: PropTypes.shape({
    tabOpen: PropTypes.string.isRequired,
    orderOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
    paging: PropTypes.shape(),
    mode: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    rating: PropTypes.shape({
      isLoading: PropTypes.bool,
    }).isRequired,
    upload_action: PropTypes.shape(),
    mediaGroups: PropTypes.shape(),
  }),
  orderProductMedias: PropTypes.shape(),
  openOrder: PropTypes.func.isRequired,
  rateClient: PropTypes.func.isRequired,
  searchOrders: PropTypes.func.isRequired,
  setTabOpen: PropTypes.func.isRequired,
  toggleOrdersListMode: PropTypes.func.isRequired,
  uploadOrder: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  provider: PropTypes.shape().isRequired,
};

export default Orders;
