import translation from '../../../translation/translation';

const validate = values => {

  const errors = {};

  if (!values.email)
    errors.email = translation().forms.errors.required;
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
    errors.email = translation().forms.errors.email_invalid;

  if (!values.password)
    errors.password = translation().forms.errors.required;

  if (values.password && values.password.length < 8)
    errors.password = translation().forms.errors.password_length;

  if (!values.confirm_password)
    errors.confirm_password = translation().forms.errors.required;

  if (values.password && values.confirm_password) {
    if (values.password !== values.confirm_password)
      errors.confirm_password = translation().forms.errors.password_not_matching;
  }

  if (!values.last_name)
    errors.last_name = translation().forms.errors.required;

  if (!values.date_of_birth)
    errors.date_of_birth = translation().forms.errors.required;

  if (!values.first_name)
    errors.first_name = translation().forms.errors.required;

  if (!values.mobile)
    errors.mobile = translation().forms.errors.required;

  return errors;
}

export default validate;
