import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import translation from '../../translation/translation';

const LsLink = props => <a href="https://lightshop.wall-market.com" {...props}>{ props.children }</a>

LsLink.propTypes = {
  children: PropTypes.node
}

const RefreshLink = props => <a href="/" {...props}>{ props.children }</a>

RefreshLink.propTypes = {
  children: PropTypes.node
}

const RestrictedArea = () => {

  return (
    <div className="Restricted-area">
      <div className="container">
        <h1>{translation().pages.restricted_area.title}</h1>
        <p>{translation().pages.restricted_area.text}</p>
        <Button
          variant="contained"
          color="primary"
          component={LsLink}
        >
          {translation().pages.restricted_area.back_to_ls}
        </Button>
        <Button
          variant="outlined"
          style={{marginLeft: 10}}
          component={RefreshLink}
        >
          {translation().pages.restricted_area.back}
        </Button>
      </div>
    </div>
  );
}

export default RestrictedArea;
