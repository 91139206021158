import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Fab from '@material-ui/core/Fab';

// import LinkUsersForm from '../forms/users/LinkUsersForm';
import User from "./User";
import translation from "../../../translation/translation";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInviteUserDialogOpen: false,
      isDetailsOpen: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    // this.handleLinkCompanyUsers = this.handleLinkCompanyUsers.bind(this);
    // this.handleOpenInviteUserDialog = this.handleOpenInviteUserDialog.bind(this);
    // this.handleCloseInviteUserDialog = this.handleCloseInviteUserDialog.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getCompanyUsers } = this.props;

    getCompanyUsers();
  }

  handleScroll(event) {
    const {
      getCompanyUsers,
      users: { paging, isLoading },
    } = this.props;

    if (!event || !event.target) return;

    if (!event.target.scrollTop || event.target.scrollTop === 0) return;

    if (
      event.target.scrollHeight - event.target.clientHeight - 20 <=
      event.target.scrollTop
    ) {
      if (paging && paging.next) {
        if (!isLoading) {
          getCompanyUsers(paging.next);
        }
      }
    }
  }

  // handleOpenInviteUserDialog() {
  //
  //   this.setState({
  //     isInviteUserDialogOpen: true
  //   });
  // }
  //
  // handleCloseInviteUserDialog() {
  //
  //   this.setState({
  //     isInviteUserDialogOpen: false
  //   });
  // }
  //
  // handleLinkCompanyUsers(values) {
  //
  //   const {
  //     linkCompanyUsers
  //   } = this.props;
  //
  //   linkCompanyUsers(
  //     values,
  //     this.handleCloseInviteUserDialog
  //   );
  // }

  renderCompanyUsersTable(data) {
    const {
      getCompanyUsers,
      adminizeCompanyUser,
      unlinkCompanyUser,
      userLogged,
    } = this.props;

    if (data && data.length > 0) {
      return (
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {translation().company.members.table.last_name}
                </TableCell>
                <TableCell>
                  {translation().company.members.table.first_name}
                </TableCell>
                <TableCell align="right">
                  {translation().company.members.table.admin}
                </TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((user) => {
                return (
                  <User
                    key={user.id}
                    adminizeCompanyUser={adminizeCompanyUser}
                    getCompanyUsers={getCompanyUsers}
                    unlinkCompanyUser={unlinkCompanyUser}
                    userLogged={userLogged}
                    {...user}
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {
    const {
      users: {
        list,
        isLoading,
        // users_actions
      },
    } = this.props;

    // const {
    //   isInviteUserDialogOpen
    // } = this.state;

    return (
      <div className="members" style={{ marginTop: 25 }}>
        {/* <div className="section-controls" style={{textAlign: 'right'}}>
          <Fab
            color="secondary"
            style={{marginLeft: 'auto'}}
            onClick={this.handleOpenInviteUserDialog}
            aria-label="Invit"
            size="medium"
          >
            <span className="icon-ajout_membre" style={{fontSize: 25}} />
          </Fab>
        </div> */}
        <div
          style={{ width: "100%", overflow: "auto", maxHeight: 500 }}
          onScroll={this.handleScroll}
        >
          {list && list.length > 0 ? (
            this.renderCompanyUsersTable(list)
          ) : !isLoading ? (
            <div className="data-empty error-message">
              {translation().company.members.no_member}
            </div>
          ) : (
            ""
          )}
        </div>
        {isLoading && (
          <div className="center-loader">
            <CircularProgress color={"secondary"} size={35} />
          </div>
        )}
        {/* <Dialog
          aria-labelledby="invite-company-user-dialog"
          onClose={this.handleCloseInviteUserDialog}
          open={isInviteUserDialogOpen}
          disableEscapeKeyDown={false}
          disableBackdropClick={false}
          scroll={"paper"}
          >
          <DialogTitle id="invite-company-user-dialog">
            {translation().company.members.dialogs.link.title}
          </DialogTitle>
          <DialogContent className="invite-user_dialog">
            <p>{translation().company.members.dialogs.link.description}</p>
            <LinkUsersForm
          onSubmit={this.handleLinkCompanyUsers}
          labels={translation().company.members.labels}
          usersActions={users_actions}
          buttonLabel={translation().company.members.buttons.link.submit}
            />
          </DialogContent>
          <DialogActions>
            <Button
          color="default"
          onClick={this.handleCloseInviteUserDialog}
            >
          {translation().company.members.buttons.link.close}
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

Users.propTypes = {
  // linkCompanyUsers: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  adminizeCompanyUser: PropTypes.func.isRequired,
  unlinkCompanyUser: PropTypes.func.isRequired,
  userLogged: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }).isRequired,
  users: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape(),
  }).isRequired,
};

export default Users;
