import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { stopSubmit } from 'redux-form';

import { fetchApi } from '../../helpers/functions/api';
import { apiPostUserAddress, apiPutUserAddress } from '../../helpers/functions/user';

import {
  toggleInvoicesEmittedListMode, togglePaymentMethodsMode, toggleAddressesMode, toggleInvoicesListMode,
  getProviderInvoiceQueue, getProviderInvoiceQueueSuccess, getProviderInvoiceQueueFailure, updateProviderInvoiceQueue,
  getProviderInvoices, getProviderInvoicesSuccess, getProviderInvoicesFailure, updateProviderInvoices,
  getPaymentMethods, getPaymentMethodsSuccess, getPaymentMethodsFailure, updatePaymentMethods,
  deletePaymentMethod, deletePaymentMethodSuccess, deletePaymentMethodFailure,
  postAddress, postAddressSuccess, postAddressFailure,
  deleteAddress,deleteAddressSuccess, deleteAddressFailure,
  editAddress, editAddressSuccess, editAddressFailure,
} from '../../redux/actions/invoicesActions';
import { getCompanyUsers, updateCompanyUsers, getCompanyUsersSuccess, getCompanyUsersFailure } from '../../redux/actions/companyActions';
import { getUserSuccess } from '../../redux/actions/userActions';
import { enqueueSnackbar } from '../../redux/actions/notificationsActions';

import config from '../../config';
import translation from '../../translation/translation';

import Invoices from '../../views/invoices/Invoices';

const mapStateToProps = (state) => ({
  invoices: state.invoices,
  company: state.company,
  user: state.user
});

const mapDispatchToProps = dispatch => {

  const getUser = (callBackSuccess, callBackFailure) => {

    fetchApi(
      "get",
      "users/self",
      null,
      null,
      null,
      user => {
        dispatch(getUserSuccess(user));

        if (callBackSuccess && typeof callBackSuccess === 'function')
          callBackSuccess();
      },
      () => {
        if (callBackFailure && typeof callBackFailure === 'function')
          callBackFailure();
      }
    );
  }

  return {
    toggleInvoicesEmittedListMode(mode) {

      if (!mode) return;

      dispatch(toggleInvoicesEmittedListMode(mode));
    },
    toggleInvoicesListMode(mode) {

      if (!mode) return;

      dispatch(toggleInvoicesListMode(mode));
    },
    togglePaymentMethodsMode(mode) {

      if (!mode) return;

      dispatch(togglePaymentMethodsMode(mode));
    },
    toggleAddressesMode(mode) {

      if (!mode) return;

      dispatch(toggleAddressesMode(mode));
    },
    getInvoiceQueue(next = null, userType = "providers", callBackSuccess) {

      if (!userType) return;

      dispatch(getProviderInvoiceQueue(next));

      fetchApi(
        'get',
        next ? next.replace(config.api_url, '') : userType + '/self/invoice-queue',
        null,
        null,
        null,
        (data, paging) => {
          next
            ? dispatch(updateProviderInvoiceQueue(data, paging))
            : dispatch(getProviderInvoiceQueueSuccess(data, paging));

            if (callBackSuccess && typeof callBackSuccess === 'function')
              callBackSuccess();
        },
        error => {
          dispatch(getProviderInvoiceQueueFailure(error));
        }
      );
    },
    getInvoicesList(next = null, userType = "providers", callBackSuccess) {

      if (!userType) return;

      dispatch(getProviderInvoices(next));

      fetchApi(
        'get',
        next ? next.replace(config.api_url, '') : userType + '/self/invoices',
        null,
        null,
        null,
        (data, paging) => {
          next
            ? dispatch(updateProviderInvoices(data, paging))
            : dispatch(getProviderInvoicesSuccess(data, paging));

          if (callBackSuccess && typeof callBackSuccess === 'function')
            callBackSuccess();
        },
        error => {
          dispatch(getProviderInvoicesFailure(error));
        }
      );
    },
    getPaymentMethods(next = null, userType = "users", callBackSuccess) {

      if (!userType) return;

      dispatch(getPaymentMethods(next));

      const query = {
        filters: "active|eq|1",
      };

      fetchApi(
        "get",
        next ? next.replace(config.api_url, '') : userType + "/self/payment-methods",
        next ? { } : query,
        null,
        null,
        (data, paging) => {
          next
            ? dispatch(updatePaymentMethods(data, paging))
            : dispatch(getPaymentMethodsSuccess(data, paging));

          if (callBackSuccess && typeof callBackSuccess === 'function')
            callBackSuccess();
        },
        error => {
          dispatch(getPaymentMethodsFailure(error));
        }
      );
    },
    deletePaymentMethod(id, userType = "users", callBackSuccess) {

      if (!id) return;

      const errorMessage = translation().invoices.payment_methods.delete.callbacks.error;
      const successMessage = translation().invoices.payment_methods.delete.callbacks.success;

      dispatch(deletePaymentMethod());

      fetchApi(
        "delete",
        userType + "/self/payment-methods/" + id,
        null,
        null,
        null,
        () => {
          dispatch(deletePaymentMethodSuccess());
          dispatch(enqueueSnackbar({
            message: successMessage,
            options: {
              variant: 'success',
            },
          }));

          if (callBackSuccess && typeof callBackSuccess === 'function')
            callBackSuccess();
        },
        error => {
          if (error && error.response) {
            dispatch(deletePaymentMethodFailure(error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage));
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(deletePaymentMethodFailure(errorMessage));
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    createAddress(values, userType = "users", callBackSuccess) {

      if (!values || !values.address || !values.name)
        return;

      dispatch(postAddress());

      apiPostUserAddress(
        userType === "companies" ? true : false,
        values.address,
        values.name,
        address => {
          getUser(
            () => {
              dispatch(postAddressSuccess(address));
              dispatch(enqueueSnackbar({
                message: translation().invoices.addresses.create.form.callbacks.success,
                options: {
                  variant: 'success',
                },
              }));

              if (callBackSuccess && typeof callBackSuccess === 'function')
                callBackSuccess();
            },
            () => {
                dispatch(postAddressSuccess(address));
                dispatch(enqueueSnackbar({
                  message: translation().invoices.addresses.create.form.callbacks.success,
                  options: {
                    variant: 'success',
                  },
                }));

                if (callBackSuccess && typeof callBackSuccess === 'function')
                  callBackSuccess();
            }
          );
        },
        error => {
          const errorMessage = translation().invoices.addresses.create.form.callbacks.error;

          if (error && error.response) {
            if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
              const fieldsErrors = ["street_number", "street", "state_id", "zip_code", "city", "country_isocode", "state_isocode", "latitude", "longitude"];
              const validationErrors = {};
              const errorsArray = error.response.data.errors;

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath) {
                  if ((fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0))
                    validationErrors["address"] =  errorsArray[i].propertyPath + ": "+ errorsArray[i].message;
                  else
                    validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
                }
              }

              dispatch(postAddressFailure(errorsArray));
              dispatch(stopSubmit(
                'createAddressForm',
                validationErrors
              ));
            }
            else {
              dispatch(postAddressFailure(error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage));
            }

            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(postAddressFailure(errorMessage));
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    deleteAddress(id, userType = "users", callBackSuccess) {

      if (!id) return;

      dispatch(deleteAddress());

      fetchApi(
        "delete",
        userType + "/self/addresses/" + id,
        null,
        null,
        null,
        () => {
          getUser(
            () => {
              dispatch(deleteAddressSuccess());
              dispatch(enqueueSnackbar({
                message: translation().invoices.addresses.delete.form.callbacks.success,
                options: {
                  variant: 'success',
                },
              }));

              if (callBackSuccess && typeof callBackSuccess === 'function')
                callBackSuccess();
            },
            () => {
              dispatch(deleteAddressSuccess());
              dispatch(enqueueSnackbar({
                message: translation().invoices.addresses.delete.form.callbacks.success,
                options: {
                  variant: 'success',
                },
              }));

              if (callBackSuccess && typeof callBackSuccess === 'function')
                callBackSuccess();
            }
          )
        },
        error => {
          const errorMessage = translation().invoices.addresses.delete.form.callbacks.error;

          if (error && error.response) {
            dispatch(deleteAddressFailure(error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage));
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(deleteAddressFailure(errorMessage));
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    editAddress(id, values, userType = "users", callBackSuccess) {

      if (!values || !values.address || !values.name)
        return;

      dispatch(editAddress());

      apiPutUserAddress(
        userType === "companies" ? true : false,
        id,
        values.address,
        values.name,
        () => {
          getUser(
            () => {
              dispatch(editAddressSuccess());
              dispatch(enqueueSnackbar({
                message: translation().invoices.addresses.edit.form.callbacks.success,
                options: {
                  variant: 'success',
                },
              }));

              if (callBackSuccess && typeof callBackSuccess === 'function')
                callBackSuccess();
            },
            () => {
              dispatch(editAddressSuccess());
              dispatch(enqueueSnackbar({
                message: translation().invoices.addresses.edit.form.callbacks.success,
                options: {
                  variant: 'success',
                },
              }));

              if (callBackSuccess && typeof callBackSuccess === 'function')
                callBackSuccess();
            }
          )
        },
        error => {
          const errorMessage = translation().invoices.addresses.edit.form.callbacks.error;

          if (error && error.response) {
            if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
              const fieldsErrors = ["street_number", "street", "zip_code", "state_id", "city", "country_isocode", "state_isocode", "latitude", "longitude"];
              const validationErrors = {};
              const errorsArray = error.response.data.errors;

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath) {
                  if ((fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0))
                    validationErrors["address"] =  errorsArray[i].propertyPath + ": "+ errorsArray[i].message;
                  else
                    validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
                }
              }

              dispatch(editAddressFailure(errorsArray));
              dispatch(stopSubmit(
                'editAddressForm',
                validationErrors
              ));
            }
            else {
              dispatch(editAddressFailure(error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage));
            }

            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(editAddressFailure(errorMessage));
             dispatch(enqueueSnackbar({
               message: errorMessage,
               options: {
                 variant: 'error',
               },
             }));
           }
         }
       );
    },
    getCompanyUsers(next = null) {

      dispatch(getCompanyUsers(next));

      fetchApi(
        "get",
        next ? next.replace(config.api_url, '') : "provider-companies/self/providers",
        null,
        null,
        null,
        (data, paging) => {
          next
            ? dispatch(updateCompanyUsers(data, paging))
            : dispatch(getCompanyUsersSuccess(data, paging));
        },
        error => {
          dispatch(getCompanyUsersFailure(error));
        }
      )
    },
    handleCompanyPaymentMethodPermissions(method, paymentId, userId, callBackSuccess, callBackFailure) {

      if (!method || !paymentId || !userId)
          return;

      fetchApi(
        method,
        "companies/self/payment-methods/" + paymentId + "/users/" + userId,
        null,
        null,
        null,
        () => {
          if (callBackSuccess && typeof callBackSuccess === 'function')
            callBackSuccess();
        },
        error => {
          const errorMessage = translation().invoices.payment_methods.manage.callbacks.error;

          if (callBackFailure && typeof callBackFailure === 'function')
            callBackFailure(error);

          if (method.toLowerCase() !== "get") {
            if (error && error.response) {
              dispatch(enqueueSnackbar({
                message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
                options: {
                  variant: 'error',
                },
              }));
            }
            else {
              dispatch(enqueueSnackbar({
                message: errorMessage,
                options: {
                  variant: 'error',
                },
              }));
            }
          }
        }
      );
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoices));
