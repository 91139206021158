/**
 * Provider actions
 */

export const acceptProviderTerms = () => ({
  type: "ACCEPT_PROVIDER_TERMS"
});

export const acceptProviderTermsSuccess = () => ({
  type: "ACCEPT_PROVIDER_TERMS_SUCCESS"
});

export const acceptProviderTermsFailure = error => ({
  type: "ACCEPT_PROVIDER_TERMS_FAILURE",
  error
});

export const getProvider = () => ({
 type: "GET_PROVIDER"
});

export const getProviderSuccess = data => ({
 type: "GET_PROVIDER_SUCCESS",
 data
});

export const getProviderFailure = error => ({
 type: "GET_PROVIDER_FAILURE",
 error
});

export const removeProvider = () => ({
 type: "REMOVE_PROVIDER"
});

export const setNotProvider = () => ({
 type: "SET_NOT_PROVIDER",
 data: 403
});

export const storeMediaType = mediaType => ({
  type: 'STORE_MEDIA_TYPE',
  mediaType: mediaType
});

export const storeMediaTypeMedia = (media, mediaTypeShortcode) => ({
  type: 'STORE_MEDIA_TYPE_MEDIA',
  media: media,
  mediaTypeShortcode: mediaTypeShortcode,
});

export const storeMediaTypeProperty = (mediaUrl, mediaTypeShortcode, mediaProperty, mediaPropertyValue) => ({
  type: 'STORE_MEDIA_TYPE_MEDIA_PROPERTY',
  mediaUrl: mediaUrl,
  mediaTypeShortcode: mediaTypeShortcode,
  mediaProperty: mediaProperty,
  mediaPropertyValue: mediaPropertyValue
})

export const removeMediaTypeMedia = (mediaUrl, mediaTypeShortcode) => ({
  type: 'REMOVE_MEDIA_TYPE_MEDIA',
  mediaUrl: mediaUrl,
  mediaTypeShortcode: mediaTypeShortcode,
});

export const removeMediaTypeMedias = (mediaTypeShortcode) => ({
  type: 'REMOVE_MEDIA_TYPE_MEDIAS',
  mediaTypeShortcode: mediaTypeShortcode,
});

export const removeMediaTypes = () => ({
  type: 'REMOVE_MEDIA_TYPES'
});

export const storeOpmInitBuffer = status => ({
  type: 'STORE_OPM_INIT_BUFFER',
  status: status,
});

export const storeOpmProductId = productId => ({
  type: 'STORE_OPM_PRODUCT_ID',
  product_id: productId
});

export const removeOpmInitBuffer = () => ({
  type: 'REMOVE_OPM_INIT_BUFFER'
});

/**
 * Availabilities actions
 */
export const getAvailabilities = (next) => ({
  type: 'GET_AVAILABILITIES',
  next
});

export const getAvailabilitiesSuccess = (data, paging) => ({
  type: 'GET_AVAILABILITIES_SUCCESS',
  data, paging
});

export const getAvailabilitiesFailure = error => ({
  type: 'GET_AVAILABILITIES',
  error
});

export const updateAvailabilities = (data, paging) => ({
  type: 'UPDATE_AVAILABILITIES',
  data, paging
})

export const postAvailability = () => ({
	type: 'POST_AVAILABILITY'
});

export const postAvailabilitySuccess = data => ({
	type: 'POST_AVAILABILITY_SUCCESS',
	data
});

export const postAvailabilityFailure = error => ({
	type: 'POST_AVAILABILITY_FAILURE',
	error
});

export const putAvailability = () => ({
	type: 'PUT_AVAILABILITY'
});

export const putAvailabilitySuccess = (id, data) => ({
	type: 'PUT_AVAILABILITY_SUCCESS',
	id,
	data
});

export const putAvailabilityFailure = error => ({
	type: 'PUT_AVAILABILITY_FAILURE',
	error
});

export const deleteAvailability = () => ({
	type: 'DELETE_AVAILABILITY'
});

export const deleteAvailabilitySuccess = id => ({
	type: 'DELETE_AVAILABILITY_SUCCESS',
	id
});

export const deleteAvailabilityFailure = error => ({
	type: 'DELETE_AVAILABILITY_FAILURE',
	error
});

/**
 * Unavailabilities actions
 */
export const getUnavailabilities = (next) => ({
  type: 'GET_UNAVAILABILITIES',
  next
});

export const getUnavailabilitiesSuccess = (data, paging) => ({
  type: 'GET_UNAVAILABILITIES_SUCCESS',
  data, paging
});

export const getUnavailabilitiesFailure = error => ({
  type: 'GET_UNAVAILABILITIES',
  error
});

export const updateUnavailabilities = (data, paging) => ({
  type: 'UPDATE_UNAVAILABILITIES',
  data, paging
})

export const postUnavailability = () => ({
	type: 'POST_UNAVAILABILITY'
});

export const postUnavailabilitySuccess = data => ({
	type: 'POST_UNAVAILABILITY_SUCCESS',
	data
});

export const postUnavailabilityFailure = error => ({
	type: 'POST_UNAVAILABILITY_FAILURE',
	error
});

export const putUnavailability = () => ({
	type: 'PUT_UNAVAILABILITY'
});

export const putUnavailabilitySuccess = (id, data) => ({
	type: 'PUT_UNAVAILABILITY_SUCCESS',
	id, data
});

export const putUnavailabilityFailure = error => ({
	type: 'PUT_UNAVAILABILITY_FAILURE',
	error
});

export const deleteUnavailability = () => ({
	type: 'DELETE_UNAVAILABILITY'
});

export const deleteUnavailabilitySuccess = id => ({
	type: 'DELETE_UNAVAILABILITY_SUCCESS',
	id
});

export const deleteUnavailabilityFailure = error => ({
	type: 'DELETE_UNAVAILABILITY_FAILURE',
	error
});
