import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Dropzone from 'react-dropzone';
import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { generateHash } from '../../../helpers/functions/utils';

import FileCard from './FileCard';

import translation from '../../../translation/translation';

const FileCardSortable = SortableElement(({file, onDeleteFile}, {sortIndex}) => {
  return (
    <FileCard
      key={sortIndex}
      file={file}
      onDeleteFile={onDeleteFile}
      inputPlaceholder={translation().orders.media_groups.file_uploader.autocomplete}
    />
  );
});

const SortableFileCardList = SortableContainer(({
  name,
  extensionsAccepted,
  filesArray,
  onDeleteFile,
  onDrop,
  uploadInProgressCount,
  totalMaxSize,
  totalMinSize,
}) => {

  /**
   * Copy dropzone click for isolate it to the button
   * Then disable the dropzone click for more usability of sortable component
   */
  let dropzoneRef = undefined;

  function createDropzoneRef(node) {
    if (node)
      dropzoneRef = node;
  }

  function handleOpenDropzone() {
    if (dropzoneRef && dropzoneRef.open)
      dropzoneRef.open();
  }

  function handleDrop(filesAccepted, filesRejected) {
    if (onDrop)
      onDrop(filesAccepted, filesRejected);
  }

  return (
    <Dropzone
      name={name}
      ref={createDropzoneRef}
      onDrop={handleDrop}
      disableClick={true}
      multiple={true}
      accept={extensionsAccepted}
      maxSize={totalMaxSize}
      minSize={totalMinSize}
    >
      {
        ({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div {...getRootProps()} className={classNames('dropzone dropper', {'dropzone--isActive dropper-ondrag': isDragActive})}>
              {
                !uploadInProgressCount && filesArray && filesArray.length <= 0
                  ? (
                    <div className="drop-it">
                      <i className="material-icons drop-icon">cloud_upload</i>
                      <p>{translation().orders.media_groups.file_uploader.drop_file}</p>
                      <span className="or-click">{translation().orders.media_groups.file_uploader.or}</span>
                      <Button
                        className="drop-btn"
                        onClick={handleOpenDropzone}
                        aria-label="Drop files"
                        color="default"
                      >
                        {translation().orders.media_groups.file_uploader.select}
                      </Button>
                    </div>
                  )
                  : (
                    <Fab
                      aria-label="Drop files"
                      size="small"
                      color="primary"
                      className="drop-corner-btn"
                      onClick={handleOpenDropzone}
                    >
                      <AddIcon />
                    </Fab>
                  )
              }
              {
                filesArray && filesArray.length > 0
                  ? filesArray.map((file, index) => {
                    return (
                      <FileCardSortable
                        file={file}
                        key={`item-${generateHash(file.url)}`}
                        sortIndex={index}
                        index={index}
                        onDeleteFile={onDeleteFile}
                        style={{zIndex: 10000}}
                      />
                    )
                })
                : ''
              }
              <input {...getInputProps()} />
              {
                _.times(uploadInProgressCount && uploadInProgressCount > 0 && uploadInProgressCount, (index) =>
                  <div className="file-incoming" key={index}>
                    <div className="file-loader">
                      <CircularProgress color="secondary" size={35}/>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      }
    </Dropzone>
  );
});

SortableFileCardList.propTypes = {
  onDeleteFile: PropTypes.func.isRequired,
  extensionsAccepted: PropTypes.any.isRequired,
  filesArray: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDrop: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  uploadInProgressCount: PropTypes.number.isRequired,
  totalMinSize: PropTypes.number,
  totalMaxSize: PropTypes.number,
  name: PropTypes.any.isRequired,
}

const FileUploader = ({
  extensionsAccepted,
  filesArray,
  onDeleteFile,
  onDrop,
  onSortEnd,
  uploadInProgressCount,
  totalMinSize,
  totalMaxSize,
  name
}) => {

  return (
    <div className="drop-zone">
      <SortableFileCardList
        axis={"xy"}
        onDeleteFile={onDeleteFile}
        extensionsAccepted={extensionsAccepted}
        filesArray={filesArray}
        helperClass="sortable-helper"
        hideSortableGhost={false}
        lockToContainerEdges={true}
        onDrop={onDrop}
        onSortEnd={onSortEnd}
        uploadInProgressCount={uploadInProgressCount}
        totalMinSize={totalMinSize}
        totalMaxSize={totalMaxSize}
        name={name}
      />
    </div>
  )
}

FileUploader.propTypes = {
  extensionsAccepted: PropTypes.any.isRequired,
  filesArray: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  uploadInProgressCount: PropTypes.number.isRequired,
  totalMinSize: PropTypes.number,
  totalMaxSize: PropTypes.number,
  name: PropTypes.any.isRequired,
}

export default FileUploader;
