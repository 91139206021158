/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/self-closing-comp */

import React from "react";

function MainContract() {
  return (
    <div className="cgs-main-contract">
      <p className="p-cgs2">
        <b>SAS Wall-Market</b>, société par actions simplifiée, au capital de{" "}
        <span className="s1">19.146 </span>euros, dont le siège social est situé{" "}
        <span className="s1">22 rue Legendre 75017 Paris</span>, ayant pour
        numéro d’identification unique le n° 532 727 740 RCS Paris, représentée
        par Monsieur Guillaume Brochut en sa qualité de Président,
      </p>
      <p className="p-cgs2">
        Ci-après dénommée « <b>Wall-Market </b>»,
      </p>
      <p className="p-cgs3">
        <b>Article 1 : OBJET</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.1
        <span className="Apple-tab-span"> </span>Les présentes conditions
        générales ont pour objet de préciser les engagements et obligations de
        la SAS Wall-Market et du prestataire (ci-après le « <b>Prestataire</b>
         ») pour tout contrat de prestation de services conclu entre le
        Prestataire et Wall-Market (ci-après le ou les « <b>Prestation(s)</b>
         »).
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.2
        <span className="Apple-tab-span"> </span>Wall-Market a pour activité
        initiale le développement d’outils numériques ainsi que l’offre de
        services en ligne (ou via tout autre medium), destinés à faciliter la
        vente et la location de biens immobiliers par des professionnels de
        l’immobilier. A cet effet, Wall-Market a substantiellement investi,
        depuis sa création, pour développer sa clientèle professionnelle et
        devenir un acteur de référence auprès de celle-ci. Forte de cette
        expérience, Wall-Market a également développé son offre de services en
        ligne aux particuliers.<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.3
        <span className="Apple-tab-span"> </span>Le Prestataire est un
        professionnel immatriculé au registre du commerce et des sociétés ou
        disposant d’un numéro SIREN (par exemple, pour les professionnels de
        l’image).
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.4
        <span className="Apple-tab-span"> </span>Le Prestataire dispose d’une
        activité propre qu’il accepte d’exercer avec Wall-Market pour le compte
        de ses clients (ci-après « <b>Client(s)</b> »).
      </p>
      <p className="p-cgs3">
        <b>Article 2 : ENGAGEMENTS ET DECLARATIONS DU PRESTATAIRE</b>
      </p>
      <p className="p-cgs2">Le Prestataire :</p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>reconnaît bénéficier d’une
        police d’assurance professionnelle couvrant l’activité professionnelle
        qu’il s’engage à réaliser avec Wall-Market, comme les risques{" "}
        <span className="s2">pouvant</span> être occasionnés lors de ses
        déplacements ou l’exécution d’une Prestation ;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>reconnaît, s’il a pour activité
        la réalisation de diagnostics techniques immobiliers obligatoires (au
        sens des articles 271-4 et suivants du Code de la construction et de
        l’habitation, de {"l'article"} 46 de la loi du 10 juillet 1965 relatif à{" "}
        {"l'attestation"} de surface privative ou de l’article 3-3 de la loi du
        6 juillet 1989), disposer d’un certificat délivré par un organisme
        accrédité par le Comité français d’accréditation (COFRAC) ;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>déclare ne pas{" "}
        <span className="s2">être</span> agent immobilier ou ne pas exercer
        cette activité par voie de délégation ;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>s’engage à respecter toutes les
        obligations énoncées dans le cadre de présentes pour l’exécution de{" "}
        <span className="s2">toute</span> prestation avec Wall-Market, en
        particulier celles relatives aux données personnelles ;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>déclare n’avoir jamais été
        condamné pénalement, conformément aux règles françaises de prescriptions
        des peines, pour une infraction pour atteinte à la vie d’une personne, à
        sa dignité, sa <span className="s2">personnalité</span>, son intégrité
        physique ou psychique, ou alors pour atteinte aux biens (par exemple :
        vol, extorsion, discrimination, viol, agression sexuelle, harcèlement
        moral ou sexuel, escroquerie, abus de confiance) ;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>s’engage à ne{" "}
        <span className="s2">pas</span> sous-traiter l’exécution d’une
        Prestation à un tiers sauf accord contraire écrit et préalable de
        Wall-Market ;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>déclare reconnaître être
        responsable de toutes les charges sociales, impôts et taxes dont il est{" "}
        <span className="s2">redevable</span> ;
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>déclare que{" "}
        <span className="s2">les</span> informations communiquées à Wall-Market
        sont à jour ;<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>s’engage à respecter la
        réglementation en vigueur applicable au traitement de données à
        caractère <span className="s2">personnel</span> et, en particulier, le
        règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril
        2016 applicable à compter du 25 mai 2018 ; et
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>s’interdit de démarcher un
        Client de Wall-Market pour lequel une Prestation est réalisée aux fins
        de lui proposer, directement ou indirectement, l’exécution d’une autre
        prestation (notamment de même nature ou liée), au moment de son
        exécution.
      </p>
      <p className="p-cgs6">
        En aucun cas, le Prestataire ne peut proposer à un consommateur ayant
        commandé une Prestation en vue de la vente ou de la location de son
        appartement, les coordonnées d’un agent immobilier. Communiquer à un
        agent immobilier les coordonnées d’un consommateur se trouvant dans
        l’hypothèse décrite ci-avant est également formellement interdit par les
        présentes et la réglementation relative aux données personnelles.
      </p>
      <p className="p-cgs6">
        En tout état de cause, le Prestataire se doit de respecter strictement
        les règles relatives au droit de la consommation et aux données
        personnelles.
      </p>
      <p className="p-cgs7">
        Ces déclarations constituent une condition essentielle de l’engagement
        de Wall-Market.
      </p>
      <p className="p-cgs3">
        <b>Article 3 : DEFINITION DES PRESTATIONS</b>
      </p>
      <p className="p-cgs7">
        La ou les Prestation(s) objet des présentes sont celles pour lesquelles
        le Prestataire a :
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>proposé ses services en
        s’enregistrant sur son espace personnel mis à disposition par
        Wall-Market, en fonction de ses compétences professionnelles ; ou
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>accepté une{" "}
        <span className="s2">demande</span> de prestation de services par
        Wall-Market, conforme à ses compétences professionnelles, en dehors de
        tout créneau renseigné par lui.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>Article 4 : CONDITIONS </b>
        <span className="s2">
          <b>{"D'EXÉCUTION"}</b>
        </span>
        <b>
          {" "}
          D’UNE PRESTATION<span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.1
        <span className="Apple-tab-span"> </span>Le Prestataire assume, en toute
        indépendance, la maîtrise et la responsabilité des Prestations qu’il a
        proposé d’exécuter.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.2
        <span className="Apple-tab-span"> </span>Le Prestataire s’engage à
        exécuter toute Prestation conformément :
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>à la réglementation en
        vigueur ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>aux usages, notamment ceux liés
        à son activité, avec tous les soins et la diligence d’un technicien et
        professionnel de haut niveau ; et
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>le cas échéant, au cahier des
        charges établi par Wall-Market.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.3
        <span className="Apple-tab-span"> </span>Le Prestataire s’engage
        également à exécuter toute Prestation{" "}
        <span className="s2">
          à la date et à l’heure convenues avec Wall-Market.
        </span>
      </p>
      <p className="p-cgs4">
        Aucun report de la date ou de l’heure convenue ne peut intervenir sans
        l’accord préalable et express de Wall-Market, même s’il est à
        l’initiative du Client de Wall-Market. Le Prestataire ne peut donc pas
        proposer le report d’une Prestation directement au Client de
        Wall-Market.
      </p>
      <p className="p-cgs4">
        Si le Prestataire n’est pas, en raison de circonstances exceptionnelles,
        en mesure d’exécuter une Prestation à la date et à l’heure convenues, il
        devra en informer sans délai Wall-Market.{" "}
        <span className="s3">
          L’incapacité pour le Prestataire d’exécuter une prestation sans en
          justifier le caractère exceptionnel emporte résiliation du contrat de
          prestation à son initiative.
        </span>
      </p>
      <p className="p-cgs9">
        <b>Article 5 : LIVRAISON</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>5.1
        <span className="Apple-tab-span"> </span>Le Prestataire reconnaît avoir
        été informé des termes liant Wall-Market à ses Clients, selon les
        conditions générales de vente de Wall-Market, en particulier s’agissant
        des délais de livraison, et avoir renseigné sa disponibilité en fonction
        de cette contrainte.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>5.2
        <span className="Apple-tab-span"> </span>Toute livraison d’une
        Prestation doit être effectuée par l’intermédiaire du Compte du
        Prestataire.
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>5.3
          <span className="Apple-tab-span"> </span>En conséquence, le
          Prestataire {"s'engage"} à livrer le(s) fichier(s) et/ou le(s) lien(s)
          URL résultant d’une Prestation dans les meilleurs délais et au plus
          tard, selon les délais suivants :
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Pour une Prestation
          impliquant le déplacement chez le Client ou son mandant :
        </b>{" "}
        livraison dans les meilleurs délais et au plus tard dans les 16 heures
        après l’heure à laquelle le Prestataire a exécuté la Prestation chez le
        Client de Wall-Market ;
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Pour une Prestation réalisée
          depuis le lieu de travail du Prestataire (ex : post-production) :{" "}
        </b>
        dans les 4 heures après avoir reçu la commande de Wall-Market.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>
          Article 6 :<span className="Apple-tab-span"> </span>PRIX, MODALITES DE
          PAIEMENT ET DE FACTURATION
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        <b>
          6.1<span className="Apple-tab-span"> </span>Prix
        </b>
      </p>
      <p className="p-cgs4">
        Le Prestataire est rémunéré, pour chaque Prestation, selon un forfait
        convenu avec Wall-Market.
      </p>
      <p className="p-cgs4">
        Le Prestataire reconnaît qu’aucune Prestation ne relève du droit
        d’auteur.
      </p>
      <p className="p-cgs4">
        Dans l’hypothèse où il contesterait cette analyse, le Prestataire
        s’engage à ne pas accepter de Prestation au titre des présentes et à en
        informer par écrit Wall-Market dans les plus brefs délais, pour lui
        exposer sa position. Une telle contestation postérieurement à
        l’acceptation des présentes pourra avoir pour effet de suspendre
        l’exécution de la/des Prestation(s) confiée(s).
      </p>
      <p className="p-cgs4">
        Si, par extraordinaire, et en dépit de l’accord des Parties, une
        Prestation devait être considérée comme relevant du droit d’auteur, le
        Prestataire reconnaît que la rémunération forfaitaire convenue intègre
        la rémunération de la cession de droits d’auteurs, tel que définis à
        l’article 9 ci-après.
        <span className="s4">
          <span className="Apple-converted-space"> </span>
        </span>
      </p>
      <p className="p-cgs4">
        <b>
          6.2<span className="Apple-tab-span"> </span>Modalités de paiement
        </b>
      </p>
      <p className="p-cgs4">Sauf accord contraire, toute Prestation sera :</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>facturée à Wall-Market
        conformément au mandat de facturation le premier jour du mois suivant la
        prestation ; et
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>réglée par Wall-Market
        automatiquement le huitième jour du mois suivant celui durant lequel une
        Prestation a été exécutée.
      </p>
      <p className="p-cgs9">
        <b>Article 7 : RESILIATION ET RESOLUTION DU CONTRAT</b>
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.1
          <span className="Apple-tab-span"> </span>Hors les hypothèses décrites
          ci-après, l’acceptation par Wall-Market d’un créneau du Prestataire
          pour l’exécution d’une Prestation pour le compte d’un Client vaut
          contrat et à ce titre, oblige les Parties
        </b>
        .<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.2
          <span className="Apple-tab-span"> </span>Clause résolutoire
        </b>
      </p>
      <p className="p-cgs4">
        La Prestation sera résiliée de plein droit, sans formalité ni mise en
        demeure, en raison de l’annulation par un Client de sa commande auprès
        de Wall-Market plus de six (6) heures avant l’exécution de celle-ci.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        Wall-Market s’engage à informer le Prestataire d’un tel évènement
        immédiatement après en avoir eu connaissance.
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.3
          <span className="Apple-tab-span"> </span>Résiliation par Wall-Market
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market pourra résilier une commande de Prestation à tout moment
        dans les 6 heures avant son exécution. Dans cette hypothèse, Wall-Market
        s’engage à dédommager le Prestataire à hauteur {"d'un"} forfait convenu
        préalablement avec celui-ci..
      </p>
      <p className="p-cgs3">
        <b>Article 8 : DÉLAI DE PRESCRIPTION ABRÉGÉ</b>
      </p>
      <p className="p-cgs4">
        Par application de l’article 2254 du Code civil, les Parties conviennent
        de réduire le délai de prescription à un an à compter de l’exécution
        d’une Prestation pour toute contestation relative à l’article 7
        ci-avant, qu’elle que soit son fondement et qu’il s’agisse de sa
        validité ou de son exécution.
      </p>
      <p className="p-cgs3">
        <b>Article 9 : RESPONSABILITE</b>
      </p>
      <p className="p-cgs8">
        9.1<span className="Apple-tab-span"> </span>Le Prestataire est seul
        responsable de tout préjudice subi par Wall-Market en raison d’un
        manquement de celui-ci à l’un de ses engagements ou déclarations au
        titre des présentes conditions générales.
      </p>
      <p className="p-cgs8">
        9.2<span className="Apple-tab-span"> </span>Le Prestataire est ainsi
        seul responsable des circonstances dans lesquelles interviendra la
        réalisation d’une Prestation et de tout dommage corporel et/ou matériel
        susceptible d’en résulter.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        Le Prestataire s’engage ainsi à supporter les préjudices et coûts liés à
        tout recours ou toute réclamation d’un Client de Wall-Market à
        l’encontre de cette dernière, en raison d’une Prestation exécutée par le
        Prestataire.
      </p>
      <p className="p-cgs4">
        Le Prestataire s’engage également à supporter tout préjudice de
        Wall-Market résultant d’une telle réclamation.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>9.3
        <span className="Apple-tab-span"> </span>Wall-Market s’engage à informer
        le Prestataire de tout recours ou toute réclamation de l’un de ses
        Clients à ce titre.<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>Article 10 : PROPRIETE INTELLECTUELLE</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.1
        <span className="Apple-tab-span"> </span>Les Parties déclarent et
        reconnaissent qu’aucun des travaux résultant de l’exécution d’une
        Prestation (ci-après « <b>Ouvrage(s)</b> ») n’est, au regard du droit
        positif et de la nature de la Prestation, protégé par le droit d’auteur
        dans la mesure où les conditions nécessaires à la qualification d’une
        œuvre originale ne sont pas caractérisées.
      </p>
      <p className="p-cgs4">
        En conséquence, tout Ouvrage constitue la propriété exclusive de
        Wall-Market qui peut seule décider de son usage.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.2
        <span className="Apple-tab-span"> </span>Si, par extraordinaire, un
        Ouvrage devait être considéré comme une œuvre relevant du droit
        d’auteur, Wall-Market et le Prestataire conviennent ce qui suit :
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Il est entendu que l’Ouvrage
        issu d’une Prestation a été réalisé dans le seul intérêt de Wall-Market
        et a toujours eu vocation à entrer dans le patrimoine intellectuel de
        cette dernière afin d’être exploité de manière exclusive ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Le Prestataire cède à
        Wall-Market, et déclare être en capacité de le faire à ce titre, à titre
        exclusif, pour le monde entier et pour la durée des droits de propriété
        intellectuelle, les droits de reproduction, d’adaptation, de
        distribution, de représentation et d’exploitation attachés aux Ouvrages
        selon la destination et les modalités précisées ci-après ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Cette cession est accomplie en
        contrepartie de la rémunération prévue à l’article 6 ci-avant.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs10">Destination de la Cession :</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>le domaine de la
        commercialisation et de la location de biens immobiliers ainsi que toute
        activité connexe{" "}
        <span className="s2">
          pour la représentation ou la communication d’un Client de
          Wall-Market ; et
        </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>le domaine de l’offre de
        services destinée à faciliter la vente et la location de biens{" "}
        <span className="s2">immobiliers</span>.
      </p>
      <p className="p-cgs11">
        En application des dispositions de l’article L.131-3 du Code de la
        propriété intellectuelle, les droits cédés à Wall-Market comprennent
        notamment :
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Pour le droit de reproduction :
        le droit de reproduire et de faire reproduire tout Ouvrage, sans
        limitation de nombre, en tout ou partie, quel qu’en soit le support
        (notamment sur tout support numérique, en particulier sur les sites
        édités par Wall-Market ou son/ses application(s), y compris en réseau)
        ou les moyens utilisés et par tous procédés notamment informatiques
        connus ou inconnus à ce jour ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Pour le droit d’adaptation : le
        droit d’adapter, de faire adapter tout ou partie des Ouvrages et en
        particulier le droit de les traduire en tout langage de programmation ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Pour le droit de représentation
        : le droit, pour tout ou partie d’un Ouvrage, d’éditer, diffuser, ou de
        faire éditer ou diffuser, de quelques manières que ce soient, par tout
        procédé, quel qu’il soit, connu ou inconnu à ce jour, et notamment par
        tous les réseaux de télécommunication et communication au public par
        voie électronique, notamment au sens de la Loi n° 2004-575 du 21 juin
        2004 pour la Confiance dans l’Economie Numérique, actuels et futurs, tel
        que les réseaux Internet ou mobile, ainsi que la radiodiffusion par tous
        moyens de télécommunication y compris la transmission par voie
        hertzienne, par câble ou par satellite, et ce, sur tous supports
        mentionnés au présent article, en tout format, directement ou par
        l’intermédiaire de tiers. Ce droit inclus le droit de communication ou
        de mise à disposition du public, par fil ou sans fil, de sorte que
        chacun puisse avoir accès à/aux Ouvrage(s) de l’endroit et au moment
        qu’il choisit individuellement selon la destination convenue ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Pour le droit d’exploitation :
        le droit de rétrocéder aux Clients, en tout ou partie, sous quelle que
        forme que ce soit et notamment par une cession, licence ou tout type de
        contrat, sous toute forme, tout ou partie des droits cédés, à titre
        temporaire ou pour toute la durée de la présente cession.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.3
        <span className="Apple-tab-span"> </span>Le Prestataire garantit
        expressément à Wall-Market la jouissance pleine et entière des droits
        cédés aux termes des présentes sur les Ouvrages.
      </p>
      <p className="p-cgs8">
        A cet égard, le Prestataire s’engage à indemniser Wall-Market de toute
        réclamation y afférente, émanant de tout tiers invoquant la violation
        d’un droit quelconque, et notamment de tous frais, dépenses,
        condamnations en cas {"d'action"} en contrefaçon, atteinte à la vie
        privée et/ou en concurrence déloyale et/ou parasitaire intentée par tout
        tiers sur le fondement d’un droit de personnalité, propriété
        intellectuelle ou industrielle quelconque lui appartenant ou dont il
        aurait la jouissance.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.4
        <span className="Apple-tab-span"> </span>En toute hypothèse, les
        Ouvrages étant réalisés au sein d’espaces privés, le Prestataire ne
        pourra pas les exploiter afin de ne pas porter atteinte aux droits
        protégés qui y sont attachés – sauf accord écrit et préalable de
        Wall-Market après avoir obtenu l’accord écrit et préalable du
        propriétaire et/ou de l’occupant du bien.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>Article 11 : DONNEES PERSONNELLES</b>
      </p>
      <p className="p-cgs4">
        Le Prestataire s’engage à respecter la réglementation relative aux
        données personnelles.
      </p>
      <p className="p-cgs4">
        Les présentes clauses ont pour objet de permettre à Wall-Market et au
        Prestataire l’exécution efficace de leurs obligations au titre de la
        réglementation relative aux données personnelles.
      </p>
      <p className="p-cgs4">
        En toute hypothèse, le Prestataire s’engage à respecter les dispositions
        de l’article 28 du Règlement Général sur la Protection des Données
        2016/679 du 27 avril 2016, reproduit en Annexe I en sa qualité de «
        sous-traitant » des données au regard de cette réglementation,
        Wall-Market en étant le « responsable de traitement ».
      </p>
      <p className="p-cgs4">
        <b>
          Wall-Market attire l’attention du Prestataire sur le fait qu’elle ne
          dispose pas de l’autorisation de ses Clients pour utiliser leurs
          données personnelles aux fins de prospection. Ceci à notamment pour
          conséquence qu’aucune donnée personnelle (ex : numéro de téléphone)
          d’un Client communiqué au Prestataire pour l’exécution d’une
          Prestation ne pourra être utilisé par celui-ci aux fins de
          prospections.<span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        <b>11.1</b>
        <span className="Apple-tab-span"> </span>
        <b>Description du traitement faisant l’objet de la Prestation</b>
      </p>
      <p className="p-cgs4">
        Le Prestataire est autorisé à traiter, pour le compte de Wall-Market,
        les données à caractère personnel nécessaires pour exécuter les
        Prestations.
      </p>
      <p className="p-cgs4">
        Nature des opérations réalisées sur les données : collecte et
        enregistrement temporaire des données du Client de Wall-Market pour
        l’exécution d’une Prestation.
      </p>
      <p className="p-cgs4">
        Finalités du traitement : exécution d’une Prestation.
      </p>
      <p className="p-cgs4">
        Les données à caractère personnel traitées portent sur :
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>1.
        <span className="Apple-tab-span"> </span>L’identité du Client de
        Wall-Market et/ou de son mandant/mandataire et toute information utile à
        la réalisation d’une Prestation à savoir : nom, prénom, adresse mail,
        adresse postale, numéro de téléphone, code d’accès à l’adresse du lieu
        d’exécution de la Prestation, le cas échéant profession ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>2.
        <span className="Apple-tab-span"> </span>La nature des achats réalisés
        par le Client de Wall-Market par l’intermédiaire de son site marchand et
        sous-traité au Prestataire.
      </p>
      <p className="p-cgs4">
        <b>
          11.2<span className="Apple-tab-span"> </span>Obligations du
          Prestataire vis-à-vis de Wall-Market
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        Le Prestataire {"s'engage"} à :
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>1.
        <span className="Apple-tab-span"> </span>traiter les données uniquement
        pour la ou les seule(s) finalité(s) faisant l’objet du présent contrat ;
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>2.
        <span className="Apple-tab-span"> </span>traiter les données
        conformément à leur finalité.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>3.
        <span className="Apple-tab-span"> </span>garantir la confidentialité des
        données à caractère personnel traitées dans le cadre du présent
        contrat ;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>4.
        <span className="Apple-tab-span"> </span>veiller à ce que les personnes
        autorisées à traiter les données à caractère personnel en vertu du
        présent contrat :<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs16">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>s’engagent à respecter la
        confidentialité ou soient soumises à une obligation légale{" "}
        <span className="s2">appropriée</span> de confidentialité ;
      </p>
      <p className="p-cgs16">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>reçoivent la formation
        nécessaire en matière de protection des données à caractère personnel
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>5.
        <span className="Apple-tab-span"> </span>prendre en compte, s’agissant
        de ses outils, produits, applications ou services, les principes de
        protection des données dès la conception et de protection des données
        par défaut.
      </p>
      <p className="p-cgs4">
        <b>
          11.3<span className="Apple-tab-span"> </span>Droit d’information des
          personnes concernées<span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market s’engage à informer son Client des opérations de traitement
        susceptibles d’être effectuées par Wall-Market ou le Prestataire
        préalablement à la collecte des données.
      </p>
      <p className="p-cgs4">
        Le Prestataire doit informer les employés de Wall-Market de toute
        opération de traitement les concernant préalablement ou au moment de la
        collecte des données.<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <b>
          11.4<span className="Apple-tab-span"> </span>Exercice des droits des
          personnes<span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        Dans la mesure du possible, le Prestataire s’engage à aider Wall-Market
        à s’acquitter de son obligation de donner suite aux demandes d’exercice
        des droits des personnes concernées : droit d’accès, de rectification,
        d’effacement et d’opposition, droit à la limitation du traitement, droit
        à la portabilité des données, droit de ne pas faire l’objet d’une
        décision individuelle automatisée (y compris le profilage).
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        Lorsque les personnes concernées exercent auprès du Prestataire des
        demandes d’exercice de leurs droits, le Prestataire s’engage à adresser
        ces demandes dès réception par courrier électronique à Wall-Market, à
        l’adresse suivante : rgpd@wall-market.com.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <b>
          11.5<span className="Apple-tab-span"> </span>Notification des
          violations de données à caractère personnel
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        Le Prestataire s’engage à notifier à Wall-Market toute violation de
        données à caractère personnel dans un délai maximum de 24 heures après
        en avoir pris connaissance par courriel (à l’adresse mail
        rgpd@wall-market.com) et téléphone (au numéro suivant :
        <span className="s5"> +33 1 42 27 45 53.</span>).
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        Cette notification est accompagnée de toute documentation utile afin de
        permettre à Wall-Market, si nécessaire, de notifier cette violation à
        l’autorité de contrôle compétente.
      </p>
      <p className="p-cgs4">
        <b>
          11.6<span className="Apple-tab-span"> </span>Mesures de sécurité et
          sort des données
        </b>
      </p>
      <p className="p-cgs4">
        <b>Le Prestataire s’engage à mettre en œuvre les mesures suivantes :</b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Destruction de toute
          information ou donnée relative au Client de Wall-Market après
          livraison d’une Prestation, ces informations n’étant plus nécessaires
          après l’exécution d’une Prestation ;
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Mise en place de toute mesure
          permettant de conserver confidentielles les informations ou données
          relatives au Client de Wall-Market avant leur destruction ;
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Mise en place d’une procédure
          visant à tester, à analyser et à évaluer régulièrement{" "}
          {"l'efficacité"} des mesures techniques et organisationnelles pour
          assurer la sécurité de tout traitement de donné en lien avec une
          Prestation réalisée pour le compte de Wall-Market ; et
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Destruction des données
          relatives aux employés de Wall-Market dans un délai maximum d’1 an
          après l’exécution d’une Prestation pour son compte.
        </b>
      </p>
      <p className="p-cgs4">
        <b>
          11.7<span className="Apple-tab-span"> </span>Documentation
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        Le Prestataire s’engage à mettre à la disposition de Wall-Market la
        documentation nécessaire pour démontrer le respect de toutes ses
        obligations.<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <b>
          11.8<span className="Apple-tab-span"> </span>Obligations de
          Wall-Market vis-à-vis du Prestataire
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market s’engage à :<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>fournir au Prestataire,
        directement ou par l’intermédiaire de son Client, les données
        nécessaires à la réalisation d’une Prestation ;
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>être à jour de ses obligations
        relatives aux données personnelles.
      </p>
      <p className="p-cgs3">
        <b>Article 12 : CONFIDENTIALITE</b>
      </p>
      <p className="p-cgs11">
        12.1<span className="Apple-tab-span"> </span>Toute information non
        protégée par le droit des données personnelles, obtenue dans le cadre de
        l’exécution d’une Prestation est confidentielle et ne pourra pas être
        partagée avec ou divulguée/communiquée à un tiers (en ce compris un
        Client) par le Prestataire pour quelque motif que ce soit.
      </p>
      <p className="p-cgs11">
        12.2<span className="Apple-tab-span"> </span>Dans l’hypothèse où le
        respect d’une telle obligation s’avérerait impossible, ou qu’il serait
        dans l’intérêt de Wall-Market qu’une information soit divulguée ou
        communiquée à un Tiers, le Prestataire s’engage à en informer
        Wall-Market pour qu’elle le dispense, le cas échéant, de cette
        obligation de confidentialité par écrit.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs11">
        12.3<span className="Apple-tab-span"> </span>Il convient de préciser
        qu’une information confidentielle concernant le bien immobilier objet de
        la Prestation (et le cas échéant, le Client de Wall-Market, dans
        l’hypothèse où cette information ne serait pas protégée par le droit des
        données personnelles) ne pourra être communiquée, partagée ou divulguée
        avec aucune autre personne que celle ayant accueilli le Prestataire pour
        l’exécution d’une Prestation voire géré l’exécution de celle-ci avec le
        Prestataire.<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs11">
        Par exemple, les agences immobilières exerçant sous une même enseigne ne
        sauraient être considérées comme un même Client. Il en résulte que les
        informations liées à une Prestation réalisée pour une agence ne peuvent
        être communiquées ou partagées avec une autre. La même analyse
        s’applique pour les agents d’une même agence pour l’application de cette
        clause.
      </p>
      <p className="p-cgs11">
        12.4<span className="Apple-tab-span"> </span>Cette obligation de
        confidentialité restera valable pendant une durée de trois (3) ans après
        l’exécution d’une Prestation.
      </p>
      <p className="p-cgs11">
        12.5<span className="Apple-tab-span"> </span>Dans le cas où le
        Prestataire contreviendrait au présent article ou nuirait de manière
        directe ou indirecte à la réputation de Wall-Market, Wall-Market se
        réserve le droit d’engager toute action judiciaire destinée à préserver
        ses droits ou obtenir réparation du préjudice en résultant.
      </p>
      <p className="p-cgs4">
        <b>Article 13 : DIVERS</b>
      </p>
      <p className="p-cgs8">
        A l’issue de chaque Prestation, le Prestataire aura la faculté de la
        commenter, par exemple, en partageant son appréciation de la performance
        des services de Wall-Market ainsi que la manière dont elle s’est
        déroulée avec le Client.<span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <b>Article 14 : DROIT APPLICABLE ET JURIDICTIONS COMPETENTES</b>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>
        <b>14.1 Les présentes sont soumises au droit français. </b>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>
        <b>
          14.2 Toute contestation relative à la validité, l’interprétation,
          l’exécution ou la rupture des présentes, qu’elle soit de nature
          contractuelle ou délictuelle, relève de la compétence territoriale
          exclusive des tribunaux de Paris.
        </b>
      </p>
      <div style={{ marginTop: 100 }}>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Annexe I</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          Reproduction de l’article 28 du Règlement Général sur la Protection
          des Données 2016/679 du 27 avril 2016 <br />
          <i>
            Pour l’interprétation et l’application de ce texte, le Prestataire
            est « sous-traitant » et Wall-Market « responsable de traitement ».
          </i>
        </p>
        <p className="p-cgs2">
          <b>Article 28 - Sous-traitant</b>
        </p>
        <p className="p-cgs2">
          1. {"Lorsqu'un"} traitement doit être effectué pour le compte {"d'un"}{" "}
          responsable du traitement, celui-ci fait uniquement appel à des
          sous-traitants qui présentent des garanties suffisantes quant à la
          mise en œuvre de mesures techniques et organisationnelles appropriées
          de manière à ce que le traitement réponde aux exigences du présent
          règlement et garantisse la protection des droits de la personne
          concernée.
        </p>
        <p className="p-cgs2">
          2. Le sous-traitant ne recrute pas un autre sous-traitant sans{" "}
          {"l'autorisation"} écrite préalable, spécifique ou générale, du
          responsable du traitement. Dans le cas {"d'une"} autorisation écrite
          générale, le sous-traitant informe le responsable du traitement de
          tout changement prévu concernant {"l'ajout"} ou le remplacement{" "}
          {"d'autres"} sous-traitants, donnant ainsi au responsable du
          traitement la possibilité {"d'émettre"} des objections à{" "}
          {"l'encontre"} de ces changements.
        </p>
        <p className="p-cgs2">
          3. Le traitement par un sous-traitant est régi par un contrat ou un
          autre acte juridique au titre du droit de {"l'Union ou du droit d'un"}{" "}
          État membre, qui lie le sous-traitant à {"l'égard"} du responsable du
          traitement, définit {"l'objet"} et la durée du traitement, la nature
          et la finalité du traitement, le type de données à caractère personnel
          et les catégories de personnes concernées, et les obligations et les
          droits du responsable du traitement. Ce contrat ou cet autre acte
          juridique prévoit, notamment, que le sous-traitant:
        </p>
        <p className="p-cgs4">
          a) ne traite les données à caractère personnel que sur instruction
          documentée du responsable du traitement, y compris en ce qui concerne
          les transferts de données à caractère personnel vers un pays tiers ou
          à une organisation internationale, à moins {"qu'il"} ne soit tenu{" "}
          {"d'y"} procéder en vertu du droit de{" "}
          {"l'Union ou du droit de l'État membre"} auquel le sous-traitant est
          soumis; dans ce cas, le sous-traitant informe le responsable du
          traitement de cette obligation juridique avant le traitement, sauf si
          le droit concerné interdit une telle information pour des motifs
          importants {"d'intérêt"} public;
        </p>
        <p className="p-cgs4">
          b) veille à ce que les personnes autorisées à traiter les données à
          caractère personnel {"s'engagent"} à respecter la confidentialité ou
          soient soumises à une obligation légale appropriée de confidentialité;
        </p>
        <p className="p-cgs4">
          c) prend toutes les mesures requises en vertu de{" l'article 32"};
        </p>
        <p className="p-cgs4">
          d) respecte les conditions visées aux paragraphes 2 et 4 pour recruter
          un autre sous-traitant;
        </p>
        <p className="p-cgs4">
          e) tient compte de la nature du traitement, aide le responsable du
          traitement, par des mesures techniques et organisationnelles
          appropriées, dans toute la mesure du possible, à s’acquitter de son
          obligation de donner suite aux demandes dont les personnes concernées
          le saisissent en vue {"d'exercer"} leurs droits prévus au chapitre
          III;
        </p>
        <p className="p-cgs4">
          f) aide le responsable du traitement à garantir le respect des
          obligations prévues aux articles 32 à 36, compte tenu de la nature du
          traitement et des informations à la disposition du sous-traitant;
        </p>
        <p className="p-cgs4">
          g) selon le choix du responsable du traitement, supprime toutes les
          données à caractère personnel ou les renvoie au responsable du
          traitement au terme de la prestation de services relatifs au
          traitement, et détruit les copies existantes, à moins que le droit de{" "}
          {"l'Union"} ou le droit de {"l'État membre n'exige"} la conservation
          des données à caractère personnel; et
        </p>
        <p className="p-cgs4">
          h) met à la disposition du responsable du traitement toutes les
          informations nécessaires pour démontrer le respect des obligations
          prévues au présent article et pour permettre la réalisation{" "}
          {"d'audits"}, y compris des inspections, par le responsable du
          traitement ou un autre auditeur {"qu'il"} a mandaté, et contribuer à
          ces audits.
        </p>
        <p className="p-cgs4">
          En ce qui concerne le point h) du premier alinéa, le sous-traitant
          informe immédiatement le responsable du traitement si, selon lui, une
          instruction constitue une violation du présent règlement ou{" "}
          {"d'autres"} dispositions du droit de {"l'Union"} ou du droit des
          États membres relatives à la protection des données.
        </p>
        <p className="p-cgs2">
          4. {"Lorsqu'un"} sous-traitant recrute un autre sous-traitant pour
          mener des activités de traitement spécifiques pour le compte du
          responsable du traitement, les mêmes obligations en matière de
          protection de données que celles fixées dans le contrat ou un autre
          acte juridique entre le responsable du traitement et le sous-traitant
          conformément au paragraphe 3, sont imposées à cet autre sous-traitant
          par contrat ou au moyen {"d'un"} autre acte juridique au titre du
          droit de {"l'Union"} ou du droit {"d'un"} État membre, en particulier
          pour ce qui est de présenter des garanties suffisantes quant à la mise
          en œuvre de mesures techniques et organisationnelles appropriées de
          manière à ce que le traitement réponde aux exigences du présent
          règlement. Lorsque cet autre sous-traitant ne remplit pas ses
          obligations en matière de protection des données, le sous-traitant
          initial demeure pleinement responsable devant le responsable du
          traitement de {"l'exécution"} par {"l'autre"} sous-traitant de ses
          obligations.
        </p>
        <p className="p-cgs2">
          5. {"L'application,"} par un sous-traitant, {"d'un"} code de conduite
          approuvé comme le prévoit {"l'article"} 40 ou {"d'un mécanisme"} de
          certification approuvé comme le prévoit {"l'article 42"} peut servir{" "}
          {"d'élément"} pour démontrer {"l'existence"} des garanties suffisantes
          conformément aux paragraphes 1 et 4 du présent article.
        </p>
        <p className="p-cgs2">
          6. Sans préjudice {"d'un"} contrat particulier entre le responsable du
          traitement et le sous-traitant, le contrat ou {"l'autre"} acte
          juridique visé aux paragraphes 3 et 4 du présent article peut être
          fondé, en tout ou en partie, sur les clauses contractuelles types
          visées aux paragraphes 7 et 8 du présent article, y compris{" "}
          {"lorsqu'elles"} font partie {"d'une"} certification délivrée au
          responsable du traitement ou au sous-traitant en vertu des articles 42
          et 43.
        </p>
        <p className="p-cgs2">
          7. La Commission peut établir des clauses contractuelles types pour
          les questions visées aux paragraphes 3 et 4 du présent article et
          conformément à la procédure {"d'examen"} visée à {"l'article 93"},
          paragraphe 2
        </p>
        <p className="p-cgs2">
          8. Une autorité de contrôle peut adopter des clauses contractuelles
          types pour les questions visées aux paragraphes 3 et 4 du présent
          article et conformément au mécanisme de contrôle de la cohérence visé
          à {"l'article 63"}.
        </p>
        <p className="p-cgs2">
          9. Le contrat ou {"l'autre"} acte juridique visé aux paragraphes 3 et
          4 se présente sous une forme écrite, y compris en format électronique.
        </p>
        <p className="p-cgs2">
          10. Sans préjudice des articles 82, 83 et 84, si, en violation du
          présent règlement, un sous-traitant détermine les finalités et les
          moyens du traitement, il est considéré comme un responsable du
          traitement pour ce qui concerne ce traitement.
        </p>
      </div>
    </div>
  );
}

export default MainContract;
