/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/self-closing-comp */

import React from "react";

function MainContract() {
  return (
    <div className="cgs-main-contract">
      <p className="p-cgs2">
        <b>SAS Wall-Market</b>, French <i>société par actions simplifiée</i>{" "}
        (simplified company) with a share capital of €19,146, whose registered
        office is located at 22 Rue Legendre, 75017 Paris, registered in the
        Paris Trade and Companies Register under unique identification number
        532 727 740, represented by Mr Guillaume Brochut in his capacity as its
        Chairman,
      </p>
      <p className="p-cgs2">
        Hereinafter referred to as « <b>Wall-Market </b>»,
      </p>
      <p className="p-cgs3">
        <b>Clause 1:  PURPOSE</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.1
        <span className="Apple-tab-span"> </span>The purpose of these general
        terms and conditions is to set out the undertakings and obligations of
        SAS Wall-Market and the provider (hereinafter the « <b>Provider</b> »)
        for any services agreement entered into between the Provider and
        Wall-Market (hereinafter the <b>Service(s)</b> »).
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.2
        <span className="Apple-tab-span"> </span>Wall-Market originally
        specialised in the development of digital tools and the provision of
        services online (or using any other medium), designed to facilitate the
        sale and rental of properties by real estate professionals. To that end,
        Wall-Market has made significant investments, since its creation, to
        develop its base of business customers and become their go-to company.
        Wall-Market then used that experience to also develop a range of online
        services for individuals.{" "}
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.3
        <span className="Apple-tab-span"> </span>The Provider is an independent
        professional running a business under the small business scheme
        (auto-entrepreneur scheme) or in the form of a company, and in any event
        running a business registered with a French SIREN or SIRET business
        number, or a registration number allocated by the administrative
        authorities of the country of the business’s registered office.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.4
        <span className="Apple-tab-span"> </span>The Provider runs its own
        business and agrees to carry on this business with Wall-Market for the
        account of Wall-Market’s customers (hereinafter the « <b>Customer(s)</b>
         »).
      </p>
      <p className="p-cgs3">
        <b>Clause 2: PROVIDER’S UNDERTAKINGS AND REPRESENTATIONS</b>
      </p>
      <p className="p-cgs2">The provider:</p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>acknowledges that it is covered
        by a professional indemnity insurance policy insuring the professional
        work that the Provider has agreed to perform with Wall-Market and the
        risks that may be associated with during travelling and/or the
        performance of a Service;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>represents that it is not a
        real estate agent and does not act as a real estate agent by delegation;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>undertakes to comply with all
        the obligations set out herein for the performance of any Service with
        Wall-Market, in particular those relating to personal data;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>represents that it has never
        been convicted, in accordance with the French rules governing limitation
        periods after which sentences can no longer be enforced, of an offence
        threatening the life, impairing the dignity or violating the personal
        rights of an individual or causing physical or psychological harm to an
        individual or causing damage to property (for example: theft, extortion,
        discrimination, rape, sexual assault, moral or sexual harassment,
        obtaining by false pretences or breach of trust);
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>undertakes not to subcontract
        the performance of a Service to a third party without the prior written
        consent of Wall-Market;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>represents that it is
        responsible for paying all social security contributions, taxes and
        levies for which it is liable;{" "}
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>represents that the information
        provided to Wall-Market is up to date;{" "}
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>undertakes to comply with the
        applicable personal data protection regulations in force and, in
        particular, Regulation (EU) 2016/679 of the European Parliament and of
        the Council of 27 April 2016, effective from 25 May 2018; and
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>undertakes not to approach a
        Customer of Wall-Market who has received a Service in order to propose
        another service (in particular of the same or a related nature) to the
        Customer, directly or indirectly, at the time of its performance.
      </p>
      <p className="p-cgs6">
        Under no circumstances may the Provider offer to give the contact
        details of a real estate agent to any consumers who have ordered a
        Service for the sale or rental of their apartment. Likewise, any
        provision of the contact details of such a consumer to a real estate
        agent is also strictly prohibited under the terms hereof and under the
        personal data regulations.
      </p>
      <p className="p-cgs6">
        In any event, the Provider must strictly comply with the rules governing
        consumer rights and personal data.
      </p>
      <p className="p-cgs7">
        These representations are an essential condition of Wall-Market’s
        engagement.
      </p>
      <p className="p-cgs3">
        <b>Clause 3: DESCRIPTION OF THE SERVICES</b>
      </p>
      <p className="p-cgs7">
        These general terms and conditions apply to any Service(s) for which the
        Provider has:
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>proposed its services by
        registering in its personal account made available by Wall-Market, in
        line with its professional expertise; or{" "}
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>accepted a request for services
        submitted by Wall-Market, matching its professional expertise, outside
        of any time slot registered by the Provider.
      </p>
      <p className="p-cgs3">
        <b>Clause 4: PERFORMANCE OF A SERVICE</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.1
        <span className="Apple-tab-span"> </span>The Provider will control and
        be liable, in a completely independent manner, for the Services it has
        proposed to perform.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.2
        <span className="Apple-tab-span"> </span>The Provider undertakes to
        perform all Services in accordance with:{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>the regulations in force;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>standard practice, in
        particular as applicable in the Provider’s industry, with all the care
        and attention of a leading professional technician; and{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>where applicable, the
        specifications drawn up by Wall-Market.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.3
        <span className="Apple-tab-span"> </span>The Provider also undertakes to
        perform all Services on the date and at the time agreed with
        Wall-Market.
      </p>
      <p className="p-cgs4">
        The agreed date and time may not be postponed without the prior express
        consent of Wall-Market, even if requested by Wall-Market’s Customer.
        Accordingly, the Provider may not suggest the postponement of a Service
        to a Wall-Market Customer directly.
      </p>
      <p className="p-cgs4">
        If the Provider is unable to perform a Service on the agreed date and at
        the agreed time due to exceptional circumstances, the Provider must
        inform Wall-Market without delay. If the Provider is unable to perform a
        service but cannot prove the exceptional nature of this failing, the
        services agreement will be terminated at its own initiative.
      </p>
      <p className="p-cgs9">
        <b>Clause 5: DELIVERY</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>5.1
        <span className="Apple-tab-span"> </span>The Provider acknowledges that
        it has been informed of the binding terms applicable between Wall-Market
        and its Customers, set out in Wall-Market’s general terms and conditions
        of sale, in particular as regards delivery times, and has notified its
        availability in line with that constraint.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>5.2
        <span className="Apple-tab-span"> </span>All Services must be delivered
        through the Provider’s Account.
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>5.3
          <span className="Apple-tab-span"> </span>Accordingly, the Provider
          undertakes to deliver the file(s) and/or the URL(s) for a Service
          promptly and at the latest, within the following times:
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>For a Service involving a
          visit to the premises of a Customer or the person for whom the
          Customer is acting:
        </b>{" "}
        prompt delivery, within no more than 16 hours of the time at which the
        Provider performed the Service at the premises of Wall-Market’s
        Customer;
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>For a Service performed from
          the Provider’s place of work (for example: post-production):{" "}
        </b>
        within 4 hours of receipt of Wall-Market’s order.{" "}
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>Clause 6: PRICES AND PAYMENT AND INVOICING TERMS</b>
      </p>
      <p className="p-cgs4">
        <b>
          6.1<span className="Apple-tab-span"> </span>Price
        </b>
      </p>
      <p className="p-cgs4">
        For each Service, the Provider will earn the fixed fee agreed with
        Wall-Market.
      </p>
      <p className="p-cgs4">
        The Provider acknowledges that none of the Services are protected by
        copyright.
      </p>
      <p className="p-cgs4">
        If the Provider disagrees, the Provider undertakes not to accept a
        Service under these general terms and conditions and to promptly inform
        Wall-Market in writing, explaining its position. If the Provider
        notifies its disagreement after the acceptance of these general terms
        and conditions, the performance of the agreed Service(s) may be
        suspended.
      </p>
      <p className="p-cgs4">
        In the unlikely event that a Service is treated as protected by
        copyright notwithstanding the agreement of the Parties, the Provider
        acknowledges that the agreed fixed fee includes the remuneration for the
        assignment of copyright, as described in Clause 9 below.{" "}
      </p>
      <p className="p-cgs4">
        <b>
          6.2<span className="Apple-tab-span"> </span>Payment terms
        </b>
      </p>
      <p className="p-cgs4">Unless otherwise agreed, all Services will be:</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>invoiced to Wall-Market in
        accordance with the invoicing authorisation on the first day of the
        month following the service; and
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>automatically paid by
        Wall-Market on the eighth days of the month following the month in which
        the Service was performed.
      </p>
      <p className="p-cgs9">
        <b>Clause 7: TERMINATION AND CANCELLATION OF THE AGREEMENT</b>
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.1
          <span className="Apple-tab-span"> </span>Save in the cases described
          below, Wall-Market’s acceptance of one of the Provider’s time slots
          for the performance of a Service for the account of a Customer forms a
          contract and as such, is binding on the Parties.{" "}
        </b>
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.2
          <span className="Apple-tab-span"> </span>Cancellation clause
        </b>
      </p>
      <p className="p-cgs4">
        The Service will be cancelled by operation of law, without any need to
        complete any formalities or give any formal notice, if a Customer
        cancels an order placed with Wall-Market more than six (6) hours before
        the fulfilment of the order.{" "}
      </p>
      <p className="p-cgs4">
        Wall-Market undertakes to immediately inform the Provider of any such
        event as soon as it becomes aware of the event.
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.3
          <span className="Apple-tab-span"> </span>Termination by Wall-Market
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market may terminate an order for Services at any time within the
        6-hour period before its fulfilment. In such a case, Wall-Market
        undertakes to compensate the Provider with a fee agreed upon in advance
        with the latter.
      </p>
      <p className="p-cgs3">
        <b>Clause 8: SHORTENED Limitation Period</b>
      </p>
      <p className="p-cgs4">
        Pursuant to Article 2254 of the French Civil Code (code civil), the
        Parties agree to reduce the limitation period to one year from the
        performance of a Service for any dispute relating to Clause 7 above,
        regardless of its basis and whether it concerns its validity or its
        performance.
      </p>
      <p className="p-cgs3">
        <b>Clause 9: LIABILITY</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>9.1
        <span className="Apple-tab-span"> </span>The Provider will be solely
        liable for any loss caused to Wall-Market by any breach of any one of
        the Provider’s undertakings or representations under these general terms
        and conditions.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>9.2
        <span className="Apple-tab-span"> </span>The Provider is therefore
        solely liable for the circumstances in which a Service is provided and
        for any personal injury and/or property damage that may be caused.{" "}
      </p>
      <p className="p-cgs8">
        The Provider thus undertakes to bear the losses and costs associated
        with any right of recourse exercised or claim filed against Wall-Market
        by one of its Customers, due to a Service performed by the Provider.
      </p>
      <p className="p-cgs8">
        The Provider also undertakes to bear any loss caused to Wall-Market by
        such a claim.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>9.3
        <span className="Apple-tab-span"> </span>Wall-Market undertakes to
        inform the Provider of any right of recourse exercised or claim filed by
        one of its Customers in that respect.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>Clause 10: INTELLECTUAL PROPERTY</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.1
        <span className="Apple-tab-span"> </span>The Parties represent and
        acknowledge that none of the works resulting from the performance of a
        Service (hereinafter the « <b>Work(s)</b> ») are protected by copyright,
        with regard to positive law and the nature of the Service, as they do
        not satisfy the conditions for being treated as an original work.
      </p>
      <p className="p-cgs4">
        Accordingly, all Works will be the exclusive property of Wall-Market,
        which may alone decide how they are to be used.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.2
        <span className="Apple-tab-span"> </span>In the unlikely event that a
        Work is treated as a work protected by copyright, Wall-Market and the
        Provider agree as follows:
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>The Work resulting from a
        Service was produced for the sole benefit of Wall-Market and it was
        always intended that it would form part of Wall-Market’s intellectual
        assets to be exploited by it on an exclusive basis;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>The Provider assigns to
        Wall-Market, and represents that it has the capacity to do so in that
        respect, exclusively, worldwide and for the duration of the intellectual
        property rights, the rights of reproduction, adaptation, distribution,
        representation and exploitation attached to the Works, for the purpose
        and on the terms specified below;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>The assignment is completed in
        consideration of the fee provided for in Clause 6 above.{" "}
      </p>
      <p className="p-cgs10">Purpose of the Assignment:</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>listing and rental of
        properties and any related business activities for the representation or
        communication of a Wall-Market Customer; and
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>provision of services to
        facilitate the sale and rental of properties.
      </p>
      <p className="p-cgs11">
        Pursuant to the provisions of Article L. 131-3 of the French
        Intellectual Property Code (code de la propriété intellectuelle), the
        rights assigned to Wall-Market include, in particular:
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>For the right of reproduction:
        the right to reproduce or cause the reproduction of any Work, without
        any limitation in number, in whole or in part, on any type of medium
        (including on any digital medium, in particular on the websites
        published by Wall-Market or its application(s), including on a network)
        and using any type of means and via any processes, including electronic
        processes, now known or hereafter devised;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>For the right of adaptation:
        the right to adapt or cause the adaptation of all or part of the Works
        and, in particular, the right to translate them into any programming
        language;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>For the right of
        representation: the right to publish or distribute, or cause the
        publication or distribution of, all or part of a Work, in any manner
        whatsoever, using any type of process whatsoever, now known or hereafter
        devised, including via all telecommunications networks and electronic
        communications to the public, including within the meaning of French
        Confidence in the Digital Economy Act No. 2004-575 of 21 June 2004,
        whether current or future, such as the Internet or mobile networks,
        along with broadcasting by any means of telecommunication, including
        transmission via terrestrial means, cable or satellite, on any type of
        medium mentioned in this Clause, in any format, directly or through
        third parties. This right includes the right to communicate or make
        available to the public, by wire or wireless means, to allow everyone to
        have access to the Work(s) at the place and at the time individually
        chosen for the agreed purpose;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>For the right of exploitation:
        the right to transfer to the Customers, in whole or in part, in any form
        whatsoever and, in particular, by means of an assignment, licence or any
        type of contract, in any form, all or part of the rights assigned, on a
        temporary basis or for the entire term of this assignment.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.3
        <span className="Apple-tab-span"> </span>The Provider expressly warrants
        to Wall-Market that it will have the full and complete enjoyment of the
        rights assigned hereunder in and to the Works.
      </p>
      <p className="p-cgs8">
        In that respect, the Provider shall indemnify Wall-Market from and
        against any claim relating thereto, filed by any third party alleging an
        infringement of any right whatsoever and, in particular, from and
        against all costs, expenses and awards in the event of proceedings based
        on an infringement, invasion of privacy and/or unfair competition and/or
        freeriding issued by any third party on the basis of any personality or
        intellectual or industrial property rights whatsoever owned by the third
        party or for which the third party holds a right of enjoyment.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.4
        <span className="Apple-tab-span"> </span>In any event, as the Works are
        produced in private areas, the Provider may not exploit them (in order
        to avoid any infringement of the protected rights attached thereto)
        without the prior written consent of Wall-Market after obtaining the
        prior written consent of the owner and/or occupier of the property.{" "}
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>Clause 11: PERSONAL DATA</b>
      </p>
      <p className="p-cgs4">
        The Provider undertakes to comply with the personal data regulations.
      </p>
      <p className="p-cgs4">
        These Clauses are designed to ensure that Wall-Market and the Provider
        will be able to effectively fulfil their obligations under the personal
        data regulations.
      </p>
      <p className="p-cgs4">
        In any case, the Provider undertakes to strictly respect the provisions
        of Article 28 of Regulation (EU) 2016/679 on the protection of personal
        data, of 27 April 2016, enclosed in Appendix I, in its capacity as
        “providor” of the data under this Regulation, Wall-Market being the
        “Controller”.
      </p>
      <p className="p-cgs4">
        <b>
          Wall-Market hereby informs the Provider that it has not obtained its
          Customers’ authorisation to use their personal data for direct
          marketing purposes. As a result, no personal data (for example:
          telephone numbers) of a Customer disclosed to the Provider for the
          performance of a Service may be used by the Provider for direct
          marketing purposes.
        </b>
      </p>
      <p className="p-cgs4">
        <b>11.1</b>
        <span className="Apple-tab-span"> </span>
        <b>Description of the processing performed as part of the Service</b>
      </p>
      <p className="p-cgs4">
        The Provider is authorised to process the personal data required to
        perform the Services, on Wall-Market’s behalf.
      </p>
      <p className="p-cgs4">
        Nature of the operations performed on the data: collection and temporary
        recording of the data of Wall-Market’s Customers for the purposes of the
        performance of a Service.
      </p>
      <p className="p-cgs4">Purpose of the processing: to perform a Service.</p>
      <p className="p-cgs4">
        The following types of personal data will be processed:
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>1.
        <span className="Apple-tab-span"> </span>The identity of Wall-Market’s
        Customer and/or the principal/agent and any information useful for the
        provision of a Service, namely: surname, forename, email address, postal
        address, telephone number, entry code for the place where the Service is
        to be performed and, where applicable, occupation;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>2.
        <span className="Apple-tab-span"> </span>The nature of the purchases
        made by Wall-Market’s Customer via its e-commerce website, outsourced to
        the Provider.
      </p>
      <p className="p-cgs4">
        <b>
          11.2<span className="Apple-tab-span"> </span>Provider’s obligations to
          Wall-Market
        </b>
      </p>
      <p className="p-cgs4">The Provider undertakes to: </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>1.
        <span className="Apple-tab-span"> </span>process the data solely for the
        purpose(s) set out in this agreement;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>2.
        <span className="Apple-tab-span"> </span>process data in line with its
        purpose;{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>3.
        <span className="Apple-tab-span"> </span>protect the confidentiality of
        the personal data processed for the purposes of this agreement;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>4.
        <span className="Apple-tab-span"> </span>ensure that the persons
        authorised to process personal data under this agreement:{" "}
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs16">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>have committed themselves to
        confidentiality or are under an appropriate statutory obligation of
        confidentiality;
      </p>
      <p className="p-cgs16">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>receive the required personal
        data protection training;{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>5.
        <span className="Apple-tab-span"> </span>take into account the
        principles of data protection by design and by default for its tools,
        products, applications and services.
      </p>
      <p className="p-cgs4">
        <b>
          11.3<span className="Apple-tab-span"> </span>Data subject right to be
          informed
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market undertakes to inform its Customer of the processing
        operations that may be performed by Wall-Market or the Provider, prior
        to the collection of the data.
      </p>
      <p className="p-cgs4">
        The Provider must inform Wall-Market’s employees of any processing of
        their data prior to or at the time of the collection of the data.{" "}
      </p>
      <p className="p-cgs4">
        <b>
          11.4<span className="Apple-tab-span"> </span>Exercise of data subject
          rights
        </b>
      </p>
      <p className="p-cgs4">
        Insofar as this is possible, the Provider shall provide assistance to
        Wall-Market for the fulfilment of its obligation to respond to requests
        for exercising data subject rights: right of access, right to
        rectification and erasure, right to object, right to restrict
        processing, right to data portability and right to prevent automated
        individual decision-making (including profiling).{" "}
      </p>
      <p className="p-cgs4">
        If the Provider receives requests for exercising their rights from the
        data subjects directly, the Provider shall forward those requests to
        Wall-Market on receipt, by emailing them to: rgpd@wall-market.com.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <b>
          11.5<span className="Apple-tab-span"> </span>Notice of personal data
          breaches
        </b>
      </p>
      <p className="p-cgs4">
        The Provider undertakes to inform Wall-Market within no more than 24
        hours of becoming aware of a personal data breach by email (to
        rgpd@wall-market.com) and by telephone (using the following number: +33
        1 42 27 45 53).
      </p>
      <p className="p-cgs4">
        The notice must enclose all appropriate documentation to allow
        Wall-Market to notify the breach to the appropriate supervisory
        authority, where necessary.
      </p>
      <p className="p-cgs4">
        <b>
          11.6<span className="Apple-tab-span"> </span>Security measures and
          post-processing arrangements
        </b>
      </p>
      <p className="p-cgs4">
        <b>The Provider undertakes to implement the following measures:</b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Destruction of any
          information or data relating to Wall-Market’s Customer after the
          delivery of a Service, as such information is of no use after the
          performance of a Service;
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Implementation of all
          measures needed to protect the confidentiality of the information or
          data relating to Wall-Market’s Customer prior to its destruction;
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Implementation of a process
          for regularly testing, assessing and evaluating the effectiveness of
          technical and organisational measures for ensuring the security of all
          data processing performed in connection with a Service provided on
          behalf of Wall-Market; and{" "}
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Destruction of data relating
          to Wall-Market’s employees within no more than one year of the end of
          the performance of a Service on its behalf.
        </b>
      </p>
      <p className="p-cgs4">
        <b>
          11.7<span className="Apple-tab-span"> </span>Documentation
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        The Provider shall make available to Wall-Market the documentation
        necessary to demonstrate compliance with all its obligations.{" "}
      </p>
      <p className="p-cgs4">
        <b>
          11.8<span className="Apple-tab-span"> </span>Wall-Market’s obligations
          to the Provider
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market undertakes to:{" "}
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>provide the Provider, directly
        or through its Customer, with the data required to provide a Service;{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>duly fulfil its personal data
        obligations.
      </p>
      <p className="p-cgs3">
        <b>Clause 12: CONFIDENTIALITY</b>
      </p>
      <p className="p-cgs11">
        12.1<span className="Apple-tab-span"> </span>Any information that is not
        protected under personal data laws obtained in connection with the
        performance of a Service is confidential and the Provider may not share
        it with or disclose/communicate it to a third party (including a
        Customer) for any reason whatsoever.
      </p>
      <p className="p-cgs11">
        12.2<span className="Apple-tab-span"> </span>In the event that the
        Provider is unable to comply with that obligation or it would be
        beneficial to Wall-Market for information to be disclosed or
        communicated to a Third Party, the Provider undertakes to inform
        Wall-Market to allow it to release the Provider from this obligation of
        confidentiality in writing, where appropriate.{" "}
      </p>
      <p className="p-cgs11">
        12.3<span className="Apple-tab-span"> </span>Confidential information
        about the property that is the subject of the Service (and where
        applicable, Wall-Market’s Customer, in the event that this information
        is not protected under personal data laws) may not be communicated,
        shared or disclosed with or to any person other than the person who met
        with the Provider for the performance of a Service and/or managed the
        performance thereof with the Provider.{" "}
      </p>
      <p className="p-cgs11">
        For example, real estate agencies operating under the same brand name
        may not be treated as one and the same Customer. Accordingly,
        information relating to a Service provided for an agency may not be
        communicated to or shared with another agency. The same analysis applies
        to agents working for the same agency for the purposes of the
        application of this Clause.
      </p>
      <p className="p-cgs11">
        12.4<span className="Apple-tab-span"> </span>This obligation of
        confidentiality will continue to apply for a period of three (3) years
        after the performance of a Service.
      </p>
      <p className="p-cgs11">
        12.5<span className="Apple-tab-span"> </span>In the event that the
        Provider breaches this Clause or directly or indirectly damages
        Wall-Market’s reputation, Wall-Market reserves the right to take any
        legal action to protect its rights or obtain compensation for the loss
        caused.
      </p>
      <p className="p-cgs4">
        <b>Clause 13: MISCELLANEOUS PROVISIONS</b>
      </p>
      <p className="p-cgs8">
        At the end of each Service, the Provider may review it, for example by
        sharing its opinion on the performance of Wall-Market’s services and how
        it went with the Customer.{" "}
      </p>
      <p className="p-cgs4">
        <b>Clause 14: GOVERNING LAW AND JURISDICTION</b>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>
        <b>
          14.1 These general terms and conditions are governed by French law.
        </b>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>
        <b>
          14.2 Any dispute relating to the validity, interpretation, performance
          or end of these general terms and conditions, whether in contract or
          tort, will be subject to the exclusive territorial jurisdiction of the
          courts in Paris.
        </b>
      </p>

      <div style={{ marginTop: 100 }}>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Appendix I</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          Reproduction of Article 28 of Regulation (EU) 2016/679 on the
          protection of personal data, of 27 April 2016 <br />
          <i>
            For the construction and enforcement of this Article, the Providor
            is the « Processor » and Wall-Market the « Controller».
          </i>
        </p>
        <p className="p-cgs2">
          <b>Article 28 - Processor</b>
        </p>
        <p className="p-cgs2">
          1. Where processing is to be carried out on behalf of a controller,
          the controller shall use only processors providing sufficient
          guarantees to implement appropriate technical and organisational
          measures in such a manner that processing will meet the requirements
          of this Regulation and ensure the protection of the rights of the data
          subject.
        </p>
        <p className="p-cgs2">
          2. The processor shall not engage another processor without prior
          specific or general written authorisation of the controller. In the
          case of general written authorisation, the processor shall inform the
          controller of any intended changes concerning the addition or
          replacement of other processors, thereby giving the controller the
          opportunity to object to such changes.
        </p>
        <p className="p-cgs2">
          3. Processing by a processor shall be governed by a contract or other
          legal act under Union or Member State law, that is binding on the
          processor with regard to the controller and that sets out the
          subject-matter and duration of the processing, the nature and purpose
          of the processing, the type of personal data and categories of data
          subjects and the obligations and rights of the controller. That
          contract or other legal act shall stipulate, in particular, that the
          processor:
        </p>
        <p className="p-cgs4">
          a) processes the personal data only on documented instructions from
          the controller, including with regard to transfers of personal data to
          a third country or an international organisation, unless required to
          do so by Union or Member State law to which the processor is subject;
          in such a case, the processor shall inform the controller of that
          legal requirement before processing, unless that law prohibits such
          information on important grounds of public interest;
        </p>
        <p className="p-cgs4">
          b) ensures that persons authorised to process the personal data have
          committed themselves to confidentiality or are under an appropriate
          statutory obligation of confidentiality;
        </p>
        <p className="p-cgs4">
          c) takes all measures required pursuant to Article 32;
        </p>
        <p className="p-cgs4">
          d) respects the conditions referred to in paragraphs 2 and 4 for
          engaging another processor;
        </p>
        <p className="p-cgs4">
          e) taking into account the nature of the processing, assists the
          controller by appropriate technical and organisational measures,
          insofar as this is possible, for the fulfilment of the{" "}
          {"controller's"} obligation to respond to requests for exercising the
          data {"subject's"} rights laid down in Chapter III;
        </p>
        <p className="p-cgs4">
          f) assists the controller in ensuring compliance with the obligations
          pursuant to Articles 32 to 36 taking into account the nature of
          processing and the information available to the processor;
        </p>
        <p className="p-cgs4">
          g) a elección del responsable, suprimirá o devolverá todos los datos
          personales una vez finalice la prestación de los servicios de
          tratamiento, y suprimirá las copias existentes a menos que se requiera
          la conservación de los datos personales en virtud del Derecho de la
          Unión o de los Estados miembros;
        </p>
        <p className="p-cgs4">
          h) makes available to the controller all information necessary to
          demonstrate compliance with the obligations laid down in this Article
          and allow for and contribute to audits, including inspections,
          conducted by the controller or another auditor mandated by the
          controller.
        </p>
        <p className="p-cgs4">
          With regard to point (h) of the first subparagraph, the processor
          shall immediately inform the controller if, in its opinion, an
          instruction infringes this Regulation or other Union or Member State
          data protection provisions.
        </p>
        <p className="p-cgs2">
          4. Where a processor engages another processor for carrying out
          specific processing activities on behalf of the controller, the same
          data protection obligations as set out in the contract or other legal
          act between the controller and the processor as referred to in
          paragraph 3 shall be imposed on that other processor by way of a
          contract or other legal act under Union or Member State law, in
          particular providing sufficient guarantees to implement appropriate
          technical and organisational measures in such a manner that the
          processing will meet the requirements of this Regulation. Where that
          other processor fails to fulfil its data protection obligations, the
          initial processor shall remain fully liable to the controller for the
          performance of that other {"processor's"} obligations.
        </p>
        <p className="p-cgs2">
          5. Adherence of a processor to an approved code of conduct as referred
          to in Article 40 or an approved certification mechanism as referred to
          in Article 42 may be used as an element by which to demonstrate
          sufficient guarantees as referred to in paragraphs 1 and 4 of
          this Article.
        </p>
        <p className="p-cgs2">
          6. Without prejudice to an individual contract between the controller
          and the processor, the contract or the other legal act referred to in
          paragraphs 3 and 4 of this Article may be based, in whole or in part,
          on standard contractual clauses referred to in paragraphs 7 and 8 of
          this Article, including when they are part of a certification granted
          to the controller or processor pursuant to Articles 42 and 43.
        </p>
        <p className="p-cgs2">
          7. The Commission may lay down standard contractual clauses for the
          matters referred to in paragraph 3 and 4 of this Article and in
          accordance with the examination procedure referred to in Article
          93(2).
        </p>
        <p className="p-cgs2">
          8. A supervisory authority may adopt standard contractual clauses for
          the matters referred to in paragraph 3 and 4 of this Article and in
          accordance with the consistency mechanism referred to in Article 63.
        </p>
        <p className="p-cgs2">
          9.The contract or the other legal act referred to in paragraphs 3 and
          4 shall be in writing, including in electronic form.
        </p>
        <p className="p-cgs2">
          10. Without prejudice to Articles 82, 83 and 84, if a processor
          infringes this Regulation by determining the purposes and means of
          processing, the processor shall be considered to be a controller in
          respect of that processing.
        </p>
      </div>
    </div>
  );
}

export default MainContract;
