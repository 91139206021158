import * as Cookies from 'js-cookie';

import config from '../../config';

export const getAccessToken = () => Cookies.get(config.access_token_cookie_name);

export const setAccessToken = (accessToken, expirationDelay) => {
  if (accessToken && expirationDelay) {
    if (config.env_mode && config.env_mode.toLowerCase() === "prod")
      Cookies.set(config.access_token_cookie_name, accessToken, {expires: expirationDelay, secure: true});
    else
      Cookies.set(config.access_token_cookie_name, accessToken, {expires: expirationDelay});
  }
}

export const removeAccessToken = () => {
  if (getAccessToken())
    Cookies.remove(config.access_token_cookie_name);
}

export const getLocale  = () => {
  const locale = Cookies.get(config.locale_cookie_name);

  if (locale)
    return locale;
  else
    return null;
}

export const setLocale = (locale) => {
  if (locale)
    Cookies.set(config.locale_cookie_name, locale, {expires: 365});
}

export const setPhoneNumber = (number) => {
  if (number) {
    Cookies.set(config.phone_number_cookie_name, number, {expires: 365});
  }
}

export const getPhoneNumber = () => {
  const number = Cookies.get(config.phone_number_cookie_name);

  if (number) {
    return number;
  }
  else {
    return config.phone_number;
  }
}

export const setContactEmail = (email) => {
  if (email) {
    Cookies.set(config.contact_email_cookie_name, email, {expires: 365});
  }
}

export const getContactEmail = () => {
  const email = Cookies.get(config.contact_email_cookie_name);

  if (email) {
    return email;
  }
  else {
    return config.contact_email;
  }
}

/**
 * Geo settings with cookies
 */
/*
export const getCurrency = () => {
  const currency = Cookies.get(config.currency_cookie_name);

  if (currency)
    return currency;
  else
    return null;
}

export const setCurrency = (currency) => {
  if (currency)
    Cookies.set(config.currency_cookie_name, currency, {expires: 365});
}

export const getTimezone = () => {
  const timezone = Cookies.get(config.timezone_cookie_name);

  if (timezone)
    return timezone;
  else
    return null;
}

export const setTimezone = (timezone) => {
  if (timezone)
    Cookies.set(config.timezone_cookie_name, timezone, {expires: 365});
}

export const getResidenceCountry = () => {

  const country = Cookies.get(config.residence_country_cookie_name);

  if (country)
    return country;
  else
    return config.default_country;
}

export const setResidenceCountry = country => {
  if (country)
    Cookies.set(config.residence_country_cookie_name, country, {expires: 365});
}
*/
