import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Today as TodayIcon,
  Visibility,
} from "@material-ui/icons";

import Details from "./details/Details";
import translation from "../../../translation/translation";

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      isAdminLoading: false,
      isDeleteLoading: false,
      isDetailsOpen: false,
      isUnlinkCompanyUserDialogOpen: false,
    };

    this.handleAdminizeUser = this.handleAdminizeUser.bind(this);
    this.handleUnlinkUser = this.handleUnlinkUser.bind(this);

    this.handleOpenDetails = this.handleOpenDetails.bind(this);
    this.handleCloseDetails = this.handleCloseDetails.bind(this);

    this.handleOpenUnlinkCompanyUserDialog = this.handleOpenUnlinkCompanyUserDialog.bind(
      this
    );
    this.handleCloseUnlinkCompanyUserDialog = this.handleCloseUnlinkCompanyUserDialog.bind(
      this
    );
  }

  UNSAFE_componentWillMount() {
    const { user } = this.props;

    if (user && user.company_admin) {
      this.setState({ checked: true });
    }
  }

  handleAdminizeUser(event, value) {
    const { adminizeCompanyUser, user } = this.props;

    this.setState({ isAdminLoading: true });

    adminizeCompanyUser(
      user.id,
      value ? true : false,
      () => {
        this.setState({
          checked: !this.state.checked,
          isAdminLoading: false,
        });
      },
      () => {
        this.setState({
          checked: this.state.checked,
          isAdminLoading: false,
        });
      }
    );
  }

  handleUnlinkUser() {
    const { getCompanyUsers, unlinkCompanyUser, user } = this.props;

    this.setState({ isDeleteLoading: true });

    unlinkCompanyUser(
      user.id,
      () => {
        getCompanyUsers();

        this.setState({
          isDeleteLoading: false,
          isUnlinkCompanyUserDialogOpen: false,
        });
      },
      () => {
        this.setState({ isDeleteLoading: false });
      }
    );
  }

  handleOpenUnlinkCompanyUserDialog() {
    this.setState({
      isUnlinkCompanyUserDialogOpen: true,
    });
  }

  handleCloseUnlinkCompanyUserDialog() {
    this.setState({
      isUnlinkCompanyUserDialogOpen: false,
    });
  }

  handleOpenDetails() {
    this.setState({
      isDetailsOpen: true,
    });
  }

  handleCloseDetails() {
    this.setState({
      isDetailsOpen: false,
    });
  }

  render() {
    const { userLogged, user, id } = this.props;

    const {
      checked,
      isAdminLoading,
      isDeleteLoading,
      isDetailsOpen,
      isUnlinkCompanyUserDialogOpen,
    } = this.state;

    const iconCheckStyle = {
      display: "block",
      textAlign: "right",
      padding: "12px 15px 12px 12px",
      fontSize: 16,
    };

    const iconUncheckedStyle = {
      ...iconCheckStyle,
      color: "#48C17B",
    };

    const iconCheckedStyle = {
      ...iconCheckStyle,
      color: "#F1664F",
    };

    return (
      <TableRow>
        <TableCell>{user.last_name}</TableCell>
        <TableCell>{user.first_name}</TableCell>
        <TableCell align="right">
          {isAdminLoading ? (
            <CircularProgress
              color="secondary"
              size={24}
              style={{ margin: 12 }}
            />
          ) : (
            <span>
              {userLogged.id !== user.id ? (
                <Checkbox
                  checked={checked}
                  onChange={this.handleAdminizeUser}
                  color="default"
                />
              ) : checked ? (
                <span className="icon-check" style={iconUncheckedStyle} />
              ) : (
                <span className="icon-fermer" style={iconCheckedStyle} />
              )}
            </span>
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="details"
            style={{ padding: 7, marginRight: 4 }}
            color="inherit"
            onClick={this.handleOpenDetails}
          >
            <Visibility fontSize="small" />
          </IconButton>
          <Link to={`schedule/${id}/${user.first_name}`}>
            <IconButton
              aria-label="calendar"
              style={{ padding: 7, marginRight: 4 }}
              color="inherit"
            >
              <TodayIcon fontSize="small" />
            </IconButton>
          </Link>
          <IconButton
            aria-label="delete"
            style={{ padding: 7 }}
            color="inherit"
            onClick={this.handleOpenUnlinkCompanyUserDialog}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <Dialog
          aria-labelledby="unlink-company-user-dialog"
          onClose={this.handleCloseUnlinkCompanyUserDialog}
          open={isUnlinkCompanyUserDialogOpen}
          disableEscapeKeyDown={false}
          disableBackdropClick={false}
          scroll={"paper"}
        >
          <DialogTitle id="unlink-company-user-dialog">
            {translation().company.members.dialogs.unlink.title}
          </DialogTitle>
          <DialogContent style={{ minWidth: 300 }}>
            <p>{translation().company.members.dialogs.unlink.description}</p>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              disabled={isDeleteLoading}
              onClick={this.handleCloseUnlinkCompanyUserDialog}
            >
              {translation().company.members.buttons.unlink.cancel}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ minWidth: 120 }}
              disabled={isDeleteLoading}
              onClick={this.handleUnlinkUser}
            >
              {isDeleteLoading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <span>
                  {translation().company.members.buttons.unlink.submit}
                </span>
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={this.handleCloseDetails}
          open={isDetailsOpen}
          maxWidth="md"
          fullWidth
          scroll="paper"
          disableEscapeKeyDown={false}
          disableBackdropClick={false}
        >
          <DialogContent style={{ padding: 0 }}>
            <Details {...this.props} />
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              disabled={isDeleteLoading}
              onClick={this.handleCloseDetails}
            >
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
    );
  }
}

User.propTypes = {
  adminizeCompanyUser: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  unlinkCompanyUser: PropTypes.func.isRequired,
  id: PropTypes.any.isRequired,
  user: PropTypes.shape({
    id: PropTypes.any.isRequired,
    last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    company_admin: PropTypes.bool,
  }).isRequired,
  userLogged: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }).isRequired,
};

export default User;
