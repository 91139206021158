import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Switch from '@material-ui/core/Switch';

import { objectMapToArray } from '../../helpers/functions/utils';
import {
  dateInTz,
  getCurrentDateUtc,
  addToDate,
  alterDate,
  createDate,
  formatDate,
} from '../../helpers/functions/dates';

import DateTimeRangePickerField from '../../components/commons/fields/DateTimeRangePickerField';
import ToggleLabel from '../../components/commons/ToggleLabel';

import Metric from './Metric';

import translation from '../../translation/translation';

class MetricsWrapper extends Component {

  constructor(props) {
    super(props);

    const today = getCurrentDateUtc();
    const todayAltered = alterDate(today, null, null, null, "23", "59", "59");
    const lastMonthDate = addToDate(todayAltered, -1, "months");
    const lastMonthDateAltered = alterDate(lastMonthDate, null, null, null, "00", "00", "00");

    this.state = {
      range: {
        from: dateInTz(lastMonthDateAltered, 'YYYY-MM-DDTHH:mm:ssZ', "UTC"),
        until: dateInTz(todayAltered, 'YYYY-MM-DDTHH:mm:ssZ', "UTC")
      },
    }

    this.providerMetrics = {
      'order-products-interval-auto': {
        route: 'order-products',
        route_admin: false,
        chart_props: {
          type: 'line',
          title: translation().metrics.metrics_names['order-products-interval-auto'],
          metric_calc_mode: "count"
        },
        query_params: {
          time_interval: "auto",
        },
      },
      'order-products-avg-provider-cost-interval-auto': {
        route: 'order-products-avg-provider-cost',
        route_admin: true,
        chart_props: {
          type: 'line',
          title: translation().metrics.metrics_names['order-products-avg-provider-cost-interval-auto'],
          metric_calc_mode: "avg"
        },
        query_params: {
          time_interval: "auto",
        },
      },
      'order-products-avg-trip-distance-interval-auto': {
        route: 'order-products-avg-trip-distance',
        route_admin: false,
        chart_props: {
          type: 'line',
          title: translation().metrics.metrics_names['order-products-avg-trip-distance-interval-auto'],
          metric_calc_mode: "function",
          metric_calc_function: {
            type: "call-api",
            route: 'order-products-avg-trip-distance',
            calc_mode: 'avg',
            query_params: {}
          }
        },
        query_params: {
          time_interval: "auto",
        },
      },
      'order-products-avg-trip-compensation-interval-auto': {
        route: 'order-products-avg-trip-compensation',
        route_admin: true,
        chart_props: {
          type: 'line',
          title: translation().metrics.metrics_names['order-products-avg-trip-compensation-interval-auto'],
          metric_calc_mode: "function",
          metric_calc_function: {
            type: "call-api",
            route: 'order-products-avg-trip-compensation',
            calc_mode: 'avg',
            query_params: {}
          }
        },
        query_params: {
          time_interval: "auto",
        },
      }
    };

    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.renderMetrics = this.renderMetrics.bind(this);
    this.handleMetricsParams = this.handleMetricsParams.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      range
    } = this.state;

    const {
      initializeMetricForm,
      toggleMetricsMode,
      user
    } = this.props;

    initializeMetricForm({
      'metrics-range': {
        selection: {
          startDate: range.from,
          endDate: range.until
        }
      }
    });

    if (user && user.data && user.data.company && user.data.company_admin)
      toggleMetricsMode("companies");
  }

  handleMetricsParams(range) {

    if (range && range.startDate && range.endDate) {

      const startAltered = createDate(alterDate(range.startDate, null, null, null, "00", "00", "00"));
      const endAltered = createDate(alterDate(range.endDate, null, null, null, "23", "59", "59"));

      this.setState({
        range: {
          from: formatDate(startAltered, 'YYYY-MM-DDTHH:mm:ss'),
          until: formatDate(endAltered, 'YYYY-MM-DDTHH:mm:ss'),
        },
      });
    }
  }

  renderMetrics(range) {

    const {
      getMetrics,
      metrics: {
        mode
      },
      user
    } = this.props;

    let metricsMap = [];

    objectMapToArray(this.providerMetrics, (route, obj, index) => {
      metricsMap.push(
        <span key={index}>
          { user.data && user.data.company && !user.data.company_admin && obj.route_admin
            ? false
            : user.data && (
              <>
                <div className="col-xs-12 col-md-6">
                  <Metric
                    mode={mode}
                    chartProps={{
                      ...obj.chart_props,
                      name: route
                    }}
                    queryParams={{
                      ...obj.query_params,
                      range
                    }}
                    filterKey={obj.filter_key}
                    defaultType="line"
                    routeName={obj.route}
                    getMetrics={getMetrics}
                    companyAdmin={user && user.data && user.data.company && user.data.company_admin ? true : false}
                  />
                </div>
                {(index + 1) % 2 === 0 ? <div className="clearfix" /> : null}
              </>
            )
          }
        </span>
      );
    });

    return metricsMap;
  }

  handleChangeMode(e, value) {

    const {
      toggleMetricsMode,
      metrics: {
        mode
      }
    } = this.props;

    if (mode && mode === "providers") {
      if (!value) return;
      toggleMetricsMode('companies');
    }
    else if (mode && mode === "companies") {
      if (value) return;
      toggleMetricsMode('providers');
    }
  }

  render() {

    const { range } = this.state;
    const {
      metrics: {
        mode
      },
      user
    } = this.props;

    return (
      <div className="App-view Metrics">
        <div className="page-title">
          <div className="container">
            <h3>{translation().metrics.page_title}</h3>
            <div className="metrics-form" style={user && user.data && user.data.company && user.data.company_admin ? {} : {top: -15}}>
              <DateTimeRangePickerField
                name="metrics-range"
                disabled={false}
                type="text"
                defaultValue=""
                dialogTitle={translation().metrics.form.labels.select_range}
                label={translation().metrics.form.labels.range}
                closeDialogLabel={translation().metrics.form.buttons.close}
                submitDialogLabel={translation().metrics.form.buttons.submit}
                onSubmitDialog={this.handleMetricsParams}
                defaultRange={{
                  startDate: range.from,
                  endDate: range.until
                }}
              />
            </div>
            { user && user.data && user.data.company && user.data.company_admin &&
              <div className="section-controls">
                <div className="toggle-mode">
                  <ToggleLabel
                    label={translation().invoices.invoices_list.switch.personal}
                    value={false}
                    onClick={this.handleChangeMode}
                  />
                  <Switch
                    checked={mode && mode === "providers" ? false : true}
                    value={mode ? mode : "providers"}
                    color="default"
                    onChange={this.handleChangeMode}
                  />
                  <ToggleLabel
                    label={translation().invoices.invoices_list.switch.pro}
                    value={true}
                    onClick={this.handleChangeMode}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <div className="metrics-wrapper">
          { this.renderMetrics(range) }
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

MetricsWrapper.propTypes = {
  initializeMetricForm: PropTypes.func.isRequired,
  getMetrics: PropTypes.func.isRequired,
  metrics: PropTypes.shape().isRequired,
  toggleMetricsMode: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired
}

 export default MetricsWrapper;
