import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initialize } from 'redux-form';

import { fetchApi } from '../../helpers/functions/api';

import MetricsWrapper from '../../views/metrics/MetricsWrapper';

import { toggleMetricsMode } from '../../redux/actions/metricsActions';

const mapStateToProps = state => ({
  metrics: state.metrics,
  user: state.user
});

const mapDispatchToProps = dispatch => {

  return {
    toggleMetricsMode(mode) {
      dispatch(toggleMetricsMode(mode));
    },
    getMetrics: (route, query, mode = "providers", callbackSuccess = null, callbackFailure = null) => {

      if (!route || !query) return;

      fetchApi(
        "get",
        mode === "companies" ? `provider-companies/self/metrics/${route}` : `providers/self/metrics/${route}`,
        {
          ...query,
          split: true
        },
        null,
        null,
        data => {
          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess(data);
        },
        error => {
          const message = error && error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "Une erreur est survenue."

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure(message);
        }
      );
    },
    initializeMetricForm(values) {
      if (!values) return;

      dispatch(initialize('metricsForm', values));
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetricsWrapper));
