export const toggleInvoicesListMode = mode => ({
  type: "TOGGLE_INVOICES_LIST_MODE",
  mode
});

export const toggleInvoicesEmittedListMode = mode => ({
  type: "TOGGLE_INVOICES_EMITTED_LIST_MODE",
  mode
});

export const toggleAddressesMode = mode => ({
  type: "TOGGLE_ADDRESSES_MODE",
  mode
});

export const togglePaymentMethodsMode = mode => ({
  type: "TOGGLE_PAYMENT_METHODS_MODE",
  mode
});

export const getProviderInvoiceQueue = next => ({
  type: "GET_PROVIDER_INVOICE_QUEUE",
  next
});

export const getProviderInvoiceQueueSuccess = (data, paging) => ({
  type: "GET_PROVIDER_INVOICE_QUEUE_SUCCESS",
  data, paging
});

export const getProviderInvoiceQueueFailure = error => ({
  type: "GET_PROVIDER_INVOICE_QUEUE_FAILURE",
  error
});

export const updateProviderInvoiceQueue = (data, paging) => ({
  type: "UPDATE_PROVIDER_INVOICE_QUEUE",
  data, paging
});

export const getProviderInvoices = next => ({
  type: "GET_PROVIDER_INVOICES",
  next
});

export const getProviderInvoicesSuccess = (data, paging) => ({
  type: "GET_PROVIDER_INVOICES_SUCCESS",
  data, paging
});

export const getProviderInvoicesFailure = error => ({
  type: "GET_PROVIDER_INVOICES_FAILURE",
  error
});

export const updateProviderInvoices = (data, paging) => ({
  type: "UPDATE_PROVIDER_INVOICES",
  data, paging
});

export const getPaymentMethods = next => ({
  type: "GET_PAYMENT_METHODS",
  next
});

export const getPaymentMethodsSuccess = (data, paging) => ({
  type: "GET_PAYMENT_METHODS_SUCCESS",
  data, paging
});

export const getPaymentMethodsFailure = error => ({
  type: "GET_PAYMENT_METHODS_FAILURE",
  error
});

export const updatePaymentMethods = (data, paging) => ({
  type: "UPDATE_PAYMENT_METHODS",
  data, paging
});

export const deletePaymentMethod = () => ({
  type: "DELETE_PAYMENT_METHOD"
});

export const deletePaymentMethodSuccess = () => ({
  type: "DELETE_PAYMENT_METHOD_SUCCESS"
});

export const deletePaymentMethodFailure = error => ({
  type: "DELETE_PAYMENT_METHOD_FAILURE",
  error
});

export const postAddress = () => ({
    type: "POST_ADDRESS"
});

export const postAddressSuccess = data => ({
  type: "POST_ADDRESS_SUCCESS",
  data
});

export const postAddressFailure = error => ({
  type: "POST_ADDRESS_FAILURE",
  error
});

export const deleteAddress = () => ({
  type: "DELETE_ADDRESS"
});

export const deleteAddressSuccess = data => ({
  type: "DELETE_ADDRESS_SUCCESS",
  data
});

export const deleteAddressFailure = error => ({
  type: "DELETE_ADDRESS_FAILURE",
  error
});

export const editAddress = () => ({
  type: "EDIT_ADDRESS"
});

export const editAddressSuccess = data => ({
  type: "EDIT_ADDRESS_SUCCESS",
  data
});

export const editAddressFailure = error => ({
  type: "EDIT_ADDRESS_FAILURE",
  error
});
