import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import Bowser from 'bowser';

import { MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import AcceptTerms from './components/acceptTerms/AcceptTerms';
import CovidCharter from './views/tmp/CovidCharter';

import Authentication from './components/authentication/Authentication';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Notifier from './components/notifications/Notifier';

import CompanyContainer from './containers/company/CompanyContainer';
import InvoicesContainer from './containers/invoices/InvoicesContainer';
import MetricsContainer from './containers/metrics/MetricsContainer';
import OrderContainer from './containers/order/OrderContainer';
import OrdersContainer from './containers/orders/OrdersContainer';
import ProfileContainer from './containers/profile/ProfileContainer';
import ScheduleContainer from './containers/schedule/ScheduleContainer';
import RequestDispatch from './containers/requestDispatch/RequestDispatchContainer';

import PageNotFound from './views/pageNotFound/PageNotFound';
import RestrictedArea from './views/restrictedArea/RestrictedArea';

import { getAccessToken } from './helpers/functions/cookies';

import theme from './helpers/theme/appTheme';
import translation from './translation/translation';

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={OrdersContainer}
      />
      <Route
        exact
        path="/order-products"
        component={OrdersContainer}
      />
      <Route
        path="/order-products/:id"
        component={OrderContainer}
      />
      <Route
        path="/company"
        component={CompanyContainer}
      />
      <Route
        path="/invoices"
        component={InvoicesContainer}
      />
      <Route
        path="/profile"
        component={ProfileContainer}
      />
      <Route
        path="/schedule/:id?/:firstName?"
        component={ScheduleContainer}
      />
      <Route
        path="/metrics"
        component={MetricsContainer}
      />
      <Route
        path="/request-dispatch"
        component={RequestDispatch}
      />
      <Route
        path="/covid-charter"
        component={CovidCharter}
      />
      <Route
        path="*"
        component={PageNotFound}
      />
      <Redirect
        from="*"
        to="/page-not-found"
      />
    </Switch>
  )
}

class App extends Component {

  UNSAFE_componentWillMount () {
    this.props.getUser();

    /**
     * Check if Internet Explorer is used as user agent. If yes, alert user to user another modern browser.
     */
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser) {
      const devicePlatform = browser.getBrowserName();
      if (devicePlatform && devicePlatform === "Internet Explorer") {
        alert(translation().commons.do_not_use_IE);
      }
    }
  }

  render() {

    const {
      acceptProviderTerms,
      reduxStore,
      removeSnackbar,
      signOut
    } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className="App">
            { reduxStore.user.loading &&
              <LinearProgress className="App-progress"/>
            }
            { reduxStore && reduxStore.user.data && reduxStore.provider && reduxStore.provider.data && reduxStore.provider.data.terms_accepted
              ? (
                <div className="App-router">
                  <Header
                    signOut={signOut}
                    user={reduxStore.user.data}
                  />
                  <Routes {...this.props} />
                  <Footer/>
                </div>
              )
              : !getAccessToken() && <Authentication {...this.props} />
            }
            <div id="iframes-container" />
            <Notifier
              notifications={reduxStore.app.notifications}
              removeSnackbar={removeSnackbar}
            />
            { reduxStore.provider === 403 &&
              <RestrictedArea />
            }
            { reduxStore.provider && reduxStore.provider.data && !reduxStore.provider.data.terms_accepted &&
              <AcceptTerms
                signOut={signOut}
                loading={reduxStore.provider.accept_terms.loading}
                onAccept={acceptProviderTerms}
                open={true}
              />
            }
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  acceptProviderTerms: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  reduxStore: PropTypes.shape({
    user: PropTypes.shape().isRequired,
    app: PropTypes.shape().isRequired,
    provider: PropTypes.any
  }),
  removeSnackbar: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired
}

export default App;
