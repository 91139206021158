import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Addresses from './addresses/Addresses';
import InvoiceQueue from './invoiceQueue/InvoiceQueue';
import InvoicesList from './invoicesList/InvoicesList';
import PaymentMethods from './paymentMethods/PaymentMethods';

import { autoScrollTop } from '../../helpers/functions/jquery';

import translation from '../../translation/translation';

class Invoices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tabOpen: 0
    }

    this.tabArray = [
      'incoming',
      "list",
      "methods",
      "addresses"
    ];

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount () {

    autoScrollTop();

    const {
      history,
    } = this.props;

    /**
     * Tabs micro routing
     */
    if (history && history.location && history.location.hash) {
      if (history.location.hash === "#incoming")
        this.setState({tabOpen: 0 })
      else if (history.location.hash === "#list")
        this.setState({tabOpen: 1 })
      else if (history.location.hash === "#methods")
        this.setState({tabOpen: 2 })
      else if (history.location.hash === "#addresses")
        this.setState({tabOpen: 3 })
    }
  }

  handleChange(event, tabOpen) {
    this.setState({ tabOpen });
    window.location.hash = this.tabArray[tabOpen];
  }

  render() {

    const { tabOpen } = this.state;

    const {
      user,
      company,
      getInvoiceQueue,
      getInvoicesList,
      getPaymentMethods,
      getCompanyUsers,
      handleCompanyPaymentMethodPermissions,
      deletePaymentMethod,
      toggleInvoicesListMode,
      toggleInvoicesEmittedListMode,
      togglePaymentMethodsMode,
      toggleAddressesMode,
      createAddress,
      deleteAddress,
      editAddress,
      invoices: {
        addresses,
        invoice_queue,
        invoices_list,
        payment_methods,
      }
    } = this.props;

    return (
      <div className="App-view">
        <div className="page-title">
          <h3>{translation().invoices.page_title}</h3>
        </div>
        <div className="container container-mobile">
          <AppBar
            position="relative"
            color="default"
            elevation={0}
          >
            <Tabs
              centered
              value={tabOpen}
              onChange={this.handleChange}
              indicatorColor="primary"
              scrollButtons="on"
            >
              <Tab label={<span className="tab-label">{translation().invoices.tab_titles.incoming}</span>} icon={<div className="tab-icon icon-encours" />} />
              <Tab label={<span className="tab-label">{translation().invoices.tab_titles.invoices}</span>} icon={<div className="tab-icon icon-facture" />} />
              <Tab label={<span className="tab-label">{translation().invoices.tab_titles.payment_methods}</span>} icon={<div className="tab-icon icon-ajout_carte" />} />
              <Tab label={<span className="tab-label">{translation().invoices.tab_titles.addresses}</span>} icon={<div className="tab-icon icon-ajout_adresse" />} />
            </Tabs>
          </AppBar>
          <div className="App-tab-container Invoices">
            { tabOpen === 0 &&
              <InvoiceQueue
                companyAdmin={
                  user &&
                  user.data &&
                  user.data.company_admin &&
                  user.data.company ? true : false
                }
                toggleInvoicesEmittedListMode={toggleInvoicesEmittedListMode}
                getInvoiceQueue={getInvoiceQueue}
                invoiceQueue={invoice_queue}
              />
            }
            { tabOpen === 1 &&
              <InvoicesList
                companyAdmin={
                  user &&
                  user.data &&
                  user.data.company_admin &&
                  user.data.company ? true : false
                }
                toggleInvoicesListMode={toggleInvoicesListMode}
                getInvoicesList={getInvoicesList}
                invoicesList={invoices_list}
              />
            }
            { tabOpen === 2 &&
              <PaymentMethods
                getCompanyUsers={getCompanyUsers}
                companyAdmin={
                  user &&
                  user.data &&
                  user.data.company_admin &&
                  user.data.company ? true : false
                }
                user={user}
                company={company}
                handleCompanyPaymentMethodPermissions={handleCompanyPaymentMethodPermissions}
                togglePaymentMethodsMode={togglePaymentMethodsMode}
                deletePaymentMethod={deletePaymentMethod}
                getPaymentMethods={getPaymentMethods}
                paymentMethods={payment_methods}
              />
            }
            { tabOpen === 3 &&
              <Addresses
                createAddress={createAddress}
                deleteAddress={deleteAddress}
                editAddress={editAddress}
                addresses={addresses}
                companyAdmin={
                  user &&
                  user.data &&
                  user.data.company_admin &&
                  user.data.company ? true : false
                }
                toggleAddressesMode={toggleAddressesMode}
                user={user}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

Invoices.propTypes = {
  handleCompanyPaymentMethodPermissions: PropTypes.func.isRequired,
  togglePaymentMethodsMode: PropTypes.func.isRequired,
  toggleAddressesMode: PropTypes.func.isRequired,
  toggleInvoicesListMode: PropTypes.func.isRequired,
  toggleInvoicesEmittedListMode: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  getInvoiceQueue: PropTypes.func.isRequired,
  getInvoicesList: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  createAddress: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  editAddress: PropTypes.func.isRequired,
  company: PropTypes.shape({
    users: PropTypes.shape().isRequired
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape(),
  }).isRequired,
  invoices: PropTypes.shape({
    invoice_queue: PropTypes.shape().isRequired,
    invoices_list: PropTypes.shape().isRequired,
    payment_methods: PropTypes.shape().isRequired,
    addresses: PropTypes.shape().isRequired,
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape().isRequired
  }).isRequired,
}

export default Invoices;
