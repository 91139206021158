import React from "react";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { teal, grey } from "@material-ui/core/colors";

import translation from "../../translation/translation";

const classes = {
  button: {
    borderRadius: 4,
    fontFamily: "'Montserrat-Regular'",
    lineHeight: "1.2",
    color: teal[500],
    borderColor: teal[500],
    "&:hover": {
      color: teal[500],
      borderColor: teal[500],
      backgroundColor: "rgb(0 150 136 / 16%)",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: 15,
    gap: "10px",
    color: grey[500],
  },
};

class ManageAccessDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      values: null,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ isOpened: true });
  }

  handleClose() {
    this.setState({ isOpened: false, value: "" });
    // RESET FORM
    // store.dispatch(
    // );
  }

  handleSubmit() {
    // Handle submit
  }

  render() {
    const { isOpened } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleOpen}
          className={classes.button}
          size="small"
        >
          {translation().order_management.access_details.button}
        </Button>
        <Dialog
          fullWidth
          open={isOpened ? true : false}
          onClose={this.handleClose}
          aria-labelledby="edit-payment-method"
          aria-describedby="edit-payment-method-description"
          // disableBackdropClick={getLoading || updateLoading}
        >
          <DialogTitle id="edit-payment-method">
            {translation().order_management.access_details.dialog_title}
          </DialogTitle>
          <DialogContent />
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              // disabled={getLoading || updateLoading}
              size="small"
            >
              {translation().commons.cancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              // disabled={updateLoading || !value}
              size="small"
            >
              {translation().commons.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ManageAccessDetails.propTypes = {
  classes: PropTypes.shape({
    loading: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
};

export default reduxForm({
  form: "manage_order_access_details",
})(withStyles(classes)(ManageAccessDetails));
