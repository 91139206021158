import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  change
} from 'redux-form';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import MuiTextField from '../../../../components/commons/fields/MuiTextField';
import MuiPhoneField from '../../../../components/commons/fields/muiPhoneField/MuiPhoneField';

import { apiPostFile } from '../../../../helpers/functions/api';

import store from '../../../../redux/store';
import config from '../../../../config';
import translation from '../../../../translation/translation';

import validate from './validate';

class CompanyForm extends Component {

  constructor(props) {

    super(props);

    this.state = {
      filesAccepted: null,
      filesRejected: null,
      uploadInProgressLogo: 0,
      uploadInProgressWatermark: 0,
      filesArrayLogo: [],
      filesArrayWatermark: [],
      fileLimitLogo: 1,
      fileLimitWatermark: 1,
      fileErrorLogo: '',
      fileErrorWatermark: '',
    }

    this.dropzoneLogoRef = undefined;
    this.dropzoneWatermarkRef = undefined;

    this.createDropzoneLogoRef = this.createDropzoneLogoRef.bind(this);
    this.createDropzoneWatermarkRef = this.createDropzoneWatermarkRef.bind(this);
    this.handleDeleteLogo = this.handleDeleteLogo.bind(this);
    this.handleDeleteWatermark = this.handleDeleteWatermark.bind(this);
    this.handleDropLogo = this.handleDropLogo.bind(this);
    this.handleDropWatermark = this.handleDropWatermark.bind(this);
    this.handleOpenDropzoneLogo = this.handleOpenDropzoneLogo.bind(this);
    this.handleOpenDropzoneWatermark = this.handleOpenDropzoneWatermark.bind(this);
  }

  UNSAFE_componentWillMount () {

    const { initialValues } = this.props;

    if (initialValues && initialValues.logo_url)
      this.setState({ filesArrayLogo: [{ thumbnail_url: initialValues.logo_url + "?client_id=" + config.api_key }] });

    if (initialValues && initialValues.watermark_url)
      this.setState({ filesArrayWatermark: [{ thumbnail_url: initialValues.watermark_url + "?client_id=" + config.api_key }] });

  }

  createDropzoneLogoRef(node) {
    if (node)
      this.dropzoneLogoRef = node;
  }

  createDropzoneWatermarkRef(node) {
    if (node)
      this.dropzoneWatermarkRef = node;
  }

  handleOpenDropzoneLogo() {

    if (this.dropzoneLogoRef && this.dropzoneLogoRef.open)
      this.dropzoneLogoRef.open();
  }

  handleOpenDropzoneWatermark() {
    if (this.dropzoneWatermarkRef && this.dropzoneWatermarkRef.open)
      this.dropzoneWatermarkRef.open();
  }

  handleDeleteLogo() {
    this.setState({ filesArrayLogo: [] });
    store.dispatch(change('updateCompanyForm', 'logo_url', ''));
  }

  handleDeleteWatermark() {
    this.setState({ filesArrayWatermark: [] });
    store.dispatch(change('updateCompanyForm', 'watermark_url', ''));
  }

  handleDropLogo(filesAccepted, filesRejected) {

    this.setState({ filesAccepted, filesRejected });

    if (filesAccepted) {
      for (let i = 0; i < filesAccepted.length; i++) {
        if ((filesAccepted.length) <= this.state.fileLimitLogo) {

          this.setState({ uploadInProgressLogo: filesAccepted.length });

          apiPostFile(
            filesAccepted[i],
            success => {
              const newFile = success.data && success.data ? success.data : '';
              const newFilesArrayLogo = [newFile];

              this.setState({
                filesArrayLogo: newFilesArrayLogo,
                fileErrorLogo: '',
                uploadInProgressLogo: this.state.uploadInProgressLogo - 1
              });

              store.dispatch(change('updateCompanyForm', 'logo_url', newFile.url));
            },
            error => {
              if (error.response && error.response.data && error.response.data.detail) {
                this.setState({
                  fileErrorLogo: error.response.data.detail,
                  uploadInProgressLogo: this.state.uploadInProgressLogo - 1
                });
              }
            }
          );
        }
        else {
          this.setState({ fileErrorLogo: translation().company.form.validation.file_limit + this.state.fileLimitLogo });
          return;
        }
      }
    }

    if (filesRejected && filesRejected.length > 0) {
      let fileErrorLogo = translation().company.form.validation.files;

      for (let j = 0; j < filesRejected.length; j++) {

        if (filesRejected[j].name)
          fileErrorLogo += (filesRejected[j].name + ", ");
      }

      fileErrorLogo += translation().company.form.validation.files_refused;

      this.setState({ fileErrorLogo });
    }
  }

  handleDropWatermark(filesAccepted, filesRejected) {

    this.setState({ filesAccepted, filesRejected });

    if (filesAccepted) {
      for (let i = 0; i < filesAccepted.length; i++) {
        if ((filesAccepted.length) <= this.state.fileLimitWatermark) {

          this.setState({ uploadInProgressWatermark: filesAccepted.length });

          apiPostFile(
            filesAccepted[i],
            success => {
              const newFile = success.data && success.data ? success.data : '';
              const newFilesArrayWatermark = [newFile];

              this.setState({
                filesArrayWatermark: newFilesArrayWatermark,
                fileErrorWatermark: '',
                uploadInProgressWatermark: this.state.uploadInProgressWatermark - 1
              });

              store.dispatch(change('updateCompanyForm', 'watermark_url', newFile.url));
            },
            error => {
              if (error.response && error.response.data && error.response.data.detail) {
                this.setState({
                  fileErrorWatermark: error.response.data.detail,
                  uploadInProgressWatermark: this.state.uploadInProgressWatermark - 1
                });
              }
            }
          );
        }
        else {
          this.setState({ fileErrorWatermark: translation().company.form.validation.file_limit + this.state.fileLimitWatermark });
          return;
        }
      }
    }

    if (filesRejected && filesRejected.length > 0) {
      let fileErrorWatermark = translation().company.form.validation.files;

      for (let j = 0; j < filesRejected.length; j++) {

        if (filesRejected[j].name)
          fileErrorWatermark += (filesRejected[j].name + ", ");
      }

      fileErrorWatermark += translation().company.form.validation.files_refused;

      this.setState({ fileErrorWatermark });
    }
  }

  render() {

    const {
      filesArrayLogo,
      fileErrorLogo,
      filesArrayWatermark,
      fileErrorWatermark,
      uploadInProgressLogo,
      uploadInProgressWatermark,
    } = this.state;

    const {
      buttonLabel,
      informations,
      handleSubmit,
      labels
    } = this.props;

    return (
      <form
        className="company-form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div className="field-wrapper">
          <Field
            name="legal_name"
            id="legal_name"
            type="text"
            label={labels.legal_name}
            component={MuiTextField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="commercial_name"
            id="commercial_name"
            type="text"
            label={labels.commercial_name}
            component={MuiTextField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="phone"
            id="phone"
            type="text"
            label={labels.phone}
            component={MuiPhoneField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="fax"
            id="fax"
            type="text"
            label={labels.fax}
            component={MuiPhoneField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="email"
            id="email"
            type="email"
            label={labels.email}
            component={MuiTextField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="website_url"
            id="website_url"
            type="text"
            label={labels.web_site}
            component={MuiTextField}
          />
        </div>
        <div className="row" style={{marginTop: 50}}>
          <div className="col-xs-12 col-sm-6">
            <div className="field-wrapper">
              <div className="upld-wrapper">
                <label className="upld-label">{labels.logo}</label>
                {
                  fileErrorLogo &&
                    <p className="error-message">{fileErrorLogo}</p>
                }
                {
                  !uploadInProgressLogo
                    ? (
                        <span>
                          {
                            filesArrayLogo && filesArrayLogo.length > 0
                              ? (
                                  <div
                                    className="company-logo"
                                    style={{backgroundImage: ('url(' + filesArrayLogo[0].thumbnail_url + ')') }}
                                  />
                                )
                              : ''
                          }
                        </span>
                      )
                    : (
                        <div className="company-logo upld-inp">
                          <CircularProgress size={35} color="secondary" />
                        </div>
                      )
                }
                <Dropzone
                  ref={this.createDropzoneLogoRef}
                  onDrop={this.handleDropLogo}
                  disableClick={true}
                  name="companyLogo"
                  accept="image/jpeg, image/png, image/gif"
                >
                  {
                    ({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <div {...getRootProps()} className={classNames('dropzone dropper company-dropper', {'dropzone--isActive dropper-ondrag': isDragActive})}>
                          {
                            !uploadInProgressLogo
                              ? (
                                  <div className="drop-it">
                                    <Button
                                      variant="contained"
                                      color="default"
                                      size="small"
                                      onClick={this.handleOpenDropzoneLogo}
                                    >
                                      <i className="material-icons" style={{marginRight: 4}}>cloud_upload</i>
                                      {translation().company.form.buttons.upload}
                                    </Button>
                                    {
                                      filesArrayLogo && filesArrayLogo[0] &&
                                        <IconButton
                                          size="small"
                                          aria-label="Delete"
                                          className="delete-file"
                                          style={{width: 30, height: 30, padding: 0, margin: 5}}
                                          onClick={this.handleDeleteLogo}
                                        >
                                          <DeleteIcon style={{fontSize: 18}}/>
                                        </IconButton>
                                    }
                                  </div>
                                )
                              : ''
                          }
                          <input {...getInputProps()} />
                        </div>
                      )
                    }
                  }
                </Dropzone>
                <Field
                  name="logo_url"
                  type="hidden"
                  component="input"
                  className="hidden-input"
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="field-wrapper">
              <div className="upld-wrapper">
                <label className="upld-label">{labels.watermark}</label>
                {
                  fileErrorWatermark &&
                    <p className="error-message">{fileErrorWatermark}</p>
                }
                {
                  !uploadInProgressWatermark
                    ? (
                        <span>
                          {
                            filesArrayWatermark && filesArrayWatermark.length > 0
                              ? (
                                <div
                                  className="company-logo"
                                  style={{backgroundImage: ('url(' + filesArrayWatermark[0].thumbnail_url + ')') }}
                                />
                              )
                            : ''
                        }
                      </span>
                    )
                  : (
                      <div className="company-logo upld-inp">
                        <CircularProgress size={35} color="secondary" />
                      </div>
                    )
                }
                <Dropzone
                  ref={this.createDropzoneWatermarkRef}
                  onDrop={this.handleDropWatermark}
                  disableClick={true}
                  name="watermarkLogo"
                  className="company-dropper"
                  accept="image/jpeg, image/png, image/gif"
                  activeClassName="dropper-ondrag"
                >
                  {
                    ({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <div {...getRootProps()} className={classNames('dropzone dropper company-dropper', {'dropzone--isActive dropper-ondrag': isDragActive})}>
                          { !uploadInProgressWatermark
                            ? (
                                <div className="drop-it">
                                  <Button
                                    color="default"
                                    size="small"
                                    variant="contained"
                                    onClick={this.handleOpenDropzoneWatermark}
                                  >
                                    <i className="material-icons" style={{marginRight: 4}}>cloud_upload</i>
                                    {translation().company.form.buttons.upload}
                                  </Button>
                                  {
                                    filesArrayWatermark && filesArrayWatermark[0] &&
                                      <IconButton
                                        size="small"
                                        aria-label="Delete"
                                        className="delete-file"
                                        style={{width: 30, height: 30, padding: 0, margin: 5}}
                                        onClick={this.handleDeleteWatermark}
                                      >
                                        <DeleteIcon style={{fontSize: 18}}/>
                                      </IconButton>
                                  }
                                </div>
                              )
                            : ''
                          }
                          <input {...getInputProps()} />
                        </div>
                      )
                    }
                  }
                </Dropzone>
                <Field
                  name="watermark_url"
                  type="hidden"
                  component="input"
                  className="hidden-input"
                />
              </div>
            </div>
          </div>
          <div className="clearfix"/>
        </div>
        <div className="submit-wrapper">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={informations.isLoading}
            style={{minWidth: 150}}
          >
            {
              informations.isLoading
                ? <CircularProgress size={20} color='secondary' />
                : <span>{buttonLabel}</span>
            }
          </Button>
        </div>
      </form>
    );
  }
}

CompanyForm.propTypes = {
  informations: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  initialValues: PropTypes.shape(),
  labels: PropTypes.shape({
    legal_name: PropTypes.string.isRequired,
    commercial_name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    web_site: PropTypes.string.isRequired,
    logo: PropTypes.string,
    watermark: PropTypes.string,
  }).isRequired,
  buttonLabel: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'updateCompanyForm',
  enableReinitialize: true,
  validate
})(CompanyForm);
