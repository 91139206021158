import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  AccountBox,
  Commute,
  Email,
  EuroSymbol,
  HowToReg,
  Map,
  Phone,
  SettingsBackupRestore,
  Stars,
} from "@material-ui/icons";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Rating from "react-rating";

const styles = () => ({
  primaryTypography: {
    fontSize: 14,
  },
  listItemText: {
    padding: "0 4px",
  },
});

class Information extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      active,
      average_rating,
      base_latitude,
      base_longitude,
      bio,
      classes,
      late_cancel_reversion_percentage,
      tax_rule,
      transportation,
      user,
    } = this.props;

    const { email, first_name, last_name, mobile } = user;

    return (
      <Grid container spacing={8}>
        <Grid item xs={12} md={4}>
          <List>
            <ListItem disableGutters style={{ paddingTop: 0 }}>
              <ListItemIcon>
                <AccountBox />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Nom"
                primaryTypographyProps={{
                  className: classes.primaryTypography,
                }}
                secondary={`${first_name} ${last_name}`}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Email"
                primaryTypographyProps={{
                  className: classes.primaryTypography,
                }}
                secondary={email}
              />
            </ListItem>
            {mobile && (
              <ListItem disableGutters>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary="Mobile"
                  primaryTypographyProps={{
                    className: classes.primaryTypography,
                  }}
                  secondary={mobile}
                />
              </ListItem>
            )}
            {transportation && transportation.name && (
              <ListItem disableGutters>
                <ListItemIcon>
                  <Commute />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary="Mode de transport"
                  primaryTypographyProps={{
                    className: classes.primaryTypography,
                  }}
                  secondary={transportation.name}
                />
              </ListItem>
            )}
            {base_latitude && base_longitude && (
              <ListItem disableGutters>
                <ListItemIcon>
                  <Map />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary="Point de départ"
                  primaryTypographyProps={{
                    className: classes.primaryTypography,
                  }}
                  secondary={
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${base_latitude},${base_longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Voir sur la carte
                    </a>
                  }
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <List>
            <ListItem disableGutters style={{ paddingTop: 0 }}>
              <ListItemIcon>
                <HowToReg />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Prestataire activé"
                primaryTypographyProps={{
                  className: classes.primaryTypography,
                }}
                style={{ display: "flex", flexDirection: "column" }}
                secondary={
                  <span style={{ height: 21 }}>
                    {active ? (
                      <span
                        className="icon-check"
                        style={{
                          color: "#48C17B",
                        }}
                      />
                    ) : (
                      <span
                        className="icon-fermer"
                        style={{
                          color: "#F1664F",
                        }}
                      />
                    )}
                  </span>
                }
              />
            </ListItem>
            {late_cancel_reversion_percentage && (
              <ListItem disableGutters>
                <ListItemIcon>
                  <SettingsBackupRestore />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary="Reversion hors délai"
                  primaryTypographyProps={{
                    className: classes.primaryTypography,
                  }}
                  secondary={`${late_cancel_reversion_percentage}%`}
                />
              </ListItem>
            )}
            {tax_rule && tax_rule.name && (
              <ListItem disableGutters>
                <ListItemIcon>
                  <EuroSymbol />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary="Type de taxe"
                  primaryTypographyProps={{
                    className: classes.primaryTypography,
                  }}
                  secondary={tax_rule.name}
                />
              </ListItem>
            )}
            {average_rating && (
              <>
                <ListItem disableGutters>
                  <ListItemIcon>
                    <Stars />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary="Notation moyenne"
                    primaryTypographyProps={{
                      className: classes.primaryTypography,
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    secondary={
                      <span style={{ display: "flex" }}>
                        <Rating
                          readonly
                          initialRating={average_rating}
                          emptySymbol={
                            <span
                              className="icon-etoilepleine"
                              style={{
                                color: "#DEDFE2",
                              }}
                            />
                          }
                          fullSymbol={
                            <span
                              className="icon-etoilepleine"
                              style={{
                                color: "#FFD700",
                              }}
                            />
                          }
                        />
                        <Typography
                          component="span"
                          variant="body2"
                          style={{ fontSize: 10, marginLeft: 4, paddingTop: 3 }}
                        >
                          ({average_rating})
                        </Typography>
                      </span>
                    }
                  />
                </ListItem>
              </>
            )}
            {bio && (
              <>
                <Divider />
                <div
                  style={{
                    padding: "4px 8px",
                    marginTop: 11,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.primaryTypography}
                  >
                    Biographie
                  </Typography>
                  <Typography
                    className="MuiTypography-colorTextSecondary"
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    {bio}
                  </Typography>
                </div>
              </>
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={4} />
      </Grid>
    );
  }
}

Information.propTypes = {
  active: PropTypes.bool,
  average_rating: PropTypes.number,
  base_latitude: PropTypes.number,
  base_longitude: PropTypes.number,
  bio: PropTypes.string,
  classes: PropTypes.shape({
    listItemText: PropTypes.string,
    primaryTypography: PropTypes.string,
  }).isRequired,
  late_cancel_reversion_percentage: PropTypes.number,
  tax_rule: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  transportation: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    is_admin: PropTypes.bool,
    mobile: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(Information);
