/* eslint-disable */

import React from 'react';

function UsingAccount() {

  return (
    <div className="cgs-using-account">
      <p className="p2">The purpose of this account user agreement (hereinafter the « <b>User Agreement</b> ») is to set out the rules governing the use and operation of the personal account (hereinafter the « <b>Account</b> ») made available to each independent professional (the « <b>Entity</b>») that has expressed a wish to work, from time to time or more regularly, with SAS Wall-Market (« <b>Wall-Market</b> »), a company organised and existing under the laws of France, and has been selected by it, for the performance of certain services sold by Wall-Market on its LightShop e-commerce website and application.</p>
      <p className="p2">The User Agreement does not govern the terms and conditions of performance of a service, which can be found in Wall-Market’s “Services Agreement - General Terms and Conditions” (hereinafter the « <b>General Terms and Conditions of the Services Agreement</b> »), available in each Entity’s Account.</p>
      <p className="p2">The User Agreement must be read carefully as it is binding on the Entity. Moreover, the Entity may also be bound to Wall-Market through its use of the Account in the event of a services agreement, on the terms set out in the General Terms and Conditions of the Services Agreement and in the Invoicing Authorisation.</p>
      <p className="p3">The User Agreement is accepted by the Entity by ticking a box. The Entity may only accept the User Agreement unconditionally and in full. Any unconditional acceptance will be treated as null and void. If the Entity does not wish to be bound by the User Agreement, it must cease browsing and the Account will be deemed to have never existed - and be immediately deactivated where applicable.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>I.<span className="Apple-tab-span">	</span>INTRODUCTION</b></p>
      <p className="p2">By opening an Account, the Entity may :</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>access all the information available in its Account (profile and information about the Entity, General Terms and Conditions of the Services Agreement, Invoicing Authorisation); </p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>provide and update information about the Entity (for example: profile and information about the Entity; profile picture);</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>express its wish to contract with Wall-Market; </p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>manage the delivery of any file or URL for a service; and</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>manage invoicing.</p>
      <p className="p2"><b>Notwithstanding the opening of an Account, Wall-Market remains free to decide whether or not to enter into a services agreement with the Entity and is not bound by any minimum volume commitments.</b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>II.<span className="Apple-tab-span">	</span>ACCESS TO AND USE OF THE ACCOUNT</b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>Access to and use of the account</b></p>
      <p className="p2">Each Entity is provided with a strictly personal Account and has exclusive access to the Account.</p>
      <p className="p2">Only the Entity alone is authorised to use its Account. It undertakes to use it in good faith.</p>
      <p className="p2">If the Entity uses subcontractors, it may obtain “administrator” access to the Accounts of the “subcontractor” Entities if it so wishes, with the prior express consent of Wall-Market. In such a case, it undertakes to inform the relevant Entities. It is solely responsible for informing them.</p>
      <p className="p2">Access to the Account may only be granted once login information has been registered, namely: a unique username chosen by the Entity and a password. <b>The first password registered by default by Wall-Market must be changed by the Entity after its first connection, after it has accepted the User Agreement.</b></p>
      <p className="p2">The Entity’s Account may be accessed at <a href="https://providers-lightshop.wall-market.com/" target="_blank" rel="noopener noreferrer">https://providers-lightshop.wall-market.com/</a>, once it has provided its login information. Wall-Market reserves the right to change this URL, primarily for technical reasons.</p>
      <p className="p2">Wall-Market may make several documents available to the Entity in its Account and is expressly authorised in that respect. However, this does not mean that it has any right whatsoever to access an Account.</p>
      <p className="p2">In order to secure its exclusive access to the Account, the Entity undertakes to choose a sufficiently secure password the first time it logs in and agrees not to disclose it, under any circumstances, to any third party. </p>
      <p className="p2">The Entity is responsible for protecting the confidentiality of its login information and for any activity on its Account. It may not be transferred or disclosed to a third party in any circumstances whatsoever. If the Entity becomes aware that another person has accessed its Account, it shall inform Wall-Market without delay.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Obligation to update information</b></p>
      <p className="p2">The Entity undertakes to inform Wall-Market of any changes to its status, any change of contact details or any other changes that could impact this User Agreement or any services agreement that may be concluded.</p>
      <p className="p2">This obligation is a perequisite for the conclusion of a services agreement with Wall-Market.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>III.<span className="Apple-tab-span">	</span>ACCOUNT FEATURES</b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>The Entity can use the Account to propose a services agreement with Wall-Market</b></p>
      <p className="p2"><span className="Apple-tab-span">	</span>A.<span className="Apple-tab-span">	</span><span className="s2">Proposal of Time Slots by the Entity, at its discretion</span></p>
      <p className="p2">After creating an account, the Entity may access a calendar (the « <b>Calendar</b> ») and register one or more periods (« <b>Time Slot(s)</b>») for the services that the Entity wishes, and is able to, perform.</p>
      <p className="p2">By registering a Time Slot, the Entity proposes to enter into a services agreement with Wall-Market on the terms set out in the General Terms and Conditions of the Services Agreement. Accordingly, the Entity will be asked, when it accepts the User Agreement, to also accept:</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>the General Terms and Conditions of the Services Agreement, as each services agreement entered into between Wall-Market and the Entity will be governed by those terms and conditions, in the version in force when the Entity proposes a Time Slot. If the Entity does not wish to be bound by the General Terms and Conditions of the Services Agreement, it should not register a Time Slot;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>the invoicing authorisation.<span className="Apple-converted-space"> </span></p>
      <p className="p2">The General Terms and Conditions of the Services Agreement and the Invoicing Authorisation are accepted by ticking a box.</p>
      <p className="p2">Time Slots may be proposed as full days or for certain times of a day only, at the discretion of the Entity.</p>
      <p className="p2">The Entity is under no obligation to propose Time Slots. Accordingly, the Account will not be automatically desactivated if the Entity does not propose a Time Slot.</p>
      <p className="p2">Once a Time Slot has been proposed, the Entity is free to modify or withdraw it at any time subject to the provisions set out below (see Clause<b><i>III.1.B</i></b>).<span className="Apple-converted-space"> </span></p>
      <p className="p2">Notwithstanding the proposal of a Time Slot by the Entity, there is no guarantee that a services agreement will be concluded.</p>
      <p className="p2">B.<span className="Apple-tab-span">	</span><span className="s2">Wall-Market’s acceptance of a Time Slot</span></p>
      <p className="p2"><b>B.1<span className="Apple-tab-span">	</span><i>Consequences of Wall-Market’s acceptance of a Time Slot</i></b></p>
      <p className="p2">Wall-Market is not under any obligation whatsoever to accept a proposed Time Slot.</p>
      <p className="p2">Wall-Market’s acceptance of a proposed Time Slot will constitute an agreement between the Parties for the performance of a service by the Entity on the terms and conditions set out in the General Terms and Conditions of the Services Agreement. </p>
      <p className="p2">The Entity is informed of Wall-Market’s acceptance via an email, push notification and/or SMS; it being agreed that only the notice given via the Entity’s Account will be binding. Accordingly, the Entity is solely liable for any problems preventing the receipt of an email, push notification and/or SMS.</p>
      <p className="p2">Wall-Market’s acceptance of a Time Slot is binding on the Entity and the Time Slot may not subsequently be modified by the Entity.</p>
      <p className="p3">Wall-Market hereby informs the Entity that, under the General Terms and Conditions of the Services Agreement, services agreements will be terminated by operation of law, without any need to complete any formalities or give any formal notice, if Wall-Market’s customer cancels the order placed with Wall-Market, more than six (6) hours before the fulfilment of the order.</p>
      <p className="p2"><b>B.2<span className="Apple-tab-span">	</span><i>Procedures for accepting Time Slots</i></b></p>
      <p className="p3">Wall-Market will only be able to accept a Time Slot if it has been requested by one of its customers. Accordingly, if it has not received any such request, Wall-Market will not be able to accept a Time Slot.</p>
      <p className="p3">In the most densely populated areas (for example: Ile-de-France), the same Time Slot may be proposed by several Entities. In such a case, Wall-Market will select one of the Entities using objective and, where applicable, subjective criteria (for example: proximity to the place of performance of the service and prior performance of one or more services in accordance with the provisions of the General Terms and Conditions of the Services Agreement, if the Entity has already had the opportunity to work with Wall-Market).</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Wall-Market can use the Account to propose a services agreement with the Entity holding the Account</b></p>
      <p className="p3">Entities holding an active Account (that has not been deactivated) authorise Wall-Market to contact them to propose a services agreement on the terms of the General Terms and Conditions of the Services Agreement even if they have not registered a Time Slot. </p>
      <p className="p3">Wall-Market will submit any such proposal by email, specifying the service to be provided, the surface area of the relevant property, the municipality/geographical area in which the service is to be performed, the date and time of the service and the fee payable. The proposal will only give rise to the conclusion of a services agreement with Wall-Market if: (i) the Entity clicks on the “accept this service” button; and (ii) the service is displayed in its calendar. If it is not displayed in its calendar, this means that the agreement has not been concluded, as another Entity has already agreed to provide the service.</p>
      <p className="p3">This is not a common occurrence in practice. It mainly happens in the least densely populated areas or in the most densely populated areas (for example: metropolises, big cities) during periods of high demand (which are beyond the control of Wall-Market and subject to change). </p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>3.<span className="Apple-tab-span">	</span>The Account can be used to deliver completed services and manage invoicing</b></p>
      <p className="p2">The Entity can use the Account to:</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>deliver the files or URLs for the services performed in accordance with the General Terms and Conditions of the Services Agreement;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>view the history of the one or more services performed with Wall-Market; and</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>manage its invoicing. Accordingly, for each service provided, Wall-Market downloads, at the beginning of the month following the performance of a service, the Entity’s invoice for its fees under one or more services agreements. Wall-Market is able to download the invoices without accessing the Entity’s Account.</p>
      <p className="p2">Files and invoices are not stored in active accounts for an unlimited period. Accordingly:</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>invoices are available for five years from their date of issue, corresponding to the statutory limitation period;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>delivered files and URLs may be destroyed at any time within 48 hours of receipt. The fact that the files or URLs may be available for a longer period of time does not mean that they cannot be destroyed or that Wall-Market has waived this right of destruction within the 48-hour period referred to above. </p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>4.<span className="Apple-tab-span">	</span>Other Account features</b></p>
      <p className="p2">The Entity may use the Account to modify or update its profile, billing address and payment information at any time. </p>
      <p className="p2"><b>IV.<span className="Apple-tab-span">	</span>ENTITY’S OBLIGATIONS</b></p>
      <p className="p2"><b>IV.1<span className="Apple-tab-span">	</span></b>The Entity agrees to procure and guarantee compliance with the obligations set out herein by its representatives, executives and employees.</p>
      <p className="p2"><b>IV.2<span className="Apple-tab-span">	</span></b>Pursuant to the principle of fairness applicable to all agreements, any Entity wishing to work regularly with Wall-Market agrees not to solicit any Wall-Market customers met via the performance of a services agreement concluded with Wall-Market, for services sold by Wall-Market, throughout the activation period of the Account and for six (6) months after its deactivation.</p>
      <p className="p6">For the purposes of the interpretation of this Clause, whenever Wall-Market’s customer is a real estate agency, the Entity acknowledges that the non-solicitation covenant applies to the entire agency and, accordingly, to all real estate or commercial agents working with and/or for the agency.</p>
      <p className="p6">For the avoidance of doubt, no Wall-Market customer may be solicited in breach of the rules governing personal data.</p>
      <p className="p2"><b>V.<span className="Apple-tab-span">	</span>COOKIES</b></p>
      <p className="p2">Wall-Market uses cookies to the extent strictly necessary for the use of the Account and the Entity’s browsing operations within its personal area. These cookies are expressly authorised under the French Data Protection Act (Article 32-II of French Data Protection Act No. 78-17 of 6 January 1978, as amended).</p>
      <p className="p2"><b>VI.<span className="Apple-tab-span">	</span>DISCLAIMER OF LIABILITY BY WALL-MARKET</b></p>
      <p className="p2">Wall-Market disclaims all liability for any fraudulent use of an Account or the data it contains. </p>
      <p className="p2"><b>VII.<span className="Apple-tab-span">	</span>DURATION</b></p>
      <p className="p2">The Account is created and made available for an unlimited period of time.</p>
      <p className="p2"><b>VIII.<span className="Apple-tab-span">	</span>DESACTIVATION OF AN ACCOUNT</b></p>
      <p className="p2"><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>Either Party may decide to deactivate the Account, by giving one (1) month’s prior notice to the other Party in the following manner: </p>
      <p className="p4"><b><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>Notice given to Wall-Market by the Entity:</b> by email to the following address: <a href="mailto: providers@wall-market.com">providers@wall-market.com</a>;</p>
      <p className="p4"><b><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>Notice given to the Entity by Wall-Market:</b> by email, to the email address provided to Wall-Market, and to the last address registered in its profile and notified to Wall-Market if to be sent by registered post.</p>
      <p className="p2"><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Wall-Market may also deactivate the Account, without notice, in the event that the Entity transfers its login information to a third party or otherwise uses the Account fraudulently.</p>
      <p className="p2"><span className="Apple-tab-span">	</span>3.<span className="Apple-tab-span">	</span>After the effective date of the deactivation of the Account, no Time Slot may be proposed and no services agreement may be concluded.</p>
      <p className="p2"><span className="Apple-tab-span"> </span>However, it should be noted that notwithstanding an active Account, the Entity is under no obligation to register Time Slots. Accordingly, unless one of the Parties deactivates the Account, it will remain activated and the Entity will be free to propose Time Slots.</p>
      <p className="p2"><b>IX.<span className="Apple-tab-span">	</span>SUSPENSION AND CLOSURE OF CALENDAR ACCESS</b></p>
      <p className="p2">Wall-Market may, without notice, block the Entity’s access to the Calendar in the event of a serious breach by the Entity of the User Agreement or of the General Terms and Conditions of the Services Agreement in connection with the performance of a services agreement. </p>
      <p className="p2"><b>X.<span className="Apple-tab-span">	</span>CONFIDENTIALITY</b></p>
      <p className="p2">The Entity undertakes to keep the terms of this agreement confidential and, as such, not to disclose them to any third party.</p>
      <p className="p8">This obligation of confidentiality will continue to apply for a period of five (5) years after the deactivation of the Account.</p>
      <p className="p2"><b>XI.<span className="Apple-tab-span">	</span>SHORTENED LIMITATION PERIOD</b></p>
      <p className="p8">Pursuant to Article 2254 of the French Civil Code (code civil), the Parties agree to reduce the limitation period to one (1) year for any dispute involving Clauses III.1, V, VIII and IX above.</p>
      <p className="p2"><b>XII.<span className="Apple-tab-span">	</span>GOVERNING LAW AND JURISDICTION </b></p>
      <p className="p8">The User Agreement is governed by French law.</p>
      <p className="p8">Any dispute relating to the validity, interpretation, performance or termination of the User Agreement, whether in contract or tort, will be subject to the exclusive territorial jurisdiction of the courts in Paris.</p>
    </div>
  )
}

export default UsingAccount;
