import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from 'react-google-autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';

class GoogleAutocompleteField extends Component {

  constructor(props)  {
    super(props);

    this.state = {
      focus: false,
      hover: false
    }

    this.handlePlaceSelected = this.handlePlaceSelected.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleMouseEnter() {
    this.setState({hover: true});
  }

  handleMouseLeave() {
    this.setState({hover: false});
  }

  handleClick() {
    this.setState({focus: true});
  }

  handleBlur() {
    this.setState({focus: false});
  }

  handlePlaceSelected(place) {

    if (!place) return;

    const {
      input,
      onSelect,
      changeInputValueOnSelect
    } = this.props;

    if (onSelect && typeof onSelect === 'function') {
      onSelect(place);

      if (changeInputValueOnSelect) {
        if (input && input.onChange && place.formatted_address)
          input.onChange(place.formatted_address);
      }
    }
  }

  render() {

    const {
      focus,
      hover
    } = this.state;

    const {
      id,
      input,
      style,
      disabled,
      label,
      className,
      failed,
      failedMessage,
      meta: {touched, error }
    } = this.props;

    const finalClassName = "google-autocomplete-field" +
                           (hover ? " hover " : ' ' ) +
                           (focus ? " focus " : ' ' ) +
                           (touched && error ? " error " : ' ' ) +
                           (failed ? " error " : ' ' )

    return (
      <div>
        <div className={finalClassName}>
          <Autocomplete
            {...input}
            id={id}
            label={label}
            placeholder={label}
            autoComplete='off'
            disabled={disabled}
            style={style}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onClick={this.handleClick}
            onBlur={this.handleBlur}
            className={className}
            onPlaceSelected={this.handlePlaceSelected}
            types={['address']}
          />
        </div>
        {!failed && touched && error && <FormHelperText error={true}>{error}</FormHelperText>}
        {failed && <FormHelperText error={true}>{failedMessage}</FormHelperText>}
      </div>
    )
  }
}

GoogleAutocompleteField.defaultProps = {
  changeInputValueOnSelect: false
};

GoogleAutocompleteField.propTypes = {
  id: PropTypes.any,
  input: PropTypes.any,
  style: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  failed: PropTypes.bool,
  failedMessage: PropTypes.string,
  changeInputValueOnSelect: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
}

export default GoogleAutocompleteField;
