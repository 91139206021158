import { connect } from "react-redux";

import AccessDetailsDialog from "../../components/orderManagement/AccessDetailsDialog";

const mapStateToProps = (state) => ({
  getLoading: state.orderManagement.providers.getLoading,
  updateLoading: state.orderManagement.providers.updateLoading,
  data: state.orderManagement.providers.data,
  error: state.orderManagement.providers.error,
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessDetailsDialog);
