/* eslint-disable */

import React from 'react';

function UsingAccount() {

  return (
    <div className="cgs-using-account">
      <p className="p2">El objeto del presente acuerdo de uso de cuenta (en lo sucesivo, el « <b>Acuerdo de uso</b> ») es delimitar las normas de uso y operación del espacio personal (en lo sucesivo, la « <b>Cuenta</b> ») facilitado a cada profesional autónomo (en lo sucesivo, la « <b>Empesa</b>») que ha expresado su deseo de colaborar, de forma puntual o regular, con la sociedad de derecho francés SAS Wall-Market (en lo sucesivo, « <b>Wall-Market</b> »), y seleccionado por ésta, para realizar determinados servicios relacionados con los servicios que Wall-Market comercializa en su sitio y mediante su aplicación comercial LightShop.</p>
      <p className="p2">El Acuerdo de uso no tiene por objeto regir las condiciones de prestación de un servicio: estas se estipulan en el «Contrato de prestación de servicios - Condiciones generales» de Wall-Market (en lo sucesivo, las <b>Condiciones Generales del Contrato de Servicio</b> »), disponible en la Cuenta de cada Empresa.</p>
      <p className="p2">Debe leerse cuidadosamente el Acuerdo de uso, ya que es vinculante para la Empresa. Además, el uso de la Cuenta también puede obligar a la Empresa frente a Wall-Market en caso de contrato de prestación de servicios, de acuerdo con los términos de las Condiciones Generales del Contrato de Servicio y los del Mandato de facturación.</p>
      <p className="p3">La aceptación del Acuerdo de uso se materializa mediante una casilla de verificación que la Empresa deberá marcar. Esta aceptación solo puede ser plena y completa. Cualquier adhesión condicional se considera nula y sin efecto. Si la Empresa no acepta quedar vinculada por el Acuerdo de uso, no debe seguir navegando y se considerará que la Cuenta nunca ha existido y, si procede, se cerrará de inmediato. </p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>I.<span className="Apple-tab-span">	</span>PREÁMBULO</b></p>
      <p className="p2">La apertura de una Cuenta permite a una Empresa:</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>acceder a toda la información disponible en su Cuenta (perfil y datos sobre la Empresa, Condiciones Generales del Contrato de Servicio, Mandato de facturación);  </p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>rellenar y modificar los datos que le conciernen (p. ej., perfil e información de la empresa, foto de perfil);</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>mostrar su voluntad de firmar contratos con Wall-Market; </p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>gestionar la entrega de cualquier archivo o enlace URL resultante de un servicio; y</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>gestionar la facturación.</p>
      <p className="p2"><b>La apertura de una Cuenta no garantiza de ninguna manera a la Empresa la celebración de un contrato de prestación de servicios con Wall-Market o un compromiso de volumen por parte de Wall-Market. </b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>II.<span className="Apple-tab-span">	</span>ACCESO Y USO DE LA CUENTA </b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>En cuanto al acceso y uso de la cuenta</b></p>
      <p className="p2">Cada Empresa tiene una cuenta, estrictamente personal, a la que tiene acceso exclusivo.</p>
      <p className="p2">
        Solo la Empresa está autorizada a utilizar su Cuenta. Se compromete a utilizarla de buena fe.
        La Empresa que subcontrata, con el acuerdo expreso y previo de Wall-Market, puede, si así lo desea, tener acceso como «administrador» a las cuentas de las Empresas «subcontratantes». En este caso, se compromete a informar a las Empresas que subcontrata. Esta información es su única responsabilidad.
      </p>
      <p className="p2">El acceso a la Cuenta requiere el registro de los datos de acceso, a saber: un usuario único elegido por la Empresa y una contraseña. <b>Al conectarse la primera vez, la Empresa deberá imperativamente cambiar la primera contraseña que Wall-Market registra por defecto, sujeto a su aceptación del Acuerdo de uso.</b></p>
      <p className="p2">Se puede acceder a la Cuenta de la Empresa en la URL <a href="https://providers-lightshop.wall-market.com/" target="_blank" rel="noopener noreferrer">https://providers-lightshop.wall-market.com/</a>, una vez que la Empresa haya introducido sus datos de acceso. Wall-Market se reserva la posibilidad, por razones técnicas en particular, de modificar la URL de acceso de la cuenta.</p>
      <p className="p2">Wall-Market tiene la posibilidad y está expresamente autorizada a este respecto a poner a disposición de la Empresa varios documentos en su Cuenta. Esta opción no le permite acceder a una Cuenta de ninguna manera.</p>
      <p className="p2">Para garantizar su acceso exclusivo a la Cuenta, la Empresa se compromete a elegir una contraseña suficientemente segura la primera vez que se conecte y a no revelarla, bajo ninguna circunstancia, a ningún tercero.</p>
      <p className="p2">La Empresa es responsable de mantener la confidencialidad de sus datos de acceso y la actividad de su Cuenta. En ningún caso podrán ser transferidos o comunicados a un tercero. En el caso de que la Empresa se entere de que otra persona tiene acceso a su Cuenta, informará a Wall-Market sin demora.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Obligación de actualización</b></p>
      <p className="p2">La Empresa se compromete a informar a Wall-Market de cualquier cambio en su estado, modificación de datos de contacto o cualquier otro cambio que pueda tener un impacto en este Acuerdo de uso o en el contrato de prestación de servicios que pueda resultar del mismo.</p>
      <p className="p2">Esta obligación es una condición esencial y necesaria para la celebración de un contrato de prestación de servicios con Wall-Market.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>III.<span className="Apple-tab-span">	</span>FUNCIONALIDADES DE LA CUENTA</b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>La Cuenta permite proponer a Wall-Market la celebración de un contrato de prestación de servicios </b></p>
      <p className="p2"><span className="Apple-tab-span">	</span>A.<span className="Apple-tab-span">	</span><span className="s2">Propuesta de un Período por la Empresa, a su elección discrecional</span></p>
      <p className="p2">La creación de una cuenta permite a la Empresa acceder a un calendario (en lo sucesivo, el « <b>Calendario</b> ») que le permite registrar uno o más períodos de tiempo (en lo sucesivo, « <b>Períodos</b>») para los servicios que la Empresa desea y puede realizar.</p>
      <p className="p2">El registro de un Período constituye una propuesta de contrato para la prestación de servicios por parte de la Empresa a Wall-Market según los términos estipulados en las Condiciones Generales del Contrato de Servicios. Por consiguiente, se solicita a la Empresa que, en el momento de aceptar el Acuerdo de uso, acepte también:</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>las Condiciones Generales del Contrato de Servicio ya que cada contrato de prestación de servicios entre Wall-Market y la Empresa se regirá por estas, en la versión vigente en el momento en que la Empresa proponga un Período. Si la Empresa no desea estar sujeta a las Condiciones Generales del Contrato de Servicio, no debe rellenar el Período;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>el mandato de facturación.</p>
      <p className="p2">La aceptación de las Condiciones Generales del Contrato de Servicio y del mandato de facturación se materializa con una casilla de verificación.</p>
      <p className="p2">Los Períodos pueden proponerse para días completos o determinadas horas solo durante un día, a discreción de la Empresa.</p>
      <p className="p2">No hay obligación por parte de la Empresa de proponer Períodos. Por lo tanto, la ausencia de una propuesta de Período no conduce al cierre de la Cuenta.</p>
      <p className="p2">Una vez propuesto un Período, la Empresa tiene la potestad de modificarlo o retirarlo en cualquier momento, con sujeción a las estipulaciones que figuran a continuación (véase la cláusula <b><i>III.1.B</i></b>).</p>
      <p className="p2">La propuesta de un Período por parte de la Empresa no garantiza la celebración de un contrato de prestación de servicios.</p>
      <p className="p2">B.<span className="Apple-tab-span">	</span><span className="s2">Aceptación de un Período por Wall-Market</span></p>
      <p className="p2"><b>B.1<span className="Apple-tab-span">	</span><i>Consecuencias de la aceptación de un Período por parte de Wall-Market</i></b></p>
      <p className="p2">Wall-Market no está obligado a aceptar una oferta de Período.</p>
      <p className="p2">La aceptación por parte de Wall-Market de la oferta de un Período se considerará como un acuerdo entre las partes para que la Empresa realice un servicio de acuerdo con los términos de las Condiciones Generales del Contrato de Servicio.</p>
      <p className="p2">Wall-Market informará a la Empresa de la aceptación de su oferta por correo electrónico, notificación push y/o SMS; entendiéndose que solo la información transmitida a través de la Cuenta de la Empresa es fehaciente. Por lo tanto, la Empresa es la única responsable de cualquier mal funcionamiento que impida la recepción de un correo electrónico, notificación push y/o SMS.</p>
      <p className="p2">La aceptación de un Período por parte de Wall-Market compromete a la Empresa, que ya no puede modificar este Período.</p>
      <p className="p3">Wall-Market destaca a la Empresa que, según las Condiciones Generales del Contrato de Servicio, todo contrato de prestación de servicios se rescindirá automáticamente, sin ninguna formalidad o aviso formal, si un Cliente de Wall-Market cancela su pedido con Wall-Market más de seis (6) horas antes de su ejecución.</p>
      <p className="p2"><b>B.2<span className="Apple-tab-span">	</span><i>Términos de aceptación de un Período por parte de Wall-Market</i></b></p>
      <p className="p3">La aceptación de un Período por parte de Wall-Market depende de la demanda de sus clientes. Por lo tanto, si no hay demanda de los clientes, Wall-Market no podrá aceptar un Período.</p>
      <p className="p3">En las zonas geográficas más densas (p. ej., metrópolis, grandes ciudades), varios Profesionales pueden proponer el mismo Período. En tal caso, Wall-Market elegirá la Empresa según criterios objetivos y, si procede, subjetivos (p. ej., proximidad al lugar de prestación del servicio, prestación de uno o más servicios anteriores de acuerdo con las estipulaciones de las Condiciones Generales del Contrato de Servicio asumiendo que la Empresa ya ha tenido la oportunidad de colaborar con Wall-Market). </p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>La Cuenta permite a Wall-Market proponer a la Empresa titular de la Cuenta la celebración de un contrato de prestación de Servicios</b></p>
      <p className="p3">La Empresa titular de una Cuenta activa (no cerrada) autoriza a Wall-Market a ponerse en contacto con ella para ofrecerle un contrato de prestación de servicios en virtud de los términos de las Condiciones Generales del Contrato de Servicio, aunque no haya registrado un Período. </p>
      <p className="p3">Esta propuesta de Wall-Market se hará por correo electrónico, especificando el servicio previsto, la superficie del bien inmueble en cuestión, el municipio del lugar donde se hará la prestación, la fecha y hora del servicio y su remuneración. Para que esta propuesta conduzca a la celebración de un contrato de prestación de servicios con Wall-Market: (i) la Empresa debe clicar en «aceptar este servicio»; y (ii) el servicio debe aparecer en su calendario. Si no aparece en su calendario, significa que el contrato no se ha celebrado porque otra Empresa ya ha aceptado prestar el servicio.</p>
      <p className="p3">En la práctica, esto ocurre con poca frecuencia. Se da principalmente en las zonas geográficas menos densas o en las zonas más densas (p. ej., metrópolis, grandes ciudades) durante los períodos de gran demanda (que no dependen de Wall-Market y pueden cambiar). </p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>3.<span className="Apple-tab-span">	</span>La Cuenta permite entregar un servicio prestado y gestionar la facturación</b></p>
      <p className="p2">La Cuenta permite a la Empresa:</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>entregar los archivos o URL resultantes de los servicios realizados de acuerdo con las Condiciones Generales del Contrato de Servicio;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>recuperar el historial de los servicios realizados con Wall-Market; y</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>gestionar su facturación. Así, para cualquier servicio realizado, Wall-Market descarga, al principio del mes siguiente a la prestación de un servicio, la factura de la Empresa correspondiente a su remuneración por uno o varios contratos de servicios. Esta operación de descarga no implica el acceso de Wall-Market a la Cuenta de la Empresa.</p>
      <p className="p2">La vida útil de los archivos o facturas de una cuenta activa no es ilimitada. Por consiguiente:</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>la disponibilidad de las facturas se limita a cinco años a partir de la fecha de expedición, lo que corresponde al plazo de prescripción legal;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>los archivos o enlaces URL entregados pueden destruirse en cualquier momento durante las 48 horas posteriores a su recepción. El hecho de que estos archivos o enlaces URL puedan estar disponibles durante un período de tiempo más largo no significa que no puedan estar disponibles o que Wall-Market renuncie a esta destrucción en el plazo de 48 horas mencionado anteriormente.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>4.<span className="Apple-tab-span">	</span>Otras características de la Cuenta</b></p>
      <p className="p2">La Cuenta permite a la Empresa cambiar o actualizar su perfil, dirección de facturación y datos de pago en cualquier momento.</p>
      <p className="p2"><b>IV.<span className="Apple-tab-span">	</span>OBLIGACIONES DE LA EMPRESA</b></p>
      <p className="p2"><b>IV.1<span className="Apple-tab-span">	</span></b>La Sociedad se compromete a que sus representantes, directivos y empleados cumplan las obligaciones estipuladas en el presente documento.</p>
      <p className="p2"><b>IV.2<span className="Apple-tab-span">	</span></b>En aplicación del principio de lealtad aplicable a todos los acuerdos, la Empresa que quiera colaborar regularmente con Wall-Market se compromete a no contactar con los clientes de Wall-Market atendidos durante la ejecución de un contrato de prestación de servicios celebrado con Wall-Market, por los servicios vendidos por Wall-Market, mientras la Cuenta esté abierta y durante seis (6) meses después de su cierre.</p>
      <p className="p6">Para la interpretación de esta cláusula, cuando el cliente de Wall-Market es una agencia inmobiliaria, la Empresa reconoce que la obligación de no contactar se aplica a toda la agencia y, por consiguiente, a todos los agentes inmobiliarios o comerciales que colaboren o pertenezcan a dicha agencia.</p>
      <p className="p6">En caso necesario, no se podrá realizar ninguna prospección de un cliente de Wall-Market en contravención de la normativa relativa a los datos personales.</p>
      <p className="p2"><b>V.<span className="Apple-tab-span">	</span>DATOS DE CONEXIÓN («COOKIES»)</b></p>
      <p className="p2">Wall-Market utiliza cookies de conexión estrictamente necesarias para el uso de la Cuenta y la navegación de la Empresa en su espacio personal. Estas cookies están expresamente autorizadas por la Ley francesa de Informática y Libertades (artículo 32-II de la Ley modificada 78-17, del 6 de enero de 1978).</p>
      <p className="p2"><b>VI.<span className="Apple-tab-span">	</span> EXCLUSIÓN DE RESPONSABILIDAD DE WALL-MARKET</b></p>
      <p className="p2">La responsabilidad de Wall-Market está excluida por cualquier uso fraudulento de una Cuenta o los datos que contiene. </p>
      <p className="p2"><b>VII.<span className="Apple-tab-span">	</span>DURACIÓN</b></p>
      <p className="p2">La Cuenta se crea y se pone a disposición por un período de tiempo ilimitado.</p>
      <p className="p2"><b>VIII.<span className="Apple-tab-span">	</span>CIERRE DE LA CUENTA</b></p>
      <p className="p2"><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>Cada una de las Partes podrá decidir cerrar la Cuenta, previa notificación a la otra Parte con un (1) mes de antelación, de la siguiente manera: </p>
      <p className="p4"><b><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>Notificación de la Empresa a Wall-Market:</b> por correo electrónico, a la siguiente dirección: <a href="mailto: providers@wall-market.com">providers@wall-market.com</a>;</p>
      <p className="p4"><b><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>Notificación de Wall-Market a la Empresa:</b> por correo electrónico, a la dirección de correo electrónico que haya indicado a Wall-Market, y a la última dirección incluida en su perfil y comunicada a Wall-Market para el eventual envío de una carta certificada con acuse de recibo.</p>
      <p className="p2"><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Wall-Market también puede cerrar la Cuenta, sin previo aviso, en el caso que la Empresa ceda su información de acceso a un tercero, o ante cualquier otro uso fraudulento por parte de la Empresa.</p>
      <p className="p2"><span className="Apple-tab-span">	</span>3.<span className="Apple-tab-span">	</span>Después de la fecha efectiva de cierre de la Cuenta, ya no se puede ofrecer ningún Período y no se puede celebrar ningún contrato de prestación de servicios..</p>
      <p className="p2"><span className="Apple-tab-span"> </span>Sin embargo, se recuerda que una Cuenta activa no obliga a la Empresa a registrar Períodos. Por consiguiente, si ninguna de las partes cierra la Cuenta, esta permanecerá abierta y la Empresa podrá proponer Períodos..</p>
      <p className="p2"><b>IX.<span className="Apple-tab-span">	</span>SUSPENSIÓN Y CIERRE DEL ACCESO AL CALENDARIO</b></p>
      <p className="p2">Wall-Market puede, sin previo aviso, privar a la Empresa de acceso al Calendario en caso de violación grave por parte de la Empresa del Acuerdo de uso o de las Condiciones Generales del Contrato de Servicio en el contexto de la ejecución de un contrato de prestación de servicios. </p>
      <p className="p2"><b>X.<span className="Apple-tab-span">	</span>CONFIDENCIALIDAD</b></p>
      <p className="p2">La Empresa se compromete a mantener el carácter confidencial de los términos del presente documento y, como tal, a no revelarlos a ningún tercero.</p>
      <p className="p8">Esta obligación de confidencialidad permanecerá en vigor durante un período de cinco (5) años después del cierre de la Cuenta.</p>
      <p className="p2"><b>XI.<span className="Apple-tab-span">	</span>PLAZO DE PRESCRIPCIÓN ABREVIADO</b></p>
      <p className="p8">Con arreglo al artículo 2254 del Código Civil francés, las partes acuerdan reducir el plazo de prescripción a un (1) año para cualquier desacuerdo relacionado con los artículos III.1, V, VIII y IX anteriores.</p>
      <p className="p2"><b>XII.<span className="Apple-tab-span">	</span>DERECHO APLICABLE Y JURISDICCIÓN COMPETENTE </b></p>
      <p className="p8">El presente acuerdo está sometido al derecho francés. </p>
      <p className="p8">Todo desacuerdo relativo a la validez, interpretación, ejecución o incumplimiento del Acuerdo de uso, ya sea contractual o extracontractual, será de la exclusiva competencia territorial de los tribunales de París.</p>
    </div>
  )
}

export default UsingAccount;
