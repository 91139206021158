import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../helpers/functions/api';

import {
  acceptRequestDispatch,
  acceptRequestDispatchSuccess,
  acceptRequestDispatchFailure,
  clearDispatchReducer,
  getDispatchDetails,
  getDispatchDetailsSuccess,
  getDispatchDetailsFailure
} from '../../redux/actions/requestDispatchActions';
import { enqueueSnackbar } from '../../redux/actions/notificationsActions';

import RequestDispatch from '../../views/requestDispatch/RequestDispatch';

import translation from '../../translation/translation';

const mapStateToProps = state => ({
  requestDispatch: state.requestDispatch
});

const mapDispatchToProps = dispatch => {
  return {
    clearDispatchReducer: () => {
      dispatch(clearDispatchReducer());
    },
    triggerRequestFailure: error => {
      dispatch(acceptRequestDispatchFailure(error));
    },
    getDispatchDetails: (id, token, start) => {
      dispatch(getDispatchDetails());

      if (!id) return;

      fetchApi(
        "get",
        "providers/self/order-products/" + id + "/dispatch-info",
        { dispatch_token: token, start },
        null,
        null,
        data => {
          dispatch(getDispatchDetailsSuccess(data));
        },
        error => {
          const message = error && error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "An error occurred.";
          dispatch(getDispatchDetailsFailure(message));
        }
      );
    },
    acceptRequestDispatch: (id, dispatchToken, startDate, callbackSuccess) => {

      dispatch(acceptRequestDispatch());

      if (!id || !dispatchToken || !startDate) return;

      fetchApi(
        "post",
        "providers/self/order-products/" + id + "/request-dispatch",
        null,
        { dispatch_token: dispatchToken, start: startDate },
        null,
        () => {
          dispatch(acceptRequestDispatchSuccess());
          dispatch(enqueueSnackbar({
            message: translation().requestdipatch.success || "Done with success.",
            options: {
              variant: 'success',
            },
          }));

          if (callbackSuccess && typeof callbackSuccess === "function")
            callbackSuccess();
        },
        error => {
          const message = error && error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "An error occurred.";
          dispatch(acceptRequestDispatchFailure(message));
          dispatch(enqueueSnackbar({
            message,
            options: {
              variant: 'error',
            },
          }));
        }
      );
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestDispatch));
