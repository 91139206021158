import React from "react";
import { change, Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { indigo, grey } from "@material-ui/core/colors";

import translation from "../../translation/translation";
import MuiSelectField from "../commons/fields/MuiSelectField";
import store from "../../redux/store";

const classes = {
  button: {
    borderRadius: 4,
    fontFamily: "'Montserrat-Regular'",
    lineHeight: "1.2",
    color: indigo[300],
    borderColor: indigo[300],
    "&:hover": {
      color: indigo[300],
      borderColor: indigo[300],
      backgroundColor: "rgb(63 81 181 / 16%)",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: 15,
    gap: "10px",
    color: grey[500],
  },
};

class ManagePaymentMethodDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      value: "",
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDialogEnter = this.handleDialogEnter.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleOpen() {
    this.setState({ isOpened: true });
  }

  handleClose() {
    this.setState({ isOpened: false, value: "" });
    store.dispatch(
      change("manage_order_payment_method", "order_payment_method", "")
    );
  }

  handleDialogEnter() {
    const { getOrderPaymentMethods } = this.props;

    getOrderPaymentMethods();
  }

  handleSelect(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit() {
    // Handle submit
  }

  render() {
    const { isOpened, value } = this.state;
    const { classes, data, getLoading, updateLoading } = this.props;

    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleOpen}
          className={classes.button}
          size="small"
        >
          {translation().order_management.payment_methods.button}
        </Button>
        <Dialog
          fullWidth
          open={isOpened ? true : false}
          onClose={this.handleClose}
          aria-labelledby="edit-payment-method"
          aria-describedby="edit-payment-method-description"
          disableBackdropClick={getLoading || updateLoading}
          onEnter={this.handleDialogEnter}
        >
          <DialogTitle id="edit-payment-method">
            {translation().order_management.payment_methods.dialog_title}
          </DialogTitle>
          <DialogContent>
            {data && data.length > 0 ? (
              <FormControl fullWidth>
                {!value && (
                  <InputLabel for="order_payment_method">
                    {translation().order_management.payment_methods.label}
                  </InputLabel>
                )}
                <Field
                  component={MuiSelectField}
                  fullWidth
                  id="order_payment_method"
                  name="order_payment_method"
                  displayEmpty
                  disabled={updateLoading || getLoading}
                  onChange={this.handleSelect}
                  value={value}
                  required
                >
                  <option />
                  {data.map((method, index) => (
                    <option key={index} value={method.id}>
                      {method.name}
                    </option>
                  ))}
                </Field>
              </FormControl>
            ) : (
              !getLoading && (
                <Typography>
                  {translation().order_management.payment_methods.empty}
                </Typography>
              )
            )}
            {getLoading && (
              <div className={classes.loading}>
                <CircularProgress size={30} color="secondary" />
                <p>
                  {translation().order_management.payment_methods.loading_text}
                </p>
              </div>
            )}
            {updateLoading && (
              <div className={classes.loading}>
                <CircularProgress size={30} color="secondary" />
                <p>
                  {translation().order_management.payment_methods.edit_loading}
                </p>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              disabled={getLoading || updateLoading}
              size="small"
            >
              {translation().commons.cancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              disabled={updateLoading || !value}
              size="small"
            >
              {translation().commons.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ManagePaymentMethodDialog.propTypes = {
  classes: PropTypes.shape({
    loading: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  getLoading: PropTypes.bool,
  updateLoading: PropTypes.bool,
  getOrderPaymentMethods: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "manage_order_payment_method",
})(withStyles(classes)(ManagePaymentMethodDialog));
