export const getOrderInvoiceAddresses = () => ({
  type: "GET_ORDER_INVOICE_ADDRESSES",
});

export const getOrderInvoiceAddressesSuccess = (data) => ({
  type: "GET_ORDER_INVOICE_ADDRESSES_SUCCESS",
  data,
});

export const getOrderInvoiceAddressesFailure = (error) => ({
  type: "GET_ORDER_INVOICE_ADDRESSES_FAILURE",
  error,
});
