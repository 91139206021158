import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab } from "@material-ui/core";

import Information from "./Information";
import Products from "./Products";

function TabContainer(props) {
  const tabStyle = {
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 15,
    minHeight: 375,
  };

  return <div style={tabStyle}>{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

class SimpleTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, ...rest } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Tabs centered value={value} onChange={this.handleChange}>
            <Tab label="Informations" />
            <Tab label="Produits" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <Information {...rest} />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <Products products={rest.products} />
          </TabContainer>
        )}
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    is_admin: PropTypes.bool,
  }).isRequired,
};

export default withStyles(styles)(SimpleTabs);
