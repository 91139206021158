import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { getLocale } from '../../helpers/functions/cookies';
import translation from '../../translation/translation';

import TermsFR from './termsFR/TermsFR';
import TermsEN from './TermsEN/TermsEN';
import TermsES from './TermsES/TermsES';

class AcceptTerms extends Component {

  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleAccept() {
    const { onAccept } = this.props;
    const { checked } = this.state;

    if (checked && onAccept && typeof onAccept === "function") {
      onAccept();
    }
  }

  handleCheck() {
    this.setState({ checked: !this.state.checked });
  }

  handleLogout() {
    const { signOut } = this.props;

    if (signOut && typeof signOut === "function") {
      signOut();
    }
  }

  handleClose() {
    const { onClose } = this.props;

    if (onClose && typeof onClose === "function") {
      onClose();
    }
  }

  renderTerms() {
    const locale = getLocale();

    if (locale && locale === "fr")
      return <TermsFR />;
    else if (locale && locale === "en")
      return <TermsEN />;
    else if (locale && locale === "es")
      return <TermsES />;
    else if (locale && locale === "it")
      return <TermsEN />;
    else
      return <TermsEN />;
  }

  render() {

    const {
      loading,
      mode,
      open
    } = this.props;

    const {
      checked,
    } = this.state;

    return (
      <div className="AcceptTerms">
        <Dialog
          open={open}
          scroll="paper"
          maxWidth="lg"
          aria-labelledby="accept-terms-of-sale"
          onClose={this.handleClose}
        >
          <DialogTitle id="accept-terms-of-sale" className="accept-terms-dialog-title">{translation().cgs.title}</DialogTitle>
          <DialogContent className="accept-terms-dialog-content">
            { this.renderTerms() }
          </DialogContent>
          { mode && mode === "readOnly"
            ? (
              <DialogActions className="accept-terms-actions">
                <Button
                  onClick={this.handleClose}
                  disabled={loading}
                  color="default"
                >
                  {translation().cgs.buttons.close}
                </Button>
              </DialogActions>
            )
            : mode === "toAccept" ? (
              <DialogActions className="accept-terms-actions">
                <div className="accept-terms-checkbox" style={{marginRight: "auto"}}>
                  <FormGroup style={{alignItems: 'flex-end'}}>
                    <FormControlLabel
                      disabled={loading}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={this.handleCheck}
                          value="accepted"
                          color="primary"
                        />
                      }
                      label={translation().cgs.checkbox_label}
                    />
                  </FormGroup>
                </div>
                { loading && <CircularProgress size={25} color="secondary" /> }
                <Button
                  onClick={this.handleLogout}
                  disabled={loading}
                  color="default"
                  variant="outlined"
                >
                  { window.matchMedia("(max-width: 850px)").matches ? <PowerSettingsNewIcon /> : translation().cgs.buttons.logout}
                </Button>
                <Button
                  onClick={this.handleAccept}
                  color="primary"
                  disabled={loading || !checked}
                  variant="contained"
                  style={{minWidth: 78}}
                >
                  {translation().cgs.buttons.accept}
                </Button>
              </DialogActions>
            ) : false
          }
        </Dialog>
      </div>
    );
  }
}

AcceptTerms.defaultProps = {
  mode: "toAccept",
  open: true
}

AcceptTerms.propTypes = {
  loading: PropTypes.bool,
  mode: PropTypes.string,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  signOut: PropTypes.func,
}

export default AcceptTerms;
