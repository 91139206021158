import { connect } from 'react-redux';

import {
  getUser, getUserSuccess, getUserFailure,
  authUser, authUserSuccess, authUserFailure
} from '../redux/actions/userActions';
import {
  acceptProviderTerms, acceptProviderTermsSuccess, acceptProviderTermsFailure,
  getProvider, getProviderSuccess, getProviderFailure, setNotProvider
} from '../redux/actions/providerActions';

import { registerDevice, unregisterDevice } from '../helpers/functions/notificationsPush';
import { setAccessToken, removeAccessToken } from '../helpers/functions/cookies';
import { fetchApi } from '../helpers/functions/api';
import { initializeGeoSettings } from '../helpers/functions/user';

import { enqueueSnackbar, removeSnackbar } from '../redux/actions/notificationsActions';
import { clearReducers } from '../redux/actions/appActions';

import translation from '../translation/translation';

import App from '../App';

const mapStateToProps = store => ({ reduxStore: store });

const mapDispatchToProps = dispatch => {

  const signOut = () => {
    unregisterDevice(
      () => {
        removeAccessToken();
        dispatch(clearReducers());
      },
      () => {
        removeAccessToken();
        dispatch(clearReducers());
      },
    );
  }

  const initProvider = (callbackSuccess) => {
    dispatch(getProvider());

    fetchApi(
      "get",
      "providers/self",
      null,
      null,
      null,
      provider => {
        if (provider) {
          dispatch(getProviderSuccess(provider));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();
        }
      },
      error => {
        let errorMessage = "An error occurred, please retry later.";

        if (error.response) {
          if (error.response.status) {
            if (error.response.status === 403) {
              errorMessage = "Forbidden access.";
              signOut();
            }
            else if (error.response.status === 401) {
              errorMessage = "Unauthorized.";
              signOut();
            }
            else
              errorMessage = "An error occurred, please retry later.";
          }
        }
        else if (error.request)
          errorMessage = "The server does not respond, please retry later.";

        dispatch(getProviderFailure(errorMessage));
        dispatch(getUserFailure(errorMessage));
        dispatch(setNotProvider());
        removeAccessToken();
      }
    );
  }

  return {
    removeSnackbar() {
      dispatch(removeSnackbar());
    },
    storeAccessToken(token, expiration, callbackFunction) {
      if (!token) return;

      setAccessToken(token, expiration);

      if (callbackFunction && typeof callbackFunction === 'function')
        callbackFunction();
    },
    authUser() {
      dispatch(authUser());
    },
    authUserSuccess() {
      dispatch(authUserSuccess());
    },
    authUserFailure(error = null) {
      dispatch(authUserFailure(error));
    },
    signOut() {
      signOut();
    },
    acceptProviderTerms() {
      dispatch(acceptProviderTerms());

      fetchApi(
        "post",
        "providers/self/accept-terms",
        null,
        null,
        null,
        () => {
          initProvider(() => {
            dispatch(acceptProviderTermsSuccess());
          });
        },
        error => {
          dispatch(acceptProviderTermsFailure());

          const message = translation().cgs.callback_error;

          if (error.response) {
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : message,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(enqueueSnackbar({
              message: message,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    getUser() {
      dispatch(getUser());

      fetchApi(
        "get",
        "users/self",
        null,
        null,
        null,
        user => {
          if (user) {
            initializeGeoSettings(
              user,
              () => {
                initProvider(() => {
                  dispatch(getUserSuccess(user));
                  registerDevice();
                });
              }
            );
          }
        },
        error => {
          let errorMessage = "An error occurred, please retry later.";

          if (error.response) {
            if (error.response.status) {
              if (error.response.status === 403)
                errorMessage = "Forbidden access.";
              else if (error.response.status === 401) {
                signOut();
                errorMessage = "Unauthorized.";
              }
              else
                errorMessage = "An error occurred, please retry later.";
            }
          }
          else if (error.request)
            errorMessage = "The server does not respond, please retry later.";

          dispatch(getUserFailure(errorMessage));
        }
      );
    },
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(App);
