const initialState = {
  list: [],
  loading: false,
  paging: null,
  error: null,
  create_action: {
    loading: false,
    error: null
  },
  delete_action: {
    loading: false,
    error: null
  }
}

const availabilitiesReducer = (state = initialState, action) => {

 switch (action.type) {
   case 'CLEAR_REDUCERS':
     return initialState;
   case 'GET_AVAILABILITIES': {
     const availabilities = action.next ? state.list : [];

     return {
       ...state,
       list: availabilities,
       loading: true
     };
   }
   case 'GET_AVAILABILITIES_SUCCESS':
     return {
       ...state,
       list: action.data,
       paging: action.paging,
       error: null,
       loading: false
     };
   case 'GET_AVAILABILITIES_FAILURE':
     return {
       ...state,
       error: action.error,
       loading: false
     };
   case 'UPDATE_AVAILABILITIES': {
     const updatedAvailabilities = state.list.concat(action.data);

     return {
       ...state,
       list: updatedAvailabilities,
       paging: action.paging ? action.paging : state.paging,
       loading: false,
       error: null
     };
   }
   case 'POST_AVAILABILITY':
     return {
       ...state,
       create_action: {
         loading: true,
         error: false
       }
     };
   case 'POST_AVAILABILITY_SUCCESS':
     return {
       ...state,
       create_action: {
         loading: false,
         error: false
       }
     };
   case 'POST_AVAILABILITY_FAILURE':
     return {
       ...state,
       create_action: {
         loading: false,
         error: action.error
       }
     };
   case 'DELETE_AVAILABILITY':
     return {
       ...state,
       delete_action: {
         loading: true,
         error: false
       }
     };
   case 'DELETE_AVAILABILITY_SUCCESS':
     return {
       ...state,
       delete_action: {
         loading: false,
         error: false
       }
     };
   case 'DELETE_AVAILABILITY_FAILURE':
     return {
       ...state,
       delete_action: {
         loading: false,
         error: action.error
       }
     };

   default:
     return state
 }
}

export default availabilitiesReducer;
