const initialState = {
  addresses: {
    mode: 'users',
    loading: false,
    error: null,
    delete_action: {
      loading: false,
      error: null
    },
    create_action: {
      loading: false,
      error: null
    },
    edit_action: {
      loading: false,
      error: null
    },
  },
  invoice_queue: {
    mode: 'providers',
    data: [],
    paging: null,
    loading: false,
    error: null
  },
  invoices_list: {
    mode: 'providers',
    data: [],
    paging: null,
    loading: false,
    error: null
  },
  payment_methods: {
    mode: 'users',
    data: [],
    paging: null,
    loading: false,
    error: null,
    delete_action: {
      loading: false,
      error: null
    },
  },
};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'TOGGLE_PAYMENT_METHODS_MODE':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          mode: action.mode
        }
      }
    case 'TOGGLE_INVOICES_EMITTED_LIST_MODE':
      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          mode: action.mode
        }
      }
    case 'TOGGLE_INVOICES_LIST_MODE':
      return {
        ...state,
        invoices_list: {
          ...state.invoices_list,
          mode: action.mode
        }
      }
    case 'TOGGLE_ADDRESSES_MODE':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          mode: action.mode
        }
      }
    case 'GET_PROVIDER_INVOICE_QUEUE': {
      const queueData = action.next ? state.invoice_queue.data : [];

      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          data: queueData,
          loading: true
        }
      };
    }
    case 'GET_PROVIDER_INVOICE_QUEUE_SUCCESS':
      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          data: action.data,
          paging: action.paging,
          error: null,
          loading: false
        }
      };
    case 'GET_PROVIDER_INVOICE_QUEUE_FAILURE':
      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          error: action.error,
          loading: false
        }
      };
    case 'UPDATE_PROVIDER_INVOICE_QUEUE': {
      const updatedQueue = state.invoice_queue.data.concat(action.data);

      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          data: updatedQueue,
          paging: action.paging ? action.paging : state.paging,
          loading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_INVOICES': {
      const invoicesList = action.next ? state.invoices_list.data : [];

      return {
        ...state,
        invoices_list: {
          ...state.invoices_list,
          data: invoicesList,
          loading: true
        }
      };
    }
    case 'GET_PROVIDER_INVOICES_SUCCESS':
      return {
        ...state,
        invoices_list: {
          ...state.invoices_list,
          data: action.data,
          paging: action.paging,
          error: null,
          loading: false
        }
      };
    case 'GET_PROVIDER_INVOICES_FAILURE':
      return {
        ...state,
        invoices_list: {
          ...state.invoices_list,
          error: action.error,
          loading: false
        }
      };
    case 'UPDATE_PROVIDER_INVOICES': {
      const updatedInvoicesList = state.invoices_list.data.concat(action.data);

      return {
        ...state,
        invoices_list: {
          ...state.invoices_list,
          data: updatedInvoicesList,
          paging: action.paging ? action.paging : state.paging,
          loading: false,
          error: null
        }
      };
    }
    case 'GET_PAYMENT_METHODS': {
      const paymentMethods = action.next ? state.payment_methods.data : [];

      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: paymentMethods,
          loading: true
        }
      };
    }
    case 'GET_PAYMENT_METHODS_SUCCESS':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: action.data,
          paging: action.paging,
          error: null,
          loading: false
        }
      };
    case 'GET_PAYMENT_METHODS_FAILURE':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          error: action.error,
          loading: false
        }
      };
    case 'UPDATE_PAYMENT_METHODS': {
      const updatedPymtMds = state.payment_methods.data.concat(action.data);

      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: updatedPymtMds,
          paging: action.paging ? action.paging : state.paging,
          loading: false,
          error: null
        }
      };
    }
    case 'DELETE_PAYMENT_METHOD':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          delete_action: {
            error: null,
            loading: true
          }
        }
      };
    case 'DELETE_PAYMENT_METHOD_SUCCESS':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          delete_action: {
            error: null,
            loading: false
          }
        }
      };
    case 'DELETE_PAYMENT_METHOD_FAILURE':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          delete_action: {
            error: action.error,
            loading: false
          }
        }
      };
    case 'POST_ADDRESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          create_action: {
            loading: true,
            error: null
          }
        }
      };
    case 'POST_ADDRESS_SUCCESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          create_action: {
            loading: false,
            error: null
          }
        }
      };
    case 'POST_ADDRESS_FAILURE':
      return {
          ...state,
          addresses: {
            ...state.addresses,
            create_action: {
              loading: false,
              error: action.error
            }
          }
        };
    case 'DELETE_ADDRESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          delete_action: {
            loading: true,
            error: null
          }
        }
      };
    case 'DELETE_ADDRESS_SUCCESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          delete_action: {
            loading: false,
            error: null
          }
        }
      };
    case 'DELETE_ADDRESS_FAILURE':
      return {
          ...state,
          addresses: {
            ...state.addresses,
            delete_action: {
              loading: false,
              error: action.error
            }
          }
        };
    case 'EDIT_ADDRESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          edit_action: {
            loading: true,
            error: null
          }
        }
      };
    case 'EDIT_ADDRESS_SUCCESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          edit_action: {
            loading: false,
            error: null
          }
        }
      };
    case 'EDIT_ADDRESS_FAILURE':
      return {
          ...state,
          addresses: {
            ...state.addresses,
            edit_action: {
              loading: false,
              error: action.error
            }
          }
        };
    default:
      return state;
  }
};
