import Bowser from 'bowser';
import { Pushwoosh } from 'web-push-notifications';

import { fetchApi } from './api';
import { getDeviceBrand } from './utils';

let pwInstance = null;

const pwConfig = {
  logLevel: 'info',
  applicationCode: "15868-310D0",
  safariWebsitePushID: 'web.com.wall-market.lightshop-providers',
  defaultNotificationTitle: 'LightShop Providers',
  defaultNotificationImage: 'https://providers-lightshop.wall-market.com/icon.png',
  autoSubscribe: false
};

const init = () => {
  pwInstance = new Pushwoosh();

  pwInstance.push(['init', pwConfig]);
  pwInstance.push(['onSubscribe', (/* api */) => {
    registerDevice();
  }]);
}

init();

export const registerDevice = () => {

  if (!pwInstance)
    return;

  pwInstance.isSubscribed()
    .then((isSubscribed) => {

      if (isSubscribed) {
        pwInstance.getParams()
          .then((params) => {
            params = params || {};

            const browser = Bowser.getParser(window.navigator.userAgent);
            const devicePlatform = browser.getBrowserName();
            const deviceModel = params && params.tags && params.tags["Device Model"] ? params.tags["Device Model"] : null;

            if (params && params.pushToken && params.pushToken.length > 1) {
              postUserSelfDevice({
                model: deviceModel,
                pusher: "lightshop-providers",
                brand: getDeviceBrand(),
                platform: devicePlatform.toLowerCase(),
                push_token: params.pushToken
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      else {
        pwInstance.subscribe();
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export const unregisterDevice = (callBackSuccess = null, callBackError = null) => {

  if (!pwInstance) {

    if (callBackError && typeof callBackError === 'function')
      callBackError();

    return;
  }

  pwInstance.getParams()
    .then((params) => {
      params = params || {};

      if (params && params.pushToken && params.pushToken.length > 1) {
        deleteUserSelfDeviceByToken(params.pushToken, callBackSuccess, callBackError);
      }
      else {
        if (callBackError && typeof callBackError === 'function')
          callBackError();
      }
    })
    .catch(() => {
      if (callBackError && typeof callBackError === 'function')
        callBackError();
    });
}

const postUserSelfDevice = data => {

  if (!data)
    return;

  fetchApi(
    "post",
    "users/self/devices",
    null,
    data,
    null,
    null,
    null
  );
}

const deleteUserSelfDeviceByToken = (pushToken, callBackSuccess, callBackError) => {

  if (!pushToken)
    return;

  fetchApi(
    "delete",
    "users/self/devices",
    null,
    { push_token: pushToken.toString() },
    null,
    callBackSuccess,
    callBackError
  );
}
