import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import PaymentMethodContainer from "../../containers/orderManagement/PaymentMethodContainer";
import InvoiceAddressContainer from "../../containers/orderManagement/InvoiceAddressContainer";
import ProviderContainer from "../../containers/orderManagement/ProviderContainer";
import AccessDetailsContainer from "../../containers/orderManagement/AccessDetailsContainer";

const classes = {
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    marginBottom: 10,
  },
};

function OrderManagment(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <ProviderContainer />
      <PaymentMethodContainer />
      <InvoiceAddressContainer />
      <AccessDetailsContainer />
    </div>
  );
}

OrderManagment.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(classes)(OrderManagment);
