import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import MuiTextField from '../../../../../components/commons/fields/MuiTextField';
import GoogleAutocompleteField from '../../../../../components/commons/fields/GoogleAutocompleteField';

import translation from '../../../../../translation/translation';
import store from '../../../../../redux/store';
import validate from './validate';

class CreateAddressForm extends React.Component {

  constructor(props) {
    super(props);

    this.handleAddressValue = this.handleAddressValue.bind(this);
  }

  handleAddressValue(addressObject) {

    if (!addressObject) return;

    store.dispatch(change('createAddressForm', 'address', addressObject.formatted_address));
  }

  render() {

    const {
      handleSubmit,
      createAction,
      mode
    } = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete='off'>
        <div className="CreateAddressForm">
          <div className="field-wrapper">
            <Field
              type="text"
              id="adresse"
              name="address"
              label={translation().invoices.addresses.create.form.labels.address}
              className="GoogleAutocompleteField"
              required={true}
              onSelect={this.handleAddressValue}
              component={GoogleAutocompleteField}
            />
          </div>
          <div className="field-wrapper">
            <Field
              name="name"
              type="text"
              label={translation().invoices.addresses.create.form.labels.address_name}
              tabIndex="-1"
              required={true}
              component={MuiTextField}
            />
          </div>
          <div className="submit-wrapper">
            <Button
              color={mode && mode === "companies" ? "secondary" : "primary"}
              type="submit"
              style={{minWidth: 120}}
              disabled={createAction.loading}
              variant='contained'
            >
              {
                createAction.loading
                  ? <CircularProgress size={20} color='secondary' />
                  : <span>{translation().invoices.addresses.create.form.buttons.submit}</span>
              }
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

CreateAddressForm.propTypes = {
  mode: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  createAction: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
};

export default reduxForm({
  form: 'createAddressForm',
  validate
})(CreateAddressForm);
