import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  Button, 
  Chip,
  CircularProgress
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import TodayIcon from '@material-ui/icons/Today';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RoomIcon from '@material-ui/icons/Room';

import { convertMinsToHrsMins, dateInTz } from '../../helpers/functions/dates';
import { getUserTimezone } from '../../helpers/functions/user';
import { parseUrl } from '../../helpers/functions/utils';

import translation from '../../translation/translation';

class RequestDispatch extends PureComponent {

  constructor(props) {
    super(props);

    this.handleDispatch = this.handleDispatch.bind(this);
  }

  UNSAFE_componentWillMount () {
    const urlQueryString = parseUrl(window.location);
    const requestQuery = urlQueryString.query;

    const { getDispatchDetails } = this.props;

    
    if (urlQueryString) {
      if (requestQuery && requestQuery.opid && requestQuery.dispatch_token && requestQuery.start) {
        getDispatchDetails(
          requestQuery.opid,
          requestQuery.dispatch_token,
          requestQuery.start,
        );
      }
    }
  }

  componentWillUnmount() {
    const { clearDispatchReducer } = this.props;

    clearDispatchReducer();
  }

  handleDispatch () {
    const urlQueryString = parseUrl(window.location);
    const requestQuery = urlQueryString.query;

    const {
      acceptRequestDispatch,
      history,
      triggerRequestFailure
    } = this.props;

    if (urlQueryString) {
      if (requestQuery && requestQuery.opid && requestQuery.dispatch_token && requestQuery.start) {
        acceptRequestDispatch(
          requestQuery.opid,
          requestQuery.dispatch_token,
          requestQuery.start,
          () => {
            if (history) {
              history.push("/order-products");
            }
          }
        );
      }
      else {
        triggerRequestFailure(translation().requestdipatch.error || "Cannot get infos from url.");
      }
    }
    else {
      triggerRequestFailure(translation().requestdipatch.error || "Cannot get infos from url.");
    }
  }

  render() {

    const {
      requestDispatch: {
        details,
        dispatch
      }
    } = this.props;

    const timezoneCode = details.data && details.data.property && details.data.property.timezone_code ? details.data.property.timezone_code : undefined;
    
    return (
      <div className="App-view Orders Order-view RequestDispatch">
        <div className="Order open">
          { details.isLoading &&
            <div className="center-loader">
              <CircularProgress color="secondary" size={35} />
            </div>
          }
          { details.data &&
            <div className="order-wrapper-no-resize">
              <div className="container">
                <div className="order-content">
                  <h1>{translation().requestdipatch.title}</h1>
                  <div>
                    { details.data.name && <h2>{details.data.name}</h2> }
                    <div className="details">
                      { details.data.order_id &&
                        <div className="detail-row">
                          <span className="detail">
                            <span className="detail-icon"><ShoppingCartIcon fontSize="small"/></span> #{details.data.order_id}
                          </span>
                        </div>
                      }
                      { details.data.start  &&
                        <div className="detail-row">
                          <span className="detail">
                            <span className="detail-icon" title={translation().orders.date}><TodayIcon fontSize="small" /></span>
                            { dateInTz(details.data.start, "localized-date") }
                          </span>
                          <span className="detail">
                            <span className="detail-icon" title={translation().orders.time}><AccessTimeIcon fontSize="small" /></span>
                            { dateInTz(details.data.start, 'HH:mm') }
                          </span>
                        </div>
                      }
                      { getUserTimezone() !== timezoneCode &&
                        <div className="property-tz-warn">
                          <span className="icon-infos" /> {translation().commons.local_datetime} {dateInTz(details.data.start, "localized-datetime", timezoneCode)} ({timezoneCode})
                        </div>
                      } 
                      <div className="detail-row">
                        { details.data.duration &&
                          <span className="detail">
                            <span className="detail-icon" title={translation().orders.duration}><TimelapseIcon fontSize="small"/></span>
                            { convertMinsToHrsMins(details.data.duration, "h") }
                          </span>
                        }
                        { details.data &&
                          details.data.property &&
                          details.data.property.size_formatted &&
                            <span className="detail">
                              <span className="detail-icon icon-surface" title={translation().orders.size} />
                              { details.data.property.size_formatted }
                            </span>
                        }
                      </div>
                      { details.data.property && details.data.property.city &&
                        <div className="detail-row">
                          <span className="detail">
                            <span className="detail-icon" title={translation().orders.payed}>
                              <RoomIcon fontSize="small" />
                            </span>
                            <span>{details.data.property.city} {details.data.property && details.data.property.zip_code ? details.data.property.zip_code : false }</span>
                          </span>
                        </div>
                      }
                      { details.data.amount_reversion_without_trip_tax_excluded_formatted &&
                        <div className="detail-row">
                          <span className="detail">
                            <span className="detail-icon" title={translation().orders.payed}>
                              <CreditCardIcon fontSize="small" />
                            </span>
                            <span>{details.data.amount_reversion_without_trip_tax_excluded_formatted} {translation().orders.tax_excl}</span>
                          </span>
                        </div>
                      }
                      { details.data.amount_trip_compensation_tax_excluded_formatted && details.data.amount_trip_compensation_tax_excluded > 0 &&
                        <div className="detail-row">
                          <span className="detail">
                            <span className="detail-icon" title="km">
                              <DirectionsCarIcon fontSize="small" />
                            </span>
                            <span>{details.data.amount_trip_compensation_tax_excluded_formatted} {translation().orders.tax_excl}*</span>
                          </span>
                        </div>
                      }
                      { details.data.amount_trip_compensation_tax_excluded > 0 &&
                        <p className="special-details">* {translation().requestdipatch.special_details}</p>
                      }
                      { Array.isArray(details.data.options) && details.data.options && details.data.options.length > 0
                        ? (
                          <div className="options">
                            { details.data.options.map((option, i) => (
                              <Chip
                                key={i}
                                className="option"
                                label={`Option : ${option.name}`}
                              />
                            ))}
                          </div>
                        )
                        : false
                      }
                      { typeof details.data.options === "string" && details.data.options && details.data.options.length > 0
                        ? (
                          <div className="options">
                            { details.data.options.split(',').map((option, i) => (
                              <Chip
                                key={i}
                                className="option"
                                label={`Option : ${option}`}
                              />
                            ))}
                          </div>
                        )
                        : false
                      }
                    </div>
                  </div>
                </div>
                <div className="buttons-wrapper">
                  <Button
                    disabled={dispatch.isLoading}
                    variant="contained"
                    component={Link}
                    to="/"
                  >
                    {translation().requestdipatch.buttons.decline}
                  </Button>
                  <Button
                    disabled={dispatch.isLoading}
                    variant="contained"
                    color="primary"
                    style={{marginLeft: 12, minWidth: 150}}  
                    onClick={this.handleDispatch}
                  >
                    { dispatch.isLoading
                      ? <CircularProgress size={25} color="secondary" />
                      : translation().requestdipatch.buttons.accept
                    }
                  </Button>
                </div>
              </div>
            </div>
          }
          { !details.isLoading && details.error &&
            <div className="buttons-wrapper">
              <div className="alert alert-danger" role="alert">
                <ErrorIcon fontSize="small" />
                <span>{details.error}</span>
              </div>
              <Button
                style={{marginTop: 8}}
                variant="contained"
                component={Link}
                to="/"
              >
                {translation().requestdipatch.buttons.home}
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

RequestDispatch.propTypes = {
  clearDispatchReducer: PropTypes.func.isRequired,
  getDispatchDetails: PropTypes.func.isRequired,
  acceptRequestDispatch: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  triggerRequestFailure: PropTypes.func.isRequired,
  requestDispatch: PropTypes.shape({
    details: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.shape(),
      error: PropTypes.any
    }).isRequired,
    dispatch: PropTypes.shape({
      isLoading: PropTypes.bool,
      isSuccess: PropTypes.bool,
      error: PropTypes.any
    }).isRequired
  }).isRequired
}

export default RequestDispatch;
