import axios from 'axios';

import {
  getAccessToken,
  getLocale,
  removeAccessToken
} from './cookies';
import {
  isObjEmpty,
  stringifyQuery,
  generateRandom
} from './utils';

import { clearReducers } from '../../redux/actions/appActions';
import store from '../../redux/store';

import config from '../../config';

const autoLogoutUser = () => {
  removeAccessToken();
  store.dispatch(clearReducers());
}

export const fetchApi = (method, route, query, data, headers, callBackSuccess, callBackError, withAccessToken = true) => {

  if (!method)
    method = "get";

  if (['get', 'post', 'put', 'delete', 'options'].indexOf(method) < 0)
    throw new Error("Wrong method");

  if (!route)
    throw new Error("Route not found");

  if (!query)
    query = {};

  if (!data)
    data = {};

  if (!headers)
    headers = {};

  const axiosRequest = {};
  const accessToken = getAccessToken();
  const locale = getLocale();

  if (withAccessToken) {
    if (accessToken)
      query.access_token = accessToken;
    else {
      autoLogoutUser();
      query.client_id = config.api_key;
    }
  }

  if (method)
    axiosRequest.method = method;

  if (route)
    axiosRequest.url = config.api_url + route;

  if (locale)
    query.locale = locale;

  /**
   * No need for the moment
   */
   /*
    const timezone = getTimezone();
    const currency = getCurrency();

    if (currency)
      query.currency = currency;

    if (timezone)
      query.timezone = timezone;

  */

  if (!isObjEmpty(query))
    axiosRequest.params = query;

  if (!isObjEmpty(data))
    axiosRequest.data = data;

  if (!isObjEmpty(headers))
    axiosRequest.headers = headers;

  axios(axiosRequest)
    .then((response) => {
      const data = response.data.data;
      const paging = response.data.paging;

      if (callBackSuccess && typeof callBackSuccess === 'function')
        callBackSuccess(data, paging);
    })
    .catch((error) => {
      if (callBackError && typeof callBackError === 'function')
        callBackError(error);

      if (error.response && error.response.status && error.response.status === 401) {
        autoLogoutUser();
      }
    });
}

export const apiReverseGeocode = (lat, lng, callBackSuccess, callBackError) => {
  fetchApi("get", "geocoder/geocode", {lat: lat, lng: lng}, null, null, callBackSuccess, callBackError);
}

/**
 * Post files to clouding
 */
export const apiPostFile = (fileToUpload, callBackSuccess, callBackError) => {

  if (!fileToUpload) return;

  if (fileToUpload) {
    const formData = new FormData();
    formData.append("file", fileToUpload);

    axios.post(
      config.api_url + "files/temp?client_id=" + config.api_key + "&tmp=" + generateRandom(1, 99999) + new Date().getTime(),
      formData,
      { headers: { "X-Requested-With": "XMLHttpRequest" },
    }).then((response) => {
      callBackSuccess(response.data);
    }).catch((error) => {
      if (callBackError && typeof callBackError === 'function')
        callBackError(error);

      if (error.response && error.response.status && error.response.status === 401) {
        autoLogoutUser();
      }
    });
  }
}

export const getApiUrl = (route, query, encodeUrl = true) => {

  if (!route)
    throw new Error("Route not found");

  if (!query)
    query = {};

  let url = null;
  let queryString, queryJoin = "";
  const accessToken = getAccessToken();

  if (accessToken)
    query.access_token = accessToken;
  else
    query.client_id = config.api_key;

  const locale = getLocale();
  if (locale)
    query.locale = locale;

  /**
  const currency = getCurrency();
  if (currency)
    query.currency = currency;

  const timezone = getTimezone();
  if (timezone)
    query.timezone = timezone;
  **/

  if (route)
    url = config.api_url + route;

  queryString = stringifyQuery(query, encodeUrl)

  if (queryString)
    queryJoin = (url.indexOf("?") >= 0) ? "&" : "?";

  return url + queryJoin + queryString;
}

/**
 * Load in DOM the payment iframe
 */
export const getApiPaymentMethodIframe = (isCompany = false, callBackFunc) => {

  if (!callBackFunc) return;

  const accessToken = getAccessToken();
  const iframeContainer =  document.getElementById('payment-frame-container');
  const iframePaymentLoader = document.getElementById('frame-payment-loader');

  if (!iframeContainer) return;

  if (iframePaymentLoader)
    iframePaymentLoader.style.display = 'block';

  const route = isCompany ? 'companies/self/payment-methods/form' : 'users/self/payment-methods/form';
  const src = config.api_url + route + '?access_token='  + ( accessToken ? accessToken : null ) + '&way=receive';
  const paymentFrame =  '<iframe id="frame-payment" class="app-iframe" height="450" title="paymentMethod" src=' +  src + ' />';

  iframeContainer.innerHTML = '';
  iframeContainer.innerHTML = paymentFrame;

  document.getElementById('frame-payment').onload = function() {

    if (iframePaymentLoader)
      iframePaymentLoader.style.display = 'none';

    const paymentFrame = document.getElementById('frame-payment');

    try {
      const frameWindowLocation = paymentFrame.contentWindow.location.href;

      if (!frameWindowLocation)
        return;
      else if (frameWindowLocation && !paymentFrame.contentWindow.location.hash)
        return;
    }
    catch (e) {
      if (iframePaymentLoader)
        iframePaymentLoader.style.display = 'none';
      return;
    }

    /**
     * Get and split all hash
     */
    const hash = paymentFrame.contentWindow.location.hash;

    if (hash === "#success")
      callBackFunc();
  }
}
