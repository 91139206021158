const initialState = {
  data: null,
  getLoading: false,
  updateLoading: false,
  error: null,
};

const invoiceAddressManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_REDUCERS":
      return initialState;

    case "GET_ORDER_INVOICE_ADDRESSES":
      return {
        ...initialState,
        getLoading: true,
      };

    case "GET_ORDER_INVOICE_ADDRESSES_SUCCESS":
      return {
        ...state,
        data: action.data,
        getLoading: false,
        error: null,
      };

    case "GET_ORDER_INVOICE_ADDRESSES_FAILURE":
      return {
        ...state,
        getLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default invoiceAddressManagementReducer;
