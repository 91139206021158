 const initialState = {
   data: null,
   loading: false,
   error: null,
   accept_terms: {
     loading: false,
     error: null
   }
 }

const providerReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'GET_PROVIDER':
      return {
        ...state,
        loading: true,
        error: null
      }
    case 'GET_PROVIDER_SUCCESS':
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      }
    case 'GET_PROVIDER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'ACCEPT_PROVIDER_TERMS':
      return {
        ...state,
        accept_terms: {
          loading: true,
          error: null
        }
      }
    case 'ACCEPT_PROVIDER_TERMS_SUCCESS':
      return {
        ...state,
        accept_terms: {
          loading: false,
          error: null
        }
      }
    case 'ACCEPT_PROVIDER_TERMS_FAILURE':
      return {
        ...state,
        accept_terms: {
          loading: false,
          error: action.error
        }
      }
    case 'SET_NOT_PROVIDER':
      return action.data
    case 'REMOVE_PROVIDER':
      return initialState
    default:
      return state
  }
}

export default providerReducer;
