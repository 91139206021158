import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { dateInTz } from '../../../helpers/functions/dates';

import ToggleLabel from '../../../components/commons/ToggleLabel';

import translation from '../../../translation/translation';

class InvoiceQueue extends Component {

  constructor(props) {
    super(props);

    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      getInvoiceQueue,
      toggleInvoicesEmittedListMode,
      companyAdmin
    } = this.props;

    getInvoiceQueue(
      null,
      companyAdmin ? "provider-companies" : "providers",
      () => {
        if (companyAdmin)
          toggleInvoicesEmittedListMode('provider-companies');
      }
    );
  }

  handleScroll(event) {

    const {
      getInvoiceQueue,
      invoiceQueue: { paging, loading }
    } = this.props;

    if (!event || !event.target) return;

    if (!event.target.scrollTop || event.target.scrollTop === 0) return;

    if (event.target.scrollHeight - event.target.clientHeight - 20 <= event.target.scrollTop) {
      if (paging && paging.next) {
        if (!loading) {
          getInvoiceQueue(paging.next);
        }
      }
    }
  }

  handleChangeMode(e, value) {

    const {
      getInvoiceQueue,
      toggleInvoicesEmittedListMode,
      invoiceQueue: {
        loading,
        mode
      }
    } = this.props;

    if (loading) return;

    if (mode && mode === "providers") {

      if (!value)
        return;

      getInvoiceQueue(
        null,
        'provider-companies',
        () => {
          toggleInvoicesEmittedListMode('provider-companies');
        }
      );
    }
    else if (mode && mode === "provider-companies") {

      if (value)
        return;

      getInvoiceQueue(
        null,
        'providers',
        () => {
          toggleInvoicesEmittedListMode('providers');
        }
      );
    }
  }

  renderTable(data) {

    const descriptionCellStyle = {
      paddingTop: 6,
      paddingBottom: 6,
      lineHeight: 1.2
    }

    if (data && data.length > 0 ) {

      const rows = [];

      for (let i = 0; i < data.length; i++) {
        let row = {};

        if (data[i].date)
          row['date'] = dateInTz(data[i].date, "localized-datetime");

        if (data[i].description && data[i].description.length > 0) {
          row['description'] =
            data[i].description.split("\n").map((line,key) => {
              return <span key={key}>{line}<br/></span>;
            });
        }
        else
          row['description'] = "-";

        if (data[i].total_tax_excluded_formatted)
          row['total_tax_excluded_formatted'] = data[i].total_tax_excluded_formatted;

        rows.push(row);
      }

      return (
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translation().invoices.invoice_queue.table.date}</TableCell>
                <TableCell>{translation().invoices.invoice_queue.table.description}</TableCell>
                <TableCell align="right">{translation().invoices.invoice_queue.table.amount}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.map((row, k) => {
                  return (
                    <TableRow key={k}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell style={descriptionCellStyle}>{row.description}</TableCell>
                      <TableCell align="right">{row.total_tax_excluded_formatted}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {

    const {
      companyAdmin,
      invoiceQueue: {
        data,
        loading,
        mode
      }
    } = this.props;

    return (
      <div className="InvoiceQueue">
        <div className="section-controls">
          { companyAdmin &&
            <div className="toggle-mode">
              <ToggleLabel
                label={translation().invoices.invoices_list.switch.personal}
                value={false}
                onClick={this.handleChangeMode}
              />
              <Switch
                checked={mode === "providers" ? false : true}
                value={mode}
                color="default"
                disabled={loading}
                onChange={this.handleChangeMode}
              />
              <ToggleLabel
                label={translation().invoices.invoices_list.switch.pro}
                value={true}
                onClick={this.handleChangeMode}
              />
            </div>
          }
        </div>
        <div style={{width: "100%", overflow: "auto", maxHeight: 600}} onScroll={this.handleScroll}>
          {
            data && data.length > 0
              ? this.renderTable(data)
              : !loading
                ? <div className="data-empty">{translation().invoices.invoice_queue.no_queue}</div>
                : ''
          }
        </div>
        { loading &&
          <div className="center-loader">
            <CircularProgress color={'secondary'} size={35} />
          </div>
        }
      </div>
    )
  }

}

InvoiceQueue.propTypes = {
  toggleInvoicesEmittedListMode: PropTypes.func.isRequired,
  companyAdmin: PropTypes.bool.isRequired,
  getInvoiceQueue: PropTypes.func.isRequired,
  invoiceQueue: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool,
    paging: PropTypes.shape(),
    mode: PropTypes.string
  }).isRequired,
}

export default InvoiceQueue;
