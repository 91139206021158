import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";

import { pad } from "../../../../../helpers/functions/utils";

import MuiSelectField from "../../../../../components/commons/fields/MuiSelectField";

import translation from "../../../../../translation/translation";
import validate from "./validate";

function renderOptions(name, minHour = 7, maxHour = 21) {
  const options = [
    <option key={"empty"} value="">
      -
    </option>,
  ];

  for (let i = minHour; i < maxHour + 1; i++) {
    options.push(
      <option key={i + (name ? name : Math.random())} value={pad(i)}>
        {pad(i)}
      </option>
    );
  }

  return options;
}

const labelStyle = {
  marginBottom: "10px",
  display: "block",
};

function CreateAvailabilityForm(props) {
  function handleFieldBlur(e) {
    e.stopPropagation();
  }

  const {
    handleSubmit,
    maxHour,
    minHour,
    createAction: { loading },
  } = props;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="col-xs-12">
        <InputLabel style={labelStyle}>
          {translation().schedule.availability.forms.labels.start}
        </InputLabel>
      </div>
      <div className="clearfix" />
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            component={MuiSelectField}
            fullWidth
            name="hour_start"
            // eslint-disable-next-line react/jsx-no-bind
            onBlurProps={handleFieldBlur}
          >
            {renderOptions("_hour_start", minHour, maxHour)}
          </Field>
        </div>
      </div>
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            component={MuiSelectField}
            fullWidth
            name="min_start"
            // eslint-disable-next-line react/jsx-no-bind
            onBlurProps={handleFieldBlur}
          >
            <option value="">-</option>
            <option value={"00"}>00</option>
            <option value={"15"}>15</option>
            <option value={"30"}>30</option>
            <option value={"45"}>45</option>
          </Field>
        </div>
      </div>
      <div className="clearfix" />
      <div className="col-xs-12">
        <InputLabel style={labelStyle}>
          {translation().schedule.availability.forms.labels.end}
        </InputLabel>
      </div>
      <div className="clearfix" />
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            component={MuiSelectField}
            fullWidth
            name="hour_end"
            // eslint-disable-next-line react/jsx-no-bind
            onBlurProps={handleFieldBlur}
          >
            {renderOptions("_hour_end", minHour, maxHour)}
          </Field>
        </div>
      </div>
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            component={MuiSelectField}
            fullWidth
            name="min_end"
            // eslint-disable-next-line react/jsx-no-bind
            onBlurProps={handleFieldBlur}
          >
            <option value="">-</option>
            <option value={"00"}>00</option>
            <option value={"15"}>15</option>
            <option value={"30"}>30</option>
            <option value={"45"}>45</option>
          </Field>
        </div>
      </div>
      <div className="clearfix" />
      <div className="submit-wrapper">
        <Button
          color="primary"
          className="btn-availability"
          disabled={loading}
          // eslint-disable-next-line react/jsx-no-bind
          onBlur={handleFieldBlur}
          style={{ minWidth: 150 }}
          type="submit"
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <span>
              {translation().schedule.availability.create.buttons.submit}
            </span>
          )}
        </Button>
      </div>
    </form>
  );
}

CreateAvailabilityForm.propTypes = {
  createAction: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
};

export default reduxForm({
  form: "createAvailabilityForm_schedule",
  enableReinitialize: true,
  validate,
})(CreateAvailabilityForm);
