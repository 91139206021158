import { apiReverseGeocode, fetchApi } from './api';
import { getLocale, setLocale, setPhoneNumber, setContactEmail } from './cookies';
import { initDateLocale } from './dates';

import config from '../../config';
import store from '../../redux/store';

/**
 * Get provider support phone number from API 
 */
export const getApiGeoPhoneNumber = (code, onSuccess, onError) => {
  if (!code) return;

  fetchApi(
    'get',
    `countries/${code.toLowerCase()}/support`,
    null,
    null,
    null,
    onSuccess,
    onError
  );
}

/**
 * Init app with geo settings
 */
export const initializeGeoSettings = (user = null, callBackFunc) => {

  if (user) {
    if (user.language && user.language.locale) {
      setLocale(user.language.locale);
      initDateLocale(user.language.locale);
    }

    /**
     * No need for now
     */
    /**
      if (user.currency && user.currency.isocode)
        setCurrency(user.currency.isocode);

      if (user.timezone_code)
        setTimezone(user.timezone_code);
    */
      
    if (user.residence_country && user.residence_country.isocode) {
      // setResidenceCountry(user.residence_country.isocode);
      getApiGeoPhoneNumber(
        user.residence_country.isocode,
        success => {
          setPhoneNumber(success.provider_support_phone_formatted ? success.provider_support_phone_formatted : success.provider_support_phone ? success.provider_support_phone : config.phone_number);
          setContactEmail(success.provider_support_email ? success.provider_support_email : config.contact_email);
        },
        () => {
          setPhoneNumber(config.phone_number);
          setContactEmail(config.contact_email);
        }
      );
    }
    else {
      setPhoneNumber(config.phone_number);
      setContactEmail(config.contact_email);
    }

    if (callBackFunc && typeof callBackFunc === 'function') {
      callBackFunc();
    }
  }
  else {
    // if (!getLocale() || !getCurrency() || !getTimezone() || !getResidenceCountry())
    if (!getLocale()) {
      apiReverseGeocode(
        { details: true },
        data => {

          if (data.locale) {
            initDateLocale(data.locale);
            setLocale(data.locale);
          }
          else {
            initDateLocale(config.default_locale);
            setLocale(config.default_locale);
          }
            
          /**
            if (data.currency)
              setCurrency(data.currency_isocode);
            else
              setCurrency(config.default_currency);

            if (data.timezone_code)
              setTimezone(data.timezone_code);
            else
              setTimezone(config.default_timezone);

            if (data.residence_country && data.residence_country.isocode)
              setResidenceCountry(data.residence_country.isocode);
            else
              setResidenceCountry(config.default_country);
          */

          if (data.residence_country && data.residence_country.isocode) {
            getApiGeoPhoneNumber(
              data.residence_country.isocode,
              success => {
                setPhoneNumber(success.provider_support_phone_formatted ? success.provider_support_phone_formatted : success.provider_support_phone ? success.provider_support_phone : config.phone_number);
                setContactEmail(success.provider_support_email ? success.provider_support_email : config.contact_email);
              },
              () => {
                setPhoneNumber(config.phone_number);
                setContactEmail(config.contact_email);
              }
            );
          }
          else {
            setContactEmail(config.contact_email);
            setPhoneNumber(config.phone_number);
          }

          if (callBackFunc && typeof callBackFunc === 'function') {
            callBackFunc();
          }
        },
        () => {
          initDateLocale(config.default_locale);
          setLocale(config.default_locale);

          /**
            setTimezone(config.default_timezone);
            setCurrency(config.default_currency);
            setResidenceCountry(config.default_country);
          */

          if (callBackFunc && typeof callBackFunc === 'function')
            callBackFunc();
        }
      );
    }
    else {
      if (callBackFunc && typeof callBackFunc === 'function')
        callBackFunc();
    }
  }
}

/**
 * Get geo location from the browser
 */
export const getBrowserGeolocation = (callBackSuccess, callBackFailure) => {

  if (!navigator.geolocation)
    return;

  navigator.geolocation.getCurrentPosition(position => {
    apiReverseGeocode(
      position.coords.latitude,
      position.coords.longitude,
      response => {
        if (callBackSuccess) {
          callBackSuccess(response);
        }
      },
      error => {
        if (callBackFailure && typeof callBackFailure === 'function')
          callBackFailure(error);
      }
    );
  });
}

/**
 * Handle users and companies addresses
 */
export const apiPostUserAddress = (isCompany = false, address, name, callBackSuccess, callBackFailure) => {

  if (!address || !name || !callBackSuccess || !callBackFailure) return;

  fetchApi(
    "get",
    "geocoder/geocode",
    { address },
    null,
    null,
    geoData => {
     /**
      * Construct new address with the address geocoded
      */
      const newAddress = {
        name,
        latitude: geoData.latitude,
        longitude: geoData.longitude,
        street: geoData.street,
        street_number: geoData.street_number,
        zip_code: geoData.zip_code,
        city: geoData.city,
        country_isocode: geoData.country_isocode,
        state_isocode: geoData.state_isocode,
      }

      fetchApi(
        'post',
        (isCompany ? 'companies' : 'users') + '/self/addresses',
        null,
        newAddress,
        null,
        callBackSuccess,
        callBackFailure
      );
    },
    errorGeo => {
      if (callBackFailure && typeof callBackFailure === 'function')
        callBackFailure(errorGeo);
    },
  );
}

export const apiPutUserAddress = (isCompany = false, id, address, name, callBackSuccess, callBackFailure) => {

  if (!address || !id || !name || !callBackSuccess || !callBackFailure) return;

  fetchApi(
    "get",
    "geocoder/geocode",
    { address },
    null,
    null,
    geoData => {
      const newAddress = {
        name,
        latitude: geoData.latitude,
        longitude: geoData.longitude,
        street: geoData.street,
        street_number: geoData.street_number,
        zip_code: geoData.zip_code,
        city: geoData.city,
        country_isocode: geoData.country_isocode,
        state_isocode: geoData.state_isocode,
      }

      fetchApi(
        'put',
        (isCompany ? 'companies' : 'users') + '/self/addresses/' + id,
        null,
        newAddress,
        null,
        callBackSuccess,
        callBackFailure
      );
    },
    errorGeo => {
      if (callBackFailure && typeof callBackFailure === 'function')
        callBackFailure(errorGeo);
    },
  );
}

/**
 * Get the user data from redux
 */
export const getUserFromStore = () => {
  const state = store.getState();

  if (state && state.user && state.user.data)
    return state.user.data;
  else
    return null;
}

export function getUserTimezone() {
  const user = getUserFromStore();

  if (user && user.timezone_code)
    return user.timezone_code;
  else
    return config.default_timezone;
}
