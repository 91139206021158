import { createMuiTheme } from '@material-ui/core/styles';

import config from '../../config';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: config.primary_color || "#FFD700",
      dark: "#fcbf13",
    },
    secondary: {
      main: config.secondary_color || "#F29D06"
    },
  },
  overrides: {
    ListItemText: {
      primary: {
        fontWeight: 500
      }
    },
    MuiTableCell: {
      root: {
        padding: "4px 12px"
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "#FFFFFF"
      },
      colorDefault: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "#FFFFFF"
      }
    },
    MuiFab: {
      secondary: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "white",
        '&:hover': {
          backgroundColor: "black"
        }
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: config.primary_color || "#FFD700",
      },
    },
    MuiSwitch: {
      switchBase: {
        color: config.primary_color || "#FFD700",
        '&$checked': {
          color: config.dark_color || "#1A2123",
        },
      }
    },
    MuiSnackbar: {
      root: {
        zIndex: 2100
      }
    },
    MuiSnackbarContent: {
      message: {
        flex: 1
      }
    },
    MuiButton: {
      root: {
        borderRadius: 50,
        padding: '11px 18px',
        fontFamily: "Montserrat-Bold",
        fontWeight: "normal",
        textTransform: "none"
      },
      outlinedPrimary: {
        color: config.primary_color || 
        "#FFD700",
        '&:hover': {
          color: config.primary_color || "#FFD700",
        },
      },
      containedPrimary: {
        '&$disabled': {
          backgroundColor: '#E0E0E0'
        },
      },
      containedSecondary: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "#FFFFFF",
        '&:hover': {
          backgroundColor: "#000000"
        },
        '&$disabled': {
          backgroundColor: '#E0E0E0'
        },
      },
      outlined: {
        padding: '11px 18px',
      },
      contained: {
        boxShadow: 'none',
      }
    },
    MuiIconButton: {
      colorInherit: {
        color: config.dark_color || "#1A2123",
      }
    },
    MuiDialog: {
      root: {
        zIndex: 2000
      },
      paper: {
        margin: 15
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: config.dark_color || "#1A2123",
        backgroundColor: "transparent",
        '&:hover': {
          backgroundColor: "#f1f1f1",
        },
      },
    },
  },
  error: {
    dark: "#f44336"
  }
});

export default theme;
