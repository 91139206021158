import React from 'react';
import { Link } from "react-router-dom";

import Button from '@material-ui/core/Button';

import translation from '../../../translation/translation';

const HomeLink = props =>
  <Link to={"/"} {...props} />

const RestrictedArea = () => {

  return (
    <div className="Restricted-area company-area">
      <div className="container">
        <h1>{translation().pages.restricted_area.title}</h1>
        <p>{translation().pages.restricted_area.text}</p>
        <Button
          variant="contained"
          style={{marginLeft: 10}}
          component={HomeLink}
        >
          {translation().pages.restricted_area.back}
        </Button>
      </div>
    </div>
  );
}

export default RestrictedArea;
