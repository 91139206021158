/**
 * Order products request dispatch actions
 */
export const acceptRequestDispatch = () => ({
  type: "ACCEPT_REQUEST_DISPATCH"
});

export const acceptRequestDispatchSuccess = () => ({
  type: "ACCEPT_REQUEST_DISPATCH_SUCCESS"
});

export const acceptRequestDispatchFailure = error => ({
  type: "ACCEPT_REQUEST_DISPATCH_FAILURE",
  error
});

export const getDispatchDetails = () => ({
  type: "GET_DISPATCH_DETAILS"
});

export const getDispatchDetailsSuccess = data => ({
  type: "GET_DISPATCH_DETAILS_SUCCESS",
  data
});

export const getDispatchDetailsFailure = error => ({
  type: "GET_DISPATCH_DETAILS_FAILURE",
  error
});

export const clearDispatchReducer = () => ({
  type: "CLEAR_DISPATCH_REDUCER"
});
