import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CompanyForm from './forms/infos/CompanyForm';
import RestrictedArea from './restrictedArea/RestrictedArea';
import Users from './users/Users';

import { autoScrollTop } from '../../helpers/functions/jquery';

import translation from '../../translation/translation';

class Company extends Component {

  constructor(props) {
    super(props);

    this.state = { tabOpen: 0 };

    this.tabArray = [
      'infos',
      "members"
    ];

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount () {

    autoScrollTop();

    const {
      history
    } = this.props;

    /**
     * Tabs micro routing
     */
    if (history && history.location && history.location.hash) {
      if (history.location.hash === "#infos")
        this.setState({tabOpen: 0});
      else if (history.location.hash === "#members")
        this.setState({tabOpen: 1});
    }
  }

  handleChange(event, tabOpen) {
    this.setState({ tabOpen });
    window.location.hash = this.tabArray[tabOpen];
  }

  render() {

    const { tabOpen } = this.state;

    const {
      adminizeCompanyUser,
      company: {
        informations,
        users,
      },
      getCompanyUsers,
      // linkCompanyUsers,
      updateCompany,
      unlinkCompanyUser,
      user: { data }
    } = this.props;

    return (
      <div className="App-view">
        { data.company && data.company_admin
          ? (
            <div>
              <div className="page-title">
                <h3>{translation().company.page_title}</h3>
              </div>
              <div className="container container-mobile">
                <AppBar
                  position="relative"
                  color="default"
                  elevation={0}
                >
                  <Tabs
                    centered={true}
                    value={tabOpen}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    scrollButtons="on"
                  >
                    <Tab label={<span className="tab-label">{translation().company.tab_titles.infos}</span>} icon={<div className="tab-icon icon-pro" />} />
                    <Tab label={<span className="tab-label">{translation().company.tab_titles.members}</span>} icon={<div className="tab-icon icon-connecte" />} />
                  </Tabs>
                </AppBar>
                <div className="App-tab-container Company">
                  { tabOpen === 0 &&
                    <CompanyForm
                      onSubmit={updateCompany}
                      informations={informations}
                      labels={translation().company.form.labels}
                      buttonLabel={translation().company.form.buttons.submit}
                      initialValues={{
                        email: data.company.email ? data.company.email: "",
                        commercial_name: data.company.commercial_name ? data.company.commercial_name: "",
                        fax: data.company.fax ? data.company.fax: "",
                        legal_name: data.company.legal_name ? data.company.legal_name: "",
                        logo_url: data.company.logo_url ? data.company.logo_url: "",
                        phone: data.company.phone ? data.company.phone: "",
                        website_url: data.company.website_url ? data.company.website_url: "",
                        watermark_url: data.company.watermark_url ? data.company.watermark_url: "",
                      }}
                    />
                  }
                  { tabOpen === 1 &&
                    <Users
                      adminizeCompanyUser={adminizeCompanyUser}
                      getCompanyUsers={getCompanyUsers}
                      // linkCompanyUsers={linkCompanyUsers}
                      unlinkCompanyUser={unlinkCompanyUser}
                      userLogged={data}
                      users={users}
                    />
                  }
                </div>
              </div>
            </div>
          )
          : <RestrictedArea />
        }
      </div>
    );
  }
}

Company.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape(),
  }).isRequired,
  adminizeCompanyUser: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  // linkCompanyUsers: PropTypes.func.isRequired,
  unlinkCompanyUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape().isRequired,
  }).isRequired,
  company: PropTypes.shape({
    informations: PropTypes.shape().isRequired,
    users: PropTypes.shape(),
  }).isRequired,
}

export default Company;
