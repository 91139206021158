const initialState = {
  mode: "providers",
  loading: false,
  error: null
}

const metricsReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'TOGGLE_METRICS_MODE':
      return {
        ...state,
        mode: action.mode
      }
    default:
      return state
  }
}

export default metricsReducer;
