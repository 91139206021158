import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { generateHash } from '../../../../helpers/functions/utils';
import config from '../../../../config';
import translation from '../../../../translation/translation';

import MediaGroup from './MediaGroup';
import MediaGroupForm from './MediaGroupForm';

function Media({media}) {

  function handleDownload() {
    const url = media.url + '/download?client_id=' + config.api_key;

    const downloadIframe = document.getElementById('frame-download');
    const iframeContainer =  document.getElementById('iframes-container');

    if (downloadIframe)
      downloadIframe.setAttribute('src', url);
    else {
      const downloadIframe =  '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +  url + ' />';
      iframeContainer.innerHTML = downloadIframe;
    }
  }

  let imgPreviewStyle = {};

  if (media.thumbnail_url)
    imgPreviewStyle = { backgroundImage: 'url("' + ( (media.thumbnail_url.indexOf('?client_id=' + config.api_key)) !== -1 ? (media.thumbnail_url + '?client_id=' + config.api_key) : media.thumbnail_url) + '")' };
  else if (media.extension)
    imgPreviewStyle = { backgroundImage: 'url("/assets/images/medias/file-extension-' + media.extension + '.png")' }
  else
    return false;

  return (
    <div className="prod-media">
      <div className="img-preview" style={imgPreviewStyle}>
        <div className="media-actions">
          <div className="icon-download action-icon" onClick={handleDownload} />
        </div>
      </div>
    </div>
  );
}

Media.propTypes = {
  media: PropTypes.shape().isRequired,
}

function renderMediasTypesUploaded(data) {

  if (!data) return;

  let mediaTypes = [];

  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].type && data[i].type === "iframe") {
        mediaTypes.push(
          <div className="media-input-wrapper" key={i}>
            {
              data[i] && data[i].medias && data[i].medias.length > 0
                ? (
                    <div className="product-medias">
                      {
                        data[i].medias.map((media, index) => {
                          return <Media key={index} media={media} />
                        })
                      }
                    </div>
                  )
                : ''
            }
            <iframe
              src={data[i].medias && data[i].medias.length > 0 && data[i].medias[0].url ? data[i].medias[0].url : ''}
              width="100%"
              height="500"
              frameBorder="0"
              allowFullScreen
              title={"iframe-" + (data[i].medias && data[i].medias.length && data[i].medias[0].url ? data[i].medias[0].url :'')}
            />
            <TextField
              value={data[i].medias && data[i].medias.length && data[i].medias[0].url ? data[i].medias[0].url : ''}
              label={translation().orders.media_groups.media_link}
              name={"input-iframe-" + (data[i].shortcode ? data[i].shortcode : "")}
              readOnly={true}
              InputLabelProps={{
                style: { color: '#FFFFFF'},
              }}
              inputProps={{
                className: "copy-clipb-" + (data[i].medias && data[i].medias.length && data[i].medias[0].url ? generateHash(data[i].medias[0].url) : 'undefined'),
                style: { color: '#FFFFFF'}
              }}
              margin="normal"
              variant="outlined"
              fullWidth={true}
              disabled={true}
            />
          </div>
        )
      }
      else if (data[i].type && data[i].type !== "form") {
        mediaTypes.push(
          <div className="product-medias" key={i}>
            {
              data[i] && data[i].medias && data[i].medias.length > 0
                ? (
                    <div className="row product-medias">
                      {
                        data[i].medias.map((media, index) => {
                          return <Media key={index} media={media} />
                        })
                      }
                    </div>
                  )
                : <p className="data-empty">{data[i].name}: no file found.</p>
            }
          </div>
        )
      }
    }
  }

  return mediaTypes;
}

class MediaGroupUploaded extends PureComponent {

  checkIfMediaGroupHasForm(mediaGroup) {
    if (!mediaGroup) return false;

    let hasForm = false;

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {

      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        const mediaType = mediaGroup.media_types[i];

        if (mediaType.type && mediaType.type === "form") {
          hasForm = true;
          break;
        }
      }
    }

    return hasForm;
  }

  render() {

    const { mediaGroup } = this.props;

    if (!mediaGroup) return;

    if (this.checkIfMediaGroupHasForm(mediaGroup)) {
      return false;
    }
    else {
      return (
        <div className="media-group" style={{paddingRight: 15, paddingLeft: 15}}>
          { mediaGroup.name &&
            <p className="name">{mediaGroup.name}</p>
          }
          { mediaGroup.media_types && renderMediasTypesUploaded(mediaGroup.media_types) }
          <div className="clearfix"/>
          <div className="product-separator">
            <div className="first-sep" />
            <div className="second-sep" />
          </div>
        </div>
      );
    }
  }
}

MediaGroupUploaded.propTypes = {
  mediaGroup: PropTypes.shape().isRequired
}

class MediaGroups extends Component {

  UNSAFE_componentWillMount () {

    const {
      getProductMediaGroups,
      product: { id },
      mode
    } = this.props;

    getProductMediaGroups(id, mode);
  }

  componentWillUnmount() {

    const {
      clearMediaGroups,
    } = this.props;

    clearMediaGroups();
  }

  renderMediaGroups({isLoading, list}, productId) {

    if (!list || !productId) return;

    let mediaGroups = [];

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        mediaGroups.push(
          <MediaGroup
            key={i}
            mediaGroup={list[i]}
            productId={productId}
          />
        );
      }
    }
    else if (isLoading) {
      mediaGroups.push(
        <div key={0} className="center-loader">
          <CircularProgress color="secondary" size={35} />
        </div>
      );
    }

    return mediaGroups;
  }

  renderMediaGroupsUploaded({isLoading, list}) {

    if (!list) return;

    let mediaGroups = [];

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        mediaGroups.push(
          <MediaGroupUploaded key={i} mediaGroup={list[i]} />
        );
      }
    }
    else if (isLoading) {
      mediaGroups.push(
        <div key={0} className="center-loader">
          <CircularProgress color="secondary" size={35} />
        </div>
      );
    }

    return mediaGroups;
  }

  renderFormMediaGroup({isLoading, list}, productId) {
    if (!list || !productId) return;

    let mediaGroups = [];

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        mediaGroups.push(
          <MediaGroupForm
            key={i}
            mediaGroup={list[i]}
            productId={productId}
          />
        );
      }
    }
    else if (isLoading) {
      mediaGroups.push(
        <div key={0} className="center-loader">
          <CircularProgress color="secondary" size={35} />
        </div>
      );
    }

    return mediaGroups;
  }

  render() {

    const {
      product,
      mediaGroups: {
        isLoading,
        list,
      },
      mode,
      providerId
    } = this.props;

    return (
      <div className="Media-Groups">
        { this.renderFormMediaGroup({isLoading, list}, product.id) }
        { product && product.uploadable && (parseInt(providerId, 10) === parseInt(product.provider_id, 10))
          ? this.renderMediaGroups({isLoading, list}, product.id)
          : product && product.delivery_status && product.delivery_status.is_received
            ? this.renderMediaGroupsUploaded({isLoading, list})
            : mode === "providers"
              ? (
                <div className="no-uploadable">
                  <p><span className="icon-infos" /> {translation().orders.content_not_done}</p>
                </div>
              )
              : false
        }
      </div>
    );
  }
}

MediaGroups.defautProps = {
  mode: "providers"
};

MediaGroups.propTypes = {
  getProductMediaGroups: PropTypes.func.isRequired,
  clearMediaGroups: PropTypes.func.isRequired,
  mediaGroups: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool,
  }),
  product: PropTypes.shape(),
  mode: PropTypes.string,
  providerId: PropTypes.any,
};

export default MediaGroups;
