import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { stopSubmit } from 'redux-form';

import { fetchApi } from '../../helpers/functions/api';
import { scrollToAnchor } from '../../helpers/functions/jquery';

import {
  // linkCompanyUsers, linkCompanyUsersSuccess, linkCompanyUsersFailure,
  getCompanyUsers, updateCompanyUsers, getCompanyUsersSuccess, getCompanyUsersFailure,
  updateCompanyInformations, updateCompanyInformationsSuccess, updateCompanyInformationsFailure
} from '../../redux/actions/companyActions';
import { storeUser } from '../../redux/actions/userActions';

import { enqueueSnackbar } from '../../redux/actions/notificationsActions';

import translation from '../../translation/translation';
import config from '../../config';

import Company from '../../views/company/Company';

const mapStateToProps = state => ({
  company: state.company,
  user: state.user
})

const mapDispatchToProps = dispatch => {

  const getUser = (callBackSuccess, callBackFailure) => {

    fetchApi(
      "get",
      "users/self",
      null,
      null,
      null,
      user => {
        dispatch(storeUser(user));

        if (callBackSuccess && typeof callBackSuccess === 'function')
          callBackSuccess();
      },
      () => {
        if (callBackFailure && typeof callBackFailure === 'function')
          callBackFailure();
      }
    );
  }

  return {
    updateCompany(values) {

      if (!values) return;

      dispatch(updateCompanyInformations());

      const successMessage = translation().company.form.callbacks.infos.success;
      const errorMessage = translation().company.form.callbacks.infos.error;

      fetchApi(
        "put",
        "companies/self",
        null,
        values,
        null,
        () => {
          getUser(
            () => {
              dispatch(updateCompanyInformationsSuccess());
              dispatch(enqueueSnackbar({
                message: successMessage,
                options: {
                  variant: 'success',
                },
              }));
            },
            () => {
              dispatch(updateCompanyInformationsSuccess());
              dispatch(enqueueSnackbar({
                message: successMessage,
                options: {
                  variant: 'success',
                },
              }));
            }
          );
        },
        error => {
          if (error && error.response) {
            if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
              const validationErrors = {};
              const errorsArray = error.response.data.errors;
              const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

              if (scrollAnchor[0])
                scrollToAnchor(scrollAnchor[0])

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath)
                  validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
              }

              dispatch(updateCompanyInformationsFailure(errorsArray));
              dispatch(stopSubmit(
                'updateCompanyForm',
                validationErrors
              ));
            }
            else {
              dispatch(updateCompanyInformationsFailure(error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage));
            }

            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(updateCompanyInformationsFailure(errorMessage));
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    getCompanyUsers(next = null) {

      dispatch(getCompanyUsers(next));

      fetchApi(
        "get",
        next ? next.replace(config.api_url, '') : "provider-companies/self/providers",
        null,
        null,
        null,
        (data, paging) => {
          next
            ? dispatch(updateCompanyUsers(data, paging))
            : dispatch(getCompanyUsersSuccess(data, paging));
        },
        error => {
          dispatch(getCompanyUsersFailure(error));
        }
      )
    },
    adminizeCompanyUser(id, checked, callBackSuccess, callBackFailure) {

      if (!id)
        return;

      fetchApi(
        "post",
        "companies/self/users/" + id + ( checked ? "/adminize" : "/unadminize" ),
        null,
        null,
        null,
        () => {
          if (callBackSuccess && typeof callBackSuccess === "function")
            callBackSuccess();
        },
        error => {
          const errorMessage = "Failed to adminize user.";

          if (callBackFailure && typeof callBackFailure === "function")
            callBackFailure();

          if (error && error.response) {
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    unlinkCompanyUser(id, callBackSuccess, callBackFailure) {

      if (!id)
        return;

      fetchApi(
        'post',
        'companies/self/users/' + id + '/unlink',
        null,
        null,
        null,
        () => {

          dispatch(enqueueSnackbar({
            message: translation().company.members.callbacks.unlink.success,
            options: {
              variant: 'success',
            },
          }));

          if (callBackSuccess && typeof callBackSuccess === "function")
            callBackSuccess();
        },
        error => {
          const errorMessage = translation().company.members.callbacks.unlink.error;

          if (callBackFailure && typeof callBackFailure === "function")
            callBackFailure();

          if (error && error.response) {
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    // linkCompanyUsers(values, callBackSuccess) {
    //
    //   if (!values)
    //     return;
    //
    //   let emailsArray = [];
    //
    //   for (let value in values) {
    //     if (values[value])
    //       emailsArray.push(values[value]);
    //   }
    //
    //   if (emailsArray && emailsArray.length > 0) {
    //
    //     dispatch(linkCompanyUsers());
    //
    //     fetchApi(
    //       'post',
    //       'companies/self/users/invite',
    //       null,
    //       { emails: emailsArray },
    //       null,
    //       () => {
    //         dispatch(linkCompanyUsersSuccess());
    //
    //         dispatch(enqueueSnackbar({
    //           message: translation().company.members.callbacks.link.success,
    //           options: {
    //             variant: 'success',
    //           },
    //         }));
    //
    //         if (callBackSuccess && typeof callBackSuccess === "function")
    //           callBackSuccess();
    //       },
    //       error => {
    //         const errorMessage = translation().company.members.callbacks.link.error;
    //
    //         dispatch(linkCompanyUsersFailure(errorMessage));
    //
    //         if (error && error.response) {
    //           dispatch(enqueueSnackbar({
    //             message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
    //             options: {
    //               variant: 'error',
    //             },
    //           }));
    //         }
    //         else {
    //           dispatch(enqueueSnackbar({
    //             message: errorMessage,
    //             options: {
    //               variant: 'error',
    //             },
    //           }));
    //         }
    //       }
    //     );
    //   }
    // },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Company));
