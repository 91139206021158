import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import BirthdateField from '../../../components/commons/fields/BirthdateField';
import MuiRadioGroupField from '../../../components/commons/fields/MuiRadioGroupField';
import MuiTextField from '../../../components/commons/fields/MuiTextField';

import translation from '../../../translation/translation';

import validate from './validate';

class RegisterForm extends Component {

  render() {

    const {
      handleSubmit,
      buttonLabel,
      reduxProps,
      labels
    } = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Field
          name="language_locale"
          readOnly={true}
          type="hidden"
          component="input"
          className="app-hidden-input"
        />
        <Field
          name="timezone_code"
          readOnly={true}
          type="hidden"
          component="input"
          className="app-hidden-input"
        />
        <Field
          name="unit_system"
          readOnly={true}
          type="hidden"
          component="input"
          className="app-hidden-input"
        />
        <Field
          name="currency_isocode"
          readOnly={true}
          type="hidden"
          component="input"
          className="app-hidden-input"
        />
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="field-wrapper" style={{marginBottom: 12}}>
              <Field
                name="gender"
                label={labels.civility}
                component={MuiRadioGroupField}
                options={[
                  { value: "F", label: labels.female },
                  { value: "M", label: labels.male }
                ]}
              />
            </div>
          </div>
          <div className="clearfix"/>
          <div className="col-sm-12 col-md-6">
            <div className="field-wrapper">
              <Field
                name="first_name"
                id="firstName"
                type="text"
                required={true}
                label={labels.first_name + "*"}
                component={MuiTextField}
                autoComplete="off"
              />
            </div>
            <div className="field-wrapper">
              <Field
                name="last_name"
                id="lastName"
                type="text"
                required={true}
                label={labels.last_name + "*"}
                component={MuiTextField}
                autoComplete="off"
              />
            </div>
            <div className="field-wrapper" style={{marginBottom: "44px"}}>
              <BirthdateField
                formName="registerForm"
                label={labels.birth_of_date + "*"}
              />
            </div>
            <div className="field-wrapper">
              <Field
                name="mobile"
                id="mobile"
                type="text"
                required={true}
                label={labels.mobile + "*"}
                component={MuiTextField}
              />
            </div>
            <div className="field-wrapper">
              <Field
                name="phone"
                id="phone"
                type="text"
                label={labels.phone}
                component={MuiTextField}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="field-wrapper">
              <Field
                name="email"
                type="mail"
                id="email"
                required={true}
                label={labels.email + "*"}
                component={MuiTextField}
                autoComplete="nope"
              />
            </div>
            <div className="field-wrapper">
              <Field
                name="password"
                type="password"
                id="password"
                required={true}
                label={labels.password + "*"}
                component={MuiTextField}
                autoComplete="nope"
              />
            </div>
            <div className="field-wrapper">
              <Field
                name="confirm_password"
                type="password"
                id="confirm_password"
                required={true}
                label={labels.confirm_password + "*"}
                component={MuiTextField}
                autoComplete="nope"
              />
            </div>
            <p className="is-pro">{translation().register.is_company}</p>
            <div className="field-wrapper">
              <Field
                name="legal_id"
                type="text"
                component={MuiTextField}
                label={labels.legal_id}
              />
            </div>
            <div className="field-wrapper">
              <Field
                name="legal_name"
                type="text"
                component={MuiTextField}
                label={labels.legal_name}
              />
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <a
          rel="noopener noreferrer"
          href="https://lightshop.wall-market.com/termsOfUse"
          target="_blank"
          className="terms-of-use"
        >
          {translation().register.terms_of_use}
        </a>
        <div className="submit-wrapper">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={reduxProps.loading}
            style={{minWidth: 150}}
          >
            {
              reduxProps.loading
                ? <CircularProgress size={20} color='secondary' />
                : <span>{buttonLabel}</span>
            }
          </Button>
        </div>
      </form>
    );
  }
}

RegisterForm.propTypes = {
  reduxProps: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  labels: PropTypes.shape({
    civility: PropTypes.string.isRequired,
    birth_of_date: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    female: PropTypes.string.isRequired,
    male: PropTypes.string.isRequired,
    phone: PropTypes.string,
    password: PropTypes.string,
    confirm_password: PropTypes.string,
    legal_id: PropTypes.string,
    legal_name: PropTypes.string,
  }).isRequired,
  buttonLabel: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'registerForm',
  enableReinitialize: true,
  validate
})(RegisterForm);
