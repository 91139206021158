import React from "react";
import PropTypes from "prop-types";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

function MuiSelectWithLabelField(props) {
  function handleChange(event, index, value) {
    const { input, onChange } = props;

    input.onChange(value);
    onChange && onChange();
  }

  const {
    id,
    input,
    label,
    classes,
    disabled,
    validate,
    placeHolder,
    fullWidth,
    displayEmpty,
    style,
    onBlurProps,
    native,
    meta: { touched, error },
    children,
  } = props;

  return (
    <div>
      <FormControl
        className={classes.formControl}
        style={fullWidth ? { width: "100%" } : {}}
      >
        {label && (
          <InputLabel error={touched && error ? true : false} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        <Select
          disabled={disabled}
          displayEmpty={displayEmpty}
          validate={validate}
          label={placeHolder}
          native={native}
          value={input.value}
          style={fullWidth ? { ...style, width: "100%" } : { style }}
          error={touched && error ? true : false}
          inputProps={{
            ...input,
            id: id,
            onBlur: (e) => {
              input.onBlur(e);
              if (e && onBlurProps) onBlurProps(e);
            },
          }}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleChange}
          children={children}
        />
        {touched && error && (
          <FormHelperText error={true}>{error}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

MuiSelectWithLabelField.propTypes = {
  id: PropTypes.any,
  input: PropTypes.any,
  placeHolder: PropTypes.string,
  classes: PropTypes.any,
  label: PropTypes.any,
  validate: PropTypes.any,
  disabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  native: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
  onBlurProps: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.shape(),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
  }),
};

MuiSelectWithLabelField.defaultProps = {
  native: true,
  displayEmpty: false,
};

export default withStyles(styles)(MuiSelectWithLabelField);
