/* eslint-disable */

import React from 'react';

function UsingAccount() {

  return (
    <div className="cgs-using-account">
      <p className="p2">La présente convention d’utilisation d’un compte (ci-après, la « <b>Convention d’utilisation</b> ») a pour objet d’encadrer les règles d’usage et de fonctionnement de l’espace personnel (ci-après, le « <b>Compte</b> ») mis à disposition de chaque professionnel indépendant (l’« <b>Entreprise </b>») ayant manifesté son souhait de collaborer, ponctuellement ou de manière régulière, avec la société de droit français SAS Wall-Market (« <b>Wall-Market</b> ») et sélectionné par elle, pour l’exécution de certaines prestations liées aux services vendus par Wall-Market sur son site et son application marchands LightShop.</p>
      <p className="p2">La Convention d’utilisation n’a pas vocation à régir les conditions d’exécution d’une prestation : celles-ci sont stipulées au « Contrat de prestation de services – Conditions générales » de Wall-Market (ci-après le « <b>Conditions Générales du Contrat de Prestation</b> »), disponibles sur le Compte de chaque Entreprise.</p>
      <p className="p2">La Convention d’utilisation doit être lue attentivement car elle engage l’Entreprise. En outre, l’utilisation du Compte peut également engager l’Entreprise auprès de Wall-Market en cas de contrat de prestation de services, conformément aux termes des Conditions Générales du Contrat de Prestation et ceux du Mandat de facturation.</p>
      <p className="p3">L’acceptation de la Convention d’utilisation est matérialisée par une case à cocher par l’Entreprise. Cette acceptation ne peut être que pleine et entière. Toute adhésion sous réserve est considérée comme nulle et non avenue. L’Entreprise qui n’accepte pas d’être lié par la Convention d’utilisation ne doit pas poursuivre sa navigation et le Compte est réputé n’avoir jamais existé – et le cas échéant, emporte sa clôture immédiate.<span className="Apple-converted-space"> </span></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>I.<span className="Apple-tab-span">	</span>PREAMBULE</b></p>
      <p className="p2">L’ouverture d’un Compte permet à une Entreprise :</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>d’accéder à toutes les informations disponibles sur son Compte (profil et informations relatives à l’Entreprise, Conditions Générales du Contrat de Prestation, Mandat de facturation) ;<span className="Apple-converted-space"> </span></p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>de renseigner et changer les informations la concernant (ex : profil et informations de l’entreprise ; photo de profil) ;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>de manifester sa volonté de contracter avec Wall-Market ;<span className="Apple-converted-space"> </span></p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>de gérer la livraison de tout fichier ou lien URL résultant d’une prestation ; et</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>de gérer la facturation.</p>
      <p className="p2"><b>L’ouverture d’un Compte ne garantit nullement à l’Entreprise la conclusion d’un contrat de prestation de services avec Wall-Market ou un engagement de volume de la part de Wall-Market.<span className="Apple-converted-space"> </span></b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>II.<span className="Apple-tab-span">	</span>ACCES AU COMPTE ET USAGE<span className="Apple-converted-space"> </span></b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>S’agissant de l’accès et de l’usage du compte</b></p>
      <p className="p2">Chaque Entreprise dispose d’un Compte, strictement personnel, pour lequel elle bénéficie d’un accès exclusif.</p>
      <p className="p2">L’Entreprise est seule autorisée à utiliser son Compte. Elle s’engage à l’utiliser de bonne foi.</p>
      <p className="p2">L’Entreprise qui sous-traite, avec l’accord express et préalable de Wall-Market peut disposer, si elle le souhaite, d’un accès « administrateur » aux Comptes des Entreprises « sous-traitantes ». Dans cette hypothèse, elle s’engage à en informer les Entreprises avec lesquelles elle sous-traite. Cette information relève de sa seule responsabilité.</p>
      <p className="p2">L’accès au Compte nécessite l’enregistrement d’informations de connexion, à savoir : un identifiant unique choisi par l’Entreprise et un mot de passe. <b>Le premier mot de passe enregistré par défaut par Wall-Market doit impérativement être modifié par l’Entreprise après sa première connexion sous réserve de son acceptation de la Convention d’utilisation.</b></p>
      <p className="p2">Le Compte de l’Entreprise est accessible via l’URL <a href="https://providers-lightshop.wall-market.com/" target="_blank" rel="noopener noreferrer">https://providers-lightshop.wall-market.com/</a>, après que l’Entreprise a renseigné ses informations de connexion. Wall-Market se réserve la possibilité, pour des raisons notamment techniques, de modifier l’URL d’accès du compte.</p>
      <p className="p2">Wall-Market dispose de la possibilité et est expressément autorisée à ce titre à mettre à disposition de l’Entreprise plusieurs documents sur son Compte. Cette faculté ne lui permet aucunement d’accéder à un Compte.</p>
      <p className="p2">Afin de garantir son accès exclusif au Compte, l’Entreprise s’engage à choisir, dès sa première connexion un mot de passe suffisamment sécurisé et à ne le divulguer, sous aucun prétexte, à aucun tiers.<span className="Apple-converted-space"> </span></p>
      <p className="p2">L’Entreprise est responsable de la préservation de la confidentialité de ses informations de connexion et des activités de son Compte. En aucun cas, elles ne peuvent être cédées ou communiquées à un tiers. <span className="s1">Dans {"l'hypothèse"} où l’Entreprise aurait connaissance de ce {"qu'une"} autre personne accède à celui-ci, elle en informera Wall-Market sans délai.</span></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Obligation de mise à jour</b></p>
      <p className="p2">L’Entreprise s’engage à informer Wall-Market de toute modification de son statut, changement de coordonnées ou toute autre évolution susceptible d’avoir une incidence sur la présente Convention d’utilisation ou du contrat de prestation de services susceptible d’en résulter.</p>
      <p className="p2">Cette obligation est une condition essentielle et nécessaire à tout contrat de prestation de services avec Wall-Market.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>III.<span className="Apple-tab-span">	</span>FONCTIONNALITES DU COMPTE</b></p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>Le Compte permet de proposer à Wall-Market de conclure un contrat de prestation de services<span className="Apple-converted-space"> </span></b></p>
      <p className="p2"><span className="Apple-tab-span">	</span>A.<span className="Apple-tab-span">	</span><span className="s2">Proposition d’un Créneau par l’Entreprise, selon son choix discrétionnaire</span></p>
      <p className="p2">La création d’un compte permet à l’Entreprise d’accéder à un calendrier (le « <b>Calendrier</b> ») lui permettant d’enregistrer une ou plusieurs périodes (« <b>Créneau(x) </b>») pour les prestations que l’Entreprise souhaite, et est en mesure, d’exécuter.</p>
      <p className="p2">L’enregistrement d’un Créneau constitue une proposition de contrat de prestation de services par l’Entreprise à Wall-Market selon les termes stipulés aux Conditions Générales du Contrat de Prestation. Pour cette raison, il est demandé à l’Entreprise, au moment de l’acceptation de la Convention d’utilisation, d’accepter également :</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>les Conditions Générales du Contrat de Prestation, car chaque contrat de prestation de services entre Wall-Market et l’Entreprise sera régi par celles-ci, en sa version en vigueur au moment d’une proposition de Créneau par l’Entreprise. Si l’Entreprise ne souhaite pas être soumise aux Conditions Générales du Contrat de Prestation, elle ne doit pas renseigner de Créneau ;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>le mandat de facturation.<span className="Apple-converted-space"> </span></p>
      <p className="p2"><span className="s3">L’acceptation des </span>Conditions Générales du Contrat de Prestation et du mandat de facturation <span className="s3">est matérialisée par une case à cocher.</span></p>
      <p className="p2">Des Créneaux peuvent être proposés pour des journées entières ou pour certains horaires seulement durant une journée, selon le choix discrétionnaire de l’Entreprise.</p>
      <p className="p2">Il n’existe aucune obligation de la part de l’Entreprise de proposer des Créneaux. Ainsi, l’absence de proposition d’un Créneau n’emporte pas la clôture du Compte.</p>
      <p className="p2">Une fois un Créneau proposé, l’Entreprise est libre de le modifier ou de le retirer à tout moment sous réserve des stipulations ci-après (cf. clause <b><i>III.1.B</i></b>).<span className="Apple-converted-space"> </span></p>
      <p className="p2">La proposition d’un Créneau par l’Entreprise ne lui garantit pas la conclusion d’un contrat de prestation de services.</p>
      <p className="p2">B.<span className="Apple-tab-span">	</span><span className="s2">Acceptation d’un Créneau par Wall-Market</span></p>
      <p className="p2"><b>B.1<span className="Apple-tab-span">	</span><i>Conséquences de l’acceptation d’un Créneau par Wall-Market</i></b></p>
      <p className="p2">Wall-Market n’est nullement tenue d’accepter une offre de Créneau.</p>
      <p className="p2">L’acceptation par Wall-Market de l’offre d’un Créneau vaut accord des parties pour exécution d’une prestation par l’Entreprise selon les termes et conditions des Conditions Générales du Contrat de Prestation.<span className="Apple-converted-space"> </span></p>
      <p className="p2">L’Entreprise est informée de l’acceptation de Wall-Market par courriel, notification push et/ou SMS ; étant entendu que seule l’information via le Compte de l’Entreprise fait foi. Ainsi, l’Entreprise est seule responsable d’éventuels dysfonctionnements empêchant la réception d’un courriel, d’une notification push et/ou d’un SMS.<span className="Apple-converted-space"> </span></p>
      <p className="p2">L’acceptation d’un Créneau par Wall-Market engage l’Entreprise qui ne peut plus modifier ce Créneau.</p>
      <p className="p3">Wall-Market attire l’attention de l’Entreprise sur le fait que, selon les <span className="s4">Conditions Générales du Contrat de Prestation</span>, tout contrat de prestation de services sera résilié de plein droit, sans formalité ni mise en demeure, en raison de l’annulation par un client de Wall-Market de sa commande auprès de Wall-Market, plus de six (6) heures avant l’exécution de celle-ci.</p>
      <p className="p2"><b>B.2<span className="Apple-tab-span">	</span><i>Modalités d’acceptation d’un Créneau par Wall-Market</i></b></p>
      <p className="p3">L’acceptation par Wall-Market d’un Créneau dépend de la demande de ses clients. Ainsi, en l’absence de demande de clients, Wall-Market ne pourra pas être en mesure d’accepter un Créneau.</p>
      <p className="p3">Dans les zones géographiques les plus denses (ex : métropoles, grandes villes, zones tendues), un même Créneau peut être proposé par plusieurs Entreprises. Dans cette hypothèse, Wall-Market choisira l’Entreprise en fonction de critères objectifs et le cas échéant subjectifs (ex : proximité avec le lieu d’exécution de la prestation, exécution d’une ou plusieurs prestation(s) antérieure(s) conformément aux stipulations des Conditions Générales du Contrat de Prestation à supposer que l’Entreprise ait déjà eu l’occasion de collaborer avec Wall-Market).</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Le Compte permet à Wall-Market de proposer à l’Entreprise titulaire du Compte de conclure un contrat de prestation de services</b></p>
      <p className="p3">L’Entreprise titulaire d’un Compte actif (non clôturé) autorise Wall-Market à la contacter pour lui proposer un contrat de prestation de services selon les termes des <span className="s4">Conditions Générales du Contrat de Prestation</span> alors même que cette dernière n’a pas enregistré de Créneau.<span className="s5"><span className="Apple-converted-space"> </span></span></p>
      <p className="p3">Cette proposition par Wall-Market est effectuée par courriel, précisant la prestation envisagée, la surface du bien immobilier concerné, la commune du lieu d’exécution, la date et l’heure d’exécution et sa rémunération. Pour que cette proposition mène à la conclusion d’un contrat de prestation de services avec Wall-Market : (i) l’Entreprise doit cliquer sur « accepter cette prestation » ; et (ii) la prestation doit s’afficher sur son calendrier. Si elle ne s’affiche pas sur son calendrier, cela signifie que le contrat n’est pas conclu, une autre Entreprise ayant déjà accepté de la réaliser.</p>
      <p className="p3">Ce cas de figure est peu fréquent en pratique. Il est essentiellement amené à se rencontrer dans les zones géographiques les moins denses ou alors dans les zones les plus denses (ex : métropoles, grandes villes, zones tendues) durant les périodes de forte demande (qui ne dépendent pas de Wall-Market et sont évolutives).</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>3.<span className="Apple-tab-span">	</span>Le Compte permet la livraison d’une prestation exécutée et la gestion de la facturation</b></p>
      <p className="p2">Le Compte permet à l’Entreprise :</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>de livrer les fichiers ou URL résultant des prestations exécutées conformément aux Conditions Générales du Contrat de prestation ;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>de retracer l’historique de la ou des prestations effectuée(s) avec Wall-Market ; et</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>de gérer sa facturation. Ainsi, pour toute prestation réalisée, Wall-Market télécharge, au début du mois suivant l’exécution d’une prestation, la facture de l’Entreprise correspondant à sa rémunération au titre d’un ou plusieurs contrat(s) de prestation(s). Cette opération de téléchargement n’implique pas l’accès par Wall-Market au Compte de l’Entreprise.</p>
      <p className="p2">La durée de vie des fichiers ou factures sur un compte non clôturé n’est pas illimitée. Ainsi :</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>la disponibilité des factures est limitée à cinq ans à compter de leur émission, correspondant au délai de prescription légal ;</p>
      <p className="p4"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>les fichiers ou lien URL livrés peuvent être détruits à tout moment dans les 48h suivant leur réception. Le fait que ces fichiers ou lien URL puissent être disponibles pour une plus longue période ne signifie pas qu’ils ne peuvent pas l’être ou que Wall-Market renonce à cette destruction dans le délai de 48h visé ci-avant.</p>
      <p className="p2"><b><span className="Apple-tab-span">	</span>4.<span className="Apple-tab-span">	</span>Autres fonctionnalités d’un Compte</b></p>
      <p className="p2">Le Compte permet à l’Entreprise de modifier ou mettre à jour, à tout moment, son profil, son adresse de facturation et ses informations de paiement. </p>
      <p className="p2"><b>IV.<span className="Apple-tab-span">	</span>OBLIGATIONS DE L’ENTREPRISE</b></p>
      <p className="p2"><b>IV.1<span className="Apple-tab-span">	</span></b>L’Entreprise s’engage et se porte fort du respect des obligations stipulées aux présentes par ses représentants, dirigeants et préposés.</p>
      <p className="p2"><b>IV.2<span className="Apple-tab-span">	</span></b>Par application du principe de loyauté applicable à toute convention, l’Entreprise qui entend collaborer de manière régulière avec Wall-Market s’engage à ne pas démarcher les clients de Wall-Market rencontrés via l’exécution d’un contrat de prestation de services conclu avec Wall-Market, pour des prestations vendues par Wall-Market et ce pendant toute la durée d’ouverture du Compte et pendant six (6) mois après sa clôture.</p>
      <p className="p6">Pour l’interprétation de cette clause, lorsque le client est une agence immobilière, l’Entreprise reconnaît que l’obligation de non-démarchage s’applique à toute l’agence et en conséquence, à tous les agents immobiliers ou commerciaux collaborant avec/ou relevant de cette agence.</p>
      <p className="p6">En tant que de besoin, aucun démarchage d’un client de Wall-Market ne pourra être effectué en contravention des règles relatives aux données personnelles.</p>
      <p className="p2"><b>V.<span className="Apple-tab-span">	</span>DONNÉES DE CONNEXION (« Cookies »)</b></p>
      <p className="p2">Wall-Market utilise des témoins de connexion (« cookies ») strictement nécessaires à l’usage du Compte et la navigation de l’Entreprise sur son espace personnel. Ces cookies sont expressément autorisés par la loi Informatique et Libertés (article 32-II de la loi Informatique et liberté modifiée n°78-17 du 6 Janvier 1978).</p>
      <p className="p2"><b>VI.<span className="Apple-tab-span">	</span>ABSENCE DE RESPONSABILITE DE WALL-MARKET</b></p>
      <p className="p2">La responsabilité de Wall-Market est exclue pour tout usage frauduleux d’un Compte ou des données qu’il contient.<span className="Apple-converted-space"> </span></p>
      <p className="p2"><b>VII.<span className="Apple-tab-span">	</span>DUREE</b></p>
      <p className="p2">Le Compte est créé et mis à disposition pour une durée illimitée.</p>
      <p className="p2"><b>VIII.<span className="Apple-tab-span">	</span>CLOTURE DU COMPTE</b></p>
      <p className="p2"><span className="Apple-tab-span">	</span>1.<span className="Apple-tab-span">	</span>Chaque Partie peut décider de clôturer le Compte, sous réserve d’un préavis d’un (1) mois notifié à l’autre Partie selon les formes suivantes :<span className="Apple-converted-space"> </span></p>
      <p className="p4"><b><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>Notification par l’Entreprise à Wall-Market</b> : par courriel, à l’adresse suivante : <a href="mailto: providers@wall-market.com">providers@wall-market.com</a>;</p>
      <p className="p4"><b><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span>Notification de Wall-Market à l’Entreprise</b> : par courriel, à l’adresse électronique qu’il aura communiqué à Wall-Market, et à la dernière adresse renseignée sur son profil et communiquée à Wall-Market pour un éventuel RAR.</p>
      <p className="p2"><span className="Apple-tab-span">	</span>2.<span className="Apple-tab-span">	</span>Wall-Market pourra également clôturer le Compte, sans préavis, en cas de cession par l’Entreprise de ses informations de connexion à un tiers, ou toute autre utilisation frauduleuse de la part de l’Entreprise.</p>
      <p className="p2"><span className="Apple-tab-span">	</span>3.<span className="Apple-tab-span">	</span>Postérieurement à la date effective de la clôture du Compte, aucun Créneau ne pourra plus être proposé et aucun contrat de prestation de services conclu.</p>
      <p className="p2"><span className="Apple-tab-span"> </span> Il est cependant rappelé qu’un Compte actif n’emporte pas obligation pour l’Entreprise d’enregistrer des Créneaux. En conséquence, en l’absence de clôture du compte par l’une ou l’autre Partie, le compte demeurera ouvert et l’Entreprise sera libre de proposer des Créneaux.</p>
      <p className="p2"><b>IX.<span className="Apple-tab-span">	</span>SUSPENSION ET FERMETURE DE L’ACCES AU CALENDRIER</b></p>
      <p className="p2">Wall-Market pourra, sans préavis, priver l’accès de l’Entreprise au Calendrier en cas de manquement grave par l’Entreprise à la Convention d’utilisation ou aux Conditions Générales du Contrat de Prestation dans le cadre de l’exécution d’un contrat de prestation de services. </p>
      <p className="p2"><b>X.<span className="Apple-tab-span">	</span>CONFIDENTIATITE</b></p>
      <p className="p2">L’Entreprise s’engage à conserver confidentiel les termes des présentes et à ce titre, à ne les divulguer à aucun tiers.</p>
      <p className="p8">Cette obligation de confidentialité restera valable pendant une durée de cinq (5) ans après la clôture du Compte.</p>
      <p className="p2"><b>XI.<span className="Apple-tab-span">	</span>DÉLAI DE PRESCRIPTION ABRÉGÉ</b></p>
      <p className="p8">Par <span className="s4">application</span> de l’article 2254 du Code civil, les parties conviennent de réduire le délai de prescription à un (1) an pour toute contestation liée aux articles III.1, V, VIII et IX ci-avant.</p>
      <p className="p2"><b>XII.<span className="Apple-tab-span">	</span>DROIT APPLICABLE ET JURIDICTIONS COMPETENTES</b><span className="Apple-converted-space"> </span></p>
      <p className="p8">La Convention d’utilisation est soumise au droit français.<span className="Apple-converted-space"> </span></p>
      <p className="p8">Toute contestation relative à la validité, l’interprétation, l’exécution ou la rupture de la Convention d’utilisation, qu’elle soit de nature contractuelle ou délictuelle, relève de la compétence territoriale exclusive des tribunaux de Paris.</p>
    </div>
  )
}

export default UsingAccount;
