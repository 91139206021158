import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';

import InvoiceMandate from './terms/InvoiceMandate';
import MainContract from './terms/MainContract';
import UsingAccount from './terms/UsingAccount';

class TermsFR extends Component {

  render() {
    return (
      <Fragment>
        <div className="accept-terms-content" id="cgs-cg">
          <Typography variant="h5" className="accept-terms-subtitle">CONDITIONS GENERALES</Typography>
          <MainContract />
        </div>
        <div className="accept-terms-content" id="user-agreement">
          <Typography variant="h5" className="accept-terms-subtitle">CONVENTION D’UTILISATION D’UN COMPTE</Typography>
          <UsingAccount />
        </div>
        <div className="accept-terms-content">
          <Typography variant="h5" className="accept-terms-subtitle">MANDAT DE FACTURATION</Typography>
          <InvoiceMandate />
        </div>
      </Fragment>
    );
  }
}

export default TermsFR;