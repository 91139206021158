import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

class User extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      checked: false
    }

    this.handlePermission = this.handlePermission.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      handleCompanyPaymentMethodPermissions,
      paymentMethodId,
      userId
    } = this.props;

    if (!paymentMethodId) return;

    this.setState({ loading: true });

    handleCompanyPaymentMethodPermissions(
      "get",
      paymentMethodId,
      userId,
      () => {
        this.setState({
          loading: false,
          checked: true
        });
      },
      () => {
        this.setState({
          loading: false,
          checked: false
        });
      }
    );
  }

  handlePermission(event, value) {

    const {
      handleCompanyPaymentMethodPermissions,
      paymentMethodId,
      userId
    } = this.props;

    this.setState({ loading: true });

    handleCompanyPaymentMethodPermissions(
      !value ? "delete" : "post",
      paymentMethodId,
      userId,
      () => {
        this.setState({
          loading: false,
          checked: !value ? false : true
        });
      },
      () => {
        this.setState({
          loading: false,
          checked: this.state.checked
        });
      }
    );
  }

  render() {

    const {
      lastName,
      firstName,
      userLogged,
      userId
    } = this.props

    const {
      loading,
      checked
    } = this.state;

    const iconCheckStyle = {
      display: "block",
      textAlign: "right",
      padding: "12px 15px 12px 12px",
      fontSize: "16px",
    }

    const iconUncheckedStyle = {
      ...iconCheckStyle,
      color: "#48C17B"
    }

    const iconCheckedStyle = {
      ...iconCheckStyle,
      color: "#F1664F"
    }

    return (
      <TableRow>
        <TableCell>{lastName}</TableCell>
        <TableCell>{firstName}</TableCell>
        <TableCell align="right">
          { loading
            ? (
              <CircularProgress
                color="secondary"
                size={24}
                style={{margin: 12}}
              />
            )
            : (
              <span>
                {
                  userLogged.id !== userId
                    ?
                      <Checkbox
                        checked={checked}
                        onChange={this.handlePermission}
                        color="default"
                      />
                    : checked
                      ? <span className="icon-check" style={iconUncheckedStyle} />
                      : <span className="icon-fermer" style={iconCheckedStyle} />
                }
              </span>
            )
          }
        </TableCell>
      </TableRow>
    );
  }
}

User.propTypes = {
  handleCompanyPaymentMethodPermissions: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userLogged: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }).isRequired,
  paymentMethodId: PropTypes.number,
  lastName: PropTypes.any.isRequired,
  firstName: PropTypes.any.isRequired,
}

export default User;
