import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';

import { toUnix, dateInTz } from '../../../helpers/functions/dates';
import { getApiUrl } from '../../../helpers/functions/api';

import ToggleLabel from '../../../components/commons/ToggleLabel';

import translation from '../../../translation/translation';

class InvoicesList extends Component {

  constructor(props) {
    super(props);

    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      getInvoicesList,
      toggleInvoicesListMode,
      companyAdmin
    } = this.props;

    getInvoicesList(
      null,
      companyAdmin ? "provider-companies" : "providers",
      () => {
        if (companyAdmin)
          toggleInvoicesListMode('provider-companies');
      }
    );
  }

  handleScroll(event) {

    const {
      getInvoicesList,
      invoicesList: {
        paging,
        loading,
        mode
      }
    } = this.props;

    if (!event || !event.target) return;

    if (!event.target.scrollTop || event.target.scrollTop === 0) return;

    if (event.target.scrollHeight - event.target.clientHeight - 20 <= event.target.scrollTop) {
      if (paging && paging.next) {
        if (!loading) {
          getInvoicesList(paging.next, mode);
        }
      }
    }
  }

  handleChangeMode(e, value) {

    const {
      getInvoicesList,
      toggleInvoicesListMode,
      invoicesList: {
        loading,
        mode
      }
    } = this.props;

    if (loading) return;

    if (mode && mode === "providers") {

      if (!value)
        return;

      getInvoicesList(
        null,
        'provider-companies',
        () => {
          toggleInvoicesListMode('provider-companies');
        }
      );
    }
    else if (mode && mode === "provider-companies") {

      if (value)
        return;

      getInvoicesList(
        null,
        'providers',
        () => {
          toggleInvoicesListMode('providers');
        }
      );
    }
  }

  renderTable(data) {

    if (data && data.length > 0 ) {

      const rows = [];

      for (let i = 0; i < data.length; i++) {
        let row = {};

        if (data[i].id)
          row['id'] = data[i].id;

        if (data[i].date)
          row['date'] = dateInTz(data[i].date, "localized-datetime");
        else
          row['date'] = "-"

        if (data[i].number_formatted)
          row['number_formatted'] = data[i].number_formatted;
        else
          row['number_formatted'] = "-";

        if (data[i].total_formatted)
          row['total_formatted'] = data[i].total_formatted;
        else
          row['total_formatted'] = '-';

        if (data[i].payment_status)
          row['payment_status'] = data[i].payment_status;
        else
          row['payment_status'] = '-';

        if (data[i].total_tax_excluded_formatted)
          row['total_tax_excluded_formatted'] = data[i].total_tax_excluded_formatted;
        else
          row['total_tax_excluded_formatted'] = '-';

        rows.push(row);
      }

      const {
        invoicesList: { mode }
      } = this.props;

      return (
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translation().invoices.invoices_list.table.number_formatted}</TableCell>
                <TableCell>{translation().invoices.invoices_list.table.date}</TableCell>
                <TableCell>{translation().invoices.invoices_list.table.status}</TableCell>
                <TableCell align="right">{translation().invoices.invoices_list.table.total_tax_excluded_formatted}</TableCell>
                <TableCell align="right">{translation().invoices.invoices_list.table.total_formatted}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.map((row, k) => {
                  return (
                    <TableRow key={k}>
                      <TableCell component="th" scope="row">{row.number_formatted}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        { row.payment_status && row.payment_status.icon_url_dark &&
                          <img
                            width={25}
                            src={row.payment_status.icon_url_dark}
                            alt={row.payment_status.name ? row.payment_status.name : "-"}
                            title={row.payment_status.name ? row.payment_status.name : "-"}
                          />
                        }
                        { row.payment_status && row.payment_status.name && window.matchMedia("(min-width: 850px)").matches &&
                          <span style={{marginLeft: 4, color: row.payment_status.color ? row.payment_status.color : "currentcolor"}}>
                            {row.payment_status.name}
                          </span>
                        }
                      </TableCell>
                      <TableCell align="right">{row.total_tax_excluded_formatted}</TableCell>
                      <TableCell align="right">{row.total_formatted}</TableCell>
                      <TableCell align="right" className="column-actions">
                        <a
                          href={
                            getApiUrl(
                              (mode ? mode : "providers") + "/self/invoices/" + row.id + "/download",
                              {mode: "attachment", v: toUnix()}
                            )
                          }
                        >
                          <IconButton
                            aria-label="download"
                            style={{padding: 7}}
                            color='inherit'
                          >
                            <span className="icon-download" style={{fontSize: 16}} />
                          </IconButton>
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {

    const {
      companyAdmin,
      invoicesList: {
        data,
        mode,
        loading
      }
    } = this.props;

    return (
      <div className="InvoicesList">
        <div className="section-controls">
          { companyAdmin &&
            <div className="toggle-mode">
              <ToggleLabel
                label={translation().invoices.invoices_list.switch.personal}
                value={false}
                onClick={this.handleChangeMode}
              />
              <Switch
                checked={mode === "providers" ? false : true}
                value={mode}
                color="default"
                disabled={loading}
                onChange={this.handleChangeMode}
              />
              <ToggleLabel
                label={translation().invoices.invoices_list.switch.pro}
                value={true}
                onClick={this.handleChangeMode}
              />
            </div>
          }
        </div>
        <div style={{width: "100%", overflow: "auto", maxHeight: 600}} onScroll={this.handleScroll}>
          { data && data.length > 0
            ? this.renderTable(data)
            : !loading
              ? <div className="data-empty">{translation().invoices.invoices_list.no_invoice}</div>
              : ''
          }
        </div>
        { loading &&
          <div className="center-loader">
            <CircularProgress color={'secondary'} size={35} />
          </div>
        }
      </div>
    )
  }
}

InvoicesList.propTypes = {
  toggleInvoicesListMode: PropTypes.func.isRequired,
  getInvoicesList: PropTypes.func.isRequired,
  companyAdmin: PropTypes.bool.isRequired,
  invoicesList: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool,
    paging: PropTypes.shape(),
    mode: PropTypes.string
  }).isRequired,
}

export default InvoicesList;
