export const getOrderProviders = () => ({
  type: "GET_ORDER_PROVIDERS",
});

export const getOrderProvidersSuccess = (data) => ({
  type: "GET_ORDER_PROVIDERS_SUCCESS",
  data,
});

export const getOrderProvidersFailure = (error) => ({
  type: "GET_ORDER_PROVIDERS_FAILURE",
  error,
});
