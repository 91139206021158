import translation from '../../../../../translation/translation';

const validate = values => {

  const errors = {};

  if (!values.date)
    errors.date = translation().forms.errors.required;

  if (!values.hour_start)
    errors.hour_start = translation().forms.errors.required;

  if (!values.min_start)
    errors.min_start = translation().forms.errors.required;

  if (!values.hour_end)
    errors.hour_end = translation().forms.errors.required;

  if (!values.min_end)
    errors.min_end = translation().forms.errors.required;

  return errors;
}

export default validate;
