import { connect } from "react-redux";

import { fetchApi } from "../../helpers/functions/api";

import {
  editOrderPaymentMethod,
  editOrderPaymentMethodSuccess,
  editOrderPaymentMethodFailure,
  getOrderPaymentMethods,
  getOrderPaymentMethodsSuccess,
  getOrderPaymentMethodsFailure,
} from "../../redux/actions/orderManagement/PaymentMethod";

import { enqueueSnackbar } from "../../redux/actions/notificationsActions";

import translation from "../../translation/translation";

import ManagePaymentMethodDialog from "../../components/orderManagement/PaymentMethodDialog";

const mapStateToProps = (state) => ({
  getLoading: state.orderManagement.paymentMethod.getLoading,
  updateLoading: state.orderManagement.paymentMethod.updateLoading,
  data: state.orderManagement.paymentMethod.data,
  error: state.orderManagement.paymentMethod.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderPaymentMethods() {
      dispatch(getOrderPaymentMethods());

      fetchApi(
        "get",
        "users/self/payment-methods/usable",
        null,
        null,
        null,
        (data) => {
          dispatch(getOrderPaymentMethodsSuccess(data));
        },
        (error) => {
          dispatch(getOrderPaymentMethodsFailure(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.payment_methods.error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.payment_methods.error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
    editOrderPaymentMethod(orderId, methodId, onSuccess) {
      if (!orderId || !methodId) return;

      dispatch(editOrderPaymentMethod());

      try {
        fetchApi(
          "put",
          `orders/${orderId}/invoice-payment-method`,
          null,
          { invoice_payment_method_id: methodId },
          null,
          () => {
            dispatch(editOrderPaymentMethodSuccess());
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.payment_methods.update
                  .success,
                options: {
                  variant: "success",
                },
              })
            );

            if (onSuccess && typeof onSuccess === "function") onSuccess();
          },
          (error) => {
            dispatch(editOrderPaymentMethodFailure(error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().order_management.payment_methods.update
                          .error,
                  status: error.response.status || undefined,
                  options: {
                    variant: "error",
                  },
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().order_management.payment_methods.update
                    .error,
                  options: {
                    variant: "error",
                  },
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(editOrderPaymentMethodFailure(error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.callbacks
              .edit_payment_method.error,
            options: {
              variant: "error",
            },
          })
        );
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePaymentMethodDialog);
