export const updateCompanyInformations = () => ({
  type: "UPDATE_COMPANY_INFORMATIONS"
});

export const updateCompanyInformationsSuccess = () => ({
  type: "UPDATE_COMPANY_INFORMATIONS_SUCCESS"
});

export const updateCompanyInformationsFailure = error => ({
  type: "UPDATE_COMPANY_INFORMATIONS_FAILURE",
  error
});

export const getCompanyUsers = (next = null) => ({
  type: "GET_COMPANY_USERS",
  next
});

export const getCompanyUsersSuccess = (data, paging) => ({
  type: "GET_COMPANY_USERS_SUCCESS",
  data,
  paging
});

export const getCompanyUsersFailure = error => ({
  type: "GET_COMPANY_USERS_FAILURE",
  error
});

export const updateCompanyUsers = (data, paging) => ({
  type: "UPDATE_COMPANY_USERS",
  data,
  paging
});

export const linkCompanyUsers = () => ({
  type: "LINK_COMPANY_USERS"
});

export const linkCompanyUsersSuccess = () => ({
  type: "LINK_COMPANY_USERS_SUCCESS"
});

export const linkCompanyUsersFailure = error => ({
  type: "LINK_COMPANY_USERS_FAILURE",
  error
});
