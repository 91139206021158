import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';

import { pad } from '../../../../../helpers/functions/utils';

import MuiSelectField from '../../../../../components/commons/fields/MuiSelectField';

import translation from '../../../../../translation/translation';
import validate from './validate';

function renderOptions(name, minHour = 7, maxHour = 21) {

  const options = [];

  for (let i = minHour; i < (maxHour + 1); i++) {
    options.push(
      <option key={i + (name ? name : Math.random())} value={pad(i)}>
        { pad(i) }
      </option>
    );
  }

  return options;
}

const labelStyle = {
  marginBottom: "10px",
  display: "block"
}

function EditAvailabilityForm(props) {

  const {
    handleSubmit,
    maxHour,
    minHour,
    editAction: {
      loading
    }
  } = props;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="col-sm-12">
        <InputLabel style={labelStyle}>{translation().schedule.availability.forms.labels.start}</InputLabel>
      </div>
      <div className="clearfix"/>
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            name="hour_start"
            fullWidth={true}
            component={MuiSelectField}
          >
            { renderOptions("_hour_start", minHour, maxHour) }
          </Field>
        </div>
      </div>
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            name="min_start"
            fullWidth={true}
            component={MuiSelectField}
          >
            <option value={"00"}>00</option>
            <option value={"15"}>15</option>
            <option value={"30"}>30</option>
            <option value={"45"}>45</option>
          </Field>
        </div>
      </div>
      <div className="clearfix"/>
      <div className="col-sm-12">
        <InputLabel style={labelStyle}>{translation().schedule.availability.forms.labels.end}</InputLabel>
      </div>
      <div className="clearfix"/>
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            name="hour_end"
            fullWidth={true}
            component={MuiSelectField}
          >
            { renderOptions("_hour_end", minHour, maxHour) }
          </Field>
        </div>
      </div>
      <div className="col-xs-6">
        <div className="field-wrapper">
          <Field
            name="min_end"
            fullWidth={true}
            component={MuiSelectField}
          >
            <option value={"00"}>00</option>
            <option value={"15"}>15</option>
            <option value={"30"}>30</option>
            <option value={"45"}>45</option>
          </Field>
        </div>
      </div>
      <div className="clearfix"/>
      <div className="submit-wrapper">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="btn-availability"
          disabled={loading}
          style={{minWidth: 150}}
        >
          { loading
            ? <CircularProgress size={20} color='secondary' />
            : <span>{translation().schedule.availability.edit.buttons.submit}</span>
          }
        </Button>
      </div>
    </form>
  );
}

EditAvailabilityForm.propTypes = {
  editAction: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'editAvailabilityForm',
  enableReinitialize: true,
  validate
})(EditAvailabilityForm);
