import React, { Component } from "react";
import PropTypes from "prop-types";

import Rating from "react-rating";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import { dateInTz } from "../../../../helpers/functions/dates";

import translation from "../../../../translation/translation";

const RateCheckbox = (props) => {
  const { id, onClick, onUnselect, fontIcon, text, checked } = props;

  function handleClick() {
    if (checked) {
      if (id && onUnselect && typeof onUnselect === "function") onUnselect(id);
    } else if (id && onClick && typeof onClick === "function") onClick(id);
  }

  return (
    <div
      className={"client-rate-radio" + (checked ? " selected" : "")}
      onClick={handleClick}
    >
      <div className={fontIcon + " option-rate-icon"} />
      {text && <span>{text}</span>}
    </div>
  );
};

RateCheckbox.propTypes = {
  id: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  fontIcon: PropTypes.string.isRequired,
  text: PropTypes.string,
  checked: PropTypes.bool,
};

class ClientRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minRating: 3,
      ratingValue: 0,
      ratingTextareaValue: "",
      ratingCheckboxValues: [],
    };

    this.handleChangeRateComments = this.handleChangeRateComments.bind(this);
    this.handleSelectRateCheckbox = this.handleSelectRateCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRate = this.handleRate.bind(this);
    this.handleUnselectRateCheckbox = this.handleUnselectRateCheckbox.bind(
      this
    );
  }

  handleRate(rate) {
    const { minRating } = this.state;

    if (rate >= minRating) {
      this.setState({
        ratingValue: rate,
        ratingCheckboxValues: [],
        ratingTextareaValue: "",
      });
    } else {
      this.setState({ ratingValue: rate });
    }
  }

  handleSubmit() {
    const {
      ratingValue,
      ratingTextareaValue,
      ratingCheckboxValues,
    } = this.state;

    const { onSubmit } = this.props;

    if (!ratingValue) return;

    if (onSubmit && typeof onSubmit === "function") {
      onSubmit({
        rating: ratingValue,
        issues: ratingCheckboxValues,
        comment: ratingTextareaValue,
      });
    }
  }

  handleChangeRateComments(event) {
    this.setState({ ratingTextareaValue: event.target.value });
  }

  handleSelectRateCheckbox(id) {
    this.setState({
      ratingCheckboxValues: [...this.state.ratingCheckboxValues, id],
    });
  }

  handleUnselectRateCheckbox(id) {
    const newArray = this.state.ratingCheckboxValues.filter((el) => {
      return el !== id;
    });

    this.setState({ ratingCheckboxValues: newArray });
  }

  render() {
    const {
      ratingValue,
      ratingTextareaValue,
      ratingCheckboxValues,
      minRating,
    } = this.state;

    const { isLoading, product, propertyAddress, onClose } = this.props;

    return (
      <div className="rating-frame">
        <IconButton
          size="small"
          aria-label="Delete"
          className="close-rating-dialog"
          onClick={onClose}
          disabled={isLoading}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <img
          src={product.icon_url_dark}
          alt={product.name}
          className="client-rate-img"
        />
        <h4 className="modal-title">{product.name}</h4>
        {propertyAddress && <p className="modal-desc">{propertyAddress}</p>}
        {product.start ? (
          <div className="client-rate-details">
            <span className="detail">
              <span className="detail-icon icon-calendrier" />
              {dateInTz(product.start, "localized-date")}
            </span>
            <span className="detail">
              <span className="detail-icon icon-horaire" />
              {dateInTz(product.start, "HH:mm")}
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="modal-content">
          <div className="client-rating">
            <Rating
              initialRating={ratingValue}
              onClick={this.handleRate}
              emptySymbol={<span className="icon-etoilepleine rate-icon" />}
              fullSymbol={
                <span className="icon-etoilepleine rate-icon filled" />
              }
            />
          </div>
          {ratingValue && ratingValue <= minRating ? (
            <div className="client-rate-more">
              <p className="rate-issue-title">
                {translation().orders.rating.title}
              </p>
              <RateCheckbox
                id="client_punctuality"
                checked={
                  ratingCheckboxValues.indexOf("client_punctuality") >= 0
                }
                fontIcon="icon-rea01"
                text={translation().orders.rating.rating_radios.appointment}
                onClick={this.handleSelectRateCheckbox}
                onUnselect={this.handleUnselectRateCheckbox}
              />
              <RateCheckbox
                id="client_behaviour"
                checked={ratingCheckboxValues.indexOf("client_behaviour") >= 0}
                fontIcon="icon-connecte"
                text={translation().orders.rating.rating_radios.attitude}
                onClick={this.handleSelectRateCheckbox}
                onUnselect={this.handleUnselectRateCheckbox}
              />
              <RateCheckbox
                id="property_status"
                checked={ratingCheckboxValues.indexOf("property_status") >= 0}
                fontIcon="icon-type"
                text={translation().orders.rating.rating_radios.property_status}
                onClick={this.handleSelectRateCheckbox}
                onUnselect={this.handleUnselectRateCheckbox}
              />
              <textarea
                rows={5}
                className="client-rate-textarea"
                placeholder={translation().orders.rating.form.labels.commentary}
                onChange={this.handleChangeRateComments}
                value={ratingTextareaValue}
              />
            </div>
          ) : (
            ""
          )}
          <div className="btn-wrapper">
            {isLoading ? (
              <div className="center-loader">
                <CircularProgress color="secondary" size={35} />
              </div>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                disabled={!ratingValue || isLoading}
                onClick={this.handleSubmit}
              >
                {translation().orders.rating.form.button.submit}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ClientRating.propTypes = {
  isLoading: PropTypes.bool,
  product: PropTypes.shape().isRequired,
  propertyAddress: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ClientRating;
