import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

const chartColorsArray = [
  "#F29D06", // primary theme color
  "#ff9800", // orange 500
  "#4db6ac", // teal 300
  "#5e35b1", // deep purple 600
  "#ffee58", // yellow 400
  "#d81b60", // pink 600
  "#388e3c", // green 700
  "#3949ab", // indingo 600
  "#ffe082", // amber 500
  "#f4511e", // deep orange 600
  "#81d4fa", // light blue 200
  "#00acc1", // cyan 600
  "#8e24aa", // purple 600
  "#4caf50", // green 500
  "#cddc39", // lime 500
  "#ffc107", // amber 200
  "#e53935", // red 600
  "#80deea", // cyan 200
  "#ff8a65", // deep orange 300
  "#7c4dff", // deep purple A200
  "#f48fb1", // pink 200
  "#0277bd", // light blue 800
  "#7986cb", // indigo 300
  "#ff5252", // red 200
  "#ce93d8", // purple 200
];

class Metric extends Component {

  constructor(props) {
    super(props);

    this.state = {
      metricNumber: 0,
      loading: false,
      loadingNumber: false,
      errorNumber: null,
      warning: null,
      error: null
    }

    this.chart = undefined;
    this.loadMetrics = this.loadMetrics.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.renderCustomChart = this.renderCustomChart.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.mode)
      this.loadMetrics(nextProps);
  }

  componentWillUnmount() {
    this.chart = undefined;
  }

  calcMetricNumber(metric, type = "count") {

    if (metric && metric.values && metric.values.length > 0) {

      let sum = 0;
      let nbElements = 0;

      for (let i = 0; i < metric.values.length; i++) {
        sum += parseFloat(metric.values[i], 10);

        if (parseFloat(metric.values[i], 10) !== 0) {
          nbElements++;
        }
      }

      if (type.toLowerCase() === "count") {
        return sum.toFixed(2);
      }
      else if (type.toLowerCase() === "avg") {
        if (nbElements === 0) {
          return 0;
        }

        return (sum / nbElements).toFixed(2);
      }
    }
    else {
      return 0;
    }
  }

  loadMetrics(nextProps, query = {}, callback = null) {

    const {
      getMetrics,
      queryParams,
      filterKey,
      chartProps,
      routeName,
      mode
    } = nextProps;

    this.setState({
      warning: null,
      error: null,
      loading: true,
      metricNumber: null
    });

    if (filterKey) {
      if (queryParams.filters)
        query.filters += ',' + filterKey + '|eq|' + 12;
      else
        query.filters = filterKey + '|eq|' + 12;
    }

    getMetrics(
      routeName,
      {
        ...queryParams,
        ...queryParams.range,
        ...query
      },
      mode,
      data => {
        this.setState({ loading: false });

        if (chartProps.metric_calc_mode) {
          if (chartProps.metric_calc_mode.toLowerCase() === "function" && chartProps.metric_calc_function) {

            if (
              chartProps.metric_calc_function.type &&
              (chartProps.metric_calc_function.type.toLowerCase() === "call-api") &&
              chartProps.metric_calc_function.route
            ) {
              this.setState({ loadingNumber: true });

              getMetrics(
                chartProps.metric_calc_function.route,
                {
                  ...query,
                  ...queryParams.range,
                  ...chartProps.metric_calc_function.queryParams
                },
                mode,
                data => {
                  this.setState({
                    loadingNumber: false,
                    metricNumber: chartProps.metric_calc_function.calc_mode ? this.calcMetricNumber(data, chartProps.metric_calc_function.calc_mode) : null
                  });
                },
                error => {
                  this.setState({
                    errorNumber: error,
                    loadingNumber: false,
                  });
                }
              );
            }
          }
          else if (chartProps.metric_calc_mode.toLowerCase() !== "function") {
            this.setState({
              metricNumber: this.calcMetricNumber(data, chartProps.metric_calc_mode)
            });
          }
        }

        if (!chartProps.hide_chart) {
          if (chartProps.custom)
            this.renderCustomChart(nextProps, data);
          else
            this.renderChart(nextProps, data);
        }

        if (callback && typeof callback === 'function')
          callback();
      },
      error => {
        this.setState({
          error,
          loading: false
        });

        callback && callback();
      }
    );
  }

  renderChart(nextProps, metric) {

    const chartContainer = document.getElementById(nextProps.chartProps.name + "-metric-chart");

    if (chartContainer) {

      this.chart && this.chart.destroy();
      chartContainer.style.display = "block";

      if (!metric || !metric.values || metric.values.length <= 0) {
        this.setState({ warning: "The array of values is empty, no data found."});
        chartContainer.style.display = "none";
        return;
      }

      this.chart = new Chart(chartContainer, {
        type: nextProps.chartProps.type ? nextProps.chartProps.type : nextProps.defaultType,
        data: {
          labels: metric.labels,
          datasets: [{
            data: metric.values,
            label: "N",
            lineTension: 0.4,
            backgroundColor: nextProps.chartProps.type && (nextProps.chartProps.type === "doughnut" || nextProps.chartProps.type === "bar" || nextProps.chartProps.type === "pie") ? chartColorsArray : "#FFECB3",
            borderColor: nextProps.chartProps.type && (nextProps.chartProps.type === "doughnut" || nextProps.chartProps.type === "bar" || nextProps.chartProps.type === "pie") ? chartColorsArray : "#F29D06",
            borderJoinStyle: "round",
            pointStyle: "circle",
            pointRadius: 1.7,
            borderWidth: nextProps.chartProps.type && (nextProps.chartProps.type === "doughnut" || nextProps.chartProps.type === "bar" || nextProps.chartProps.type === "pie") ? 0 : 2,
          }],
        },
        options: {
          legend: {
            display: nextProps.chartProps.options && nextProps.chartProps.options.legend ? nextProps.chartProps.options.legend : false
          },
          tooltips: {
            enabled: nextProps.chartProps.options && nextProps.chartProps.options.tooltips ? nextProps.chartProps.options.tooltips : true
          }
        }
      });
    }
  }

  renderCustomChart(nextProps, metric) {

    const customMetricContainer = document.getElementById(nextProps.chartProps.name + "-custom-chart");

    if (customMetricContainer) {

      if (!metric || !metric.values || metric.values.length <= 0) {
          this.setState({ warning: "The array of values is empty, no data found." });
          return;
      }

      let evol = this.state.metricNumber;

      if (nextProps.chartProps.type && nextProps.chartProps.type.toLowerCase() === "trending") {

        ReactDOM.render(
          <div className="type-trending">
            { evol > 0 &&
              <div className="trending trend-up">
                <i className="material-icons">trending_up</i>
                <span>+ {evol}%</span>
              </div>
            }
            { evol < 0 &&
              <div className="trending trend-down">
                <i className="material-icons">trending_down</i>
                <span>{evol}%</span>
              </div>
            }
            { evol === 0 &&
              <div className="trending trend-flat">
                <i className="material-icons">trending_flat</i>
                <span>{evol}%</span>
              </div>
            }
          </div>,
          customMetricContainer
        );
      }
    }
  }

  render() {

    const {
      loading,
      warning,
      error,
      metricNumber,
      loadingNumber
    } = this.state;

    const {
      chartProps,
    } = this.props;

    const loaderWrapperStyle = {
      marginTop: 24,
      marginBottom: 24,
      textAlign: 'center'
    };

    return (
      <Paper className="metric" style={chartProps.hide_chart && {marginBottom: 0}}>
        { chartProps.title &&
          <div className="metric-title">
            { chartProps.title }
            { !chartProps.custom && metricNumber
               ? (
                <span className="metric-nbr">
                  {metricNumber}
                </span>
              )
              : false
            }
            { loadingNumber &&
              <CircularProgress
                size={25}
                thickness={3}
                color="secondary"
                style={{marginLeft: 5, top: 5}}
              />
            }
          </div>
        }
        { loading &&
          <div style={loaderWrapperStyle}>
            <CircularProgress
              size={40}
              thickness={4}
              color="secondary"
            />
          </div>
        }
        { error
          ? <code className="alert alert-danger" role="alert"><i className="material-icons alert-icon">warning</i> { error }</code>
          : !loading && !chartProps.hide_chart && !chartProps.custom && <canvas id={chartProps.name + "-metric-chart"} />
        }
        { !error && !loading && chartProps.custom &&
          <div id={chartProps.name + "-custom-chart"} />
        }
        { warning &&
          <code className="alert alert-warning" role="alert">
            <i className="material-icons alert-icon">info</i> {warning}
          </code>
        }
      </Paper>
    )
  }
}

Metric.propTypes = {
  chartProps: PropTypes.shape().isRequired,
  mode: PropTypes.string
};

export default Metric;
