import React from "react";
import PropTypes from "prop-types";

/**
 * Toggle react hook for switch user/company mode.
 */
function ToggleLabel(props) {
  function handleClick() {
    const { onClick, value } = props;

    /**
     * Pass null as first argument (event)
     */
    onClick(null, value);
  }

  const { label } = props;

  return (
    <span
      className="toggle-label"
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleClick}
    >
      {label}
    </span>
  );
}

ToggleLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default ToggleLabel;
