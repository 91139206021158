/* eslint-disable react/self-closing-comp */

import React from 'react';

function InvoiceMandate() {

  return (
    <div className="cgs-mandate">
      <p className="p3"><b>1.<span className="Apple-tab-span">	</span></b><span className="s1"><b>PURPOSE</b></span></p>
      <p className="p3"><span className="s1">The Principal, an independent professional running a business under the small business scheme (auto-entrepreneur scheme) or in the form of a company, and in any event running a business registered with a French SIREN or SIRET business number, or a registration number allocated by the administrative authorities of the country of the business’s registered office, hereby authorises the Agent:</span></p>
      <p className="p4"><span className="s2">SAS Wall-Market<br/>
      </span>22 rue Legendre 75017 Paris<br/>Paris Trade and Companies Register No. Paris 532 727 740</p>
      <p className="p3"><span className="s1">to issue invoices in the Principal’s name and on its behalf for the services provided on behalf of Wall-Market.</span></p>
      <p className="p5">This invoicing authorisation is granted to the Agent when it accepts the “Account User Agreement”, by ticking the relevant box.</p>
      <p className="p3"><b>2. <span className="Apple-tab-span">	</span></b><span className="s1"><b>PRINCIPAL’S UNDERTAKINGS</b></span></p>
      <p className="p3"><span className="s1">The Principal retains full responsibility for its legal and tax obligations in terms of invoicing for the original invoices issued in the name and on behalf of the Principal under this authorisation, in particular with regard to its VAT obligations.</span></p>
      <p className="p3"><span className="s1">Accordingly, the Principal expressly undertakes to:</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Report to the tax authorities (or any appropriate authorities depending on its registration and/or residence) the VAT collected when it falls due,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Pay the VAT stated on the invoices issued under this invoicing authorisation to the appropriate administrative authorities,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Immediately request a duplicate invoice if one has not been made available to it by the Agent,</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Immediately notify to the Agent in writing any changes to the identification details of its business entity; and</span></p>
      <p className="p2"><span className="Apple-tab-span">	</span>•<span className="Apple-tab-span">	</span><span className="s1">Notify to the Agent, within one month of the date of communication of an invoice, any challenge to the content of the invoice.</span></p>
      <p className="p3"><b>3. <span className="Apple-tab-span">	</span></b><span className="s1"><b>GOVERNING LAW AND JURISDICTION</b></span></p>
      <p className="p3"><span className="s1">This authorisation is governed by and subject to French law. </span></p>
      <p className="p3"><span className="s1">The Parties agree that any dispute relating to this Authorisation will be subject to the exclusive jurisdiction of the courts in Paris (France).</span></p>
      <p className="p6"><span className="s1"><b>VALID RECORD OF THE AUTHORISATION</b></span></p>
    </div>
  )
}

export default InvoiceMandate;
