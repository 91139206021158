const initialState = {
  users: {
    list: [],
    paging: null,
    isLoading: false,
    error: null,
    users_actions: {
      link: {
        isLoading: false,
        error: null
      }
    }
  },
  informations: {
    isLoading: false,
    error: null
  },
}

const companyReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'UPDATE_COMPANY_INFORMATIONS':
      return {
        ...state,
        informations: {
          isLoading: true,
          error: null
        }
      }
    case 'UPDATE_COMPANY_INFORMATIONS_SUCCESS':
      return {
        ...state,
        informations: {
          isLoading: false,
          error: null
        }
      }
    case 'UPDATE_COMPANY_INFORMATIONS_FAILURE':
      return {
        ...state,
        informations: {
          isLoading: false,
          error: action.error
        }
      }
    case 'GET_COMPANY_USERS': {
      const userList = action.next ? state.users.list : [];

      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
          list: userList
        }
      }
    }
    case 'GET_COMPANY_USERS_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          list: action.data,
          paging: action.paging,
          isLoading: false,
          error: null
        }
      }
    case 'GET_COMPANY_USERS_FAILURE':
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          error: action.error
        }
      }
    case 'UPDATE_COMPANY_USERS': {
      const updated_users = state.users.list.concat(action.data);

      return {
        ...state,
        users: {
          ...state.users,
          list: updated_users,
          error: null,
          isLoading: false,
          paging: action.paging ? action.paging : state.paging
        }
      }
    }
    case "LINK_COMPANY_USERS":
      return {
        ...state,
        users: {
          ...state.users,
          users_actions: {
            ...state.users_actions,
            link: {
              isLoading: true,
              error: null
            }
          }
        }
      }
    case "LINK_COMPANY_USERS_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          users_actions: {
            ...state.users_actions,
            link: {
              isLoading: false,
              error: null
            }
          }
        }
      }
    case "LINK_COMPANY_USERS_FAILURE":
      return {
        ...state,
        users: {
          ...state.users,
          users_actions: {
            ...state.users_actions,
            link: {
              isLoading: false,
              error: action.error
            }
          }
        }
      }

    default:
      return state
  }
}

export default companyReducer;
