const initialState = {
  currentView: "week",
  currentTimeRange: {},
  loading: false,
  error: null,
  availabilities: {
    delete: {
      loading: false,
      error: null,
    },
    create: {
      loading: false,
      error: null,
    },
    edit: {
      loading: false,
      error: null,
    },
    loading: false,
    error: null
  },
  unavailabilities: {
    delete: {
      loading: false,
      error: null,
    },
    create: {
      loading: false,
      error: null,
    },
    edit: {
      loading: false,
      error: null,
    },
    loading: false,
    error: null
  },
  events: {
    list: [],
    loading: false,
    error: null
  },
  hash: {
    value: null,
    loading: false,
    error: null
  },
  users: {
    list: [],
    paging: null,
    isLoading: false,
    error: null,
    selected: null
  },
}

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
   case 'CLEAR_REDUCERS':
     return initialState;
   case 'CLEAR_SCHEDULE':
     return initialState;
    case 'STORE_CURRENT_VIEW':
      return {
        ...state,
        currentView: action.data
      }
    case 'STORE_CURRENT_TIME_RANGE':
      return {
        ...state,
        currentTimeRange: action.data
      }
    case 'GET_PROVIDER_CALENDAR': {
      return {
        ...state,
        events: {
          list: [],
          loading: true,
          error: null,
        }
      }
    }
    case 'GET_PROVIDER_CALENDAR_SUCCESS': {
      return {
        ...state,
        events: {
          list: action.data,
          loading: false,
          error: null,
        }
      }
    }
    case 'GET_PROVIDER_CALENDAR_FAILURE': {
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          error: action.error,
        }
      }
    }

    /**
     * Availabilities actions
     */
    case 'POST_AVAILABILITY':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          loading: true,
          create: {
            loading: true,
            error: null,
          }
        }
      }
    case 'POST_AVAILABILITY_SUCCESS': {
      const newAvailabilities = state.events.list.concat(action.data);

      return {
        ...state,
        events: {
          ...state.events,
          list: newAvailabilities,
        },
        availabilities: {
          ...state.availabilities,
          loading: false,
          create: {
            loading: false,
            error: null,
          }
        }
      }
    }
    case 'POST_AVAILABILITY_FAILURE':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          loading: false,
          create: {
            loading: false,
            error: action.error,
          }
        }
      }

    case 'PUT_AVAILABILITY':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          edit: {
            loading: true,
            error: null,
          }
        }
      }
    case 'PUT_AVAILABILITY_SUCCESS': {

      let eventsUpdated = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdated[event]['id'] === action.id)
          eventsUpdated[event] = action.data
      }

      return {
         ...state,
          events: {
            ...state.events,
            list: eventsUpdated,
          },
          availabilities: {
            ...state.availabilities,
            edit: {
              loading: false,
              error: null,
            }
          }
        }
      }
    case 'PUT_AVAILABILITY_FAILURE':
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
        },
        availabilities: {
          ...state.availabilities,
          edit: {
            loading: false,
            error: null,
          }
        }
      }
    case 'DELETE_AVAILABILITY':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          delete: {
            loading: true,
            error: null,
          }
        }
      }
    case 'DELETE_AVAILABILITY_SUCCESS': {

      const eventsAfterDelete = state.events.list.filter((el) => {
        return (el.id !== action.id)
      });

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsAfterDelete,
        },
        availabilities: {
          ...state.availabilities,
          delete: {
            loading: false,
            error: null,
          }
        }
      }
    }
    case 'DELETE_AVAILABILITY_FAILURE':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          delete: {
            loading: false,
            error: action.error,
          }
        }
      }

    /**
     * Unavailabilities actions
     */
    case 'POST_UNAVAILABILITY':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          loading: true,
          create: {
            loading: true,
            error: null,
          }
        }
      }
    case 'POST_UNAVAILABILITY_SUCCESS': {
      const newEvents = state.events.list.concat(action.data);

      return {
        ...state,
        events: {
          ...state.events,
          list: newEvents,
        },
        unavailabilities: {
          ...state.unavailabilities,
          loading: false,
          create: {
            loading: false,
            error: null,
          }
        }
      }
    }
    case 'POST_UNAVAILABILITY_FAILURE':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          loading: false,
          create: {
            loading: false,
            error: action.error,
          }
        }
      }
    case 'PUT_UNAVAILABILITY':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          edit: {
            loading: true,
            error: null,
          }
        }
      }
    case 'PUT_UNAVAILABILITY_SUCCESS': {

      let eventsUpdatedUna = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdatedUna[event]['id'] === action.id)
          eventsUpdatedUna[event] = action.data
      }

      return {
         ...state,
          events: {
            ...state.events,
            list: eventsUpdatedUna,
          },
          unavailabilities: {
            ...state.unavailabilities,
            edit: {
              loading: false,
              error: null,
            }
          }
        }
      }
    case 'PUT_UNAVAILABILITY_FAILURE':
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
        },
        unavailabilities: {
          ...state.unavailabilities,
          edit: {
            loading: false,
            error: null,
          }
        }
      }
    case 'DELETE_UNAVAILABILITY':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          delete: {
            loading: true,
            error: null,
          }
        }
      }
    case 'DELETE_UNAVAILABILITY_SUCCESS': {

      const eventsAfterDeleteU = state.events.list.filter((el) => {
        return (el.id !== action.id)
      });

      return {
          ...state,
          events: {
              ...state.events,
              list: eventsAfterDeleteU,
          },
          unavailabilities: {
              ...state.unavailabilities,
              delete: {
                  loading: false,
                  error: null,
              }
          }
      }
    }
    case 'DELETE_UNAVAILABILITY_FAILURE':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          delete: {
            loading: false,
            error: action.error,
          }
        }
      }
    case 'GET_HASH':
      return {
        ...state,
        hash: {
          value: null,
          loading: true,
          error: null,
        }
      }
    case 'GET_HASH_SUCCESS':
      return {
        ...state,
        hash: {
          value: action.data,
          loading: false,
          error: null,
        }
      }
    case 'GET_HASH_FAILURE':
      return {
        ...state,
        hash: {
          ...state.hash,
          loading: false,
          error: action.error,
        }
      }
    case 'SELECT_SCHEDULE_COMPANY_USER':
      return {
        ...state,
        users: {
          ...state.users,
          selected: action.selected
        }
      }
    case 'GET_COMPANY_USERS_SCHEDULE': {
      const userList = action.next ? state.users.list : [];

      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
          list: userList
        }
      }
    }
    case 'GET_COMPANY_USERS_SCHEDULE_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          list: action.data,
          paging: action.paging,
          isLoading: false,
          error: null
        }
      }
    case 'GET_COMPANY_USERS_SCHEDULE_FAILURE':
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          error: action.error
        }
      }
    case 'UPDATE_COMPANY_USERS_SCHEDULE': {
      const updated_users = state.users.list.concat(action.data);

      return {
        ...state,
        users: {
          ...state.users,
          list: updated_users,
          error: null,
          isLoading: false,
          paging: action.paging ? action.paging : state.paging
        }
      }
    }
    default:
      return state
  }
}

export default scheduleReducer;
