import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const styles = () => ({
  root: {
    width: "100%",
    overflowX: "auto",
    maxHeight: 375,
    marginTop: 15,
  },
  head: {
    backgroundColor: grey[200],
  },
  title: {
    color: "black",
    fontWeight: 600,
  },
});

function Products(props) {
  const { classes, products } = props;

  return (
    <Paper className={classes.root} elevation={0}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell align="left" className={classes.title}>
              Nom du produit
            </TableCell>
            <TableCell align="left" className={classes.title}>
              Radius
            </TableCell>
            <TableCell align="left" className={classes.title}>
              Taux horaire
            </TableCell>
            <TableCell align="left" className={classes.title}>
              Indemnités kilométrique
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell align="left">
                {product.product && product.product.name
                  ? product.product.name
                  : "-"}
              </TableCell>
              <TableCell align="left">
                {product.radius ? product.radius : "-"}
              </TableCell>
              <TableCell align="left">
                {product.reversion_amount ? product.reversion_amount : "-"}
              </TableCell>
              <TableCell align="left">
                {product.trip_compensation ? product.trip_compensation : "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

Products.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      radius: PropTypes.number.isRequired,
      reversion_amount: PropTypes.number.isRequired,
      trip_compensation: PropTypes.number.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default withStyles(styles)(Products);
