import React from "react";
import PropTypes from "prop-types";

import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

function MuiSelectField(props) {
  function handleChange(event, index, value) {
    const { input, onChange } = props;

    input.onChange(value);
    onChange && onChange();
  }

  const {
    id,
    input,
    label,
    disabled,
    validate,
    fullWidth,
    displayEmpty,
    style,
    onBlurProps,
    native,
    meta: { touched, error },
    children,
  } = props;

  return (
    <React.Fragment>
      <Select
        id={id}
        disabled={disabled}
        displayEmpty={displayEmpty}
        validate={validate}
        label={label}
        native={native}
        style={fullWidth ? { ...style, width: "100%" } : { style }}
        error={touched && error ? true : false}
        inputProps={{
          ...input,
          onBlur: (e) => {
            input.onBlur(e);
            if (e && onBlurProps) onBlurProps(e);
          },
        }}
        onChange={handleChange}
        children={children}
      />
      {touched && error && (
        <FormHelperText error={true}>{error}</FormHelperText>
      )}
    </React.Fragment>
  );
}

MuiSelectField.propTypes = {
  id: PropTypes.any,
  input: PropTypes.any,
  label: PropTypes.any,
  validate: PropTypes.any,
  disabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  native: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
  onBlurProps: PropTypes.func,
  onChange: PropTypes.func,
  //eslint-disable-next-line
  style: PropTypes.shape(),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
  }),
};

MuiSelectField.defaultProps = {
  native: true,
  displayEmpty: false,
};

export default MuiSelectField;
