import { connect } from "react-redux";

import { fetchApi } from "../../helpers/functions/api";

import {
  getOrderProviders,
  getOrderProvidersSuccess,
  getOrderProvidersFailure,
} from "../../redux/actions/orderManagement/providers";

import { enqueueSnackbar } from "../../redux/actions/notificationsActions";

import translation from "../../translation/translation";

import ProviderDialog from "../../components/orderManagement/ProviderDialog";

const mapStateToProps = (state) => ({
  getLoading: state.orderManagement.providers.getLoading,
  updateLoading: state.orderManagement.providers.updateLoading,
  data: state.orderManagement.providers.data,
  error: state.orderManagement.providers.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderProviders() {
      dispatch(getOrderProviders());

      fetchApi(
        "get",
        "provider-companies/self/providers",
        null,
        null,
        null,
        (data) => {
          dispatch(getOrderProvidersSuccess(data));
        },
        (error) => {
          dispatch(getOrderProvidersFailure(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.provider.error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.provider.error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDialog);
