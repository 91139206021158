/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/self-closing-comp */

import React from "react";

function MainContract() {
  return (
    <div className="cgs-main-contract">
      <p className="p-cgs2">
        <b>SAS Wall-Market</b>, sociedad por acciones simplificada con un
        capital de 19 146 euros, con sede social en 22 rue Legendre 75017 París,
        titular del número de identificación único 532 727 740 del Registro
        Mercantil de París, representada por el señor Guillaume Brochut en
        calidad de presidente,
      </p>
      <p className="p-cgs2">
        En lo sucesivo denominada « <b>Wall-Market </b>»,
      </p>
      <p className="p-cgs3">
        <b>Artículo 1: OBJETO</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.1
        <span className="Apple-tab-span"> </span>El objeto de estas condiciones
        generales es especificar los compromisos y obligaciones de la SAS
        Wall-Market y del prestador de servicios (en lo sucesivo, el  
        <b>Prestador</b> »), para todo contrato de prestación de servicios
        celebrado entre el Prestador y Wall-Market (en lo sucesivo, el o los 
        <b>Servicios</b> »).
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.2
        <span className="Apple-tab-span"> </span>La actividad inicial de
        Wall-Market es el desarrollo de herramientas digitales, así como la
        oferta de servicios en línea (o a través de cualquier otro medio),
        destinados a facilitar la venta y alquiler de bienes inmuebles por parte
        de los profesionales del sector inmobiliario. Para ello, desde que se
        fundó, Wall-Market ha realizado inversiones sustanciales para
        incrementar su cartera de clientes profesionales y convertirse en un
        agente de referencia entre estos. A partir de esta experiencia,
        Wall-Market también ha desarrollado una gama de servicios en línea para
        particulares.{" "}
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.3
        <span className="Apple-tab-span"> </span>El Prestador de servicios es un
        profesional independiente que dirige su empresa bajo el esquema de
        pequeña empresa (esquema de auto empresario) o en forma de empresa y, en
        cualquier caso dirige una empresa registrada con número francés SIREN o
        SIRET, o un número de registro asignado por las autoridades del país.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>1.4
        <span className="Apple-tab-span"> </span>El Prestador tiene su propia
        actividad que se compromete a llevar a cabo con Wall-Market por cuenta
        de sus clientes (en lo sucesivo <b>Clientes(s)</b> »).
      </p>
      <p className="p-cgs3">
        <b>Clause 2: COMPROMISOS Y DECLARACIONES DEL PRESTADOR DE SERVICIOS</b>
      </p>
      <p className="p-cgs2">El prestador:</p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>reconoce que tiene contratada
        una póliza de seguro profesional que cubre la actividad profesional que
        emprende con Wall-Market y los riesgos que pueden producirse durante sus
        desplazamientos o la prestación de un Servicio;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>declara que no es un agente
        inmobiliario o no ejerce esta actividad por delegación;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>se compromete a cumplir todas
        las obligaciones estipuladas en el presente documento para prestar
        cualquier Servicio con Wall-Market, en particular las relativas a los
        datos personales;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>declara que nunca ha sido
        condenado, con arreglo a las leyes francesas sobre prescripción de
        penas, por un delito contra la vida, la dignidad, la personalidad, la
        integridad física o psicológica de una persona, o por daños a los bienes
        (p. ej.: robo, extorsión, discriminación, violación, agresión sexual,
        acoso moral o sexual, estafa, abuso de confianza);
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>se compromete a no subcontratar
        la prestación de un Servicio a un tercero salvo que Wall-Market acuerde
        previamente lo contrario por escrito;
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>declara que reconoce que es
        responsable de todas las cotizaciones sociales e impuestos que debe
        devengar;{" "}
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>declara que los datos
        comunicados a Wall-Market están actualizados;{" "}
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>se compromete a cumplir la
        normativa aplicable en materia de tratamiento de datos personales y, en
        particular, el Reglamento (UE) 2016/679 del Parlamento Europeo y del
        Consejo, de 27 de abril de 2016, aplicable a partir del 25 de mayo de
        2018; y
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>se abstendrá de contactar un
        Cliente de Wall-Market al que se presta un Servicio con el fin de
        ofrecerle, directa o indirectamente, la prestación de otro servicio (en
        particular del mismo tipo o relacionado), en el momento de su
        prestación.
      </p>
      <p className="p-cgs6">
        En ningún caso el Prestador podrá ofrecer a un consumidor que haya
        solicitado un Servicio para vender o alquilar su apartamento, los datos
        de contacto de un agente inmobiliario. Facilitar a un agente
        inmobiliario los datos de contacto de un consumidor que se encuentra en
        la situación descrita anteriormente también está prohibido por el
        presente y por la normativa relativa a los datos personales.
      </p>
      <p className="p-cgs6">
        En cualquier caso, el Prestador debe cumplir estrictamente la normativa
        en materia de derecho de consumo y datos personales.
      </p>
      <p className="p-cgs7">
        Estas declaraciones son una condición esencial del compromiso de
        Wall-Market.
      </p>
      <p className="p-cgs3">
        <b>Artículo 3: DEFINICIÓN DE LOS SERVICIOS</b>
      </p>
      <p className="p-cgs7">
        El o los Servicios objeto del presente Contrato son aquellos para los
        que el Prestador:
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>ofreció sus servicios al darse
        de alta en su espacio personal puesto a disposición por Wall-Market, de
        acuerdo con sus habilidades profesionales; o{" "}
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>aceptó una solicitud de
        prestación de servicios por parte de Wall-Market, de acuerdo con sus
        habilidades profesionales, fuera de cualquier período que pudiera haber
        indicado.{" "}
      </p>
      <p className="p-cgs3">
        <b>Artículo 4: CONDICIONES DE PRESTACIÓN DE UN SERVICIO </b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.1
        <span className="Apple-tab-span"> </span>El Prestador asume, con total
        independencia, el control y responsabilidad de los Servicios que se ha
        propuesto realizar.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.2
        <span className="Apple-tab-span"> </span>El Prestador se compromete a
        realizar cualquier Servicio de acuerdo con:{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>la normativa vigente;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>los usos, en particular los
        relacionados con su actividad, con todo el cuidado y diligencia de un
        técnico y profesional de alto nivel; y{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>si procede, las
        especificaciones fijadas por Wall-Market.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>4.3
        <span className="Apple-tab-span"> </span>El Prestador también se
        compromete a realizar cualquier Servicio en la fecha y hora acordadas
        con Wall-Market.
      </p>
      <p className="p-cgs4">
        Ningún aplazamiento de la fecha u hora acordada puede tener lugar sin el
        consentimiento previo y expreso de Wall-Market, incluso si es a
        iniciativa del Cliente de Wall-Market. Por lo tanto, el Prestador no
        puede proponer el aplazamiento de un Servicio directamente al Cliente de
        Wall-Market.
      </p>
      <p className="p-cgs4">
        Si, debido a circunstancias excepcionales, el Prestador no puede
        realizar un Servicio en la fecha y hora acordadas, informará a
        Wall-Market sin demora. Si el Prestador de servicios no puede prestar un
        Servicio sin justificar su carácter excepcional, el contrato de
        prestación de servicios se rescindirá por iniciativa propia.
      </p>
      <p className="p-cgs9">
        <b>Artículo 5: ENTREGA</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>5.1
        <span className="Apple-tab-span"> </span>El Prestador reconoce haber
        sido informado de los términos que vinculan Wall-Market con sus
        Clientes, de acuerdo con las condiciones generales de venta de
        Wall-Market, en particular respecto a los plazos de entrega, y haber
        informado de su disponibilidad de acuerdo con esta limitación.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>5.2
        <span className="Apple-tab-span"> </span>Cualquier entrega de un
        Servicio debe hacerse a través de la Cuenta del Prestador.
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>5.3
          <span className="Apple-tab-span"> </span>Por consiguiente, el
          Prestador se compromete a entregar los archivos y/o los enlaces URL
          resultantes de la prestación de un Servicio lo antes posible y a más
          tardar, en los siguientes plazos:
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Para un Servicio que implique
          una visita al cliente o a su poderdante:
        </b>{" "}
        entrega lo antes posible y a más tardar dentro de las 16 horas
        siguientes a la hora en que el Prestador realizó el Servicio en la
        propiedad del cliente de Wall-Market;
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Para un Servicio realizado
          desde el lugar de trabajo del Prestador (p. ej., postproducción):
        </b>{" "}
        en el plazo de 4 horas después de recibir el pedido de Wall-Market.{" "}
      </p>
      <p className="p-cgs3">
        <b>Artículo 6: PRECIOS, CONDICIONES DE PAGO Y FACTURACIÓN </b>
      </p>
      <p className="p-cgs4">
        <b>
          6.1<span className="Apple-tab-span"> </span>Precio
        </b>
      </p>
      <p className="p-cgs4">
        Por cada Servicio, se remunerará al Prestador con una cuota acordada con
        Wall-Market.
      </p>
      <p className="p-cgs4">
        El Prestador reconoce que ninguno de los Servicios está protegido por
        derechos de autor.
      </p>
      <p className="p-cgs4">
        En el caso de que impugne este análisis, el Prestador se compromete a no
        aceptar ningún Servicio en virtud del presente Contrato y a informar a
        Wall-Market por escrito lo antes posible, con el fin de explicar su
        posición. Cualquier desacuerdo de este tipo posterior a la aceptación
        del presente Contrato puede tener el efecto de suspender la prestación
        de los Servicios que se le han confiado.{" "}
      </p>
      <p className="p-cgs4">
        Si, a título excepcional, y a pesar del acuerdo de las Partes, se
        considera que un Servicio está amparado por el derecho de autor, el
        Prestador reconoce que la cuota acordada incluye la remuneración por la
        cesión de los derechos de autor, tal y como se define en el artículo 9
        posterior.{" "}
      </p>
      <p className="p-cgs4">
        <b>
          6.2<span className="Apple-tab-span"> </span>Condiciones de pago
        </b>
      </p>
      <p className="p-cgs4">Salvo acuerdo en contrario, todos los Servicios:</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>se facturarán a Wall-Market
        conforme al mandato de facturación el primer día del mes siguiente al
        Servicio; y
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Wall-Market los abonará
        automáticamente el octavo día del mes siguiente al mes en que se realizó
        el Servicio.
      </p>
      <p className="p-cgs9">
        <b>Artículo 7: RESCISIÓN Y CANCELACIÓN DEL CONTRATO</b>
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.1
          <span className="Apple-tab-span"> </span>Aparte de las hipótesis
          descritas a continuación, la aceptación por parte de Wall-Market de un
          período del Prestador para la prestación de un Servicio a cuenta de un
          Cliente se considerará un contrato y, como tal, será vinculante para
          las Partes.{" "}
        </b>
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.2
          <span className="Apple-tab-span"> </span>Cláusula resolutoria
        </b>
      </p>
      <p className="p-cgs4">
        El Servicio se rescindirá automáticamente, sin ninguna formalidad o
        aviso formal, si un Cliente cancela su pedido con Wall-Market más de
        seis (6) horas antes de su ejecución.{" "}
      </p>
      <p className="p-cgs4">
        Wall-Market se compromete a avisar al Prestador inmediatamente después
        de ser informado de la cancelación.
      </p>
      <p className="p-cgs4">
        <b>
          <span className="Apple-tab-span"> </span>7.3
          <span className="Apple-tab-span"> </span>Rescisión por parte de
          Wall-Market
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market puede rescindir una orden de Servicio en cualquier momento
        durante las 6 horas antes de su prestación. En tal caso, Wall-Market se
        compromete a compensar al Prestador una cuota acordada previamente con
        este último.
      </p>
      <p className="p-cgs3">
        <b>Artículo 8: PLAZO DE PRESCRIPCIÓN ABREVIADO</b>
      </p>
      <p className="p-cgs4">
        Con arreglo al artículo 2254 del Código Civil francés, las Partes
        acuerdan reducir el plazo de prescripción a un (1) año a partir de la
        fecha de prestación de un Servicio para cualquier desacuerdo relacionado
        con el Artículo 7 anterior, independientemente de su fundamento y de que
        se refiera a su validez o prestación.
      </p>
      <p className="p-cgs3">
        <b>Artículo 9: RESPONSABILIDAD</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>9.1
        <span className="Apple-tab-span"> </span>El Prestador será el único
        responsable de todo perjuicio causado a Wall-Market como resultado del
        incumplimiento por su parte de cualquiera de sus compromisos o
        declaraciones en virtud de estas condiciones generales.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>9.2
        <span className="Apple-tab-span"> </span>Por lo tanto, el Prestador es
        el único responsable de las circunstancias en las que se presta el
        Servicio y de cualquier lesión personal y/o daño material que pueda
        resultar.{" "}
      </p>
      <p className="p-cgs8">
        El Prestador se compromete así a asumir las pérdidas y costes asociados
        con cualquier recurso o reclamación de un Cliente de Wall-Market contra
        esta, debido a un Servicio realizado por el Prestador.
      </p>
      <p className="p-cgs8">
        El Prestador también se compromete a asumir cualquier perjuicio para
        Wall-Market que resulte de tal reclamación.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>9.3
        <span className="Apple-tab-span"> </span>Wall-Market se compromete a
        informar al Prestador de cualquier recurso o reclamación de uno de sus
        Clientes a este respecto.{" "}
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs3">
        <b>Artículo 10: PROPIEDAD INTELECTUAL</b>
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.1
        <span className="Apple-tab-span"> </span>Las Partes declaran y reconocen
        que ninguna de las obras resultantes de la prestación de un Servicio (en
        lo sucesivo, « <b>Obras</b> ») está, en lo que respecta al derecho
        positivo y la naturaleza del Servicio, protegida por los derechos de
        autor en la medida en que no se dan las condiciones necesarias para que
        se califiquen como obra original.
      </p>
      <p className="p-cgs4">
        Por consiguiente, toda Obra es propiedad exclusiva de Wall-Market, que
        es la única que puede decidir acerca de su uso.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.2
        <span className="Apple-tab-span"> </span>Si, como excepción, una Obra
        debe ser considerada como una obra sujeta a derechos de autor,
        Wall-Market y el Prestador acuerdan lo siguiente:
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Se entiende que la Obra
        resultante de un Servicio ha sido producida en interés exclusivo de
        Wall-Market y siempre se ha pretendido que forme parte del patrimonio
        intelectual de esta última para ser explotada a título exclusivo;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>El Prestador cede a
        Wall-Market, y declara que tiene la capacidad de hacerlo a título
        exclusivo, para todo el mundo y mientras estén vigentes los derechos de
        propiedad intelectual, los derechos de reproducción, adaptación,
        distribución, representación y explotación vinculados a las Obras, de
        acuerdo con la finalidad y los términos que se especifican a
        continuación;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Esta cesión se hace como
        contrapartida de la remuneración prevista en el artículo 6 anterior.{" "}
      </p>
      <p className="p-cgs10">Destino de la Cesión:</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>el ámbito de la
        comercialización y arrendamiento de bienes inmuebles, así como cualquier
        actividad relacionada con la representación o comunicación de un cliente
        de Wall-Market; y
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>el ámbito de la oferta de
        servicios para facilitar la venta y alquiler de bienes inmuebles.
      </p>
      <p className="p-cgs11">
        Con arreglo a las disposiciones del artículo L.131-3 del Código de
        Propiedad Intelectual francés, los derechos cedidos a Wall-Market
        incluyen en particular:
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Para el derecho de
        reproducción: el derecho a reproducir y hacer reproducir cualquier Obra,
        sin limitación de número, en su totalidad o en parte, cualquiera que sea
        el soporte (en particular en cualquier soporte digital, especialmente en
        los sitios web publicados por Wall-Market o sus aplicaciones, incluso en
        red) o los medios utilizados y por cualquier procedimiento, en
        particular los procedimientos informáticos conocidos o desconocidos
        hasta la fecha;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Para el derecho de adaptación:
        el derecho a adaptar, hacer adaptar la totalidad o parte de las Obras y
        en particular el derecho a traducirlas a cualquier lenguaje de
        programación;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Para el derecho de
        representación: el derecho, para la totalidad o parte de una Obra, de
        publicar, distribuir o hacer publicar o distribuir, de cualquier manera,
        por cualquier procedimiento, conocido o desconocido hasta la fecha, y en
        particular por todas las redes de telecomunicaciones y comunicación al
        público por medios electrónicos, en particular en el sentido de la Ley
        2004-575, de 21 de junio de 2004, de confianza en la economía digital,
        actuales y futuras, como Internet o las redes móviles, así como la
        radiodifusión por cualquier medio de telecomunicación, incluida la
        transmisión por medios hertzianos, cable o satélite, en todos los
        soportes mencionados en el presente artículo, en cualquier formato,
        directamente o a través de terceros. Este derecho incluye el derecho a
        comunicar o poner a disposición del público, por medios alámbricos o
        inalámbricos, para que todos puedan tener acceso a la o las Obras desde
        el lugar y en el momento que cada uno elija según el destino acordado;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>Para el derecho de explotación:
        el derecho a hacer una retrocesión a los Clientes, de la totalidad o una
        parte, en cualquier forma y en particular mediante una cesión, licencia
        o cualquier tipo de contrato, en cualquier forma, de la totalidad o
        parte de los derechos cedidos, de forma temporal o por todo el plazo en
        que esté vigente esta cesión.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.3
        <span className="Apple-tab-span"> </span>El Prestador garantiza
        expresamente a Wall-Market el pleno y completo disfrute de los derechos
        cedidos sobre las Obras en virtud del presente contrato.
      </p>
      <p className="p-cgs8">
        El Prestador de Servicios se compromete a indemnizar a Wall-Market por
        cualquier reclamación relacionada, de cualquier tercero que invoque la
        vulneración de cualquier derecho y, en particular, por todos los costes,
        gastos, sentencias en caso de juicio por falsificación, violación de la
        privacidad y/o competencia desleal y/o parasitaria interpuesta por
        cualquier tercero sobre la base de un derecho de personalidad, propiedad
        intelectual o industrial que le pertenezca o del que pueda hacer uso.
      </p>
      <p className="p-cgs4">
        <span className="Apple-tab-span"> </span>10.4
        <span className="Apple-tab-span"> </span>En cualquier caso, como las
        Obras se realizan en espacios privados, el Prestador no podrá
        utilizarlas para no infringir los derechos protegidos que llevan
        aparejados, salvo que haya obtenido el acuerdo previo por escrito de
        Wall-Market una vez esta haya obtenido el acuerdo previo y por escrito
        del propietario y/o ocupante del bien inmueble.{" "}
      </p>
      <p className="p-cgs3">
        <b>Artículo 11: DATOS PERSONALES</b>
      </p>
      <p className="p-cgs4">
        El Prestador se compromete a cumplir la normativa relativa a los datos
        personales.
      </p>
      <p className="p-cgs4">
        La finalidad de las presentes cláusulas es permitir que Wall-Market y el
        Prestador cumplan efectivamente sus obligaciones con arreglo a la
        normativa relativa a los datos personales.
      </p>
      <p className="p-cgs4">
        En todo caso, el Prestador de servicios se compromete a respetar
        estrictamente lo dispuesto en el Artículo 28 del Reglamento (EU)
        2016/679 sobre la protección de datos personales, del 27 de abril de
        2016, adjunto en el Anexo I, en su calidad de “proveedor“ de datos
        personales, bajo este reglamento Wall-Market es el “Responsable“.
      </p>
      <p className="p-cgs4">
        <b>
          Wall-Market señala al Prestador que no tiene la autorización de sus
          Clientes para utilizar sus datos personales con fines de prospección.
          Por consiguiente, ningún dato personal (p. ej., el número de teléfono)
          de un Cliente comunicado al Prestador para la prestación de un
          Servicio podrá ser utilizado por el Prestador con fines de
          prospección.{" "}
        </b>
      </p>
      <p className="p-cgs4">
        <b>11.1</b>
        <span className="Apple-tab-span"> </span>
        <b>Descripción del tratamiento objeto del Servicio</b>
      </p>
      <p className="p-cgs4">
        El Prestador está autorizado a realizar el tratamiento, en nombre de
        Wall-Market, de los datos personales necesarios para la prestación de
        los Servicios.
      </p>
      <p className="p-cgs4">
        Naturaleza de las operaciones realizadas sobre los datos: recogida y
        almacenamiento temporal de los datos del Cliente de Wall-Market para la
        prestación de un Servicio.
      </p>
      <p className="p-cgs4">Finalidad del tratamiento: prestar un Servicio.</p>
      <p className="p-cgs4">Los datos personales tratados se refieren a:</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>1.
        <span className="Apple-tab-span"> </span>La identidad del Cliente de
        Wall-Market y/o su poderdante/apoderado y cualquier información útil
        para la prestación de un Servicio, a saber: apellido, nombre, dirección
        de correo electrónico, dirección postal, número de teléfono, código de
        acceso a la dirección del lugar donde se realizará el Servicio y, si
        procede, profesión;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>2.
        <span className="Apple-tab-span"> </span>La naturaleza de las compras
        realizadas por el Cliente de Wall-Market en su sitio web y
        subcontratadas al Prestador.
      </p>
      <p className="p-cgs4">
        <b>
          11.2<span className="Apple-tab-span"> </span>Obligaciones del
          Prestador para con Wall-Market{" "}
        </b>
      </p>
      <p className="p-cgs4">El Prestador se compromete a:</p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>1.
        <span className="Apple-tab-span"> </span>efectuar el tratamiento de los
        datos con el único propósito objeto de este contrato;{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>2.
        <span className="Apple-tab-span"> </span>efectuar el tratamiento de los
        datos de acuerdo con su propósito.{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>3.
        <span className="Apple-tab-span"> </span>garantizar la confidencialidad
        de los datos personales tratados en el marco del presente contrato;
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>4.
        <span className="Apple-tab-span"> </span>garantizar que las personas
        autorizadas a efectuar el tratamiento de los datos personales en virtud
        del presente contrato:{" "}
      </p>
      <p className="p-cgs16">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>se comprometan a mantener la
        confidencialidad o están sujetas a una obligación legal apropiada de
        confidencialidad;
      </p>
      <p className="p-cgs16">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>reciban la formación necesaria
        en materia de protección de datos personales.
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>5.
        <span className="Apple-tab-span"> </span>tener en cuenta, en lo que
        respecta a sus herramientas, productos, aplicaciones o servicios, los
        principios de protección de datos desde la fase de diseño y de
        protección de datos por defecto.
      </p>
      <p className="p-cgs4">
        <b>
          11.3<span className="Apple-tab-span"> </span>Derecho de información de
          los interesados{" "}
        </b>
      </p>
      <p className="p-cgs4">
        Wall-Market se compromete a informar a su Cliente de las operaciones de
        tratamiento que pueden ser llevadas a cabo por Wall-Market o el
        Prestador antes de la recogida de datos.
      </p>
      <p className="p-cgs4">
        El Prestador debe informar a los empleados de Wall-Market de cualquier
        operación de tratamiento que les afecte antes o en el momento de la
        recogida de datos.
      </p>
      <p className="p-cgs4">
        <b>
          11.4<span className="Apple-tab-span"> </span>Ejercicio de los derechos
          individuales{" "}
        </b>
      </p>
      <p className="p-cgs4">
        En la medida de lo posible, el Prestador se compromete a ayudar a
        Wall-Market a cumplir su obligación de responder a las solicitudes de
        ejercicio de los derechos de los interesados: derecho de acceso,
        rectificación, cancelación y oposición, derecho a limitar el
        tratamiento, derecho a la portabilidad de los datos, derecho a no ser
        objeto de una decisión individual automatizada (incluida la elaboración
        de perfiles).{" "}
      </p>
      <p className="p-cgs4">
        Cuando los interesados solicitan al Prestador poder ejercer sus
        derechos, este se compromete a enviar esas solicitudes tan pronto como
        las reciba por correo electrónico a Wall-Market, a la siguiente
        dirección: rgpd@wall-market.com.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p-cgs4">
        <b>
          11.5<span className="Apple-tab-span"> </span>Notificación de
          violaciones de la seguridad de los datos personales{" "}
        </b>
      </p>
      <p className="p-cgs4">
        El Prestador se compromete a notificar a Wall-Market cualquier violación
        de la seguridad de los datos personales en un plazo máximo de 24 horas
        después de tener conocimiento de ella por correo electrónico (a la
        dirección de correo electrónico rgpd@wall-market.com) y teléfono (al
        siguiente número: +33 1 42 27 45 53.).{" "}
      </p>
      <p className="p-cgs4">
        Esta notificación irá acompañada de toda la documentación útil para que
        Wall-Market pueda, si procede, notificar la violación de la seguridad a
        la autoridad de control competente.
      </p>
      <p className="p-cgs4">
        <b>
          11.6<span className="Apple-tab-span"> </span>Medidas de seguridad de
          los datos
        </b>
      </p>
      <p className="p-cgs4">
        <b>El Prestador se compromete a aplicar las siguientes medidas:</b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Destruir cualquier
          información o datos relativos al Cliente de Wall-Market tras la
          entrega de un Servicio, puesto que dichos datos ya no serán necesarios
          una vez prestado un Servicio;
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Aplicar todas las medidas que
          permitan mantener de forma confidencial la información o datos
          relativos al Cliente de Wall-Market antes de su destrucción;
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Establecer un procedimiento
          para probar, analizar y evaluar regularmente la eficacia de las
          medidas técnicas y organizativas para garantizar la seguridad de
          cualquier tratamiento de datos en relación con un Servicio realizado
          en nombre de Wall-Market; y{" "}
        </b>
      </p>
      <p className="p-cgs8">
        <b>
          <span className="Apple-tab-span"> </span>•
          <span className="Apple-tab-span"> </span>Destruir los datos relativos
          a los empleados de Wall-Market en un plazo máximo de un (1) año
          después de la prestación de un Servicio en su nombre.
        </b>
      </p>
      <p className="p-cgs4">
        <b>
          11.7<span className="Apple-tab-span"> </span>Documentación
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p-cgs4">
        El Prestador se compromete a poner a disposición de Wall-Market la
        documentación necesaria para demostrar el cumplimiento de todas sus
        obligaciones.
      </p>
      <p className="p-cgs4">
        <b>
          11.8<span className="Apple-tab-span"> </span>Obligaciones de
          Wall-Market para con el Prestador
        </b>
      </p>
      <p className="p-cgs4">Wall-Market se compromete a: </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>proporcionar al Prestador,
        directamente o por medio de su Cliente, los datos necesarios para la
        prestación de un Servicio;{" "}
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>•
        <span className="Apple-tab-span"> </span>estar al día de sus
        obligaciones en materia de datos personales.
      </p>
      <p className="p-cgs3">
        <b>Artículo 12: CONFIDENCIALIDAD</b>
      </p>
      <p className="p-cgs11">
        12.1<span className="Apple-tab-span"> </span>Toda información no
        protegida por la normativa de datos personales que se obtenga en el
        curso de la prestación de un Servicio es confidencial y no puede ser
        compartida o revelada o comunicada a un tercero (incluido un Cliente)
        por el Prestador por ninguna razón.
      </p>
      <p className="p-cgs11">
        12.2<span className="Apple-tab-span"> </span>En el caso de que el
        cumplimiento de esta obligación resulte imposible, o que sea de interés
        para Wall-Market que la información sea revelada o comunicada a un
        tercero, el Prestador se compromete a informar a Wall-Market de ello
        para que pueda, si procede, liberar al Prestador de esta obligación de
        confidencialidad por escrito.{" "}
      </p>
      <p className="p-cgs11">
        12.3<span className="Apple-tab-span"> </span>Debe especificarse que la
        información confidencial relativa al bien inmueble que es objeto del
        Servicio (y, si procede, al Cliente de Wall-Market, en el caso de que
        esta información no esté protegida por la normativa de datos personales)
        no puede ser comunicada, compartida o revelada a ninguna persona que no
        sea la que ha recibido al Prestador para la prestación de un Servicio
        y/o gestionado la prestación del mismo con el Prestador.{" "}
      </p>
      <p className="p-cgs11">
        Por ejemplo, las agencias inmobiliarias que operan bajo la misma marca
        no pueden ser consideradas como un mismo Cliente. Por consiguiente, la
        información relativa a un Servicio prestado para una agencia no puede
        comunicarse o compartirse con otra. El mismo análisis se aplica a los
        agentes de una misma agencia para la aplicación de esta cláusula.
      </p>
      <p className="p-cgs11">
        12.4<span className="Apple-tab-span"> </span>Esta obligación de
        confidencialidad permanecerá en vigor durante un período de tres (3)
        años después de la prestación de un Servicio.
      </p>
      <p className="p-cgs11">
        12.5<span className="Apple-tab-span"> </span>En el caso de que el
        Prestador contravenga este artículo o dañe directa o indirectamente la
        reputación de Wall-Market, esta se reserva el derecho de emprender
        cualquier acción legal para preservar sus derechos u obtener
        compensación por el perjuicio resultante.
      </p>
      <p className="p-cgs4">
        <b>Artículo 13: VARIOS</b>
      </p>
      <p className="p-cgs8">
        Al final de cada Servicio, el Prestador tendrá derecho a comentarlo, por
        ejemplo, compartiendo con el Cliente su evaluación de la prestación de
        los servicios de Wall-Market, así como la forma en que se llevó a cabo.{" "}
      </p>
      <p className="p-cgs4">
        <b>Artículo 14: DERECHO APLICABLE Y JURISDICCIÓN COMPETENTE</b>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>
        <b>14.1 El presente Contrato está sometido al derecho francés. </b>
      </p>
      <p className="p-cgs8">
        <span className="Apple-tab-span"> </span>
        <b>
          14.2 Todo desacuerdo relativo a la validez, la interpretación, la
          ejecución o el incumplimiento de las presentes condiciones, ya sea
          contractual o extracontractual, será de la exclusiva competencia
          territorial de los tribunales de París.
        </b>
      </p>
      <div style={{ marginTop: 100 }}>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Anexo I</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          Reproducción del artículo 28 del Reglamento (UE) 2016/679 del
          Parlamento Europeo y del Consejo de 27 de abril de 2016. <br />
          Para la construcción y aplicación de este artículo el Prestador es el
          “Proveedor“ y Wall-Market el “Responsable“.
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>CAPÍTULO IV</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Responsable del tratamiento y encargado del tratamiento</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Sección 1</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Obligaciones generales</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Artículo 28</b>
        </p>
        <p className="p-cgs2" style={{ textAlign: "center" }}>
          <b>Encargado del tratamiento</b>
        </p>
        <p className="p-cgs2">
          <b>
            1. Cuando se vaya a realizar un tratamiento por cuenta de un
            responsable del tratamiento, este elegirá únicamente un encargado
            que ofrezca garantías suficientes para aplicar medidas técnicas y
            organizativas apropiados, de manera que el tratamiento sea conforme
            con los requisitos del presente Reglamento y garantice la protección
            de los derechos del interesado.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            2. El encargado del tratamiento no recurrirá a otro encargado sin la
            autorización previa por escrito, específica o general, del
            responsable. En este último caso, el encargado informará al
            responsable de cualquier cambio previsto en la incorporación o
            sustitución de otros encargados, dando así al responsable la
            oportunidad de oponerse a dichos cambios.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            3. El tratamiento por el encargado se regirá por un contrato u otro
            acto jurídico con arreglo al Derecho de la Unión o de los Estados
            miembros, que vincule al encargado respecto del responsable y
            establezca el objeto, la duración, la naturaleza y la finalidad del
            tratamiento, el tipo de datos personales y categorías de
            interesados, y las obligaciones y derechos del responsable. Dicho
            contrato o acto jurídico estipulará, en particular, que el
            encargado:
          </b>
        </p>
        <p className="p-cgs4">
          a) tratará los datos personales únicamente siguiendo instrucciones
          documentadas del responsable, inclusive con respecto a las
          transferencias de datos personales a un tercer país o una organización
          internacional, salvo que esté obligado a ello en virtud del Derecho de
          la Unión o de los Estados miembros que se aplique al encargado; en tal
          caso, el encargado informará al responsable de esa exigencia legal
          previa al tratamiento, salvo que tal Derecho lo prohíba por razones
          importantes de interés público;
        </p>
        <p className="p-cgs4">
          b) garantizará que las personas autorizadas para tratar datos
          personales se hayan comprometido a respetar la confidencialidad o
          estén sujetas a una obligación de confidencialidad de naturaleza
          estatutaria;
        </p>
        <p className="p-cgs4">
          c) tomará todas las medidas necesarias de conformidad con el artículo
          32;
        </p>
        <p className="p-cgs4">
          d) respetará las condiciones indicadas en los apartados 2 y 4 para
          recurrir a otro encargado del tratamiento;
        </p>
        <p className="p-cgs4">
          e) asistirá al responsable, teniendo cuenta la naturaleza del
          tratamiento, a través de medidas técnicas y organizativas apropiadas,
          siempre que sea posible, para que este pueda cumplir con su obligación
          de responder a las solicitudes que tengan por objeto el ejercicio de
          los derechos de los interesados establecidos en el capítulo III;
        </p>
        <p className="p-cgs4">
          f) ayudará al responsable a garantizar el cumplimiento de las
          obligaciones establecidas en los artículos 32 a 36, teniendo en cuenta
          la naturaleza del tratamiento y la información a disposición del
          encargado;
        </p>
        <p className="p-cgs4">
          g) a elección del responsable, suprimirá o devolverá todos los datos
          personales una vez finalice la prestación de los servicios de
          tratamiento, y suprimirá las copias existentes a menos que se requiera
          la conservación de los datos personales en virtud del Derecho de la
          Unión o de los Estados miembros;
        </p>
        <p className="p-cgs4">
          h) pondrá a disposición del responsable toda la información necesaria
          para demostrar el cumplimiento de las obligaciones establecidas en el
          presente artículo, así como para permitir y contribuir a la
          realización de auditorías, incluidas inspecciones, por parte del
          responsable o de otro auditor autorizado por dicho responsable.
        </p>
        <p className="p-cgs4">
          En relación con lo dispuesto en la letra h) del párrafo primero, el
          encargado informará inmediatamente al responsable si, en su opinión,
          una instrucción infringe el presente Reglamento u otras disposiciones
          en materia de protección de datos de la Unión o de los Estados
          miembros.
        </p>

        <p className="p-cgs2">
          <b>
            4. Cuando un encargado del tratamiento recurra a otro encargado para
            llevar a cabo determinadas actividades de tratamiento por cuenta del
            responsable, se impondrán a este otro encargado, mediante contrato u
            otro acto jurídico establecido con arreglo al Derecho de la Unión o
            de los Estados miembros, las mismas obligaciones de protección de
            datos que las estipuladas en el contrato u otro acto jurídico entre
            el responsable y el encargado a que se refiere el apartado 3, en
            particular la prestación de garantías suficientes de aplicación de
            medidas técnicas y organizativas apropiadas de manera que el
            tratamiento sea conforme con las disposiciones del presente
            Reglamento. Si ese otro encargado incumple sus obligaciones de
            protección de datos, el encargado inicial seguirá siendo plenamente
            responsable ante el responsable del tratamiento por lo que respecta
            al cumplimiento de las obligaciones del otro encargado.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            5. La adhesión del encargado del tratamiento a un código de conducta
            aprobado a tenor del artículo 40 o a un mecanismo de certificación
            aprobado a tenor del artículo 42 podrá utilizarse como elemento para
            demostrar la existencia de las garantías suficientes a que se
            refieren los apartados 1 y 4 del presente artículo.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            6. Sin perjuicio de que el responsable y el encargado del
            tratamiento celebren un contrato individual, el contrato u otro acto
            jurídico a que se refieren los apartados 3 y 4 del presente artículo
            podrá basarse, total o parcialmente, en las cláusulas contractuales
            tipo a que se refieren los apartados 7 y 8 del presente artículo,
            inclusive cuando formen parte de una certificación concedida al
            responsable o encargado de conformidad con los artículos 42 y 43.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            7. La Comisión podrá fijar cláusulas contractuales tipo para los
            asuntos a que se refieren los apartados 3 y 4 del presente artículo,
            de acuerdo con el procedimiento de examen a que se refiere el
            artículo 93, apartado 2.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            8. Una autoridad de control podrá adoptar cláusulas contractuales
            tipo para los asuntos a que se refieren los apartados 3 y 4 del
            presente artículo, de acuerdo con el mecanismo de coherencia a que
            se refiere el artículo 63.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            9. El contrato u otro acto jurídico a que se refieren los apartados
            3 y 4 constará por escrito, inclusive en formato electrónico.
          </b>
        </p>
        <p className="p-cgs2">
          <b>
            10. Sin perjuicio de lo dispuesto en los artículos 82, 83 y 84, si
            un encargado del tratamiento infringe el presente Reglamento al
            determinar los fines y medios del tratamiento, será considerado
            responsable del tratamiento con respecto a dicho tratamiento.
          </b>
        </p>
      </div>
    </div>
  );
}

export default MainContract;
