import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 12,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 12,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  menuItem: {
    fontSize: 14,
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.shape(),
  innerProps: PropTypes.shape(),
  children: PropTypes.node,
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.any
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

Control.propTypes = {
  selectProps: PropTypes.shape(),
  innerRef: PropTypes.any,
  children: PropTypes.node,
  innerProps: PropTypes.any,
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      className={props.selectProps.classes.menuItem}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  selectProps: PropTypes.shape(),
  innerRef: PropTypes.any,
  innerProps: PropTypes.any,
  children: PropTypes.node,
  isFocused: PropTypes.any,
  isSelected: PropTypes.any,
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  selectProps: PropTypes.shape(),
  innerProps: PropTypes.any,
  children: PropTypes.node,
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      singleValue: PropTypes.any
    })
  }),
  innerProps: PropTypes.any,
  children: PropTypes.any
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      valueContainer: PropTypes.any
    })
  }),
  children: PropTypes.any
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.any
  }),
  innerProps: PropTypes.any,
  children: PropTypes.any
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AutoCompleteField extends Component {

  constructor(props) {
    super(props);

    this.state = { value: null }

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  UNSAFE_componentWillMount () {

    const { defaultValue } = this.props;

    if (defaultValue && typeof defaultValue === 'object')
      this.setState({ value: defaultValue });
  }

  handleChange(value) {
    this.setState({ value });

    const { onSelectValue } = this.props;

    if (onSelectValue && typeof onSelectValue === 'function')
      onSelectValue(value)
  }

  handleInputChange(value) {

    const { onInputChange } = this.props;

    if (onInputChange && typeof onInputChange === 'function')
      onInputChange(value)
  }

  getOptionValue(option) {

    if (option.id)
      return option.id
  }

  getOptionLabel(option) {
    if (option.name)
      return option.name
  }

  render() {

    const {
      classes,
      isDisabled,
      isClearable,
      name,
      placeholder,
      suggestions,
      theme,
    } = this.props;

    const { value } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        fontSize: 12,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            name={name}
            classes={classes}
            styles={selectStyles}
            options={suggestions}
            isDisabled={isDisabled}
            components={components}
            value={value}
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            placeholder={placeholder}
            isClearable={isClearable}
            getOptionValue={this.getOptionValue}
            getOptionLabel={this.getOptionLabel}
          />
        </NoSsr>
      </div>
    );
  }
}

AutoCompleteField.propTypes = {
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  onSelectValue: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.shape().isRequired,
  theme: PropTypes.shape().isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  suggestions: PropTypes.arrayOf(
    PropTypes.shape().isRequired,
  ).isRequired,
};

export default withStyles(styles, { withTheme: true })(AutoCompleteField);
