import { connect } from "react-redux";

import { fetchApi } from "../../helpers/functions/api";

import {
  getOrderInvoiceAddresses,
  getOrderInvoiceAddressesSuccess,
  getOrderInvoiceAddressesFailure,
} from "../../redux/actions/orderManagement/invoiceAddress";

import { enqueueSnackbar } from "../../redux/actions/notificationsActions";

import translation from "../../translation/translation";

import InvoiceAddressDialog from "../../components/orderManagement/InvoiceAddressDialog";

const mapStateToProps = (state) => ({
  getLoading: state.orderManagement.invoiceAddress.getLoading,
  updateLoading: state.orderManagement.invoiceAddress.updateLoading,
  data: state.orderManagement.invoiceAddress.data,
  error: state.orderManagement.invoiceAddress.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderInvoiceAddresses() {
      dispatch(getOrderInvoiceAddresses());

      fetchApi(
        "get",
        "users/self/addresses/usable",
        null,
        null,
        null,
        (data) => {
          dispatch(getOrderInvoiceAddressesSuccess(data));
        },
        (error) => {
          dispatch(getOrderInvoiceAddressesFailure(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.invoice_address.error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.invoice_address.error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceAddressDialog);
