import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: blue[500],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 5,
  },
  messageContainer: {
    marginLeft: 7,
    display: 'flex',
    flexDirection: 'column'
  },
  messageItem: {
    marginBottom: 0,
    marginTop: 0,
    marginRight: 'auto'
  },
  messageItemSplitted: {
    marginBottom: 5,
    marginTop: 0,
    marginRight: 'auto'
  }
});

function CustomSnackbarContent(props) {

  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span
          style={{display: 'flex', alignItems: 'center'}}
          id="client-snackbar"
        >
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <div className={classes.messageContainer}>
            {
              message.split('\n').map((item, key) => {
                return <p className={message.split('\n').length > 1 ? classes.messageItemSplitted : classes.messageItem} key={key}>{item}</p>
              })
            }
          </div>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

CustomSnackbarContent.propTypes = {
  classes: PropTypes.shape(),
  className: PropTypes.func,
  message: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
}

const SnackbarContentWrapper = withStyles(styles)(CustomSnackbarContent);

class Notifier extends Component {

  render() {

    const {
      notifications,
      removeSnackbar
    } = this.props;

    return (
      <div>
        <Snackbar
          key={notifications.key}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={notifications.message ? true : false}
          autoHideDuration={5000}
          disableWindowBlurListener={true}
          ClickAwayListenerProps={{
            onClickAway: () => { return false }
          }}
          onClose={removeSnackbar}
        >
          <SnackbarContentWrapper
            onClose={removeSnackbar}
            variant={notifications && notifications.options && notifications.options.variant ? notifications.options.variant : 'info'}
            message={notifications.message ? notifications.message : ''}
          />
        </Snackbar>
      </div>
    );
  }
}

Notifier.propTypes = {
  removeSnackbar: PropTypes.func.isRequired,
  notifications: PropTypes.shape({
    key: PropTypes.any,
    options: PropTypes.shape(),
    message: PropTypes.string
  })
}

export default Notifier;
