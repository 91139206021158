import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailIcon from '@material-ui/icons/Email';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneIcon from '@material-ui/icons/Phone';

import translation from '../../translation/translation';
import { getContactEmail, getPhoneNumber } from '../../helpers/functions/cookies';

class Contact extends Component {

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose && typeof onClose === "function") {
      onClose();
    }
  }

  render() {
    const { open } = this.props;

    return (
      <div className="Contact">
        <Dialog
          open={open}
          scroll="paper"
          maxWidth="sm"
          aria-labelledby="contact-dialog"
          onClose={this.handleClose}
        >
          <DialogTitle id="contact-dialog" className="contact-title">{translation().contact.title}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{marginBottom: 30}}>
              {translation().contact.baseline}
            </DialogContentText>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={getContactEmail()}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary={getPhoneNumber()}
                  secondary={translation().contact.hours}
                />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions className="contact-actions">
            <Button
              onClick={this.handleClose}
              color="default"
            >
              {translation().contact.close}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

Contact.defaultProps = {
  open: false
};

Contact.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default Contact;
