import React from 'react';
import PropTypes from 'prop-types';

import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';

import { getUserTimezone } from '../../../helpers/functions/user';

import translation from '../../../translation/translation';

const ScheduleEventMonth = ({event}) => {
  return (
    <div className={event.className ? event.className : ''}>
      { event.title ? event.title : (event && event.type === 'unavailability' ? translation().schedule.unavailability.unavailability : "") }
      { event.type === "orderProduct" && event.timezone_code && (getUserTimezone() !== event.timezone_code) &&
        <ErrorSharpIcon className="timezone-warning" />
      }
    </div>
  );
}

ScheduleEventMonth.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.any,
    style: PropTypes.shape(),
    className: PropTypes.string,
    type: PropTypes.string,
    timezone_code: PropTypes.string
  }).isRequired
}

export default ScheduleEventMonth;
