import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RegisterForm from './form/RegisterForm';

import { getLocale } from '../../helpers/functions/cookies';

import config from '../../config';
import translation from '../../translation/translation';

class Register extends Component {

  render() {

    const {
      onCloseRegister,
      register,
      registerUser
    } = this.props;

    return (
      <div className="Register">
        <h2 style={{textAlign: 'center'}}>{translation().register.page_title}</h2>
        <RegisterForm
          onSubmit={registerUser}
          labels={translation().register.form.labels}
          buttonLabel={translation().register.form.buttons.submit}
          initialValues={{
            language_locale: getLocale(),
            timezone_code: config.default_timezone,
            currency_isocode: config.default_currency,
            unit_system: config.default_unit_system
          }}
          reduxProps={register}
        />
        <div style={{textAlign: 'center'}}>
          <div onClick={onCloseRegister} className="trigger-register-form login">
            {translation().register.form.buttons.back_to_login || "Back to login screen."}
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  onCloseRegister: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  register: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired
};

export default Register;
