import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { formatDate } from '../../helpers/functions/dates';
import { autoScrollTop } from '../../helpers/functions/jquery';

import ProfileForm from './forms/infos/ProfileForm';
import PasswordForm from './forms/password/PasswordForm';
import SettingsForm from './forms/settings/SettingsForm';

import translation from '../../translation/translation';

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = { tabOpen: 0 };

    this.tabArray = [
      'infos',
      "settings",
      "password"
    ];

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount () {

    autoScrollTop();

    const { history } = this.props;

    /**
     * Tabs micro routing
     */
    if (history && history.location && history.location.hash) {
      if (history.location.hash === "#infos")
        this.setState({ tabOpen: 0 });
      else if (history.location.hash === "#settings")
        this.setState({ tabOpen: 1 });
      else if (history.location.hash === "#password")
        this.setState({ tabOpen: 2 });
    }
  }

  handleChange(event, tabOpen) {
    this.setState({ tabOpen });
    window.location.hash = this.tabArray[tabOpen];
  }

  render() {

    const { tabOpen } = this.state;

    const {
      updatePassword,
      updateSettings,
      updateUser,
      profile: { informations, settings, password },
      user: { data }
    } = this.props;

    return (
      <div className="App-view">
        <div className="page-title">
          <h3>{translation().profile.page_title}</h3>
        </div>
        <div className="container container-mobile">
          <AppBar
            color="default"
            position="relative"
            elevation={0}
          >
            <Tabs
              centered={true}
              value={tabOpen}
              onChange={this.handleChange}
              indicatorColor="primary"
              scrollButtons="on"
            >
              <Tab label={<span className="tab-label">{translation().profile.tab_titles.personal_info}</span>} icon={<div className="tab-icon icon-connecte" />} />
              <Tab label={<span className="tab-label">{translation().profile.tab_titles.settings}</span>} icon={<div className="tab-icon icon-parametres" />} />
              <Tab label={<span className="tab-label">{translation().profile.tab_titles.password}</span>} icon={<div className="tab-icon icon-connexion" />} />
            </Tabs>
          </AppBar>
          <div className="App-tab-container Profile">
            { tabOpen === 0 &&
              <ProfileForm
                onSubmit={updateUser}
                informations={informations}
                labels={translation().profile.form.labels}
                buttonLabel={translation().profile.form.buttons.submit}
                initialValues={{
                  gender: data.gender,
                  first_name: data.first_name,
                  last_name: data.last_name,
                  mobile: data.mobile,
                  phone: data.phone,
                  email: data.email,
                  birthdayYear: formatDate(data.date_of_birth, 'YYYY'),
                  birthdayMonth: formatDate(data.date_of_birth, 'M'),
                  birthdayDay: formatDate(data.date_of_birth, 'D'),
                  date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
                  picture_url: data.picture_url ? data.picture_url : "",
                }}
              />
            }
            { tabOpen === 1 &&
              <SettingsForm
                onSubmit={updateSettings}
                settings={settings}
                labels={translation().profile.form.labels}
                buttonLabel={translation().profile.form.buttons.submit}
                initialValues={{
                  language_locale: data.language && data.language.locale ? data.language.locale : "",
                  currency_isocode: data.currency && data.currency.isocode ? data.currency.isocode : "",
                  residence_country_isocode: data.residence_country && data.residence_country.isocode ? data.residence_country.isocode : "",
                  citizen_country_isocode: data.citizen_country && data.citizen_country.isocode ? data.citizen_country.isocode : "",
                  timezone_code: data.timezone_code ? data.timezone_code : "",
                  unit_system: data.unit_system ? data.unit_system : "",
                }}
              />
            }
            { tabOpen === 2 &&
              <PasswordForm
                onSubmit={updatePassword}
                password={password}
                labels={translation().profile.form.labels}
                buttonLabel={translation().profile.form.buttons.submit}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape(),
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape().isRequired,
  }).isRequired,
  profile: PropTypes.shape({
    informations: PropTypes.shape().isRequired,
    settings: PropTypes.shape().isRequired,
    password: PropTypes.shape().isRequired,
  }).isRequired,
}

export default Profile;
