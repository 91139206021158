import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';

/**
 * Setup the localizer by providing the moment (or globalize) Object
 * to the correct localizer.
 */
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

class ScheduleCalendar extends Component {

  constructor(props) {
    super(props);

    this.calendarRef = undefined;
    this.handleView = this.handleView.bind(this);

    this.setCalRef = element => {
      this.calendarRef = element;
    };
  }

  handleView(view) {

    if (!view) return;

    const {
      defaultDate,
      onNavigate,
      onView
    } = this.props;

    if (onNavigate)
      onNavigate(this.calendarRef && this.calendarRef._values && this.calendarRef._values.date ? this.calendarRef._values.date : defaultDate, view);

    if (onView)
      onView(view);

  }

  getEventProps(event) {
    if (event.type) {
      return {
        className: 'type-' + event.type
      }
    }
  }

  render() {

    const {
      messages,
      formats,
      events,
      components,
      onNavigate,
      selectSlot,
      onSelectEvent,
      minTime,
      maxTime,
      step,
      view,
      defaultView,
      timeslots,
      defaultDate,
      getDrilldownView,
      onRangeChange,
      readOnly
    } = this.props;

    return (
      <BigCalendar
        ref={this.setCalRef}
        components={components}
        messages={messages}
        formats={formats}
        step={step}
        onSelectEvent={onSelectEvent}
        onNavigate={onNavigate}
        onSelectSlot={selectSlot}
        min={minTime}
        max={maxTime}
        onRangeChange={onRangeChange}
        getDrilldownView={getDrilldownView}
        view={view}
        onView={this.handleView}
        timeslots={timeslots}
        events={events}
        eventPropGetter={this.getEventProps}
        defaultView={defaultView}
        views={['week', 'month', 'day']}
        defaultDate={defaultDate}
        selectable={readOnly ? false : "ignoreEvents"}
      />
    );
  }
}

ScheduleCalendar.propTypes = {
  messages: PropTypes.shape({
    previous: PropTypes.string,
    next: PropTypes.string,
    today: PropTypes.string,
    month: PropTypes.string,
    week: PropTypes.string,
    day: PropTypes.string,
    agenda: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
  }).isRequired,
  formats: PropTypes.shape({
    timeGutterFormat: PropTypes.string,
    dayFormat: PropTypes.string,
    dayHeaderFormat: PropTypes.string,
  }).isRequired,
  components: PropTypes.shape({
    week: PropTypes.shape(),
    day: PropTypes.shape(),
    month: PropTypes.shape(),
  }).isRequired,
  events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  view: PropTypes.string,
  onNavigate: PropTypes.func.isRequired,
  selectSlot: PropTypes.func.isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  getDrilldownView: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  defaultView: PropTypes.string.isRequired,
  timeslots: PropTypes.number.isRequired,
  defaultDate: PropTypes.any,
  minTime: PropTypes.any,
  maxTime: PropTypes.any,
  readOnly: PropTypes.bool,
}

export default ScheduleCalendar;
