import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';

import ToggleLabel from '../../../components/commons/ToggleLabel';

import CreateAddressForm from './forms/create/CreateAddressForm';
import EditAddressForm from './forms/edit/EditAddressForm';

import translation from '../../../translation/translation';

function IconButtonEditAddress(props) {

  function handleClick() {

    const {
      id,
      name,
      address,
      onOpen,
    } = props;

    onOpen({
      id,
      name,
      address
    });
  }

  return (
    <IconButton
      aria-label="editer"
      style={{padding: 7}}
      color='inherit'
      onClick={handleClick}
    >
      <span className="icon-editer" style={{fontSize: 14}} />
    </IconButton>
  );
}

IconButtonEditAddress.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  address: PropTypes.any.isRequired,
  onOpen: PropTypes.func.isRequired
}

function IconButtonDeleteAddress(props) {

  function handleClick() {

    const {
      id,
      onOpen,
    } = props;

    onOpen(id);
  }

  return (
    <IconButton
      aria-label="delete"
      style={{padding: 7}}
      color='inherit'
      onClick={handleClick}
    >
      <span className="icon-delete" style={{fontSize: 14}} />
    </IconButton>
  );
}

IconButtonDeleteAddress.propTypes = {
  id: PropTypes.any.isRequired,
  onOpen: PropTypes.func.isRequired
}

class Addresses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isCreateAddressDialogOpen: false,
      isDeleteAddressDialogOpen: false,
      isEditAddressDialogOpen: false,
      selected: null
    }

    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handlePostAddress = this.handlePostAddress.bind(this);
    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleDeleteAddress = this.handleDeleteAddress.bind(this);
    this.handleOpenCreateAddressDialog = this.handleOpenCreateAddressDialog.bind(this);
    this.handleCloseCreateAddressDialog = this.handleCloseCreateAddressDialog.bind(this);
    this.handleOpenDeleteAddressDialog =  this.handleOpenDeleteAddressDialog.bind(this);
    this.handleCloseDeleteAddressDialog = this.handleCloseDeleteAddressDialog.bind(this);
    this.handleOpenEditAddressDialog =  this.handleOpenEditAddressDialog.bind(this);
    this.handleCloseEditAddressDialog = this.handleCloseEditAddressDialog.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      companyAdmin,
      toggleAddressesMode
    } = this.props;

    if (companyAdmin)
      toggleAddressesMode('companies');
  }

  handleChangeMode(e, value) {

    const {
      toggleAddressesMode,
      addresses: { mode }
    } = this.props;

    if (mode && mode === "users") {

      if (!value)
        return;

      toggleAddressesMode('companies');
    }
    else if (mode && mode === "companies") {

      if (value)
        return;

      toggleAddressesMode('users');
    }
  }

  handleOpenCreateAddressDialog() {
    this.setState({isCreateAddressDialogOpen: true});
  }

  handleCloseCreateAddressDialog() {
    this.setState({isCreateAddressDialogOpen: false});
  }

  handleOpenEditAddressDialog(address) {

    if( !address) return;

    this.setState({
      isEditAddressDialogOpen: true,
      selected: address
    });
  }

  handleCloseEditAddressDialog() {
    this.setState({
      isEditAddressDialogOpen: false,
      selected: null
    });
  }

  handleOpenDeleteAddressDialog(id) {

    if( !id) return;

    this.setState({
      isDeleteAddressDialogOpen: true,
      selected: id
    });
  }

  handleCloseDeleteAddressDialog() {
    this.setState({
      isDeleteAddressDialogOpen: false,
      selected: null
    });
  }

  handlePostAddress(values) {

    const {
      createAddress,
      addresses: {
        mode
      }
    } = this.props;

    createAddress(
      values,
      mode,
      () => {
        this.handleCloseCreateAddressDialog();
      }
    );
  }

  handleEditAddress(values) {

    const {
      editAddress,
      addresses: {
        mode
      }
    } = this.props;

    const {
      selected
    } = this.state;

    if (!selected.id) return;

    editAddress(
      selected.id,
      values,
      mode,
      () => {
        this.handleCloseEditAddressDialog();
      }
    );
  }

  handleDeleteAddress() {

    const {
      deleteAddress,
      addresses: {
        mode
      }
    } = this.props;

    const {
      selected
    } = this.state;

    deleteAddress(
      selected,
      mode,
      () => {
        this.handleCloseDeleteAddressDialog();
      }
    );
  }

  renderTable(data) {

    if (data && data.length > 0 ) {

      const rows = [];

      for (let i = 0; i < data.length; i++) {
        let row = {};

        if (data[i].id)
          row['id'] = data[i].id

        if (data[i].name)
          row['name'] = data[i].name;
        else
          row['name'] = '-';

        row['address'] =
          (data[i].street_number ? data[i].street_number : '') +
          ' ' +
          (data[i].street ? data[i].street : '') +
          ' ' +
          (data[i].city ? data[i].city : '') +
          ' ' +
          (data[i].zip_code ? data[i].zip_code : '')

        rows.push(row);
      }

      return (
        <div className="table-wrapper">
          <Table style={{marginBottom: 50}}>
            <TableHead>
              <TableRow>
                <TableCell>{translation().invoices.addresses.table.name}</TableCell>
                <TableCell>{translation().invoices.addresses.table.address}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.map((row, k) => {
                  return (
                    <TableRow key={k}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell align='right' className="column-actions">
                        <IconButtonEditAddress
                          onOpen={this.handleOpenEditAddressDialog}
                          id={row.id}
                          name={row.name}
                          address={row.address}
                        />
                        <IconButtonDeleteAddress
                          onOpen={this.handleOpenDeleteAddressDialog}
                          id={row.id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {

    const {
      isCreateAddressDialogOpen,
      isDeleteAddressDialogOpen,
      isEditAddressDialogOpen,
      selected
    } = this.state;

    const {
      companyAdmin,
      user: { data },
      addresses: {
        create_action,
        delete_action,
        edit_action,
        mode
      }
    } = this.props;

    return (
      <div className="Addresses">
        <div className="section-controls">
          {
            companyAdmin &&
              <div className="toggle-mode">
                <ToggleLabel
                  label={translation().invoices.addresses.switch.personal}
                  value={false}
                  onClick={this.handleChangeMode}
                />
                <Switch
                  checked={mode === "users" ? false : true}
                  value={mode}
                  color="default"
                  onChange={this.handleChangeMode}
                />
                <ToggleLabel
                  label={translation().invoices.addresses.switch.pro}
                  value={true}
                  onClick={this.handleChangeMode}
                />
              </div>
          }
          <Fab
            color={mode === "users" ? "primary" : "secondary"}
            style={{marginLeft: companyAdmin ? '-48px' : 'auto'}}
            className="Fab-responsive-class-helper"
            onClick={this.handleOpenCreateAddressDialog}
            aria-label="create"
            size="medium"
          >
            <span className="icon-ajout_adresse" style={{fontSize: 25}} />
          </Fab>
        </div>
        {
          mode && mode === "users"
            ? data && data.addresses && data.addresses.length > 0
              ? this.renderTable(data.addresses)
              : <div className="data-empty">{translation().invoices.addresses.no_address}</div>
            : mode === "companies" && companyAdmin
              ? data && data.company && data.company.addresses && data.company.addresses.length > 0
                ? this.renderTable(data.company.addresses)
                : <div className="data-empty">{translation().invoices.addresses.no_address}</div>
              : <div className="data-empty">{translation().invoices.addresses.no_address}</div>
        }
        <Dialog
          aria-labelledby="create-address-dialog-title"
          aria-describedby="create-address-description"
          onClose={this.handleCloseCreateAddressDialog}
          open={isCreateAddressDialogOpen}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          scroll={"paper"}
        >
          <DialogTitle style={{textAlign: 'center'}} id="create-address-dialog-title">
            {translation().invoices.addresses.create.title}
          </DialogTitle>
          <DialogContent>
            {
              mode && mode === "users"
                ? <p className="mode-type-in-dialog">{translation().invoices.addresses.create.personal}</p>
                : mode && mode === "companies"
                  ? <p className="mode-type-in-dialog">{translation().invoices.addresses.create.pro}</p>
                  : ''
            }
            <CreateAddressForm
              onSubmit={this.handlePostAddress}
              mode={mode}
              createAction={create_action}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={create_action.loading}
              onClick={this.handleCloseCreateAddressDialog}
            >
              {translation().invoices.addresses.create.form.buttons.close}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="delete-address-dialog-title"
          aria-describedby="delete-address-description"
          onClose={this.handleCloseDeleteAddressDialog}
          open={isDeleteAddressDialogOpen}
          scroll={"paper"}
        >
          <DialogTitle id="delete-address-dialog-title">
            {translation().invoices.addresses.delete.title}
          </DialogTitle>
          <DialogContent>
            {translation().invoices.addresses.delete.description}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={delete_action.loading}
              onClick={this.handleCloseDeleteAddressDialog}
            >
              {translation().invoices.addresses.delete.form.buttons.cancel}
            </Button>
            <Button
              color={mode && mode === "companies" ? "secondary" : "primary"}
              style={{minWidth: 120}}
              disabled={delete_action.loading}
              variant='contained'
              onClick={this.handleDeleteAddress}
            >
              {
                delete_action.loading
                  ? <CircularProgress size={20} color='secondary' />
                  : <span>{translation().invoices.addresses.delete.form.buttons.submit}</span>
              }
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="edit-address-dialog-title"
          aria-describedby="edit-address-description"
          onClose={this.handleCloseEditAddressDialog}
          open={isEditAddressDialogOpen}
          scroll={"paper"}
        >
          <DialogTitle id="edit-address-dialog-title">
            {translation().invoices.addresses.edit.title}
          </DialogTitle>
          <DialogContent>
            {translation().invoices.addresses.edit.description}
            <EditAddressForm
              onSubmit={this.handleEditAddress}
              mode={mode}
              editAction={edit_action}
              initialValues={{
                address: selected && selected.address,
                name: selected && selected.name
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={edit_action.loading}
              onClick={this.handleCloseEditAddressDialog}
            >
              {translation().invoices.addresses.edit.form.buttons.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

Addresses.propTypes = {
  createAddress: PropTypes.func.isRequired,
  companyAdmin: PropTypes.bool.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  editAddress: PropTypes.func.isRequired,
  toggleAddressesMode: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape().isRequired
  }).isRequired,
  addresses: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    delete_action: PropTypes.shape().isRequired,
    create_action: PropTypes.shape().isRequired,
    edit_action: PropTypes.shape().isRequired
  }).isRequired,
}

export default Addresses;
