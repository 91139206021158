import translation from '../../../../translation/translation';

const validate = values => {

  const errors = {};

  if (!values.legal_name)
    errors.legal_name = translation().forms.errors.required;

  if (!values.commercial_name)
    errors.commercial_name = translation().forms.errors.required;

  return errors;
}

export default validate;
