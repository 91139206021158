import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MediaTypeForm from './MediaTypeForm';

class MediaGroupFrom extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
      filesUploadedCount: {},
      fileLimitMaxSum: 0,
      fileLimitMinSum: 0,
    };

    this.handleComplete = this.handleComplete.bind(this);
    this.renderMediaGroupForm = this.renderMediaGroupForm.bind(this);
    this.renderFilesRestrictions = this.renderFilesRestrictions.bind(this);
  }
  
  UNSAFE_componentWillMount () {

    const { mediaGroup } = this.props;

    let filesUploadedCount = {};
    let fileLimitMaxSum = null;
    let fileLimitMinSum = null;

    if (mediaGroup.quantity_min)
      fileLimitMinSum = mediaGroup.quantity_min;

    if (mediaGroup.quantity_max)
      fileLimitMaxSum = mediaGroup.quantity_max;

    if (mediaGroup.media_types && mediaGroup.media_types.length) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        if (mediaGroup.media_types[i].medias) {
          filesUploadedCount[mediaGroup.media_types[i].shortcode] = mediaGroup.media_types[i].medias.length;
        }
      }
    }

    this.setState({
      fileLimitMaxSum,
      fileLimitMinSum,
      filesUploadedCount
    });
  }

  checkIfMediaGroupHasForm(mediaGroup) {
    if (!mediaGroup) return false;

    let hasForm = false;

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {

      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        const mediaType = mediaGroup.media_types[i];

        if (mediaType.type && mediaType.type === "form") {
          hasForm = true;
          break;
        }
      }
    }

    return hasForm;
  }

  handleComplete(mediaTypeShortCode) {
    const { filesUploadedCount } = this.state;

    filesUploadedCount[mediaTypeShortCode] += 1;

    this.setState({ filesUploadedCount });
  }

  renderFilesRestrictions(mediaGroup) {

    const { filesUploadedCount } = this.state;

    if (!mediaGroup) return;

    let filesRestrictions = [];

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {

      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        const mediaType = mediaGroup.media_types[i];

        /**
         * Add class upload status to nb file counter
         */
        let filesUploadStatus = filesUploadedCount[mediaType.shortcode]  > 0 ? "pending" : "";

        if (filesUploadedCount[mediaType.shortcode] >= mediaType.quantity_min) {
          filesUploadStatus = "done";
        }

        if (mediaType.type && mediaType.type === "form") {
          filesRestrictions.push(
            <div className="files-infos" key={i}>
              <div className="nb-files">
                <span className={"counter " + filesUploadStatus}>{filesUploadedCount[mediaType.shortcode]}/{mediaType.quantity_min && mediaType.quantity_min}</span>
              </div>
              <div className="files-details">
                <span className={"file-img icon-file-type-" + (mediaType.type ? mediaType.type : "")} />
                <div className="restrictions">
                  { mediaType.name &&
                    <p className="name"><span>{mediaType.name}</span></p>
                  }
                </div>
              </div>
            </div>
          );
        }
      }
    }

    return filesRestrictions;
  }

  renderMediaGroupForm(mediaGroup) {
    if (!mediaGroup) return;

    const { productId } = this.props;

    let forms = [];

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        if ( mediaGroup.media_types[i].type &&  mediaGroup.media_types[i].type === "form") {
          forms.push(
            <MediaTypeForm
              key={i}
              mediaType={mediaGroup.media_types[i]}
              productId={productId}
              onComplete={this.handleComplete}
            />
          );
        }
      }
    }

    return forms;
  }

  render() {

    const { mediaGroup } = this.props;

    if (this.checkIfMediaGroupHasForm(mediaGroup)) {
      return (
        <div className="media-group">
          <div className="col-xs-12">
            { mediaGroup.name &&
              <p className="name">{mediaGroup.name}</p>
            }
            { this.renderFilesRestrictions(mediaGroup) }
            <div className="clearfix"/>
            { this.renderMediaGroupForm(mediaGroup) }
          </div>
          <div className="clearfix"/>
          <div className="product-separator" style={{margin: '15px 0'}}>
            <div className="first-sep" />
            <div className="second-sep" />
          </div>
        </div>
      );
    }
    else {
      return false;
    }
  }
}

MediaGroupFrom.propTypes = {
  mediaGroup: PropTypes.shape({
    name: PropTypes.any.isRequired,
    quantity_min: PropTypes.any,
    quantity_max: PropTypes.any,
    size_max: PropTypes.any,
    size_min: PropTypes.any,
    mime_types: PropTypes.any,
    extensions: PropTypes.any,
    media_types: PropTypes.any
  }),
  productId: PropTypes.any.isRequired
};

export default MediaGroupFrom;
