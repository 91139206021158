/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";

import CreateUnavailabilityForm from "../forms/unavailabilities/create/CreateUnavailabilityForm";

import translation from "../../../translation/translation";

function CreateUnavailabilityPopup(props) {
  function handleCreateUnavailability(values) {
    if (!values) return;

    const { createUnavailability } = props;

    createUnavailability(values);
  }

  function handleBlockBlur(e) {
    if (e) e.stopPropagation();
  }

  function handleClose() {
    const { onClose } = props;

    onClose();
  }

  const { createUnavailabilityLoading, maxHour, minHour } = props;

  return (
    <div className="event-popup">
      <div className="create-unavailability">
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Close"
              className="event-action"
              disabled={createUnavailabilityLoading}
              onClick={handleClose}
              onBlur={handleBlockBlur}
            >
              <span className="icon-fermer" />
            </IconButton>
          </div>
          <div className="event-title">
            {translation().schedule.unavailability.create.title}
          </div>
        </div>
        <div className="event-pp-content">
          <div className="new-unavailability">
            <CreateUnavailabilityForm
              onSubmit={handleCreateUnavailability}
              createAction={{
                loading: createUnavailabilityLoading,
              }}
              maxHour={maxHour}
              minHour={minHour}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

CreateUnavailabilityPopup.propTypes = {
  createUnavailability: PropTypes.func.isRequired,
  createUnavailabilityLoading: PropTypes.bool,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateUnavailabilityPopup;
