import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { generateHash } from '../../../helpers/functions/utils';
import { setMediaTypeProperty } from '../../../helpers/functions/redux';

import ApiAutoCompleteComponent from '../../../components/commons/fields/ApiAutoCompleteComponent';

import config from '../../../config';

class FileCard extends Component {

  constructor(props) {
    super(props);

    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleSelectValue = this.handleSelectValue.bind(this);
  }

  handleDeleteFile() {

    const {
      file,
      onDeleteFile
    } = this.props;

    if (file && onDeleteFile)
      onDeleteFile(file);

  }

  handleSelectValue(value) {

    const {
      file
    } = this.props;

    /**
     * Specific part of code for order product moderation
     */
    if (value && typeof value === 'object')
      setMediaTypeProperty(file.url, file.media_type.shortcode, 'media_template_id', value.id);
    else
      setMediaTypeProperty(file.url, file.media_type.shortcode, 'media_template_id', "null");

  }

  render() {

    const {
      file,
      index,
      inputPlaceholder,
    } = this.props;

    let imgPreviewStyle = null;

    if (file.thumbnail_url)
      imgPreviewStyle = { backgroundImage: 'url("' + ( (file.thumbnail_url.indexOf('?client_id=' + config.api_key)) !== -1 ? (file.thumbnail_url + '?client_id=' + config.api_key) : file.thumbnail_url) + '")' }
    else
      imgPreviewStyle = { backgroundImage: 'url("/assets/images/medias/file-extension-' + file.extension + '.png")' }

    return (
      <div className={"file-preview " + (file.extension ? file.extension : "")} key={index}>
        <div className="cover-actions">
          <IconButton
            size="small"
            aria-label="Delete"
            className="delete-file"
            onClick={this.handleDeleteFile}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="img-preview" style={imgPreviewStyle} />
        {
          file && file.media_type && file.media_type.has_templates &&
          <ApiAutoCompleteComponent
            id={"template-file-" + generateHash(file.url)}
            name={"template-file-" + generateHash(file.url)}
            placeholder={inputPlaceholder ? inputPlaceholder + "*" : "Search & select*"}
            defaultValue={
              file.media_template &&
              file.media_template.name &&
              file.media_template.id ?
                {
                  name: file.media_template.name,
                  id: file.media_template.id
                }
              : null
            }
            dataSourceRoute={"media-types/" + file.media_type.id + "/templates"}
            dataSourceParams={{ sort: 'name', limit: 5 }}
            isClearable={true}
            listStyle={{fontSize: '9px!important'}}
            menuStyle={{fontSize: '9px!important'}}
            onSelectValue={this.handleSelectValue}
            onDeleteValue={this.handleRemoveValue}
          />
        }
      </div>
    );
  }
}

FileCard.propTypes = {
  index: PropTypes.any,
  inputPlaceholder: PropTypes.string.isRequired,
  file: PropTypes.shape({
    media_type: PropTypes.shape(),
    url: PropTypes.string,
    thumbnail_url: PropTypes.string,
    extension: PropTypes.any,
    media_template: PropTypes.shape(),
  }).isRequired,
  onDeleteFile: PropTypes.func.isRequired
}

export default FileCard;
