/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";

import { getLocale } from "../../../helpers/functions/cookies";
import { pad } from "../../../helpers/functions/utils";

import CreateAvailabilityForm from "../forms/availabilities/create/CreateAvailabilityForm";

import translation from "../../../translation/translation";

function CreateAvailabilityPopup(props) {
  function handleCreateAvailability(values) {
    if (!values) return;

    const { createAvailability, slot } = props;

    if (slot) createAvailability(values, slot);
  }

  function handleBlockBlur(e) {
    if (e) e.stopPropagation();
  }

  const { createAvailabilityLoading, maxHour, minHour, onClose, slot } = props;

  const startHour = pad(slot.start.getHours());
  const startMinutes = pad(slot.start.getMinutes());
  const endHour = pad(slot.end.getHours());
  const endMinutes = pad(slot.end.getMinutes());

  return (
    <div className="event-popup">
      <div className="create-availability">
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Close"
              className="event-action"
              disabled={createAvailabilityLoading}
              onClick={onClose}
              onBlur={handleBlockBlur}
            >
              <span className="icon-fermer" />
            </IconButton>
          </div>
          <div className="event-title">
            {translation().schedule.availability.create.title}
          </div>
        </div>
        <div className="event-pp-content">
          <div className="new-availability">
            <span className="detail">
              <span className="icon-horaire date-icon" />
              <div className="detail-date">
                {slot.start.toLocaleDateString(getLocale(), {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })}{" "}
                <br />
              </div>
            </span>
          </div>
          <CreateAvailabilityForm
            onSubmit={handleCreateAvailability}
            onBlur={handleBlockBlur}
            minHour={minHour}
            maxHour={maxHour}
            initialValues={{
              hour_end: endHour.toString(),
              hour_start: startHour.toString(),
              min_end: endMinutes.toString(),
              min_start: startMinutes.toString(),
            }}
            createAction={{
              loading: createAvailabilityLoading,
            }}
          />
        </div>
      </div>
    </div>
  );
}

CreateAvailabilityPopup.propTypes = {
  createAvailability: PropTypes.func.isRequired,
  createAvailabilityLoading: PropTypes.bool,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  slot: PropTypes.shape({
    start: PropTypes.any.isRequired,
    end: PropTypes.any.isRequired,
  }).isRequired,
};

export default CreateAvailabilityPopup;
