/* eslint-disable react/jsx-no-bind */
import React, { Component } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";

import { handleInfiniteScroll } from "../../../helpers/functions/jquery";
import { getApiPaymentMethodIframe } from "../../../helpers/functions/api";

import ToggleLabel from "../../../components/commons/ToggleLabel";
import User from "./users/User";

import translation from "../../../translation/translation";

function IconButtonManage(props) {
  function handleClick() {
    const { id, onClick } = props;

    onClick(id);
  }

  return (
    <IconButton
      aria-label="manage"
      style={{ padding: 7 }}
      color="inherit"
      onClick={handleClick}
    >
      <span className="icon-connexion" style={{ fontSize: 14 }} />
    </IconButton>
  );
}

IconButtonManage.propTypes = {
  id: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};

function IconButtonDelete(props) {
  function handleClick() {
    const { id, onClick } = props;

    onClick(id);
  }

  return (
    <IconButton
      aria-label="delete"
      style={{ padding: 7 }}
      color="inherit"
      onClick={handleClick}
    >
      <span className="icon-delete" style={{ fontSize: 14 }} />
    </IconButton>
  );
}

IconButtonDelete.propTypes = {
  id: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};

function ButtonDelete(props) {
  function handleClick() {
    const {
      mode,
      selected,
      onClose,
      getPaymentMethods,
      deletePaymentMethod,
    } = props;

    deletePaymentMethod(selected, mode ? mode : "users", () => {
      getPaymentMethods(null, mode ? mode : "users");
      onClose();
    });
  }

  const { mode, disabled, loading } = props;

  return (
    <Button
      color={mode && mode === "companies" ? "secondary" : "primary"}
      style={{ minWidth: 120 }}
      disabled={disabled}
      variant="contained"
      onClick={handleClick}
    >
      {loading ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <span>
          {translation().invoices.payment_methods.delete.buttons.submit}
        </span>
      )}
    </Button>
  );
}

ButtonDelete.propTypes = {
  mode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.any,
  deletePaymentMethod: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

class PaymentMethods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddPaymentMethodDialogOpen: false,
      isDeletePaymentMethodDialogOpen: false,
      isManageUserPaymentMethodOpen: false,
      selected: null,
    };

    this.handleScrollPaymentMethods = this.handleScrollPaymentMethods.bind(
      this
    );
    this.handleScrollCompanyUsers = this.handleScrollCompanyUsers.bind(this);
    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleGetCompanyUsers = this.handleGetCompanyUsers.bind(this);
    this.handlePaymentIframes = this.handlePaymentIframes.bind(this);
    this.handleOpenAddPaymentMethodDialog = this.handleOpenAddPaymentMethodDialog.bind(
      this
    );
    this.handleCloseAddPaymentMethodsDialog = this.handleCloseAddPaymentMethodsDialog.bind(
      this
    );
    this.handleOpenDeletePaymentMethodDialog = this.handleOpenDeletePaymentMethodDialog.bind(
      this
    );
    this.handleCloseDeletePaymentMethodsDialog = this.handleCloseDeletePaymentMethodsDialog.bind(
      this
    );
    this.handleOpenManageUserPaymentMethodDialog = this.handleOpenManageUserPaymentMethodDialog.bind(
      this
    );
    this.handleCloseManageUserPaymentMethodDialog = this.handleCloseManageUserPaymentMethodDialog.bind(
      this
    );
    this.renderPaymentMethodsTable = this.renderPaymentMethodsTable.bind(this);
    this.renderCompanyUsersTable = this.renderCompanyUsersTable.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      getPaymentMethods,
      togglePaymentMethodsMode,
      companyAdmin,
    } = this.props;

    getPaymentMethods(null, companyAdmin ? "companies" : "users", () => {
      if (companyAdmin) togglePaymentMethodsMode("companies");
    });
  }

  handleScrollPaymentMethods(event) {
    const {
      getPaymentMethods,
      paymentMethods: { paging, loading, mode },
    } = this.props;

    if (!event || !event.target) return;

    if (!event.target.scrollTop || event.target.scrollTop === 0) return;

    if (
      event.target.scrollHeight - event.target.clientHeight - 20 <=
      event.target.scrollTop
    ) {
      if (paging && paging.next) {
        if (!loading) {
          getPaymentMethods(paging.next, mode);
        }
      }
    }
  }

  handleScrollCompanyUsers() {
    const {
      getCompanyUsers,
      company: { users },
    } = this.props;

    if (handleInfiniteScroll(".company-users-table-scroll")) {
      if (users.paging && users.paging.next) {
        if (!users.isLoading) getCompanyUsers(users.paging.next);
      }
    }
  }

  handleGetCompanyUsers() {
    const {
      getCompanyUsers,
      company: { users },
    } = this.props;

    if (!users.isLoading) getCompanyUsers();
  }

  handleChangeMode(e, value) {
    const {
      getPaymentMethods,
      togglePaymentMethodsMode,
      paymentMethods: { loading, mode },
    } = this.props;

    if (loading) return;

    if (mode && mode === "users") {
      if (!value) return;

      getPaymentMethods(null, "companies", () => {
        togglePaymentMethodsMode("companies");
      });
    } else if (mode && mode === "companies") {
      if (value) return;

      getPaymentMethods(null, "users", () => {
        togglePaymentMethodsMode("users");
      });
    }
  }

  handleOpenAddPaymentMethodDialog() {
    this.setState({ isAddPaymentMethodDialogOpen: true });
  }

  handleCloseAddPaymentMethodsDialog() {
    this.setState({ isAddPaymentMethodDialogOpen: false });
  }

  handleOpenDeletePaymentMethodDialog(id) {
    if (!id) return;

    this.setState({
      isDeletePaymentMethodDialogOpen: true,
      selected: id,
    });
  }

  handleCloseDeletePaymentMethodsDialog() {
    this.setState({
      isDeletePaymentMethodDialogOpen: false,
      selected: null,
    });
  }

  handleOpenManageUserPaymentMethodDialog(id) {
    if (!id) return;

    this.setState({
      isManageUserPaymentMethodOpen: true,
      selected: id,
    });
  }

  handleCloseManageUserPaymentMethodDialog() {
    this.setState({
      isManageUserPaymentMethodOpen: false,
      selected: null,
    });
  }

  handlePaymentIframes() {
    const {
      getPaymentMethods,
      paymentMethods: { mode },
    } = this.props;

    getApiPaymentMethodIframe(
      mode && mode === "companies" ? true : false,
      () => {
        getPaymentMethods(
          null,
          mode ? mode : "users",
          this.handleCloseAddPaymentMethodsDialog
        );
      }
    );
  }

  renderPaymentMethodsTable(data) {
    if (data && data.length > 0) {
      const rows = [];

      for (let i = 0; i < data.length; i++) {
        let row = {};

        if (data[i].id) row["id"] = data[i].id;
        else row["id"] = "NaN";

        if (data[i].type) row["type"] = data[i].type;
        else row["type"] = "-";

        if (data[i].name) row["name"] = data[i].name;
        else row["name"] = "-";

        if (data[i].currency_isocode)
          row["currency_isocode"] = data[i].currency_isocode;
        else row["currency_isocode"] = "-";

        if (data[i].owner_name) row["owner_name"] = data[i].owner_name;
        else row["owner_name"] = "-";

        rows.push(row);
      }

      const typeStyle = {
        VISA: {
          color: "#6374ff",
          fontWeight: 500,
        },
        MASTERCARD: {
          color: "#ffbe1c",
          fontWeight: 500,
        },
        IBAN: {
          color: "#16cf7d",
          fontWeight: 500,
        },
      };

      return (
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {translation().invoices.payment_methods.table.type}
                </TableCell>
                <TableCell>
                  {translation().invoices.payment_methods.table.currency}
                </TableCell>
                <TableCell>
                  {translation().invoices.payment_methods.table.alias}
                </TableCell>
                <TableCell>
                  {translation().invoices.payment_methods.table.owner}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, k) => {
                return (
                  <TableRow key={k}>
                    <TableCell
                      style={
                        typeStyle[row.type]
                          ? typeStyle[row.type]
                          : { fontWeight: 500 }
                      }
                    >
                      {row.type}
                    </TableCell>
                    <TableCell>{row.currency_isocode}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.owner_name}</TableCell>
                    <TableCell align="right" className="column-actions">
                      {this.props.paymentMethods.mode &&
                        this.props.paymentMethods.mode === "companies" && (
                          <IconButtonManage
                            id={row.id}
                            onClick={
                              this.handleOpenManageUserPaymentMethodDialog
                            }
                          />
                        )}
                      <IconButtonDelete
                        id={row.id}
                        onClick={this.handleOpenDeletePaymentMethodDialog}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  renderCompanyUsersTable(data) {
    const { handleCompanyPaymentMethodPermissions, user } = this.props;

    if (data && data.length > 0) {
      const rows = [];

      for (let i = 0; i < data.length; i++) {
        let row = {};

        if (data[i].user && data[i].user.id) row["id"] = data[i].user.id;
        else row["id"] = "NaN";

        if (data[i].user && data[i].user.last_name)
          row["last_name"] = data[i].user.last_name;
        else row["last_name"] = "-";

        if (data[i].user && data[i].user.first_name)
          row["first_name"] = data[i].user.first_name;
        else row["first_name"] = "-";

        rows.push(row);
      }

      return (
        <div className="table-wrapper" style={{ marginTop: 35 }}>
          <Table style={{ marginBottom: 50 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {
                    translation().invoices.payment_methods.manage.table
                      .last_name
                  }
                </TableCell>
                <TableCell>
                  {
                    translation().invoices.payment_methods.manage.table
                      .first_name
                  }
                </TableCell>
                <TableCell align="right">
                  {translation().invoices.payment_methods.manage.table.allowed}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <User
                    key={index}
                    userId={row.id}
                    lastName={row.last_name}
                    firstName={row.first_name}
                    userLogged={user.data}
                    paymentMethodId={this.state.selected}
                    handleCompanyPaymentMethodPermissions={
                      handleCompanyPaymentMethodPermissions
                    }
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {
    const {
      isAddPaymentMethodDialogOpen,
      isDeletePaymentMethodDialogOpen,
      isManageUserPaymentMethodOpen,
      selected,
    } = this.state;

    const {
      company,
      companyAdmin,
      deletePaymentMethod,
      getPaymentMethods,
      paymentMethods: { data, loading, delete_action, mode },
    } = this.props;

    return (
      <div className="PaymentMethods">
        <div className="section-controls">
          {companyAdmin && (
            <div className="toggle-mode">
              <ToggleLabel
                label={translation().invoices.payment_methods.switch.personal}
                value={false}
                onClick={this.handleChangeMode}
              />
              <Switch
                checked={mode === "users" ? false : true}
                value={mode}
                color="default"
                disabled={loading}
                onChange={this.handleChangeMode}
              />
              <ToggleLabel
                label={translation().invoices.payment_methods.switch.pro}
                value={true}
                onClick={this.handleChangeMode}
              />
            </div>
          )}
          <Fab
            color={mode === "users" ? "primary" : "secondary"}
            style={{ marginLeft: companyAdmin ? "-48px" : "auto" }}
            onClick={this.handleOpenAddPaymentMethodDialog}
            className="Fab-responsive-class-helper"
            aria-label="Add"
            size="medium"
          >
            <span className="icon-ajout_carte" style={{ fontSize: 25 }} />
          </Fab>
        </div>
        <div
          style={{ width: "100%", overflow: "auto", maxHeight: 600 }}
          onScroll={this.handleScrollPaymentMethods}
        >
          {data && data.length > 0 ? (
            this.renderPaymentMethodsTable(data)
          ) : !loading ? (
            <div className="data-empty">
              {translation().invoices.payment_methods.empty}
            </div>
          ) : (
            ""
          )}
        </div>
        {loading && (
          <div className="center-loader">
            <CircularProgress color={"secondary"} size={35} />
          </div>
        )}
        <Dialog
          aria-labelledby="add-payment-method-dialog-title"
          aria-describedby="add-payment-method-description"
          onClose={this.handleCloseAddPaymentMethodsDialog}
          open={isAddPaymentMethodDialogOpen}
          onEnter={this.handlePaymentIframes}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          scroll={"paper"}
        >
          <DialogTitle
            style={{ textAlign: "center" }}
            id="add-payment-method-dialog-title"
          >
            {translation().invoices.payment_methods.create.title}
          </DialogTitle>
          <DialogContent>
            {mode && mode === "users" ? (
              <p className="mode-type-in-dialog">
                {translation().invoices.payment_methods.create.personal}
              </p>
            ) : mode && mode === "companies" ? (
              <p className="mode-type-in-dialog">
                {translation().invoices.payment_methods.create.pro}
              </p>
            ) : (
              ""
            )}
            <div className="add-payment-method-wrapper">
              <div id="payment-frame-container" />
              <div className="center-loader" id="frame-payment-loader">
                <CircularProgress color={"secondary"} size={35} />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseAddPaymentMethodsDialog}>
              {translation().invoices.payment_methods.create.buttons.close}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="delete-payment-method-dialog-title"
          aria-describedby="delete-payment-method-description"
          onClose={this.handleCloseDeletePaymentMethodsDialog}
          open={isDeletePaymentMethodDialogOpen}
          onEnter={this.handlePaymentIframes}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          scroll={"paper"}
        >
          <DialogTitle id="delete-payment-method-dialog-title">
            {translation().invoices.payment_methods.delete.title}
          </DialogTitle>
          <DialogContent>
            {translation().invoices.payment_methods.delete.description}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={delete_action.loading}
              onClick={this.handleCloseDeletePaymentMethodsDialog}
            >
              {translation().invoices.payment_methods.delete.buttons.cancel}
            </Button>
            <ButtonDelete
              mode={mode}
              selected={selected}
              disabled={delete_action.loading}
              deletePaymentMethod={deletePaymentMethod}
              getPaymentMethods={getPaymentMethods}
              onClose={this.handleCloseDeletePaymentMethodsDialog}
              loading={delete_action.loading}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="manage-payment-method-dialog-title"
          aria-describedby="manage-payment-method-description"
          onClose={this.handleCloseManageUserPaymentMethodDialog}
          open={isManageUserPaymentMethodOpen}
          onEnter={this.handleGetCompanyUsers}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          scroll="paper"
        >
          <DialogTitle id="manage-payment-method-dialog-title">
            {translation().invoices.payment_methods.manage.title}
          </DialogTitle>
          <DialogContent
            className="company-users-table-scroll"
            style={{ maxHeight: 500 }}
            onScroll={this.handleScrollCompanyUsers}
          >
            {translation().invoices.payment_methods.manage.description}
            {company.users &&
            company.users.list &&
            company.users.list.length > 0
              ? this.renderCompanyUsersTable(company.users.list)
              : false}
            {company.users && company.users.isLoading && (
              <div className="center-loader" style={{ marginTop: 20 }}>
                <CircularProgress color="secondary" size={35} />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseManageUserPaymentMethodDialog}>
              {translation().invoices.payment_methods.manage.buttons.close}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

PaymentMethods.propTypes = {
  handleCompanyPaymentMethodPermissions: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  companyAdmin: PropTypes.bool.isRequired,
  togglePaymentMethodsMode: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape().isRequired,
  }).isRequired,
  company: PropTypes.shape({
    users: PropTypes.shape().isRequired,
  }).isRequired,
  paymentMethods: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool,
    delete_action: PropTypes.shape().isRequired,
    mode: PropTypes.string.isRequired,
    paging: PropTypes.shape(),
  }).isRequired,
};

export default PaymentMethods;
