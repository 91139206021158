import { connect } from 'react-redux';
import { fetchApi } from '../../helpers/functions/api';
import { stopSubmit } from 'redux-form';
import { scrollToAnchor } from '../../helpers/functions/jquery';

import {
    registerUser, registerUserSuccess, registerUserFailure
} from '../../redux/actions/userActions';

import { enqueueSnackbar } from '../../redux/actions/notificationsActions';

import translation from '../../translation/translation';

import Register from '../../views/register/Register';

const mapStateToProps = state => ({ register: state.register })

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    registerUser: credidentials => {

      if (!credidentials)
        return;

      dispatch(registerUser());

      if (credidentials.legal_id && credidentials.legal_name) {

        const credidentialsCompany = {};

        credidentialsCompany.legal_id = credidentials.legal_id;
        credidentialsCompany.legal_identifier = "siren";
        credidentialsCompany.legal_name = credidentials.legal_name;
        credidentialsCompany.commercial_name = credidentials.legal_name;

        credidentialsCompany.users = [
          {
            gender: credidentials.gender ? credidentials.gender : null,
            first_name: credidentials.first_name ? credidentials.first_name : null,
            last_name: credidentials.last_name ? credidentials.last_name : null,
            date_of_birth: credidentials.date_of_birth ? credidentials.date_of_birth : null,
            email: credidentials.email ? credidentials.email : null,
            mobile: credidentials.mobile ? credidentials.mobile : null,
            phone: credidentials.phone ? credidentials.phone : null,
            password: credidentials.password ? credidentials.password : null,
            timezone: credidentials.timezone ? credidentials.timezone : null,
            currency_isocode: credidentials.currency_isocode ? credidentials.currency_isocode : null,
            language_locale: credidentials.language_locale ? credidentials.language_locale : null,
          }
        ];

        fetchApi(
          'post',
          'companies',
          null,
          credidentialsCompany,
          null,
          success => {

            dispatch(registerUserSuccess(success));
            dispatch(enqueueSnackbar({
              message: translation().register.form.callbacks.success,
              options: {
                variant: 'success',
              },
            }));

            if (ownProps.onRegisterSuccess && typeof ownProps.onRegisterSuccess === 'function')
              ownProps.onRegisterSuccess();

          },
          error => {
            const errorMessage = translation().register.form.callbacks.failure;

            if (error && error.response) {

              if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                const validationErrors = {};
                const errorsArray = error.response.data.errors;
                const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

                if (scrollAnchor[0])
                  scrollToAnchor(scrollAnchor[0])

                for (let i = 0; i < errorsArray.length; i++) {
                  if (errorsArray[i].propertyPath)
                    validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
                }

                dispatch(registerUserFailure(errorsArray));
                dispatch(stopSubmit(
                  'registerForm',
                  validationErrors
                ));
              }

              dispatch(enqueueSnackbar({
                message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
                options: {
                  variant: 'error',
                },
              }));
            }
            else {
              dispatch(registerUserFailure(errorMessage));
              dispatch(enqueueSnackbar({
                message: errorMessage,
                options: {
                  variant: 'error',
                },
              }));
            }
          }
        );
      }
      else if (credidentials.legal_name && !credidentials.legal_id) {

        dispatch(registerUserFailure(translation().register.form.callbacks.fill_siren));

        dispatch(stopSubmit(
          'registerForm',
          { legal_id: translation().register.form.callbacks.fill_siren }
        ));

        dispatch(enqueueSnackbar({
          message: translation().register.form.callbacks.fill_siren,
          options: {
            variant: 'warning',
          },
        }));
      }
      else if (!credidentials.legal_name && credidentials.legal_id) {

        dispatch(registerUserFailure(translation().register.form.callbacks.fill_company_name));

        dispatch(stopSubmit(
          'registerForm',
          { legal_name: translation().register.form.callbacks.fill_company_name }
        ));

        dispatch(enqueueSnackbar({
          message: translation().register.form.callbacks.fill_company_name,
          options: {
            variant: 'warning',
          },
        }));
      }
      else {
        fetchApi(
          'post',
          'users',
          null,
          credidentials,
          null,
          success => {
            dispatch(registerUserSuccess(success));
            dispatch(enqueueSnackbar({
              message: translation().register.form.callbacks.success,
              options: {
                variant: 'success',
              },
            }));

            if (ownProps.onRegisterSuccess && typeof ownProps.onRegisterSuccess === 'function')
              ownProps.onRegisterSuccess();

          },
          error => {
            const errorMessage = translation().register.form.callbacks.failure;

            if (error && error.response) {

              if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                const validationErrors = {};
                const errorsArray = error.response.data.errors;
                const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

                if (scrollAnchor[0])
                  scrollToAnchor(scrollAnchor[0])
                  
                for (let i = 0; i < errorsArray.length; i++) {
                  if (errorsArray[i].propertyPath)
                    validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
                }

                dispatch(registerUserFailure(errorsArray));
                dispatch(stopSubmit(
                  'registerForm',
                  validationErrors
                ));
              }

              dispatch(enqueueSnackbar({
                message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
                options: {
                  variant: 'error',
                },
              }));
            }
            else {
              dispatch(registerUserFailure(errorMessage));
              dispatch(enqueueSnackbar({
                message: errorMessage,
                options: {
                  variant: 'error',
                },
              }));
            }
          }
        );
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
