import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  change,
  Field,
  reduxForm
} from 'redux-form';
import Dropzone from 'react-dropzone';
import classNames from 'classnames'

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import BirthdateField from '../../../../components/commons/fields/BirthdateField';
import MuiRadioGroupField from '../../../../components/commons/fields/MuiRadioGroupField';
import MuiTextField from '../../../../components/commons/fields/MuiTextField';
import MuiPhoneField from '../../../../components/commons/fields/muiPhoneField/MuiPhoneField';

import { apiPostFile } from '../../../../helpers/functions/api';

import translation from '../../../../translation/translation';
import store from '../../../../redux/store';
import config from '../../../../config';
import validate from './validate';

class ProfileForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filesAccepted: null,
      filesRejected: null,
      uploadInProgressLogo: 0,
      filesArrayLogo: [],
      fileLimitLogo: 1,
      fileErrorLogo: '',
    }

    this.dropzoneLogoRef = undefined;

    this.createDropzoneLogoRef = this.createDropzoneLogoRef.bind(this);
    this.handleDeleteLogo = this.handleDeleteLogo.bind(this);
    this.handleDropLogo = this.handleDropLogo.bind(this)
    this.handleOpenDropzoneLogo = this.handleOpenDropzoneLogo.bind(this);
  }

  UNSAFE_componentWillMount () {

    const { initialValues } = this.props;

    if (initialValues && initialValues.picture_url)
      this.setState({ filesArrayLogo: [{ thumbnail_url: initialValues.picture_url + "?client_id=" + config.api_key }] });

  }

  createDropzoneLogoRef(node) {
    if (node)
      this.dropzoneLogoRef = node;
  }

  handleOpenDropzoneLogo() {

    if (this.dropzoneLogoRef && this.dropzoneLogoRef.open)
      this.dropzoneLogoRef.open();
  }

  handleDeleteLogo() {
    this.setState({ filesArrayLogo: [] });
    store.dispatch(change('updateProfileForm', 'picture_url', ''));
  }

  handleDropLogo(filesAccepted, filesRejected) {

    this.setState({ filesAccepted, filesRejected });

    if (filesAccepted) {
      for (let i = 0; i < filesAccepted.length; i++) {
        if ((filesAccepted.length) <= this.state.fileLimitLogo) {

          this.setState({ uploadInProgressLogo: filesAccepted.length });

          apiPostFile(
            filesAccepted[i],
            success => {
              const newFile = success.data && success.data ? success.data : '';
              const newFilesArrayLogo = [newFile];

              this.setState({
                filesArrayLogo: newFilesArrayLogo,
                fileErrorLogo: '',
                uploadInProgressLogo: this.state.uploadInProgressLogo - 1
              });

              store.dispatch(change('updateProfileForm', 'picture_url', newFile.url));
            },
            error => {
              if (error.response && error.response.data && error.response.data.detail) {
                this.setState({
                  fileErrorLogo: error.response.data.detail,
                  uploadInProgressLogo: this.state.uploadInProgressLogo - 1
                });
              }
            }
          );
        }
        else {
          this.setState({ fileErrorLogo: translation().profile.form.validation.file_limit + this.state.fileLimitLogo });
          return;
        }
      }
    }

    if (filesRejected && filesRejected.length > 0) {
      let fileErrorLogo = translation().profile.form.validation.files;

      for (let j = 0; j < filesRejected.length; j++) {

        if (filesRejected[j].name)
          fileErrorLogo += (filesRejected[j].name + ", ");
      }

      fileErrorLogo += translation().profile.form.validation.files_refused;

      this.setState({ fileErrorLogo });
    }
  }

  render() {

    const {
      buttonLabel,
      informations,
      handleSubmit,
      labels
    } = this.props;

    const {
      uploadInProgressLogo,
      filesArrayLogo,
      fileErrorLogo,
    } = this.state;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="field-wrapper" style={{marginBottom: 12}}>
          <Field
            name="gender"
            label={labels.civility}
            component={MuiRadioGroupField}
            options={[
              { value: "F", label: labels.female },
              { value: "M", label: labels.male }
            ]}
          />
        </div>
        <div className="field-wrapper">
          <BirthdateField
            formName="updateProfileForm"
            label={labels.birth_of_date}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="first_name"
            id="firstName"
            type="text"
            label={labels.first_name}
            component={MuiTextField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="last_name"
            id="lastName"
            type="text"
            label={labels.last_name}
            component={MuiTextField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="phone"
            id="phone"
            type="text"
            label={labels.phone}
            component={MuiPhoneField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="mobile"
            id="mobile"
            type="text"
            label={labels.mobile}
            component={MuiPhoneField}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="email"
            type="mail"
            id="email"
            label={labels.email}
            component={MuiTextField}
          />
        </div>
        <div className="field-wrapper" style={{marginTop: 50}}>
          <div className="upld-wrapper">
            <label className="upld-label">{labels.profile_picture}</label>
            { fileErrorLogo &&
              <p className="error-message">{fileErrorLogo}</p>
            }
            {
              !uploadInProgressLogo
                ? (
                  <span>
                    {
                        filesArrayLogo && filesArrayLogo.length > 0
                          ? (
                            <div
                              className="profile-pic"
                              style={{backgroundImage: ('url(' + filesArrayLogo[0].thumbnail_url + ')') }}
                            />
                          )
                          : ''
                    }
                  </span>
                )
                : (
                  <div className="profile-pic upld-inp">
                    <CircularProgress size={35} color="secondary" />
                  </div>
                )
            }
            <Dropzone
              ref={this.createDropzoneLogoRef}
              onDrop={this.handleDropLogo}
              disableClick={true}
              name="profilePicture"
              accept="image/jpeg, image/png, image/gif"
            >
              {
                ({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div {...getRootProps()} className={classNames('dropzone dropper profile-dropper', {'dropzone--isActive dropper-ondrag': isDragActive})}>
                      {
                        !uploadInProgressLogo
                          ? (
                            <div className="drop-it">
                              <Button
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={this.handleOpenDropzoneLogo}
                              >
                                <i className="material-icons" style={{marginRight: 4}}>cloud_upload</i>
                                {translation().profile.form.buttons.upload}
                              </Button>
                              {
                                  filesArrayLogo && filesArrayLogo[0] &&
                                <IconButton
                                  size="small"
                                  aria-label="Delete"
                                  className="delete-file"
                                  style={{width: 30, height: 30, padding: 0, margin: 5}}
                                  onClick={this.handleDeleteLogo}
                                >
                                  <DeleteIcon style={{fontSize: 18}}/>
                                </IconButton>
                              }
                            </div>
                          )
                          : ''
                      }
                      <input {...getInputProps()} />
                    </div>
                  )
                }
              }
            </Dropzone>
            <Field
              name="picture_url"
              type="hidden"
              component="input"
              className="hidden-input"
            />
          </div>
        </div>
        <div className="submit-wrapper">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={informations.loading}
            style={{minWidth: 150}}
          >
            {
              informations.loading
                ? <CircularProgress size={20} color='secondary' />
                : <span>{buttonLabel}</span>
            }
          </Button>
        </div>
      </form>
    );
  }
}

ProfileForm.propTypes = {
  informations: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    picture_url: PropTypes.string,
  }),
  labels: PropTypes.shape({
    civility: PropTypes.string.isRequired,
    birth_of_date: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    female: PropTypes.string.isRequired,
    male: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    profile_picture: PropTypes.string.isRequired,
  }).isRequired,
  buttonLabel: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'updateProfileForm',
  enableReinitialize: true,
  validate
})(ProfileForm);
