import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";

import AcceptTerms from "../acceptTerms/AcceptTerms";
import Contact from "../contact/Contact";
import config from "../../config";
import translation from "../../translation/translation";
import { getPhoneNumber } from "../../helpers/functions/cookies";

const drawerWidth = 215;

const styles = (theme) => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  envLabel: {
    backgroundColor: "#42a5f5",
    borderRadius: 50,
    padding: "4px 12px",
    lineHeight: 1,
    fontSize: 12,
  },
  supportButton: {
    borderColor: "#FFFFFF",
    marginLeft: 12,
    color: "#FFFFFF",
    fontFamily: "Montserrat-regular",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#FFFFFF",
      color: "black",
    },
  },
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      isContactOpen: false,
      isTermsOpen: false,
    };

    this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
    this.handleToggleContact = this.handleToggleContact.bind(this);
    this.handleToggleTerms = this.handleToggleTerms.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.renderEnvironmentLabel = this.renderEnvironmentLabel.bind(this);
  }

  handleToggleDrawer() {
    if (!this.state.drawerOpen) this.setState({ drawerOpen: true });
    else this.setState({ drawerOpen: false });
  }

  handleToggleContact() {
    if (!this.state.isContactOpen) this.setState({ isContactOpen: true });
    else this.setState({ isContactOpen: false });
  }

  handleToggleTerms() {
    if (!this.state.isTermsOpen) this.setState({ isTermsOpen: true });
    else this.setState({ isTermsOpen: false });
  }

  handleSignOut() {
    const { signOut } = this.props;

    signOut();
  }

  renderEnvironmentLabel() {
    const { classes } = this.props;

    switch (config.api_env) {
      case "dev":
        return <div className={classes.envLabel}>{config.api_env}</div>;
      case "sandbox":
        return <div className={classes.envLabel}>{config.api_env}</div>;
      case "local":
        return <div className={classes.envLabel}>{config.api_env}</div>;
      default:
        return false;
    }
  }

  render() {
    const { classes, user } = this.props;

    const { drawerOpen, isContactOpen, isTermsOpen } = this.state;

    return (
      <div className="Header">
        {/* <div className="provider-stop-covid">
          <Link to="/covid-charter">
            <img
              src="/assets/images/tmp/provider-stop-covid.png"
              alt="Shop close"
              height="62"
              width="62"
              className="provider-stop-covid-icon"
            />
            <span>{translation().covid.title}</span>
          </Link>
        </div> */}
        <AppBar
          position="fixed"
          color="primary"
          id="AppHeader"
          style={{ zIndex: 2000 }}
        >
          <Toolbar>
            <div className="logo-link">
              <a href="/">
                <img
                  className="app-logo"
                  src="/assets/images/commons/logo-ls-provider.svg"
                  alt={config.site_name}
                />
              </a>
            </div>
            {this.renderEnvironmentLabel()}
            <Typography
              variant="inherit"
              color="inherit"
              style={{ flex: "0 1 auto", marginLeft: "auto" }}
            >
              <a href="tel: ${getPhoneNumber()}" className="phone-link">
                <span className="icon-telephone" />
                <span className="phone-number"> {getPhoneNumber()}</span>
              </a>
            </Typography>
            <Button
              variant="outlined"
              className={classes.supportButton}
              component={Link}
              href="https://blog.wall-market.com/support"
              target="_blank"
              rel="noopener noreferrer"
              size="small"
            >
              {translation().commons.support}
            </Button>
            <IconButton
              style={{ color: "white", flex: "0 1 auto", marginLeft: "10px" }}
              aria-label="Open drawer"
              onClick={this.handleToggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={this.handleToggleDrawer}
          className="header-drawer"
          style={{ zIndex: 2100 }}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className="user-area">
            <div className="col-xs-3" style={{ padding: 0, paddingRight: 12 }}>
              <img
                className="user-avatar"
                src={
                  user.picture_url
                    ? user.picture_url + "?client_id=" + config.api_key
                    : "/assets/images/commons/default-user-picture.png"
                }
                alt="Profile pic"
              />
            </div>
            <div
              className="col-xs-9"
              style={{ textAlign: "right", padding: 0, paddingLeft: 5 }}
            >
              <div className="user-name">
                {user && user.first_name ? user.first_name : ""}
              </div>
              <span
                role="button"
                className="user-logout"
                aria-label="Sign out"
                onClick={this.handleSignOut}
              >
                {translation().header.logout}
              </span>
            </div>
            <div className="clearfix" />
          </div>
          <Divider />
          <div
            tabIndex={0}
            role="button"
            style={{ outline: 0 }}
            onClick={this.handleToggleDrawer}
            onKeyDown={this.handleToggleDrawer}
          >
            <List component="nav">
              <NavLink to="/order-products">
                <ListItem button>
                  <ListItemIcon>
                    <span className="link-icon icon-upload" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.orders}
                  </ListItemText>
                </ListItem>
              </NavLink>
              <NavLink to="/schedule">
                <ListItem button>
                  <ListItemIcon>
                    <span className="link-icon icon-calendrier" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.schedule}
                  </ListItemText>
                </ListItem>
              </NavLink>
              <NavLink to="/profile">
                <ListItem button>
                  <ListItemIcon>
                    <span className="link-icon icon-connecte" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.profile}
                  </ListItemText>
                </ListItem>
              </NavLink>
              {user.company && user.company_admin && (
                <NavLink to="/company">
                  <ListItem button>
                    <ListItemIcon>
                      <span className="link-icon icon-pro" />
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      {translation().header.company}
                    </ListItemText>
                  </ListItem>
                </NavLink>
              )}
              <NavLink to="/invoices">
                <ListItem button>
                  <ListItemIcon>
                    <span className="link-icon icon-facture" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.invoice}
                  </ListItemText>
                </ListItem>
              </NavLink>
              <NavLink to="/metrics">
                <ListItem button>
                  <ListItemIcon>
                    <i
                      className="material-icons"
                      style={{ fontSize: 20, height: 16, width: 16 }}
                    >
                      bar_chart
                    </i>
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.metrics}
                  </ListItemText>
                </ListItem>
              </NavLink>
            </List>
            <div className="header-nav-bottom">
              <div
                className="header-nav-bottom-item"
                onClick={this.handleToggleContact}
                role="button"
              >
                {translation().footer.contact}
              </div>
              <div className="header-nav-bottom-divider" />
              <Link
                className="header-nav-bottom-item"
                component={Link}
                href="https://blog.wall-market.com/support"
                target="_blank"
                rel="noopener noreferrer"
                size="small"
              >
                {translation().commons.support}
              </Link>
              <div className="header-nav-bottom-divider" />
              <div
                className="header-nav-bottom-item"
                onClick={this.handleToggleTerms}
                role="button"
              >
                {translation().footer.legal_notice}
              </div>
            </div>
          </div>
        </Drawer>
        <AcceptTerms
          mode="readOnly"
          open={isTermsOpen}
          onClose={this.handleToggleTerms}
        />
        <Contact open={isContactOpen} onClose={this.handleToggleContact} />
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.shape().isRequired,
  signOut: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default withStyles(styles)(Header);
