import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { formatDate } from '../../../helpers/functions/dates';
import { getLocale } from '../../../helpers/functions/cookies';

class DateTimeRangePickerField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectionRange: {
        startDate: new Date(this.props.defaultRange.startDate ? this.props.defaultRange.startDate : '' ),
        endDate: new Date(this.props.defaultRange.endDate ? this.props.defaultRange.endDate : ''),
        key: 'selection',
      },
      textValue: formatDate(this.props.defaultRange.startDate, "localized-date") + ' - ' + formatDate(this.props.defaultRange.endDate, "localized-date") || "",
    }

    this.handleSelect = this.handleSelect.bind(this);
    this.handleOpenPickerDialog = this.handleOpenPickerDialog.bind(this);
    this.handleClosePickerDialog = this.handleClosePickerDialog.bind(this);
    this.handleSubmitPickerDialog = this.handleSubmitPickerDialog.bind(this);
  }

  handleOpenPickerDialog() {
    this.setState({ open: true });
  }

  handleClosePickerDialog() {
    this.setState({ open: false });
  }

  handleSubmitPickerDialog() {
    this.setState({ open: false });

    const {
      onSubmitDialog
    } = this.props;

    const {
      selectionRange
    } = this.state;

    if (onSubmitDialog && typeof onSubmitDialog === 'function')
      onSubmitDialog(selectionRange);
  }

  handleSelect(ranges) {

    this.setState({
      selectionRange: ranges.selection,
      textValue: formatDate(ranges.selection.startDate, "localized-date") + ' - ' + formatDate(ranges.selection.endDate, "localized-date")
    });

    if (this.props.onChange && typeof this.props.onChange === 'function')
      this.props.onChange(ranges);

    if (this.props.input && this.props.input.onChange)
      this.props.input.onChange(ranges);
  }

	render() {

    const {
      open,
      textValue,
      selectionRange
    } = this.state;

    const {
      dialogTitle,
      submitDialogLabel,
      closeDialogLabel,
      fullWidth,
      disabled,
      defaultValue,
      label
    } = this.props;

    return (
      <span>
        <TextField
          label={label || defaultValue}
          disabled={disabled}
          fullWidth={fullWidth}
          value={textValue}
          onClick={this.handleOpenPickerDialog}
          autoComplete="off"
        />
        <Dialog
          open={open}
          onClose={this.handleClosePickerDialog}
          aria-labelledby="time-range-dialog"
          maxWidth="lg"
          scroll="paper"
        >
          { dialogTitle &&
            <DialogTitle id="time-range-dialog">
              {dialogTitle}
            </DialogTitle>
          }
          <DialogContent>
            <DateRangePicker
              ranges={[selectionRange]}
              locale={rdrLocales[getLocale()] ? rdrLocales[getLocale()] : rdrLocales['enUS']}
              rangeColors={['#1A2123']}
              onChange={this.handleSelect}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={this.handleClosePickerDialog}
            >
              {closeDialogLabel ? closeDialogLabel : "Close"}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={this.handleSubmitPickerDialog}
            >
              {submitDialogLabel ? submitDialogLabel : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </span>
		);
	}
}

DateTimeRangePickerField.propTypes = {
  dialogTitle: PropTypes.string,
  submitDialogLabel: PropTypes.string,
  closeDialogLabel: PropTypes.string,
  defaultRange: PropTypes.shape({
    startDate: PropTypes.any,
    endDate: PropTypes.any,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  onSubmitDialog: PropTypes.func,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
}

export default DateTimeRangePickerField;
