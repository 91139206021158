/**
 * Calendar actions
 */
export const clearSchedule = data => ({
	type: 'CLEAR_SCHEDULE',
	data
});

export const setCurrentView = data => ({
	type: 'STORE_CURRENT_VIEW',
	data
});

export const setCurrentTimeRange = data => ({
	type: 'STORE_CURRENT_TIME_RANGE',
	data
});


/**
 * Get providers events
 */
export const getProviderCalendar = () => ({
  type: "GET_PROVIDER_CALENDAR"
});

export const getProviderCalendarSuccess = data => ({
  type: "GET_PROVIDER_CALENDAR_SUCCESS",
  data
});

export const getProviderCalendarFailure = error => ({
  type: "GET_PROVIDER_CALENDAR_FAILURE",
  error
});

/**
* Get providers hash
*/
export const getHash = () => ({
  type: "GET_HASH"
});

export const getHashSuccess = data => ({
  type: "GET_HASH_SUCCESS",
  data
});

export const getHashFailure = error => ({
  type: "GET_HASH_FAILURE",
  error
});

/**
 * Get providers members for calendar visualization
 */
export const getCompanyUsers = (next = null) => ({
	type: "GET_COMPANY_USERS_SCHEDULE",
	next
});

export const getCompanyUsersSuccess = (data, paging) => ({
	type: "GET_COMPANY_USERS_SCHEDULE_SUCCESS",
	data,
	paging
});

export const getCompanyUsersFailure = error => ({
	type: "GET_COMPANY_USERS_SCHEDULE_FAILURE",
	error
});

export const updateCompanyUsers = (data, paging) => ({
	type: "UPDATE_COMPANY_USERS_SCHEDULE",
	data,
	paging
});

export const selectScheduleCompanyUser = selected => ({
	type: "SELECT_SCHEDULE_COMPANY_USER",
	selected
});
