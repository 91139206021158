const initialState = {
  loading: false,
  error: null,
  data: null
}

const registerReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'REGISTER_USER':
      return {
        ...initialState,
        loading: true
      }
    case 'REGISTER_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case 'REGISTER_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default registerReducer;
