import translation from '../../../../../translation/translation';

const validate = values => {

  const errors = {};

  if (!values.name)
    errors.name = translation().forms.errors.required;

  if (!values.address)
    errors.address = translation().forms.errors.required;

  return errors;
}

export default validate;
