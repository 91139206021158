import React, { Component } from "react";
import PropTypes from "prop-types";
import Rating from "react-rating";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import CommentIcon from "@material-ui/icons/Comment";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import Fab from "@material-ui/core/Fab";
import LaunchIcon from "@material-ui/icons/Launch";
import PersonIcon from "@material-ui/icons/Person";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import TodayIcon from "@material-ui/icons/Today";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import WorkIcon from "@material-ui/icons/Work";

import {
  adjustOrderCard,
  orderContentScroll,
} from "../../../helpers/functions/jquery";
import {
  dateInTz,
  convertMinsToHrsMins,
} from "../../../helpers/functions/dates";
import { getUserTimezone } from "../../../helpers/functions/user";
import {
  addThreeDots,
  isObjEmpty,
  objectMapToArray,
} from "../../../helpers/functions/utils";
import MediaGroups from "../../../components/commons/orders/mediaGroups/MediaGroups";
import ClientRating from "../../../components/commons/orders/rating/Rating";
import OrderManagment from "../../../components/orderManagement/OrderManagment";
import translation from "../../../translation/translation";
import config from "../../../config";

function DownloadIconButton({ link }) {
  function handleDownloadMedia() {
    const url = link + "/download?client_id=" + config.api_key;
    const downloadIframe = document.getElementById("frame-download");
    const iframeContainer = document.getElementById("iframes-container");

    if (downloadIframe) {
      downloadIframe.setAttribute("src", url);
    } else {
      const downloadIframe =
        '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +
        url +
        " />";
      iframeContainer.innerHTML = downloadIframe;
    }
  }

  return (
    <IconButton
      aria-label="download"
      style={{ padding: 7, color: "#FFFFFF" }}
      onClick={handleDownloadMedia}
      size="small"
    >
      <span className="icon-download" style={{ fontSize: 12 }} />
    </IconButton>
  );
}

DownloadIconButton.propTypes = {
  link: PropTypes.string.isRequired,
};

class OrderCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRatingOpen: false,
      isRatingAfterUploadOpen: false,
      ratingValue: false,
    };

    this.handleEscKeyPress = this.handleEscKeyPress.bind(this);
    this.handleOpenOrderContent = this.handleOpenOrderContent.bind(this);
    this.handleCloseOrderContent = this.handleCloseOrderContent.bind(this);
    this.handleUploadOrder = this.handleUploadOrder.bind(this);
    this.handleOpenRateModal = this.handleOpenRateModal.bind(this);
    this.handleOpenRateAfterUploadModal = this.handleOpenRateAfterUploadModal.bind(
      this
    );
    this.handleCloseRateModal = this.handleCloseRateModal.bind(this);
    this.handleCloseRateAfterUploadModal = this.handleCloseRateAfterUploadModal.bind(
      this
    );
    this.handleSubmitRating = this.handleSubmitRating.bind(this);
    this.handleSubmitRatingAfterUpload = this.handleSubmitRatingAfterUpload.bind(
      this
    );
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("resize", adjustOrderCard);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", adjustOrderCard);
    window.removeEventListener("keydown", this.handleEscKeyPress);
  }

  handleEscKeyPress(event) {
    event = event || window.event;
    let isEscape = false;

    if (event.key) isEscape = event.key === "Escape" || event.key === "Esc";
    else if (event.code) isEscape = event.code === "Escape";
    else isEscape = event.keyCode === 27;

    if (isEscape) this.handleCloseOrderContent();
  }

  handleOpenOrderContent() {
    const {
      data: { id },
      orderOpen,
      onOpenOrder,
    } = this.props;

    window.addEventListener("keydown", this.handleEscKeyPress);

    const isOrderOpen = orderOpen === id ? true : false;

    if (isOrderOpen) {
      this.handleCloseOrderContent();
      return;
    }

    onOpenOrder(id);
    orderContentScroll(id);
  }

  handleCloseOrderContent() {
    window.removeEventListener("keydown", this.handleEscKeyPress);

    const { onCloseOrder } = this.props;

    onCloseOrder();
  }

  renderOptions(options) {
    if (!options) return;

    let optionsChips = [];

    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        optionsChips.push(
          <Chip key={i} className="option" label={options[i].name} />
        );
      }
    }

    return optionsChips;
  }

  handleUploadOrder() {
    const {
      data,
      mode,
      getOrders,
      onCloseOrder,
      orderProductMedias,
      tabOpen,
      uploadAction,
      uploadOrder,
    } = this.props;

    if (uploadAction.isLoading) return;

    if (orderProductMedias && data.id) {
      uploadOrder(orderProductMedias, data.id, () => {
        if (data.client_rating) {
          onCloseOrder();
          getOrders(null, mode, tabOpen);
        } else {
          this.handleOpenRateAfterUploadModal();
        }
      });
    }
  }

  handleOpenRateModal() {
    this.setState({ isRatingOpen: true });
  }

  handleCloseRateModal() {
    this.setState({ isRatingOpen: false });
  }

  handleOpenRateAfterUploadModal() {
    this.setState({ isRatingAfterUploadOpen: true });
  }

  handleCloseRateAfterUploadModal() {
    const { getOrders, mode, tabOpen, onCloseOrder } = this.props;

    this.setState({ isRatingAfterUploadOpen: false });
    onCloseOrder();
    getOrders(null, mode, tabOpen);
  }

  handleSubmitRating(values) {
    if (!values || !values.rating) return;

    const { data, rateClient } = this.props;

    rateClient(values, data.id, () => {
      this.setState({ ratingValue: values.rating });
      this.handleCloseRateModal();
    });
  }

  handleSubmitRatingAfterUpload(values) {
    if (!values || !values.rating) return;

    const { data, rateClient } = this.props;

    rateClient(values, data.id, () => {
      this.setState({ ratingValue: values.rating });
      this.handleCloseRateAfterUploadModal();
    });
  }

  checkIsOrderReadyToSend() {
    const { orderProductMedias } = this.props;

    let mediaTypesReady = 0;

    if (orderProductMedias && Object.keys(orderProductMedias).length) {
      for (let mediaType in orderProductMedias) {
        const nbMedias = orderProductMedias[mediaType].medias
          ? orderProductMedias[mediaType].medias.length
          : 0;
        const nbMin = orderProductMedias[mediaType].quantity_min
          ? orderProductMedias[mediaType].quantity_min
          : 0;
        const nbMax = orderProductMedias[mediaType].quantity_max
          ? orderProductMedias[mediaType].quantity_max
          : 10000000;

        if (nbMedias >= nbMin && nbMedias <= nbMax) mediaTypesReady++;
      }

      if (mediaTypesReady === Object.keys(orderProductMedias).length)
        return true;
      else return false;
    } else return false;
  }

  renderAttributes(attr) {
    switch (attr.type) {
      case "file":
        return <DownloadIconButton link={attr.value} />;
      case "image":
        return <DownloadIconButton link={attr.value} />;
      default:
        return attr.value;
    }
  }

  render() {
    const {
      clearMediaGroups,
      data,
      isLoading,
      getProductMediaGroups,
      mediaGroups,
      uploadAction,
      orderOpen,
      rating,
      mode,
      user,
      provider,
    } = this.props;

    const { isRatingOpen, isRatingAfterUploadOpen, ratingValue } = this.state;

    const timezoneCode =
      data.order && data.order.property && data.order.property.timezone_code
        ? data.order.property.timezone_code
        : undefined;
    const isOrderOpen = orderOpen === data.id ? true : false;

    let backgroundImage = "/assets/images/account/order-planned.jpg";

    if (
      data.delivery_status &&
      data.delivery_status.shortcode &&
      data.delivery_status.shortcode === "done"
    ) {
      backgroundImage = "/assets/images/account/order-done.jpg";
    }

    if (data.cover_url) backgroundImage = data.cover_url;

    const coverStyle = {
      backgroundImage: "url(" + backgroundImage + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };

    return (
      <li
        className={"Order " + (isOrderOpen ? "open" : "")}
        id={"order-" + data.id}
      >
        <div className="shadow-wrapper">
          <div className="order-cover" style={coverStyle}>
            <Fab
              disabled={
                isLoading || mediaGroups.isLoading || uploadAction.isLoading
              }
              className="mui-trigger-content"
              onClick={this.handleOpenOrderContent}
            >
              <div className={"trigger-content " + (isOrderOpen ? "open" : "")}>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </div>
            </Fab>
          </div>
          <div className="overview">
            <div className="col-sm-12">
              <p className="order-adres">
                {data.order &&
                data.order.property &&
                data.order.property.address
                  ? addThreeDots(data.order.property.address, 82)
                  : ""}
                <a
                  target="_blank"
                  href={
                    "http://maps.google.com?q=" + data.order.property.address
                  }
                  rel="noopener noreferrer"
                  className="launch-address-map"
                >
                  <LaunchIcon className="launch-address-icon" />
                </a>
              </p>
              {data.provider_name &&
                user.data.company &&
                user.data.company_admin && (
                  <div className="provider">
                    <WorkIcon className="provider-icon" /> {data.provider_name}
                  </div>
                )}
            </div>
            <div className="clearfix" />
            <div className="timeline">
              <div className="col-xs-5 planning">
                {data.start ? (
                  <span>
                    <span className="planning-icon icon-calendrier" />{" "}
                    {dateInTz(data.start, "localized-date")}
                  </span>
                ) : (
                  <br />
                )}
              </div>
              <div className="col-xs-3 planning">
                {data.start ? (
                  <span>
                    <span className="planning-icon icon-horaire" />{" "}
                    {dateInTz(data.start, "HH:mm")}
                  </span>
                ) : (
                  <br />
                )}
              </div>
              <div
                className="col-xs-4 status"
                style={{
                  color: data.delivery_status.color
                    ? data.delivery_status.color
                    : "#000000",
                }}
              >
                <img
                  alt="Status"
                  src={
                    data.delivery_status && data.delivery_status.icon_url_dark
                      ? data.delivery_status.icon_url_dark
                      : ""
                  }
                  className="status-img"
                />
                {data.delivery_status && data.delivery_status.name
                  ? data.delivery_status.name
                  : ""}
              </div>
              {getUserTimezone() !== timezoneCode && (
                <div className="col-xs-12">
                  <div className="property-tz-warn">
                    <span className="icon-infos" />{" "}
                    <span>
                      {translation().commons.local_datetime}{" "}
                      {dateInTz(data.start, "HH:mm", timezoneCode)} (
                      {timezoneCode})
                    </span>
                  </div>
                </div>
              )}
              <div className="clearfix" />
            </div>
            <div className="time-track">
              <div className="tracker" />
            </div>
            <div className="products-img">
              <div
                className={
                  "product-img " + (data.uploadable ? "" : "unavailable")
                }
              >
                <img
                  src={data.icon_url_dark ? data.icon_url_dark : ""}
                  alt={data.name ? data.name : "Missing img"}
                />
              </div>
              <span
                className={"name " + (data.uploadable ? "" : "unavailable")}
              >
                {data.name ? addThreeDots(data.name, 35) : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="order-pointer">
          <div className="pointer" />
        </div>
        {isOrderOpen && (
          <div className="order-wrapper" ref={adjustOrderCard}>
            <div className="order-actions">
              <IconButton
                aria-label="Close"
                className="order-action"
                onClick={this.handleCloseOrderContent}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="container">
              <div className="order-content row">
                <div className="col-xs-12 col-sm-9">
                  {data.name && <h2>{data.name}</h2>}
                  {user.data &&
                    user.data.company &&
                    user.data.company_admin && <OrderManagment />}
                  <div className="details row">
                    <div className="col-sm-12 col-md-6">
                      <div className="planning">
                        <h4>{translation().orders.order}</h4>
                        {data.id && (
                          <div className="detail-row">
                            {data.order_id && (
                              <span className="detail">
                                <span className="detail-icon">
                                  <ShoppingCartIcon fontSize="small" />
                                </span>{" "}
                                #{data.order_id}
                              </span>
                            )}
                          </div>
                        )}
                        {data.start && (
                          <div className="detail-row">
                            <span className="detail">
                              <span
                                className="detail-icon"
                                title={translation().orders.date}
                              >
                                <TodayIcon fontSize="small" />
                              </span>
                              {dateInTz(data.start, "localized-date")}
                            </span>
                            <span className="detail">
                              <span
                                className="detail-icon"
                                title={translation().orders.time}
                              >
                                <AccessTimeIcon fontSize="small" />
                              </span>
                              {dateInTz(data.start, "HH:mm")}
                            </span>
                          </div>
                        )}
                        {getUserTimezone() !== timezoneCode && (
                          <div className="property-tz-warn">
                            <span className="icon-infos" />{" "}
                            <span>
                              {translation().commons.local_datetime}{" "}
                              {dateInTz(
                                data.start,
                                "localized-datetime",
                                timezoneCode
                              )}{" "}
                              ({timezoneCode})
                            </span>
                          </div>
                        )}
                        <div className="detail-row">
                          {data.duration && (
                            <span className="detail">
                              <span
                                className="detail-icon"
                                title={translation().orders.duration}
                              >
                                <TimelapseIcon fontSize="small" />
                              </span>
                              {convertMinsToHrsMins(data.duration, "h")}
                            </span>
                          )}
                          {data.order &&
                            data.order.property &&
                            data.order.property.size_formatted && (
                              <span className="detail">
                                <span
                                  className="detail-icon icon-surface"
                                  title={translation().orders.size}
                                />
                                {data.order.property.size_formatted}
                              </span>
                            )}
                        </div>
                        {data.provider_name &&
                          user.data &&
                          user.data.company &&
                          user.data.company_admin && (
                            <div className="detail-row">
                              <span className="detail">
                                <span
                                  className="detail-icon"
                                  title={translation().orders.provider_name}
                                >
                                  <WorkIcon fontSize="small" />
                                </span>{" "}
                                <span>{data.provider_name}</span>
                              </span>
                            </div>
                          )}
                        {data.amount_provider_reversion_tax_excluded_formatted &&
                          user &&
                          user.data &&
                          !user.data.company && (
                            <div className="detail-row">
                              <span className="detail">
                                <span
                                  className="detail-icon"
                                  title={translation().orders.payed}
                                >
                                  <CreditCardIcon fontSize="small" />
                                </span>{" "}
                                <span>
                                  {
                                    data.amount_provider_reversion_tax_excluded_formatted
                                  }{" "}
                                  {translation().orders.tax_excl}{" "}
                                  {data.settled
                                    ? ""
                                    : translation().orders.estimed_price}
                                </span>
                              </span>
                            </div>
                          )}
                        {data.amount_provider_reversion_tax_excluded_formatted &&
                          user &&
                          user.data &&
                          user.data.company &&
                          user.data.company_admin && (
                            <div className="detail-row">
                              <span className="detail">
                                <span
                                  className="detail-icon"
                                  title={translation().orders.payed}
                                >
                                  <CreditCardIcon fontSize="small" />
                                </span>{" "}
                                <span>
                                  {
                                    data.amount_provider_reversion_tax_excluded_formatted
                                  }{" "}
                                  {translation().orders.tax_excl}{" "}
                                  {data.settled
                                    ? ""
                                    : translation().orders.estimed_price}
                                </span>
                              </span>
                            </div>
                          )}
                      </div>
                      {data.options && data.options.length > 0 ? (
                        <div className="options">
                          <h4>{translation().orders.options}</h4>
                          {this.renderOptions(data.options)}
                        </div>
                      ) : (
                        ""
                      )}
                      {data.attributes && !isObjEmpty(data.attributes) && (
                        <div className="options product-attributes">
                          <h4>{translation().orders.attributes}</h4>
                          {objectMapToArray(
                            data.attributes,
                            (prop, attr, index) =>
                              attr.value && (
                                <div className="detail-row" key={index}>
                                  <span className="detail">
                                    <span
                                      className="detail-icon"
                                      style={{ fontSize: "inherit" }}
                                    >
                                      {attr.label}:
                                    </span>{" "}
                                    {this.renderAttributes(attr)}
                                  </span>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <h4>{translation().orders.details}</h4>
                      {data && data.order && data.order.user && (
                        <div className="detail-row">
                          <span className="detail">
                            <span
                              className="detail-icon"
                              title={translation().orders.client}
                            >
                              <PersonIcon fontSize="small" />
                            </span>{" "}
                            {data.order.user && data.order.user.first_name
                              ? data.order.user.first_name + " "
                              : ""}
                            {data.order.user && data.order.user.last_name
                              ? data.order.user.last_name
                              : ""}
                            {data.order.company &&
                            data.order.company.commercial_name
                              ? " (" + data.order.company.commercial_name + ")"
                              : ""}
                          </span>
                        </div>
                      )}
                      {data && data.property_contact_name && (
                        <div className="detail-row">
                          <span className="detail">
                            <span
                              className="detail-icon"
                              title={translation().orders.person_on_place}
                            >
                              <PersonPinCircleIcon fontSize="small" />
                            </span>{" "}
                            {data.property_contact_name}
                            {data.property_contact_linking_phone ? (
                              <span className="detail-contact-phone">
                                <a
                                  href={
                                    "tel:" + data.property_contact_linking_phone
                                  }
                                >
                                  ({data.property_contact_linking_phone})
                                </a>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      )}
                      <div className="detail-row">
                        {data && data.property_access_details && (
                          <span className="detail">
                            <span
                              className="detail-icon"
                              title={translation().orders.access_details}
                            >
                              <VpnKeyIcon fontSize="small" />
                            </span>{" "}
                            {data.property_access_details}
                          </span>
                        )}
                      </div>
                      <div className="detail-row">
                        {data && data.property_contact_comment && (
                          <span className="detail" style={{ marginTop: 12 }}>
                            <div
                              className="detail-icon"
                              title={translation().orders.comments}
                            >
                              <CommentIcon fontSize="small" />
                            </div>
                            <p className="detail-comments">
                              {data.property_contact_comment}
                            </p>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3 margin-mobile col-btn-dl">
                  {data.client_rating || ratingValue ? (
                    <div className="client-rating">
                      <Rating
                        initialRating={data.client_rating || ratingValue}
                        readonly={true}
                        emptySymbol={
                          <span className="icon-etoilepleine rate-icon" />
                        }
                        fullSymbol={
                          <span className="icon-etoilepleine rate-icon filled" />
                        }
                      />
                    </div>
                  ) : data.delivery_status &&
                    data.delivery_status.is_received &&
                    provider.data &&
                    provider.data.id &&
                    parseInt(provider.data.id, 10) ===
                      parseInt(data.provider_id, 10) ? (
                    <div className="client-rating">
                      <span
                        onClick={this.handleOpenRateModal}
                        className="trigger-rating"
                      >
                        {translation().orders.rating.trigger_rating}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.uploadable &&
                  provider.data &&
                  provider.data.id &&
                  parseInt(provider.data.id, 10) ===
                    parseInt(data.provider_id, 10) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        !this.checkIsOrderReadyToSend() ||
                        uploadAction.isLoading
                      }
                      onClick={this.handleUploadOrder}
                    >
                      <i className="material-icons" style={{ marginRight: 5 }}>
                        unarchive
                      </i>
                      <span>{translation().orders.send}</span>
                    </Button>
                  ) : (
                    false
                  )}
                </div>
                <div className="clearfix" />
                <MediaGroups
                  getProductMediaGroups={getProductMediaGroups}
                  clearMediaGroups={clearMediaGroups}
                  mediaGroups={mediaGroups}
                  product={data}
                  mode={mode}
                  providerId={provider.data.id}
                />
                <div className="col-xs-12 col-btn-dl">
                  {data.uploadable &&
                  provider.data &&
                  provider.data.id &&
                  parseInt(provider.data.id, 10) ===
                    parseInt(data.provider_id, 10) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        !this.checkIsOrderReadyToSend() ||
                        uploadAction.isLoading
                      }
                      onClick={this.handleUploadOrder}
                    >
                      <i className="material-icons" style={{ marginRight: 5 }}>
                        unarchive
                      </i>
                      <span>{translation().orders.send}</span>
                    </Button>
                  ) : (
                    false
                  )}
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        )}
        <Dialog
          open={isRatingOpen}
          onClose={this.handleCloseRateModal}
          aria-labelledby="client-rating-dialog-title"
          disableBackdropClick={rating.isLoading}
          maxWidth="lg"
          PaperProps={{
            className: "client-rate-dialog",
          }}
        >
          <DialogTitle
            style={{ textAlign: "center" }}
            id="client-rating-dialog-title"
          >
            {translation().orders.rating.trigger_rating}
          </DialogTitle>
          <DialogContent>
            <ClientRating
              product={data}
              propertyAddress={data.order.property.address}
              onClose={this.handleCloseRateModal}
              onSubmit={this.handleSubmitRating}
              isLoading={rating.isLoading}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={isRatingAfterUploadOpen}
          onClose={this.handleCloseRateAfterUploadModal}
          aria-labelledby="client-rating-upload-dialog-title"
          disableBackdropClick={rating.isLoading}
          maxWidth="lg"
          PaperProps={{
            className: "client-rate-dialog",
          }}
        >
          <DialogTitle
            style={{ textAlign: "center" }}
            id="client-rating-upload-dialog-title"
          >
            {translation().orders.rating.trigger_rating}
          </DialogTitle>
          <DialogContent>
            <ClientRating
              product={data}
              propertyAddress={data.order.property.address}
              onClose={this.handleCloseRateAfterUploadModal}
              onSubmit={this.handleSubmitRatingAfterUpload}
              isLoading={rating.isLoading}
            />
          </DialogContent>
        </Dialog>
      </li>
    );
  }
}

OrderCard.defautProps = {
  mode: "providers",
};

OrderCard.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  data: PropTypes.shape(),
  isLoading: PropTypes.bool,
  getOrders: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  mediaGroups: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool,
  }),
  orderOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOpenOrder: PropTypes.func.isRequired,
  onCloseOrder: PropTypes.func.isRequired,
  orderProductMedias: PropTypes.shape(),
  uploadAction: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
  uploadOrder: PropTypes.func.isRequired,
  tabOpen: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
  rating: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
  rateClient: PropTypes.func.isRequired,
  provider: PropTypes.shape().isRequired,
};

export default OrderCard;
