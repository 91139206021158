const initialState = {
  details: {
    isLoading: false,
    data: null,
    error: null
  },
  dispatch: {
    isLoading: false,
    error: null,
    isSuccess: false
  }
}

const requestDispatchReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case "CLEAR_DISPATCH_REDUCER":
      return initialState;
    case "ACCEPT_REQUEST_DISPATCH":
      return {
        ...state,
        dispatch: {
          ...initialState.details,
          isLoading: true
        }
      }
    case "ACCEPT_REQUEST_DISPATCH_SUCCESS":
      return {
        ...state,
        dispatch: {
          isLoading: false,
          error: null,
          isSuccess: true
        }
      }
    case "ACCEPT_REQUEST_DISPATCH_FAILURE":
      return {
        ...state,
        dispatch: {
          isLoading: false,
          error: action.error,
          isSuccess: false
        }
      }
    case "GET_DISPATCH_DETAILS":
      return {
        ...state,
        details: {
          ...initialState.details,
          isLoading: true
        }
      }
    case "GET_DISPATCH_DETAILS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          error: null,
          data: action.data
        }
      }
    case "GET_DISPATCH_DETAILS_FAILURE":
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          error: action.error
        }
      }
    default:
      return state
  }
}

export default requestDispatchReducer;
